import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useForm from 'Shared/Hooks/useForm';
import ReferencialContext from 'Shared/ReferencialContext';
import { Autocomplete, TextField } from '@mui/material';
import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PageActions from './PageActions';
import Trace from './Trace';
import { setLoadingTrace } from '../traceabilitySlice';
import { getClientsChange } from '../TraceabilityServices';
import useClientsRef from './useClientsRef';
import { PopperMy } from '../constants';

const ClientTrace = () => {
  const [isLast, setIsLast] = useState(false);
  const [page, setPage] = useState(0);
  const [traces, setTraces] = useState([]);
  const [clientsRef, setClientsRef] = useState([]);
  const { findClients } = useClientsRef();
  const dispatch = useDispatch();
  const { values, onChange } = useForm({ client: null });
  const {
    state: {
      referencial: { clients },
    },
  } = useContext(ReferencialContext);

  useEffect(async () => {
    try {
      dispatch(setLoadingTrace());
      const { changes, page: currentPage } = await getClientsChange(
        { page, search: values.client?.id },
      );
      if (changes.length === 0) {
        setIsLast(true);
      } else {
        setIsLast(false);
        setTraces(changes);
        setPage(currentPage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingTrace());
    }
  }, [page, values.client]);

  useEffect(() => {
    setClientsRef(findClients(traces));
  }, [traces]);

  const onChangeSelect = (e, value) => {
    onChange({ target: { name: 'client', value } });
  };
  return (
    <>
      <Autocomplete
        PopperComponent={PopperMy}
        options={clients}
        disablePortal
        onChange={onChangeSelect}
        value={values.client}
        getOptionLabel={(option) => (option.code ? `${option.code}: ${option.name.slice(0, 15)}...` : '')}
        filterOptions={(options, v) => options.filter(
          (option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase())
            || option.code?.toLowerCase().startsWith(v.inputValue.toLowerCase()),
        )}
        size="small"
        ListboxComponent={ListboxComponent}
        onClick={(e) => { e.stopPropagation(); }}
        autoHighlight
        renderInput={(params) => (
          <TextField
            {...params}
            label="Rechercher"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
        popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
      />
      <PageActions page={page} setPage={setPage} isLast={isLast} />
      {traces.map((trace) => (
        <Trace
          key={`key-trace-card-${trace.commitMetadata.id}`}
          changes={trace.changes}
          clientsRef={clientsRef}
          commitMetadata={trace.commitMetadata}
          isClient
        />
      ))}
    </>
  );
};

export default ClientTrace;
