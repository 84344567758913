/* eslint-disable max-len */
import {
  Box, Grid, Popper, TextField, Autocomplete,
} from '@mui/material';
import React, { useContext, useMemo } from 'react';
import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import ReferencialContext from 'Shared/ReferencialContext';
import DatePicker from '@mui/lab/DatePicker';
import { TYPES_COMMANDES } from 'Shared/constants/order';
import useStyles from './style';
import { mapOrderStatus } from '../SageOrders/constants';

const PopperMy = (props) => (
  <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />
);
const STATUSLIST = Object.keys(mapOrderStatus).map((k) => ({
  id: k,
  value: mapOrderStatus[k].label,
}));
const Filters = ({
  values, handleInputChange, product, type = false,
}) => {
  const {
    state: {
      referencial: {
        clients = [], expeditions, paymentModes, products,
      },
    },
  } = useContext(ReferencialContext);
  const cyties = useMemo(() => clients.map((c) => c.city).unique(), [clients]);
  const classes = useStyles();
  return (
    <Grid
      container
      style={{
        padding: 10,
        background: '#E9EBED 0% 0% no-repeat padding-box',
        bordeRradius: '0px 0px 12px 12px',
        opacity: 1,
        marginBottom: 16,
      }}
      spacing={1}
    >
      <Grid item xs={2}>
        <Autocomplete
          PopperComponent={PopperMy}
          autoSelect
          classes={{ root: classes.filter }}
          options={clients || []}
          onChange={handleInputChange('client')}
          value={values.client}
          size="small"
          autoHighlight
          fullWidth
          getOptionLabel={(option) => `${option.code} : ${option.name}`}
          filterOptions={(options, v) => options.filter(
            (option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase())
                || option.code?.toLowerCase().includes(v.inputValue.toLowerCase()),
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Client"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }} // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                ...params.InputProps,
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          PopperComponent={PopperMy}
          autoSelect
          classes={{ root: classes.filter }}
          options={expeditions || []}
          onChange={handleInputChange('expedition')}
          value={values.expedition}
          size="small"
          autoHighlight
          fullWidth
          getOptionLabel={(option) => option.name}
          filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Expedition"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                ...params.InputProps,
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
        />
      </Grid>
      <Grid xs={2} item>
        <Autocomplete
          PopperComponent={PopperMy}
          autoSelect
          classes={{ root: classes.filter }}
          options={paymentModes || []}
          onChange={handleInputChange('paymentMode')}
          value={values.paymentMode}
          size="small"
          autoHighlight
          fullWidth
          getOptionLabel={(option) => option.name}
          filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Mode de paiment"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                ...params.InputProps,
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          PopperComponent={PopperMy}
          autoSelect
          classes={{ root: classes.filter }}
          options={cyties || []}
          onChange={handleInputChange('city')}
          value={values.city}
          size="small"
          autoHighlight
          fullWidth
          getOptionLabel={(option) => option}
          filterOptions={(options, v) => options.filter((option) => option.toLowerCase().includes(v.inputValue.toLowerCase()))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ville"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                ...params.InputProps,
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
        />
      </Grid>
      {type && (
        <Grid item xs={2}>
          <Autocomplete
            PopperComponent={PopperMy}
            autoSelect
            classes={{ root: classes.filter }}
            options={TYPES_COMMANDES || []}
            onChange={handleInputChange('type')}
            value={values.type}
            size="small"
            autoHighlight
            fullWidth
            getOptionLabel={(option) => option.label}
            filterOptions={(options, v) => options.filter((option) => option.label?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type de pièce"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
          />
        </Grid>
      )}
      {product && (
        <Grid item xs={2}>
          <Autocomplete
            autoSelect
            classes={{ root: classes.filter }}
            options={STATUSLIST || []}
            onChange={handleInputChange('status')}
            value={values.status}
            size="small"
            autoHighlight
            fullWidth
            getOptionLabel={(option) => option.value}
            filterOptions={(options, v) => options.filter((option) => option.value.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Statut"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }} // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
          />
        </Grid>
      )}
      {!product && (
        <Grid item xs={2}>
          <Autocomplete
            autoSelect
            classes={{ root: classes.filter }}
            options={products || []}
            onChange={handleInputChange('product')}
            value={values.product}
            size="small"
            autoHighlight
            fullWidth
            getOptionLabel={(option) => option.reference}
            ListboxComponent={ListboxComponent}
            filterOptions={(options, v) => options.filter((option) => option.reference.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Produit"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={2} style={{ display: 'flex', flexGrow: 1, columnGap: 5 }}>
        <Box style={{ flexGrow: 1 }}>
          <DatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            id="startDate"
            label="Début"
            className={classes.date}
            value={values.startDate || null}
            onChange={handleInputChange('startDate')}
            fullWidth
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputProps={{
              disableUnderline: true,
              classes: { formControl: classes.formControlDate },
            }}
            InputLabelProps={{
              classes: { root: classes.labelDate },
            }}
          />
        </Box>
        <Box style={{ flexGrow: 1 }}>
          <DatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            id="endDate"
            label="Fin"
            fullWidth
            className={classes.date}
            value={values.endDate || null}
            onChange={handleInputChange('endDate')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputProps={{
              disableUnderline: true,
              classes: { formControl: classes.formControlDate },
            }}
            InputLabelProps={{
              classes: { root: classes.labelDate },
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Filters;
