import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext, { setReferencial } from 'Shared/ReferencialContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { SALES_CONDITIONS } from '../constants';
import {
  defaultMessage,
  unlinkProductSettings,
  updateProductSettings,
} from '../ProductSettingsServices';
import RelatedProduct from './RelatedProduct';

const Setting = () => {
  const { state: product } = useLocation();
  const [open, setOpen] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const history = useHistory();
  const {
    dispatch,
    state: { referencial },
  } = useContext(ReferencialContext);
  const [values, setValues] = useState({
    reference: product?.reference,
    quantityLimit: product?.quantityLimit,
    salesCondition: product?.salesCondition,
    gameQuantity: product?.gameQuantity,
  });
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  useEffect(() => {
    setSelectedProduct(referencial.products?.find((p) => p.reference === product?.reference));
  }, [referencial.products, setSelectedProduct, product]);
  const updateSettings = useCallback(async () => {
    setLoading(true);
    try {
      await updateProductSettings({ reference: product.reference, ...values });
      toast.success('Les paramétres ont été bien enregistrés');
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
      history.push('/products-settings');
    }
  }, [values, setLoading]);
  const unlinkProduct = async () => {
    setLoading(true);
    try {
      await unlinkProductSettings(product?.reference);
      toast.success('la liason à été bien supprimée');
      const { products } = referencial;
      const altredProducts = products.map((p) => {
        const pr = { ...p };
        if (p.reference === product?.reference) {
          pr.linkedReference = null;
        }
        return pr;
      });
      dispatch(setReferencial({ ...referencial, products: altredProducts }));
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      {open && (
      <RelatedProduct
        handleClose={handleClose}
        reference={product?.reference}
        linkedReference={selectedProduct?.linkedReference}
      />
      )}
      <Paper style={{ margin: '20px 30px', padding: 20 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="quantityLimit"
              value={values.quantityLimit}
              label="La quantité limite"
              variant="outlined"
              onChange={handleChange}
              fullWidth
              size="small"
              type="number"
              inputProps={{ min: 0 }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="demo-simple-select-outlined-label">
                le conditionnement de vente de produit
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={values.salesCondition}
                onChange={handleChange}
                label="le conditionnement de vente de produit"
                name="salesCondition"
              >
                <MenuItem value={null}>
                  <em>None</em>
                </MenuItem>
                {SALES_CONDITIONS.map((s) => (
                  <MenuItem value={s.value}>{s.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {values.salesCondition === 'GAME' && (
          <Grid item xs={6}>
            <TextField
              name="gameQuantity"
              value={values.gameQuantity}
              label="quantité du jeu"
              variant="outlined"
              onChange={handleChange}
              fullWidth
              size="small"
              type="number"
              inputProps={{ min: 0 }}
            />
          </Grid>
          )}
          <Grid item xs={6}>
            <Box style={{ display: 'flex', columnGap: 20, width: '100%' }}>
              <Button variant="contained" onClick={handleOpen} style={{ flexGrow: 1 }}>
                Produit lié
              </Button>
              {product?.linkedReference && (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
                style={{ flexGrow: 1 }}
                onClick={unlinkProduct}
              >
                Supprimer la liason
              </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} container direction="row-reverse">
            <Grid item>
              <Button variant="contained" color="primary" onClick={updateSettings}>
                Enregistrer
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Setting;
