/* eslint-disable max-len */
import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { TRACE_TYPES } from '../constants';
import useFindObjectName from './useFindObjectName';

const Diff = ({ change }) => {
  if (change.left === change.right) return null;

  return (
    <Typography sx={{ font: 'normal bold 14px Montserrat', color: '#38485C' }}>
      {change.changeType === 'ListChange'
        ? 'Lignes ajouter'
        : `${change.property}: ${change.left} > ${change.right}`}
    </Typography>
  );
};

const getParentName = (parents, id) => parents
  ?.find((parent) => parent.details?.find((detail) => detail.id === id));

const DetailHeader = ({ globalId, parents = [] }) => {
  const parent = getParentName(parents, globalId.cdoId);
  const productRef = parent?.details?.find((detail) => detail.id === globalId.cdoId);
  return (
    parent
      ? (
        <Typography sx={{ font: 'normal bold 15px Montserrat', color: '#38485C' }}>
          {`${globalId.entity === 'ChildEntityProposition' ? 'Détails Proposition N°: ' : 'Détails Ops Achat N°'}: ${parent?.name}, Produit: ${productRef?.productReference}`}
        </Typography>
      )
      : (
        <Typography sx={{ font: 'normal bold 15px Montserrat', color: '#38485C' }}>
          {`Ligne supprimé avec l'identifiant: ${globalId.cdoId}`}
        </Typography>
      )
  );
};

const Trace = ({
  changes, commitMetadata, productsRef, clientsRef, parents = [], isDoc = false,
  isClient = false,
  lines,
}) => {
  const { name } = useFindObjectName({
    productsRef, isDoc, isClient, objectId: changes[0].globalId.cdoId, parents, clientsRef,
  });
  const isNewObject = changes[0].changeType === 'NewObject';
  const isRemove = isDoc ? changes[0].changeType === 'ObjectRemoved'
    : changes.find((change) => change.property === 'active' && !change.right);
  const TYPE_KEY = isRemove ? 'removeObject' : changes[0].changeType;
  const isDetailOps = isDoc ? ['ChildEntityOpsAchat', 'ChildEntityProposition'].includes(changes[0].globalId.entity) : false;
  console.log(changes[0].globalId.cdoId, lines);
  // eslint-disable-next-line eqeqeq
  const property = useMemo(() => lines?.find((line) => line.id == changes[0].globalId.cdoId)?.property,
    [lines, changes]);
  return (
    <Box sx={{
      background: '#FFF',
      boxShadow: '0px 20px 80px rgba(0, 0, 0, 0.07)',
      borderRadius: '12px',
      p: 2.2,
      mt: 1,
      mb: 1,
    }}
    >
      <Typography sx={{ font: 'normal bold 18px Montserrat', color: '#38485C' }}>
        {`${TRACE_TYPES[TYPE_KEY] || changes[0].changeType} : ${isDetailOps ? 'Ligne' : (property || name)}`}
      </Typography>
      {isDetailOps && <DetailHeader globalId={changes[0].globalId} parents={parents} />}
      <Box sx={{ mt: 1, mb: 1, overflowWrap: 'break-word' }}>
        {(!isNewObject && !isRemove) ? changes.map((change) => (
          <Diff change={change} key={change.commitMetadata.id} />
        ))
          : null}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ font: 'normal medium 13px Montserrat', color: '#007BC6' }}>
          {commitMetadata.author === 'anonymousUser' ? 'Ops Syncronisation.' : commitMetadata.author}
        </Typography>
        <Typography sx={{ font: 'normal medium 11px Montserrat', color: '#007BC6' }}>
          {format(new Date(commitMetadata.commitDate), 'dd/MM/yyyy hh:mm')}
        </Typography>
      </Box>

    </Box>
  );
};

export default Trace;
