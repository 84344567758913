// eslint-disable-next-line import/prefer-default-export
export const Fields = [
  {
    type: 'text',
    id: 'code',
    label: 'Code client',
    disabled: true,
  },
  {
    type: 'text',
    id: 'name',
    label: 'Intitulé client',
  },
  {
    type: 'autocomplete',
    id: 'zone',
    label: 'Zone',
    listName: 'areas',
    filter: (option, value) => option
      ?.toLowerCase().includes(value),
    optionLabel: (option) => option,

  },
  {
    type: 'autocomplete',
    id: 'city',
    label: 'Ville',
    listName: 'cities',
    filter: (option, value) => option
      ?.toLowerCase().includes(value),
    optionLabel: (option) => option,
  },
  {
    type: 'text',
    id: 'address',
    label: 'Adresse',
  },
  {
    type: 'text',
    id: 'postalCode',
    label: 'Code postal',
  },
  {
    type: 'text',
    id: 'phone',
    label: 'Téléphone',
    disabled: true,
  },
];
