import {
  Box, CardMedia, Chip, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { toast } from 'react-toastify';
import OptionIcon from 'Images/option.svg';
import { TYPES_COMMANDES } from '../constants';

export const DOCUMENTS = {
  3: 'Bon de livraison',
  4: 'Bon de retour',
  6: 'Facture',
  7: 'Facture de retour',
};
export const downloadFromLink = (url, filename, onSucces = () => {}) => (event) => {
  event.stopPropagation();
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      onSucces();
    })
    .catch(() => {
      toast.error('Erreur Système');
    });
};
export const mapOrderStatus = {
  NON_ASSIGNEE: { label: 'Non assignée', class: 'NotAssignedStatus' },
  EN_ATTENTE_PICKAGE: { label: 'En attente', class: 'awaitingPicking' },
  EN_ATTENTE_VALIDATION: { label: 'Validation', class: 'awaitingValidation' },
  EN_COURS_PICKAGE: { label: 'En cours', class: 'pickingInprogress' },
  CLOTUREE: { label: 'Clôturée', class: 'closed' },
  BL_GENERE: { label: 'BL généré', class: 'invoiced' },
  MANQUE: { label: 'Manque', class: 'manque' },
  STOCK: { label: 'Stock', class: 'stock' },
  IMPRIME: { label: 'Imprimé', class: 'closed' },
  PIQUE: { label: 'Piqué', class: 'closed' },
};
const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};

const getColorFromRowValue = (row) => {
  if (row.noColor) return {};
  if (row.orange) return { color: '#FFA500' };
  if (row.red) return { color: 'red' };
  if (row.green) return { color: 'green' };
  return {};
};
export const columns = [
  {
    id: 'reference',
    label: 'Reference',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    id: 'commercial',
    label: 'Commercial',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    id: 'date',
    label: 'Date',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ ...defaultColumnStyle, mr: '9px' }} display="inline">
        {format(new Date(value), 'dd/MM/yyyy hh:mm:ss')}
      </Typography>
    ),
  },
  {
    id: 'client',
    label: 'Client',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'type',
    label: 'Type',
    style: TitleStyle,
    render: ({ value }) => value && (
    <Chip
      label={TYPES_COMMANDES.find((t) => t.id === value)?.label}
      style={TYPES_COMMANDES.find((t) => t.id === value)?.style}
    />
    ),
  },
  {
    id: 'paymentMode',
    label: 'Moyen de paiment',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'expedition',
    label: 'Expedition',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'city',
    label: 'Ville',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'amountTTC',
    label: 'Montant TTC',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'amountHT',
    label: 'Montant HT',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'statusP',
    label: 'Statut du règlement',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
];
export const columnsSAV = [
  {
    id: 'date',
    label: 'Date et heure',
    style: TitleStyle,
    render: ({ value, row }) => (
      <Typography
        style={{ ...defaultColumnStyle, mr: '9px', ...getColorFromRowValue(row) }}
        display="inline"
      >
        {format(new Date(value), 'dd/MM/yyyy hh:mm:ss')}
      </Typography>
    ),
    order: 'date',
  },
  {
    id: 'type',
    label: 'Type de la pièce',
    style: TitleStyle,
    render: ({ value }) => value && (
    <Chip
      label={(
        <div style={{ font: 'normal normal 600 14px/18px Montserrat' }}>
          {TYPES_COMMANDES.find((t) => t.id === value)?.label}
        </div>
        )}
      style={TYPES_COMMANDES.find((t) => t.id === value)?.style}
    />
    ),
    order: 'type',
  },
  {
    id: 'statusO',
    label: 'Statut OPS',
    style: TitleStyle,
    render: ({ value, row }) => (
      <Typography
        className={row.statusClass}
        style={{ ...defaultColumnStyle, background: 'transparent' }}
      >
        {value || '-'}
      </Typography>
    ),
    order: 'orderStatus.status',
  },
  {
    id: 'reference',
    label: 'Numéro de la pièce',
    style: TitleStyle,
    render: ({ value, row }) => (
      <Typography
        style={{
          ...defaultColumnStyle,
          ...getColorFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
    order: 'reference',
  },
  {
    id: 'client',
    label: 'Code et nom du client',
    style: TitleStyle,
    render: ({ value, row }) => (
      <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row) }}>
        {value}
      </Typography>
    ),
    order: 'client.name',
  },
  {
    id: 'expedition',
    label: 'Mode d’expédition',
    style: TitleStyle,
    render: ({ value, row }) => (
      <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row) }}>
        {value}
      </Typography>
    ),
    order: 'expedition.name',
  },
  {
    id: 'expeditionNum',
    label: 'Numéro d’expédition',
    style: TitleStyle,
    render: ({ value, row }) => (
      <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row) }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'paymentMode',
    label: 'Moyen de paiment',
    style: TitleStyle,
    render: ({ value, row }) => (
      <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row) }}>
        {value}
      </Typography>
    ),
    order: 'paymentMode.name',
  },
  {
    id: 'commercial',
    label: 'Commercial',
    style: TitleStyle,
    render: ({ value, row }) => (
      <Typography
        style={{
          color: '#333D66',
          fontSize: '16px',
          fontWeight: 500,
          ...defaultColumnStyle,
          ...getColorFromRowValue(row),
        }}
      >
        {value}
      </Typography>
    ),
  },
  {
    id: 'amountTTC',
    label: 'Montant TTC',
    style: TitleStyle,
    render: ({ value, row }) => (
      <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row) }}>
        {value}
      </Typography>
    ),
  },
  {
    id: 'restAmount',
    label: 'le reste non réglé',
    style: TitleStyle,
    render: ({ row }) => (
      <Typography style={{ ...defaultColumnStyle, ...getColorFromRowValue(row) }}>
        {row.amountTTC - row.paidAmount}
      </Typography>
    ),
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    render: ({ row }) => (
      <Box
        style={{
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 30,
          width: 30,
          padding: 5,
          cursor: 'pointer',
        }}
        onClick={row.openPopOver(row)}
      >
        <CardMedia component="img" src={OptionIcon} style={{ width: 20 }} />
      </Box>
    ),
  },
];
export const Fields = [
  {
    type: 'autocomplete',
    id: 'commercial',
    label: 'Nom Commercial',
    listName: 'sales',
    filter: (option, value) => option.name?.toLowerCase().includes(value),
    optionLabel: (option) => option.name,
  },
  {
    type: 'number',
    id: 'packageNumber',
    label: 'Nombre de colis',
  },
  {
    type: 'text',
    id: 'declarationNum',
    label: 'Numero de declaration d’expedition',
  },
  {
    type: 'autocomplete',
    id: 'expedition',
    label: 'Mode d’expedition',
    listName: 'expeditions',
    filter: (option, value) => option.name?.toLowerCase().includes(value),
    optionLabel: (option) => option.name,
  },
  {
    type: 'autocomplete',
    id: 'paymentMode',
    label: 'Mode de paiement',
    listName: 'paymentModes',
    filter: (option, value) => option.name?.toLowerCase().includes(value),
    optionLabel: (option) => option.name,
  },
  {
    type: 'text',
    id: 'packedBy',
    label: 'Emballé par',
  },
];
