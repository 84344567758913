import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import * as React from 'react';

export default function SelectSmall({
  name, label, value, onChange, options, large, ...rest
}) {
  // const [age, setAge] = React.useState('');

  /* const handleChange = (event) => {
    onChange(event.target.value);
  }; */
  // MuiInputBase-root-MuiOutlinedInput
  return (
    <FormControl
      style={{
        minWidth: 200,
      }}
      size="small"
      variant="outlined"
    >
      <InputLabel id="demo-select-small">{label}</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={value}
        label={label}
        name={name}
        onChange={onChange}
        // style={{ height: '42px', boxShadow: '0 2px 4px 0 rgba(0,0,0,0.17)' }}
        {...rest}
      >
        {options?.map((option) => (
          <MenuItem value={option.value || option.id || option}>
            {option.label || option.name || option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
