import React from 'react';
import { Box, Typography } from '@mui/material';
import ButtonApp from 'Shared/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useStyles from './style';

const Supplier = ({
  id, name, reference, onRemoveSupplier, onEditSupplier,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box>
        <Typography className={classes.name}>{name}</Typography>
        <Box className={classes.subDetails}>
          <Typography className={classes.code}>{`${reference.reference || ' '} - `}</Typography>
          <Typography className={classes.price}>{`${reference.prix} ${reference.devise}`}</Typography>
        </Box>
      </Box>
      <Box sx={{ alignSelf: 'center' }}>
        <ButtonApp
          startIcon={<EditIcon />}
          label="Modifier"
          onClick={onEditSupplier(id)}
          style={{
            background: 'transparent',
            boxShadow: 'none',
            color: '#38485C',
            padding: 0,
            borderRadius: 0,
            marginRight: 5,
          }}
        />
        <ButtonApp
          startIcon={<DeleteIcon />}
          label="Supprimer"
          onClick={onRemoveSupplier(id)}
          style={{
            background: 'transparent',
            boxShadow: 'none',
            color: '#F86060',
            padding: 0,
            borderRadius: 0,
            paddingLeft: 5,
            borderLeft: '1px solid #38485c1c',
          }}
        />
      </Box>
    </Box>
  );
};
const Suppliers = ({
  suppliers, onHandleRemoveSupplier, onHandleEditSupplier, isSearch,
}) => (
  <>
    {suppliers.map((supplier) => (
      <Supplier
        id={supplier.id}
        key={supplier.code}
        name={supplier.name}
        reference={supplier.referenceFournisseurs}
        onRemoveSupplier={onHandleRemoveSupplier}
        onEditSupplier={onHandleEditSupplier}
        isSearch={isSearch}
      />
    ))}
  </>
);

export default Suppliers;
