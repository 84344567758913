/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  loading: false,
  userRole: '',
};

export const traceabilitySlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setOpenTrace: (state) => {
      state.isOpen = !state.isOpen;
    },
    setLoadingTrace: (state) => {
      state.loading = !state.loading;
    },
    setuserRole: (state, action) => {
      state.userRole = action.payload;
    },
  },
});

export const { setOpenTrace, setLoadingTrace, setuserRole } = traceabilitySlice.actions;

export default traceabilitySlice.reducer;
