import { TextField } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  input: {
    marginRight: 16,
    flexGrow: 1,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    width: '200px',
    border: 'none',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  notched: {
    border: 'none',
  },
}));
const Input = ({
  label, name, onChange, value, type = 'text', style = {}, ...rest
}) => {
  const classes = useStyles();
  const { InputProps = {}, ...restX } = rest || {};
  return (
    <TextField
      onChange={onChange}
      value={value}
      label={label}
      type={type}
      name={name}
      size="small"
      className={classes.input}
      style={{ maxWidth: 200, ...style }}
      InputProps={{
        ...InputProps,
        classes: { notchedOutline: classes.notched },
      }}
      InputLabelProps={{
        classes: { root: classes.label },
      }}
      {...restX}
    />
  );
};

export default Input;
