import { Box } from '@mui/material';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import RecoveryEdit from './RecoveryEdit';
import RecoveryListTabs from './RecoveryListTabs/RecoveryListTabs';

const Recovery = ({ path }) => (
  <div>
    <Box style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}>
      <Switch>
        <Route exact path={`${path}/`} component={RecoveryListTabs} />
        <Route exact path={`${path}/edit`} component={RecoveryEdit} />
      </Switch>
    </Box>
  </div>
);

export default Recovery;
