import { securePost } from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getAnnoncements = async () => {
  const { data } = await securePost('/ci/api/GetFrontProducts');
  return data;
};
export const getDashboardData = async (userId) => {
  const { data } = await securePost(`/ci/api/GetDashboardData?user_id=${userId}`);
  return data;
};
export const getObjectives = async (userId) => {
  const { data } = await securePost(`/ci/api/GetObjectives?user_id=${userId}`);
  return data;
};
