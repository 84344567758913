/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';

const base = (state) => state.traceability;

export const makeSelectOpenState = () => createSelector(
  [base], (traceability) => traceability.isOpen,
);

export const makeSelectLoaderState = () => createSelector(
  [base], (traceability) => traceability.loading,
);

export const makeSelectUserRole = () => createSelector(
  [base], (traceability) => traceability.userRole,
);
