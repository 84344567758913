import {
  Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import EnhancedTable from 'Shared/EnhancedTable';
import SearchIcon from '@mui/icons-material/Search';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { toast } from 'react-toastify';
import FullScreenDialog from 'Shared/FullScreenDialog';
import ConfirmDialog from 'Shared/ConfirmDialog';
import { columns } from './constants';
import { defaultMessage, deleteAnnouncementApi, getAnnoncementsApi } from './AnnouncementService';
import AnnoncementAdd from './AnnouncementAdd';

const Announcement = () => {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [announcements, setAnnouncements] = useState({});
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [annouceId, setAnnouceId] = React.useState(null);
  const [annouceEdit, setAnnouceEdit] = React.useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const onHandleNew = () => {
    setOpen(true);
  };
  const goBack = () => {
    if (activeStep === 1) {
      setActiveStep(0);
    } else {
      setOpen(false);
    }
  };
  const onClose = (state) => {
    setOpen(state);
    setActiveStep(0);
  };
  console.log(setAnnouceEdit);
  const getAnnoncements = useCallback(async () => {
    setLoading(true);
    try {
      const announcementList = await getAnnoncementsApi(search);
      setAnnouncements(announcementList);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setAnnouncements, setLoading, search]);
  const deleteAnnouncement = useCallback(async () => {
    try {
      setLoading(true);
      await deleteAnnouncementApi(annouceId);
      toast.success('Annonce a été bien supprimé');
      setOpenConfirm(false);
      getAnnoncements();
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setLoading, annouceId, setOpenConfirm, getAnnoncements]);
  useEffect(() => {
    getAnnoncements();
  }, [getAnnoncements]);
  const handleChange = (event) => {
    setSearch(event.target.value);
  };
  const onHandleRemoveAnnouce = (id) => () => {
    setOpenConfirm(true);
    setAnnouceId(id);
  };
  const onCloseAnnouceModal = () => {
    setOpen(false);
    getAnnoncements();
    setActiveStep(0);
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <FullScreenDialog open={open} setOpen={onClose} goBack={goBack}>
        <AnnoncementAdd
          annouceToedit={annouceEdit}
          onClose={onCloseAnnouceModal}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
        />
      </FullScreenDialog>
      {openConfirm && <ConfirmDialog title="" content="Etes-vous sûr de vouloir supprimer l`annonce?" handleClose={() => setOpenConfirm(false)} handleClick={deleteAnnouncement} />}
      <Box style={{
        display: 'flex', alignItems: 'center', paddingBottom: 25, borderBottom: '1px solid #CCCCCC',
      }}
      >
        <Typography style={{ flexGrow: 1 }}>
          Gestion des annonces:
        </Typography>
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="outlined-adornment-password">Chercher</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type="text"
            value={search}
            onChange={handleChange}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end" size="large">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
          )}
            labelWidth={70}
          />
        </FormControl>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'row-reverse', padding: '10px 0' }}>
        <Button variant="outlined" color="primary" onClick={onHandleNew}>
          Créer une nouvelle annonce
        </Button>
      </Box>
      <EnhancedTable
        rows={announcements?.content || []}
        headCells={columns(onHandleRemoveAnnouce)}
        count={announcements?.totalElements || 0}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </>
  );
};

export default Announcement;
