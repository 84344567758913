import { IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};
export const columns = (editQuantity) => [
  {
    id: 'reference',
    label: 'Reference',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'des',
    label: 'DÉSIGNATION',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'qteMin',
    label: 'QTÉ MIN',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'qteMax',
    label: 'QTÉ MAX',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    render: ({ row }) => (
      <IconButton
        style={{ background: '#FFFFFF 0% 0% no-repeat padding-box', borderRadius: 12 }}
        size="small"
        onClick={editQuantity(row)}
      >
        <EditIcon fontSize="small" style={{ color: '#000' }} />
      </IconButton>
    ),
  },
];
