import {
  Button,
  Checkbox, Chip, TextField, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  color: '#727CA2',
  fontSize: '12px',
  fontWeight: 500,
  background: '#F8F8FF',
  borderBottom: 'none',
  textTransform: 'uppercase',
};
const defaultColumnStyle = { color: '#333D66', fontSize: '14px' };
export const mapStatusRegelement = {
  NON_REGLE: { label: 'facture non réglée', color: 'default' },
  PARTIELLEMENT_REGLE: { label: 'facture partiellement réglée', color: 'secondary' },
  REGLE: { label: 'réglée', color: 'primary' },
};
export const generateColumns = (paid) => {
  const rest = !paid ? [{
    id: 'rest',
    label: 'montant reste',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  }, {
    id: 'amountEntered',
    label: 'Montant à régler',
    style: TitleStyle,
    render: ({ value, row }) => <TextField style={{ width: 150 }} type="number" disabled={!row.selected} size="small" value={value} onChange={row.handleChangeAmount} label="Montant" variant="outlined" />,
  },
  ] : [{
    id: 'amountEntered',
    label: 'Montant réglé',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  }, {
    id: 'dettach',
    label: '',
    style: TitleStyle,
    render: ({ row }) => <Button variant="contained" color="primary" onClick={row.dettach}>Détacher</Button>,
  }];
  const selected = !paid ? [{
    id: 'selected',
    label: '',
    style: TitleStyle,
    render: ({ value, row }) => (
      <Checkbox
        value={value}
        checked={value}
        color="primary"
        onChange={row.handleSelectInvoice}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    )
    ,
  }] : [];
  return [
    ...selected,
    {
      id: 'reference',
      label: 'Numero facture',
      style: TitleStyle,
      render: ({ value, row }) => (
        <>
          <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
          <Chip
            size="small"
            label={mapStatusRegelement[row.reglementStatus]?.label}
            color={mapStatusRegelement[row.reglementStatus]?.color}
          />
        </>
      ),
    },
    {
      id: 'date',
      label: 'date facture',
      style: TitleStyle,
      render: ({ value }) => (
        <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{format(new Date(value), 'dd/MM/yyyy HH:mm')}</Typography>
      ),
    },
    {
      id: 'amount',
      label: 'montant total de la facture',
      style: TitleStyle,
      render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
    },
    {
      id: 'paidAmount',
      label: 'montant réglé',
      style: TitleStyle,
      render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
    },
    ...rest,
  ];
};
