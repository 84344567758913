import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import {
  defaultMessage,
  editProductreferentialQuantities,
} from 'Components/ProductSettings/ProductSettingsServices';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import useStyles from './styles';

const EditProductQuantity = ({ product, handleClose }) => {
  const classes = useStyles();
  const [values, setValues] = useState({ qteMin: product.qteMin, qteMax: product.qteMax });
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const editReferentialQuantities = useCallback(async () => {
    setLoading(true);
    try {
      await editProductreferentialQuantities({
        id: product?.productId,
        qteMin: values.qteMin,
        qteMax: values.qteMax,
      });
      handleClose();
      toast.success('Produit paramétré avec succès');
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setLoading, values]);
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Dialog
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        open
        classes={{ scrollPaper: classes.scrollPaper }}
        PaperProps={{
          style: {
            background: '#F9FAFC 0% 0% no-repeat padding-box',
            border: '1px solid #C5C5C5',
            borderRadius: 12,
            top: 50,
          },
        }}
        BackdropProps={{
          style: {
            background: '#245283 0% 0% no-repeat padding-box',
            boxShadow: '0px 12px 26px #0000000D',
            opacity: 0.67,
          },
        }}
      >
        <DialogContent>
          <Typography
            style={{
              font: 'normal normal 600 32px/39px Montserrat',
              color: '#000000',
              textTransform: 'capitalize',
            }}
          >
            {product?.des}
          </Typography>
          <Typography
            style={{
              font: 'normal normal 600 18px/22px Montserrat',
              color: '#007BC6',
              textTransform: 'uppercase',
            }}
          >
            RÉF:
            {product?.reference}
          </Typography>
          <Grid container spacing={2} style={{ marginTop: 46, marginBottom: 46 }}>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel className={classes.label}>QTE min</InputLabel>
                <OutlinedInput
                  value={values.qteMin}
                  name="qteMin"
                  onChange={handleChange}
                  type="number"
                  labelWidth={60}
                  classes={{
                    root: classes.rootInput,
                    input: classes.input,
                    notchedOutline: classes.notchedOutline,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel className={classes.label}>QTE max</InputLabel>
                <OutlinedInput
                  value={values.qteMax}
                  name="qteMax"
                  type="number"
                  onChange={handleChange}
                  labelWidth={60}
                  classes={{
                    root: classes.rootInput,
                    input: classes.input,
                    notchedOutline: classes.notchedOutline,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
          <Button
            onClick={editReferentialQuantities}
            color="primary"
            autoFocus
            variant="contained"
            endIcon={<SaveIcon />}
            style={{
              background: 'transparent linear-gradient(248deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
              boxShadow: '0px 4px 10px #108CD650',
              borderRadius: 12,
            }}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditProductQuantity;
