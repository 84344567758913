import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getVehiculesByArticle } from 'Components/ExternSearch/ExternSearchServices';

import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import PaginationActions from 'Components/ExternSearch/PaginationActions';
import NoEquivalent from '../NoEquivalent';
import VehiculeCard from './VehiculeCard';

const WIDTH_CARD = 219;
const useStyles = makeStyles(() => ({
  root: { justifyContent: 'flex-end' },
  nextButtonEnabled: {
    width: 46,
    height: 46,
    minWidth: 46,
    boxSizing: 'border-box',
    background:
      'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 10px #108CD650',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
  },
  nextButtonDisabled: {
    width: 46,
    height: 46,
    minWidth: 46,
    boxSizing: 'border-box',
    background: '#D7DFE4 0% 0% no-repeat padding-box',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#286183',
  },
  pageable: {
    margin: '0 18px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const TecDocCarsProduct = ({ productRefTecDoc }) => {
  const [cars, setCars] = useState({ content: [], totalElements: 0 });
  const [loader, setLoader] = useState(false);
  const [width, setWidth] = useState(0);
  const [page, setPage] = useState(0);
  const [indexCard, setIndexCard] = useState(0);
  const ref = useRef(null);
  const classes = useStyles();
  useEffect(async () => {
    if (!productRefTecDoc) {
      return;
    }
    try {
      setLoader(true);
      const carx = await getVehiculesByArticle(productRefTecDoc, page);
      setCars({ ...carx, content: [...cars.content, ...carx.content] });
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  }, [productRefTecDoc, page]);

  useLayoutEffect(() => {
    setWidth(ref?.current?.clientWidth || 0);
  }, [cars]);

  useEffect(() => {
    function handleWindowResize() {
      setWidth(ref.current.clientWidth || 0);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const cardPerSlide = parseInt(width / WIDTH_CARD, 10) || 3;

  const goStep = (step) => () => {
    if (loader) {
      return;
    }

    const targetIndexEnd = (cardPerSlide + indexCard + step);
    if (targetIndexEnd < cars.totalElements && targetIndexEnd >= cars.content.length) {
      setPage(page + 1);
    }
    setIndexCard(indexCard + step);
  };

  if (!productRefTecDoc || (!loader && cars.totalElements === 0)) {
    return (
      <Box style={{ marginTop: '80px', marginBottom: '80px' }}>
        <NoEquivalent message="Pas de véhicule compatible" />
      </Box>
    );
  }

  return (
    <>
      <Box className={classes.pageable}>
        {cars.totalElements === 0 ? null : `${indexCard + 1} - ${cardPerSlide + indexCard} / ${cars.totalElements}`}
        <PaginationActions
          indexCard={indexCard}
          classes={classes}
          prevElem={goStep(-1)}
          nextElem={goStep(1)}
          cardPerSlide={cardPerSlide}
          size={cars.totalElements}
        />
      </Box>
      <Box ref={ref} style={{ display: 'flex', position: 'relative', minHeight: '160px' }}>
        {loader ? <GlobalLoader style={{ height: '100%', position: 'absolute' }} /> : null}
        {cars.content
          .slice(indexCard, cardPerSlide + indexCard)
          .map((car) => (<VehiculeCard vehicule={car} key={`${car.manuId}-${car.carId}`} />))}
      </Box>
    </>
  );
};

export default TecDocCarsProduct;
