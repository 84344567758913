import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Box, Typography } from '@mui/material';
import RelatedProduct from 'Components/Catalog/Products/RelatedProduct';
import ArticleCard from './index';
import NodataMessage from '../NodataMessage';
import { getArticles } from '../ExternSearchServices';
import ExternSearchContext from '../context';
import { saveRelatedList } from '../actions';
import PaginationActions from '../PaginationActions';

const noFunc = () => null;
const RelatedList = ({
  list,
  title,
  classes,
  isArticle = false,
  onHandleOpenProductDetail = noFunc,
  isNoData = false,
  saveArticles = false,
}) => {
  const contentWidth = useRef(null);
  const [cardPerSlide, setCardPerSlide] = useState(0);
  const [indexCard, setIndexCard] = useState(0);
  const [tecdocList, setTecdocList] = useState([]);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setCardPerSlide(
        parseInt(
          (contentWidth?.current?.getBoundingClientRect()?.width - 50) / (isArticle ? 238 : 280),
          10,
        ) || 3,
      );
    });
    if (contentWidth?.current) resizeObserver.observe(contentWidth?.current);
    return () => resizeObserver.disconnect();
  }, [setCardPerSlide, contentWidth, isArticle]);
  const nextElem = () => {
    setIndexCard(indexCard + 1);
  };
  const prevElem = () => {
    setIndexCard(indexCard - 1);
  };
  const { dispatch } = useContext(ExternSearchContext);

  useEffect(async () => {
    if (isArticle) {
      const promise = await Promise.all(
        list
          ?.map((article) => article.articleNumber)
          .unique()
          .map((articleNumber) => getArticles(articleNumber, undefined, undefined, {}, 1)),
      );

      const dummy = promise
        .flatMap((p) => p.articles)
        .reduce((prev, current) => {
          const isExist = prev.find((item) => item.articleNumber === current.articleNumber);
          return isExist ? prev : [...prev, current];
        }, []);
      if (saveArticles) {
        dispatch(saveRelatedList(dummy.map((d) => d.articleNumber)));
      }
      setTecdocList(dummy);
    }
  }, [list, isArticle, dispatch, saveArticles]);
  return (
    <Box>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Typography style={{ flexGrow: 1 }} className={classes.title}>
          {title}
        </Typography>
        <PaginationActions
          indexCard={indexCard}
          classes={classes}
          prevElem={prevElem}
          nextElem={nextElem}
          cardPerSlide={cardPerSlide}
          size={isArticle ? tecdocList?.length : list?.length}
        />
      </Box>
      {!isNoData ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            overflow: 'hidden',
            columnGap: 15,
          }}
          ref={contentWidth}
        >
          {isArticle
            ? tecdocList?.slice(indexCard, cardPerSlide + indexCard).map((item) => (
              <ArticleCard
                imgs={item.images}
                category=""
                brand={item.mfrName}
                name={item.genericArticles}
                desc={item.articleText}
                original={item}
                key={item.articleNumber}
                  // isRelated
                onHandleOpenProductDetail={onHandleOpenProductDetail}
              />
            ))
            : list
              ?.slice(indexCard, cardPerSlide + indexCard)
              .map((po) => (
                <RelatedProduct
                  product={po}
                  key={po?.id}
                  handleClick={() => onHandleOpenProductDetail(po)}
                />
              ))}
        </Box>
      ) : (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <NodataMessage isSmall />
        </Box>
      )}
    </Box>
  );
};

export default RelatedList;
