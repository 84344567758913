/* eslint-disable import/prefer-default-export */
export const TYPES = ['FA',
  'MF',
  'SPECIALE',
];
export const BLOCKING = ['Manuel',
  'Automatique',
];
export const CONTACTS = ['Fixe',
  'Mobile',
  'Email',
  'Whatsapp',
];
export const NOTESTYPE = ['Commentaire',
  'Attachement',
];
