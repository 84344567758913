import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 10px 20px 25px',
    height: 'calc(100vh - 104px)',
    overflowY: 'auto',
  },
  name: {
    font: 'normal 600 24px Montserrat',
  },
  nameEdit: {
    display: 'flex',
    alignItems: 'center',
  },
  title: { display: 'flex', justifyContent: 'space-between', marginBottom: '15px' },
  btn: { marginTop: 'auto', marginBottom: 'auto', marginLeft: 10 },
  successMsg: {
    marginTop: '20px',
    textAlign: 'center',
    font: 'normal 600 18px Montserrat',
  },
  successCheck: {
    borderRadius: '50%',
    width: '65px',
    height: '65px',
    backgroundColor: '#00B894',
    margin: 'auto',
    color: '#FFF',
    display: 'flex',
  },
}));

export default useStyles;
