import {
  Box, Skeleton, Tab, Tabs, Typography,
} from '@mui/material';
import DialogCmp, { HeaderImage } from 'Shared/Dialog';
import React, { useEffect, useState } from 'react';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import useRelatedProducts from 'Shared/Hooks/useRelatedProducts';
import { getProductStock } from 'Utils/ulilities';
import useStyles, { useStylesSuppliers } from './style';
import { getRefSuppliers } from '../importServices';

const formatCurrency = (currency = '') => {
  const currencyx = currency.toUpperCase();
  switch (currencyx) {
    case 'YEN':
      return `\u00A5 - ${currency}`;
    case 'EURO':
      return `\u20AC - ${currency}`;
    case 'DOLAR':
      return `\u0024 - ${currency}`;
    default:
      return ` - ${currency}`;
  }
};

const Item = ({ label, value, classes }) => (
  <>
    <Typography className={classes.itemLabel}>{label}</Typography>
    <Typography className={classes.itemValue}>{value}</Typography>
  </>
);

const MiniProduct = ({
  reference, name, img, stock, price,
}) => (
  <Box sx={{ display: 'flex', marginBottom: '10px' }}>
    <HeaderImage image={img} alt={name} />
    <Box sx={{ width: '80%' }}>
      <Typography style={{
        font: 'normal normal 600 18px Montserrat',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      >
        {name}
      </Typography>
      <Typography style={{ font: 'normal normal 600 15px Montserrat' }}>
        {`Référeance: ${reference}`}
      </Typography>
      <Typography style={{ font: 'normal normal 600 15px Montserrat' }}>
        {`Qté Stock: ${stock}`}
      </Typography>
      <Typography style={{ font: 'normal normal 600 15px Montserrat', color: '#007BC6' }}>
        {`${price} Dhs`}
      </Typography>
    </Box>
  </Box>
);

const RelatedProducts = ({ product }) => {
  const { relatedProducts } = useRelatedProducts(product);

  return (
    <Box sx={{
      height: '160px',
      overflowY: 'auto',
      '::-webkit-scrollbar': {
        width: '7px',
      },
      '::-webkit-scrollbar-track': {
        background: '#FFF',
        borderRadius: '10px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#D8D8D8',
        borderRadius: '10px',
        '& :hover': {
          background: '#888',
        },
      },
    }}
    >
      {relatedProducts.map((po) => (
        <MiniProduct
          reference={po.reference}
          name={(`${po.designation} ${po.designation}`)}
          img={po.img}
          stock={getProductStock(po)}
          price={po.priceDevise}
        />
      ))}
    </Box>
  );
};

const Suppliers = ({ productId }) => {
  const classes = useStylesSuppliers();
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    try {
      setLoading(true);
      const data = await getRefSuppliers(productId);
      setSuppliers(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [productId]);

  return (
    <>
      {loading
        ? (
          <>
            <Skeleton animation="wave" variant="rounded" sx={{ width: '100%' }} height={20} />
            <Skeleton animation="wave" variant="rounded" sx={{ mt: '10px', width: '100%' }} height={20} />
          </>
        )
        : suppliers.map((supplier) => (
          <Box sx={{ borderBottom: '1px solid #C5C5C5', padding: '12px', display: 'flex' }}>
            <Typography className={classes.label}>{supplier.reference}</Typography>
            <Typography className={classes.label}>{supplier.supplierName}</Typography>
            <Typography className={classes.label}>{supplier.prix}</Typography>
            <Typography className={classes.label}>{formatCurrency(supplier.devise)}</Typography>
          </Box>
        ))}
    </>
  );
};

const DetailProduct = ({ product, openDetail, onClose }) => {
  const [tab, setTab] = useState(0);
  const classes = useStyles();

  const onChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (!openDetail) {
      setTab(0);
    }
  }, [openDetail]);

  if (!openDetail) {
    return null;
  }

  return (
    <>
      <DialogCmp
        title={product.iH ? (
          <Box className={classes.title}>
            <Box>{product.designation}</Box>
            <Box className={classes.oilIcon}>
              <>
                <OilBarrelIcon />
                <Typography>HUILE</Typography>
              </>
            </Box>
          </Box>
        ) : product.designation}
        subTitle={(<Typography className={classes.subTitle}>{`Référence: ${product.reference}`}</Typography>)}
        endIcon={false}
        open={openDetail}
        handleClose={onClose}
        styleOk={{ display: 'none' }}
        styleKo={{ display: 'none' }}
        headerImg={product.img}
      >
        <Box sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <div className={classes.tabRoot}>
            <Tabs
              value={tab}
              onChange={onChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
              size="small"
            >
              <Tab
                classes={{
                  root: classes.tab,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper,
                }}
                label="Identification"
                value={0}
              />
              <Tab
                classes={{
                  root: classes.tab,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper,
                }}
                label="Infos suppléméntaires"
                value={1}
              />
              <Tab
                classes={{
                  root: classes.tab,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper,
                }}
                label="Info douanière"
                value={2}
              />
              <Tab
                classes={{
                  root: classes.tab,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper,
                }}
                label="Fournisseur"
                value={3}
              />
            </Tabs>
          </div>
          {tab === 3 ? (
            <Box className={classes.topRefSuppliers}>
              <Typography className={classes.label}>REFERENCE</Typography>
              <Typography className={classes.label}>FOURNISSEUR</Typography>
              <Typography className={classes.label}>PRIX EN DEVISE</Typography>
              <Typography className={classes.label}>DEVIS</Typography>
            </Box>
          ) : null }
          <Box className={classes.tabContent} style={tab === 3 ? { minHeight: 257 } : {}}>
            {tab === 0 ? (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '100%' }}>
                  <Item label="FAMILLE" value={product.fId} classes={classes} />
                  <Item label="PRIX EN DEVIS" value={`${product.priceDevise || ''} DH`} classes={classes} />
                  <Item label="PRIX DE VENT" value={`${product.p || ''} DH`} classes={classes} />
                  <Item label="COÉF PRIX VENTE" value={product.coefVente} classes={classes} />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Item label="PRIX D’ACHAT" value={`${product.priceAch || ''} DH`} classes={classes} />
                  <Item label="PRIX DE REVIENT" value={`${product.puNet || ''} DH`} classes={classes} />
                  <Item label="SAISONNIER/AUTRES" value={product.saisonnier} classes={classes} />
                </Box>
              </Box>
            ) : null}
            {tab === 1 ? (
              <>
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%' }}>
                    <Item label="MARQUE" value={product.bId} classes={classes} />
                    <Item label="REFERENCE ORIGINE" value={product.rO} classes={classes} />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Item label="DIMENSION" value={product.d} classes={classes} />
                    <Item label="REF TEC DOC" value={product.refT} classes={classes} />
                  </Box>
                </Box>
                <Item label="EQUIVALENT" value={null} classes={classes} />
                <RelatedProducts product={product} />
              </>
            ) : null}
            {tab === 2 ? (
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ width: '100%' }}>
                  <Item label="NOMENCLATURE" value={product.nomenclature} classes={classes} />
                  <Item
                    label="TAUX DOUANE"
                    value={(
                      <>
                        <Typography className={classes.itemValue} style={{ marginBottom: 0 }}>
                          TAUX EUROPÉEN
                          {' '}
                          <span style={{ color: '#007BC6' }}>
                            {product.europeanRate ? `${product.europeanRate} %` : null}
                          </span>
                        </Typography>
                        <Typography className={classes.itemValue}>
                          TAUX NON EUROPÉEN
                          {' '}
                          <span style={{ color: '#007BC6' }}>
                            {product.nonEuropeanRate ? `${product.nonEuropeanRate} %` : null}
                          </span>
                        </Typography>
                      </>
                  )}
                    classes={classes}
                  />
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Item label="ORIGINE" value={product.pays} classes={classes} />
                  <Item label="INFO" value={product.information} classes={classes} />
                </Box>
              </Box>
            ) : null}
            {tab === 3 ? <Suppliers productId={product.productId} /> : null}
          </Box>
        </Box>
      </DialogCmp>
    </>
  );
};

export default DetailProduct;
