import { Typography } from '@mui/material';
import React from 'react';

export const PAYEMENT_STATUSES = [
  { code: 'NON_ATTACHE', label: 'Non attachée' },
  { code: 'PARTIELLEMENT_ATTACHE', label: 'partiellement attachée' },
  { code: 'ATTACHE', label: 'Totalement attachée' },
];
/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  color: '#727CA2',
  fontSize: '12px',
  fontWeight: 500,
  background: '#F8F8FF',
  borderBottom: 'none',
  textTransform: 'uppercase',
};
const defaultColumnStyle = { color: '#333D66', fontSize: '14px' };
export const columns = [
  {
    id: 'date',
    label: 'Date de saisie du règlement',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
    order: 'receptionDate',
  },
  {
    id: 'clientCode',
    label: 'Code Client',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
    order: 'client.code',
  },
  {
    id: 'clientName',
    label: 'Intitulé client',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
    order: 'client.name',
  },
  {
    id: 'type',
    label: 'Nature de pièce de règlement',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
    order: true,
  },
  {
    id: 'bank',
    label: 'Banque',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
    order: true,
  },
  {
    id: 'reference',
    label: 'Numéro de pièce de règlement',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
    order: true,
  },
  {
    id: 'amount',
    label: 'Montant',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
    order: true,
  },
  {
    id: 'statusLabel',
    label: 'Statut',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
];
