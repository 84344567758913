import { createContext } from 'react';

export const initialState = {
  referencial: {},
  status: '',
  selectedFamily: null,
  selectedProduct: null,
  selectedBrand: null,
};
const ReferencialContext = createContext(initialState);

export const { Provider } = ReferencialContext;
export default ReferencialContext;
