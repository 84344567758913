import React from 'react';
import { Typography, CardMedia, Box } from '@mui/material';
import NoData from 'Images/no_data.svg';

const NodataMessage = ({ isSmall = false }) => (
  <Box style={{
    textAlign: isSmall ? 'left' : 'center',
    marginTop: isSmall ? 20 : '100px',
    display: 'flex',
    flexDirection: isSmall ? 'row' : 'column',
    justifyContent: isSmall && 'center',
    marginLeft: isSmall && 'auto',
    marginRight: isSmall && 'auto',
  }}
  >
    <CardMedia component="img" src={NoData} style={{ width: isSmall ? 65 : 100, height: isSmall ? 90 : 150, margin: !isSmall && 'auto' }} />
    <Typography
      style={{
        font: isSmall ? '600 16px Montserrat' : '600 18px Montserrat',
        color: '#38485C',
        margin: 'auto',
        marginLeft: isSmall ? 40 : 'auto',
        marginTop: isSmall ? 'auto' : '38px',
        width: '600px',
      }}
    >
      Aucun produit correspondant n’a été trouvé , veuillez essayez avec une autre référence.
    </Typography>
  </Box>
);

export default NodataMessage;
