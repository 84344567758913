import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  filter: {
    marginRight: 16,
    minWidth: 170,
    marginBottom: 16,
  },
  date: { marginTop: -23, marginRight: 10 },
}));

export default useStyles;
