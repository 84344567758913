/* eslint-disable import/prefer-default-export */
export const Fields = [
  {
    type: 'text',
    id: 'ice',
    label: 'ICE',
  },
  {
    type: 'text',
    id: 'rc',
    label: 'Numéro RC',
  },
  {
    type: 'text',
    id: 'raisonSociale',
    label: 'Identifiant fiscale (IF)',
  },
  {
    type: 'text',
    id: 'patente',
    label: 'Patente',
  },
];
