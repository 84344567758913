import { findStartAndEndDate } from 'Components/Catalog/ProductsTable/constants';
import axios from 'axios';
import {
  getToken,
  secureDelete,
  secureGet,
  securePost,
  securePut,
  baseURL,
} from 'config/apiConfig';
import { format } from 'date-fns';

export const defaultMessage = 'Erreur système';

const url = '/ci/api/proposition/';

export const getPropositions = async ({
  size = 10, page = 0, name, start, end, id, statusList,
}) => {
  const sDate = start ? `&startDate=${format(start, 'yyy/MM/dd')}` : '';
  const eDate = end ? `&endDate=${format(end, 'yyy/MM/dd')}` : '';
  const xName = name && name.length > 0 ? `&name=${name}` : '';
  const xId = id ? `&id=${id}` : '';
  const xStatus = statusList && statusList.length > 0
    ? statusList.reduce((prev, curr) => `${prev}&status=${curr}`, '')
    : '';
  const { data } = await secureGet(
    `${url}?page=${page}&size=${size}${sDate}${eDate}${xName}${xId}${xStatus}`,
  );
  return {
    ...data,
    content: data.content.map((item) => ({ ...item, date: new Date(item.createdOn) })),
  };
};

export const getExportProposition = async (id, name) => {
  const token = await getToken();
  const { data } = await axios.get(`${baseURL}${url}export/${id}?token=${token}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: {},
    responseType: 'arraybuffer',
  });
  const blolUrl = window.URL.createObjectURL(
    new Blob([data], { type: 'application/vnd.ms-excel' }),
  );
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = blolUrl;
  a.download = `${name}.xlsx`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(blolUrl);
  return data;
};

export const savePropositions = async (proposition, startEnddates = {}) => {
  const dates = findStartAndEndDate(startEnddates);
  const { data } = await securePost(url, {
    ...proposition,
    quantitiesDateRequest: dates,
  });
  return data;
};

export const addProductToPropositions = async (propositionId, products, datesFilter = {}) => {
  const dates = findStartAndEndDate(datesFilter);
  const xS = `?filterStartDate=${dates.startDate}`;
  const xE = `&filterEndDate=${dates.endDate}`;
  const { data } = await securePut(`${url}${propositionId}${xS}${xE}`, products);
  return data;
};

export const validatePropositions = async (propositionId) => {
  const { data } = await securePut(`${url}${propositionId}/VALIDATED`);
  return data;
};

export const removePropositions = async (propositionId) => {
  const { data } = await secureDelete(`${url}${propositionId}`);
  return data;
};

export const getProductsPropositions = async (propositionId, size = 10, page = 0) => {
  const { data } = await secureGet(`${url}${propositionId}/products?size=${size}&page=${page}`);
  return data;
};

export const removeProductsFromPropositions = async (propositionId, products) => {
  const { data } = await securePut(`${url}${propositionId}/products`, products);
  return data;
};

export const getPropositionProductNotes = async (propositionProductId) => {
  const { data } = await secureGet(`${url}${propositionProductId}/notes`);
  return data.map((comment) => ({
    ...comment,
    created_on: new Date(comment.created_on),
    comments: comment?.comments
      .map((sub) => ({ ...sub, created_on: new Date(sub.created_on) }))
      .sort((y, z) => z.created_on - y.created_on),
  }));
};

export const addPropositionProductNotes = async (propositionProductId, commentDto) => {
  const { data } = await securePost(`${url}${propositionProductId}/notes`, commentDto);
  return data;
};

export const deltePropositionProductNotes = async (propositionProductId, noteId) => {
  const { data } = await secureDelete(`${url}${propositionProductId}/notes/${noteId}`);
  return data;
};

export const addManualProductToPropositions = async (propositionId, manualProduct) => {
  const { data } = await securePut(`${url}${propositionId}/manual-product`, manualProduct);
  return data;
};

export const getPropositionProductIds = async (propositionId, callback) => {
  const { data } = await secureGet(`${url}${propositionId}/product-ids`);
  if (typeof callback === 'function') {
    callback(data);
  }
  return data;
};

export const importProductOrderQuantitiesByFile = async (file, propositionId) => {
  const formData = new FormData();
  formData.append('file', file);
  const path = `${url}upload-quantities-order/${propositionId}`;
  const { data } = await securePost(path, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};
