/* eslint-disable import/prefer-default-export */
export const ROLES = [
  {
    id: 1,
    label: 'ADMIN',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: 'blue' },
  },
  {
    id: 3,
    label: 'SALES',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: 'green' },
  },
  {
    id: 4,
    label: 'FACTURATION',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: 'red' },
  },
  {
    id: 6,
    label: 'TRANSFERT',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: 'gray' },
  },
  {
    id: 7,
    label: 'GAME_MASTER',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: 'fuchsia' },
  },
  {
    id: 8,
    label: 'COMMERCIAL',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: '#497E76' },
  },
  {
    id: 9,
    label: 'SERVICE_CLIENT',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: '#79553D' },
  },
  {
    id: 9,
    label: 'COMPTA',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: '#3E3B32' },
  },
  {
    id: 10,
    label: 'ROLE_CLIENT',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: '#316650' },
  },
  {
    id: 11,
    label: 'RECOUVREMENT',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: '#C93C20' },
  },
  {
    id: 12,
    label: 'SAV',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: '#FDF4E3' },
  },
  {
    id: 13,
    label: 'REGLEMENT',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: '#8A6642' },
  },
  {
    id: 14,
    label: 'BUYER',
    style: { background: '#EAF5FF 0% 0% no-repeat padding-box', color: '#025669' },
  },
];
