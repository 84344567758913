import React from 'react';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import useStyles from './style-display';

const NoteSAV = ({ note }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.addedBy}>
          {`${note?.addedBy?.firstName} ${note?.addedBy?.lastName}`}
        </Typography>
        <Typography className={classes.time}>
          {format(new Date(note.createdOn), 'HH:mm - dd/MM/yyyy')}
        </Typography>
      </Box>
      <Typography className={classes.comment}>{note?.comment}</Typography>
    </Box>
  );
};

export default NoteSAV;
