import { Button, Grid } from '@mui/material';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext from 'Shared/ReferencialContext';
import RenderInput from 'Shared/RenderInput';
import { Fields, TYPES } from '../constants';
import { defaultMessage, editCLient } from '../RecoveryServices';

const RecoveryEdit = () => {
  const {
    state: {
      referencial: { clients: clientList, expeditions, paymentModes },
    },
  } = useContext(ReferencialContext);
  const history = useHistory();
  const { state: client } = useLocation();
  const [values, setValues] = useState(client);
  const [loading, setLoading] = useState(false);
  const handleInputChange = (prop) => (event, value) => {
    setValues({ ...values, [prop]: event instanceof Date ? event : value || event?.target.value });
  };
  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      expedition: typeof client.expedition === 'string' ? (expeditions || []).find((e) => e.name === client.expedition) : client.expedition,
      payment: typeof client.payment === 'string' ? (paymentModes || []).find((e) => e.name === client.payment) : client.payment,
    }));
  }, [expeditions, paymentModes]);
  const lists = useMemo(() => ({
    cities: (clientList || []).map((c) => c.city).unique(),
    areas: (clientList || []).map((c) => c.zone).unique(),
    expeditions: expeditions || [],
    paymentModes: paymentModes || [],
    types: TYPES,
  }),
  [clientList, expeditions, paymentModes]);
  const handleEdit = async () => {
    try {
      setLoading(true);
      await editCLient({
        ...values,
        expeditionCode: values.expedition?.code,
        paymentCode: values.payment?.code,
      });
      toast.success('Client modifié avec succès');
      history.push('/recovery');
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Grid container spacing={2} style={{ padding: '20px', margin: 0, width: '100%' }}>
        {Fields.map((field) => (
          <Grid xs={6} item>
            <RenderInput
              input={field.type === 'autocomplete' ? { ...field, [field.listName]: lists[field.listName] } : field}
              value={values[field.id]}
              handleChange={handleInputChange(field.id)}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} style={{ padding: '20px', margin: 0, width: '100%' }} direction="row-reverse">
        <Button variant="contained" color="primary" onClick={handleEdit} disabled={!values.ice}>Modifier</Button>
      </Grid>
    </>

  );
};

export default RecoveryEdit;
