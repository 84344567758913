// eslint-disable-next-line import/prefer-default-export
export const Fields = [
  {
    type: 'autocomplete',
    id: 'noteType',
    label: 'Type de note',
    listName: 'noteType',
    filter: (option, value) => option
      ?.toLowerCase().includes(value),
    optionLabel: (option) => option,
  },
  {
    type: 'text',
    id: 'comment',
    label: 'Commentaire',
  },
  {
    type: 'file',
    id: 'file',
    label: 'Attachement',
  },
];
