import { secureGet, securePost } from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getPaymentDocuments = async (sortParams, filters = {}) => {
  const url = `/ci/api/reglements/documents?page=${sortParams.page}&size=${sortParams.size}${
    sortParams.sort ? `&sort=${sortParams.sort}` : ''
  }`;
  const { data } = await securePost(url, filters);
  return data;
};
export const attachDocumentsToInvoices = async (body) => {
  const url = '/ci/api/reglements/attach';
  const { data } = await securePost(url, body);
  return data;
};

export const detachDocumentsToInvoices = async (echeance) => {
  const url = `/ci/api/reglements/unattach?echeance=${echeance}`;
  const { data } = await securePost(url);
  return data;
};

export const getUnpaidOrders = async (clientCode) => {
  const url = `/ci/api/reglements/unpaidOrders?client=${clientCode}`;
  const { data } = await secureGet(url);
  return data;
};
export const getReglementById = async (id) => {
  const url = `/ci/api/reglements/documents/${id}`;
  const { data } = await secureGet(url);
  return data;
};

export const getReglementKpis = async (filters = {}) => {
  const url = '/ci/api/reglements/kpis';
  const { data } = await securePost(url, filters);
  return data;
};
