import { Box, IconButton, Typography } from '@mui/material';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import RangeDate from 'Shared/RangeDate';
import EnhancedTable from 'Shared/EnhancedTable';
import SyncIcon from '@mui/icons-material/Sync';
import ReferencialContext from 'Shared/ReferencialContext';
import useForm from 'Shared/Hooks/useForm';
import DialogCmp from 'Shared/Dialog';
import { useDispatch } from 'react-redux';
import { setAppLoadingOff, setAppLoadingOn } from 'appSlice';
import { getUnhonored } from './unhonoredService';
import { columns } from './constants';
import UnhonoredDetail from './UnhonoredDetail';

const INIT_FILTERS = {
  client: null, product: null, start: null, end: null,
};
const Unhonored = () => {
  const {
    state: {
      referencial: {
        clients = [], products,
      },
    },
  } = useContext(ReferencialContext);
  const [selectedOrder, setSelectedOrder] = useState({ order: null, isOpen: false });
  const [unhonoreds, setUnhonoreds] = useState({ content: [], totalElements: 0 });
  const { values, onChange, reset } = useForm(INIT_FILTERS);
  const dispatch = useDispatch();

  const onHandleOrder = (order) => {
    setSelectedOrder({ order, isOpen: !selectedOrder.isOpen });
  };
  const getList = useCallback(async () => {
    dispatch(setAppLoadingOn());
    const data = await getUnhonored(values);
    dispatch(setAppLoadingOff());
    const content = data.content.map((row) => ({ ...row, click: () => onHandleOrder(row) }));
    setUnhonoreds({ ...data, content });
  }, [values]);

  useEffect(() => {
    getList(values);
  }, [values]);

  const onChangeC = (name) => (event, value) => {
    onChange({ target: { name, value: name === 'date' ? event : value } });
  };
  const onChangeDate = (value, isStartDate) => {
    onChange({ target: { name: isStartDate ? 'start' : 'end', value } });
  };
  return (
    <Box sx={{ padding: '20px 10px 20px 25px', overflowY: 'auto', height: 'calc(100vh - 104px)' }}>
      <DialogCmp
        title={`Details du documents: ${selectedOrder.order?.reference}`}
        subTitle={(
          <Typography sx={{ color: '#007BC6', font: 'normal 600 18px Montserrat' }}>
            {selectedOrder.isOpen ? `${selectedOrder.order?.client.code} - ${selectedOrder.order?.client.name}` : ''}
          </Typography>
          )}
        endIcon={false}
        // fullWidth={false}
        open={selectedOrder.isOpen}
        handleClose={() => onHandleOrder({})}
        // onHandleSubmit={onSubmitComment}
        styleOk={{ display: 'none' }}
        styleKo={{ display: 'none' }}
      >
        <UnhonoredDetail order={selectedOrder.order} />
      </DialogCmp>
      <Box style={{ padding: '30px 15px' }}>
        <Typography
          display="block"
          style={{
            font: 'normal normal 600 26px Montserrat',
            color: '#000000',
          }}
        >
          Gestion Des Ventes Non Honorees
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        background: '#E9EBED padding-box',
        padding: '10px 7px',
        borderRadius: '0px 0px 12px 12px',
        mb: 2,
      }}
      >
        <AutoCompleteCmp
          options={clients}
          onChange={onChangeC('client')}
          value={values.client}
          label="Client"
          name="type"
          isPrimaryTheme
          style={{ marginTop: 8, marginBottom: 4, maxWidth: 150 }}
          getOptionLabel={(option) => `${option.code} : ${option.name}`}
          filterOptions={(options, v) => options.filter(
            (option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase())
                || option.code?.toLowerCase().includes(v.inputValue.toLowerCase()),
          )}
        />
        <AutoCompleteCmp
          options={products}
          onChange={onChangeC('product')}
          value={values.client}
          label="Référence"
          name="product"
          isPrimaryTheme
          style={{ marginTop: 8, marginBottom: 4, maxWidth: 150 }}
          getOptionLabel={(option) => option.reference}
          filterOptions={(options, v) => options.filter(
            (option) => option.reference.toLowerCase().includes(v.inputValue.toLowerCase()),
          )}
        />
        <RangeDate
          value={values}
          handleChange={onChangeDate}
          text={{ start: 'Date début', end: 'Date fin' }}
          styleInput={{ marginTop: 8, marginBottom: 4 }}
        />
        <IconButton
          size="small"
          style={{
            background: '#FFFFFF padding-box',
            boxShadow: '0px 4px 10px #9B9B9B50',
            border: '1px solid #EBEDED',
            borderRadius: 12,
            width: 40,
            height: 40,
            marginTop: 8,
            marginLeft: 'auto',
          }}
          onClick={() => reset(INIT_FILTERS)}
        >
          <SyncIcon style={{ color: '#3479BF' }} />
        </IconButton>
      </Box>
      <EnhancedTable
        getData={getList}
        rows={unhonoreds.content}
        headCells={columns}
        count={unhonoreds.totalElements}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </Box>
  );
};

export default Unhonored;
