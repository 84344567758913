import api, { secureGet, securePost } from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getOrders = async (sortParams, filters = {}) => {
  let url = `/ci/api/GetOrderHistorybyCriteria?page=${sortParams.page}&size=${sortParams.size}${
    sortParams.sort ? `&sort=${sortParams.sort}` : ''
  }`;
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      url += Array.isArray(filters[key])
        ? filters[key].reduce((prev, curr) => `${prev}&${key}=${curr}`, '')
        : `&${key}=${filters[key]}`;
    }
  });
  const { data } = await secureGet(url);
  return data;
};
export const getBlockedOrders = async () => {
  const { data } = await securePost('/ci/api/GetPendingTransactionHistory');
  return data;
};
export const getAllBlockedOrders = async () => {
  const { data } = await secureGet('/ci/api/GetAllPendingTransactionHistory');
  return data;
};
export const getDashboard = async (code) => {
  const { data } = await securePost(`/ci/api/GetDashboardByClientCode?client_code=${code}`);
  return data;
};
export const downloadBL = async (id) => {
  const url = `/ci/api/getBl/${id}`;
  const { data } = await api.post(`${url}?token=${localStorage.getItem('token')}`, null, {
    headers: {
      accept: 'application/pdf',
    },
    responseType: 'blob',
  });
  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = id;
  link.click();
  link.remove();
  return data;
};

export const downloadInvoice = async (id) => {
  const url = `/ci/api/getFacture/${id}`;
  const { data } = await api.post(`${url}?token=${localStorage.getItem('token')}`, null, {
    headers: {
      accept: 'application/pdf',
    },
    responseType: 'blob',
  });
  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = id;
  link.click();
  link.remove();
  return data;
};

export const downloadBC = async (id) => {
  const url = `/ci/api/getBc/${id}`;
  const { data } = await api.post(`${url}?token=${localStorage.getItem('token')}`, null, {
    headers: {
      accept: 'application/pdf',
    },
    responseType: 'blob',
  });
  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL, '_blank');
};
export const addOrder = async (order) => {
  const extra = order.isblocked ? { id: order.id } : {};
  const comptantExtra = order.client?.comptant
    ? { ice: order.ice, clientName: order.clientName }
    : {};
  const body = {
    city: order.city,
    clientCode: order.client.code,
    comment: order.personalInfo.trim(),
    expedition: order.expedition.name,
    paymentMode: order.paymentMode.name,
    inventory: order.deposit,
    commercial: order?.commercial?.id,
    souche: order.type.id,
    uuid: order.id,
    channel: 'DESKTOP',
    products: JSON.stringify(
      order.articles.map((a) => (a.reference !== 'AUTRES'
        ? {
          productId: a.reference,
          quantity: parseInt(a.quantity, 10),
          remise: a.editRemise ? a.r : undefined,
          price: a.editBrutPrice ? a.p : undefined,
          mandatory: a.mandatory,
        }
        : {
          productId: a.reference,
          quantity: parseInt(a.quantity, 10),
          remise: a.r,
          name: a.des,
          price: a.p,
          mandatory: a.mandatory,
        })),
    ),
    ...extra,
    ...comptantExtra,
  };
  const { data } = await securePost('/ci/api/AddTransactionSpecialDesktop', body);
  return data;
};

export const getPriceRangeOrders = async (client, reference) => {
  const { data } = await securePost(
    `/ci/api/product/priceRange?client=${client}&reference=${reference}`,
  );
  return data;
};

export const getAllPaymentModeMapping = async () => {
  const { data } = await secureGet('/ci/api/paymentModes');
  return data;
};

export const getCommerciaux = async () => {
  const url = '/ci/api/GetCommerciaux';
  const { data } = await securePost(url);
  return data;
};
