import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import { saveOrders } from 'Components/Orders/Actions';
import OrdersContext, { Provider } from 'Components/Orders/Context';
import Filters from 'Components/Orders/NewFilters';
import { DEFAULT_VALUES } from 'Components/Orders/NewOrder/constants';
import OrderReducer from 'Components/Orders/reducer';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import ConnectedStore from 'Shared/ConnectedStore';
import EnhancedTable from 'Shared/EnhancedTable';
import { initialState } from 'Shared/ReferencialContext';
import CacheManager from 'Utils/CacheManager';
import { withoutTime } from 'Utils/ulilities';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';
import { useHistory } from 'react-router-dom';
import { columns } from './constants';
import useStyles from './style';
import SuccessDialog from '../SuccessDialog';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#f9fafc',
    height: 50,
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  title: {
    font: 'normal normal 500 24px/29px Montserrat',
    letterSpacing: 0,
    color: '#000000',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <IconButton aria-label="close" onClick={onClose} size="large">
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h6" className={classes.title}>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const AffectToOrder = ({ product, handleClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    dispatch,
    state: { orders = [] },
  } = useContext(OrdersContext);
  const [quantity, setQuantity] = useState(1);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [orderRef, setOrderRef] = useState('false');
  const [values, setValues] = useState(DEFAULT_VALUES);
  const handleInputChange = (prop) => (event, value) => {
    setValues({ ...values, [prop]: event instanceof Date ? event : value || event?.target.value });
  };
  const getOrders = useCallback(async () => {
    const storedOrders = await CacheManager.readData('salesOrders');
    if (orders.length === 0 && storedOrders && storedOrders.length) {
      dispatch(saveOrders(storedOrders));
    }
  }, [dispatch, orders]);
  useEffect(() => {
    getOrders();
  }, [getOrders]);
  const handleQuantityChange = (value) => (event) => {
    setQuantity(parseInt(value || event.target.value, 10));
  };
  const affectProducts = useCallback(
    (id) => () => {
      if (quantity) {
        dispatch(
          saveOrders(
            orders.map((o) => (o.id === id
              ? {
                ...o,
                articles: [
                  ...o.articles,
                  {
                    ...product,
                    quantity: parseInt(quantity, 10) >= 1 ? parseInt(quantity, 10) : 1,
                    total: (product.p * parseInt(quantity, 10)).toFixed(2),
                  },
                ],
              }
              : o)),
          ),
        );
        setOpenSuccess(true);
        setOrderRef(id);
      } else {
        toast.info('Merci de saisir la quantité');
      }
    },
    [orders, dispatch, product, quantity],
  );
  const newOrder = () => {
    if (quantity) {
      dispatch(
        saveOrders([
          ...orders,
          {
            articles: [
              {
                ...product,
                quantity: parseInt(quantity, 10) >= 1 ? parseInt(quantity, 10) : 1,
                total: (product.p * parseInt(quantity, 10)).toFixed(2),
              },
            ],
            date: new Date(),
            id: Math.floor((1 + Math.random()) * 0x10000)
              .toString(16)
              .substring(1),
          },
        ]),
      );
      toast.success('Commande créée avec succès');
      setOpenSuccess(true);
    } else {
      toast.info('Merci de saisir la quantité');
    }
  };
  const salesOrders = useMemo(() => orders
    .filter(
      (o) => (!values.client || values.client.code === o.client?.code)
          && (!values.expedition || values.expedition.code === o.expedition?.code)
          && (!values.paymentMode || values.paymentMode.code === o.paymentMode?.code)
          && (!values.city || values.city === o.city)
          && (!values.product
            || o.articles.map((a) => a.reference).includes(values.product?.reference))
          && (!values.startDate || withoutTime(values.startDate) <= withoutTime(o.date))
          && (!values.endDate || withoutTime(values.endDate) >= withoutTime(o.date)),
    )
    .map(
      (o) => ({
        amount: o.articles
          ?.reduce((prev, curr) => prev + parseInt(curr.quantity, 10) * curr.p, 0)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$& '),
        client: o.client?.name,
        date: o.date,
        expedition: o.expedition?.name,
        paymentMode: o.paymentMode?.name,
        personalInfo: o.personalInfo,
        id: o.id,
        reference: o.id,
        type: o.type?.name,
        affect: affectProducts(o.id),
      }),
      [orders],
    ));
  const handleRefresh = () => {
    setValues({ ...values });
  };
  return (
    <>
      {openSuccess && (
      <SuccessDialog
        handleClose={() => {
          setOpenSuccess(false);
          handleClose();
        }}
        message={`Votre produit ${product.reference} a été bien assigner a la commande ${orderRef}`}
        icon={DoneIcon}
        validateButton={() => history.push(
          'order-add',
          orders.find((o) => o.id === orderRef),
        )}
        buttonTitle="Voir la commande"
      />
      )}
      {!openSuccess && (
      <Dialog
        onClose={handleClose}
        maxWidth="lg"
        open
        fullWidth
        PaperProps={{
          style: {
            background: '#F9FAFC 0% 0% no-repeat padding-box',
            border: '1px solid #C5C5C5',
            borderRadius: 24,
          },
        }}
        BackdropProps={{
          style: {
            background: '#245283 0% 0% no-repeat padding-box',
            boxShadow: '0px 12px 26px #0000000D',
            opacity: 0.67,
          },
        }}
      >
        <DialogTitle onClose={handleClose}>Selectionnez une commande</DialogTitle>
        <DialogContent style={{ backgroundColor: '#FFF' }}>
          <Box style={{ display: 'flex', padding: 10 }}>
            <Typography
              style={{
                font: 'normal normal 600 32px/32px Montserrat',
                letterSpacing: 0,
                color: '#000000',
                textTransform: 'capitalize',
                flexGrow: 1,
              }}
            >
              Assigner A Une Commande
            </Typography>
            <Box style={{ paddingLeft: 10, alignItems: 'center' }}>
              <IconButton
                style={{
                  background:
                    'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
                  boxShadow: '0px 4px 10px #108CD650',
                  borderRadius: 12,
                  color: '#FFF',
                  marginRight: 10,
                  padding: 7,
                }}
                onClick={quantity - 1 > 0 && handleQuantityChange(quantity - 1)}
                size="large"
              >
                <RemoveIcon />
              </IconButton>
              <TextField
                variant="outlined"
                size="small"
                value={quantity}
                name="quantity"
                onChange={handleQuantityChange()}
                inputProps={{ min: 1 }}
                style={{ width: 80, borderRadius: 16 }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  classes: { input: classes.input, root: classes.rootQuantity },
                }}
              />
              <IconButton
                type="number"
                inputProps={{ min: 0 }}
                onClick={handleQuantityChange(quantity + 1)}
                style={{
                  background:
                    'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
                  boxShadow: '0px 4px 10px #108CD650',
                  borderRadius: 12,
                  color: '#FFF',
                  marginLeft: 10,
                  padding: 7,
                }}
                size="large"
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
          {false && (
            <Box style={{ display: 'flex' }}>
              <Box style={{ flexGrow: 1 }}>
                <Typography variant="subtitle1"> Réference </Typography>
                <Typography>{product.reference}</Typography>
              </Box>
              <Box style={{ flexGrow: 1 }}>
                <Typography variant="subtitle1"> Désignation </Typography>
                <Typography>{product.des}</Typography>
              </Box>
              <Box style={{ flexGrow: 1 }}>
                <Typography variant="subtitle1"> Prix </Typography>
                <Typography>{product.p}</Typography>
              </Box>
              <Box style={{ flexGrow: 1 }}>
                <Typography variant="subtitle1"> Quantité </Typography>
                <TextField
                  label="Quantité"
                  variant="outlined"
                  type="number"
                  onChange={handleQuantityChange}
                  value={quantity}
                  inputProps={{ min: 1 }}
                  size="small"
                />
              </Box>
            </Box>
          )}
          {false && (
            <Button variant="contained" fullWidth style={{ margin: '10px 0' }} onClick={newOrder}>
              Créer une Nouvelle commande
            </Button>
          )}
          <Filters
            values={values}
            handleInputChange={handleInputChange}
            handleRefresh={handleRefresh}
          />
          <EnhancedTable
            rows={salesOrders}
            headCells={columns}
            count={salesOrders.length}
            rowsPerPageOptions={[10, 15, 20]}
          />
        </DialogContent>
      </Dialog>
      )}
    </>
  );
};

export default ConnectedStore(AffectToOrder, OrderReducer, initialState, Provider);
