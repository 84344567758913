/* eslint-disable max-len */
import {
  Box, IconButton, Popper, TextField, Autocomplete,
} from '@mui/material';
import React, { useContext, useMemo } from 'react';
import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import ReferencialContext from 'Shared/ReferencialContext';
import RefreshIcon from '@mui/icons-material/Refresh';
import DatePicker from '@mui/lab/DatePicker';
import { TYPES_COMMANDES } from 'Shared/constants/order';
import useStyles from './style';
import { mapOrderStatus } from '../SageOrders/constants';

const PopperMy = (props) => (
  <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />
);
const STATUSLIST = Object.keys(mapOrderStatus).map((k) => ({
  id: k,
  value: mapOrderStatus[k].label,
}));
const Filters = ({
  values, handleInputChange, product, handleRefresh, type = false,
}) => {
  const {
    state: {
      referencial: {
        clients = [], expeditions, paymentModes, products,
      },
    },
  } = useContext(ReferencialContext);
  const cyties = useMemo(() => clients.map((c) => c.city).unique(), [clients]);
  const classes = useStyles();
  const onRefresh = () => {
    handleRefresh();
  };
  return (
    <Box
      style={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Autocomplete
        PopperComponent={PopperMy}
        autoSelect
        classes={{ root: classes.filter }}
        style={{ width: 150 }}
        options={clients || []}
        onChange={handleInputChange('client')}
        value={values.client}
        size="small"
        autoHighlight
        getOptionLabel={(option) => `${option.code} : ${option.name}`}
        filterOptions={(options, v) => options.filter(
          (option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase())
              || option.code?.toLowerCase().includes(v.inputValue.toLowerCase()),
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Client"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Autocomplete
        PopperComponent={PopperMy}
        autoSelect
        style={{ width: 150 }}
        classes={{ root: classes.filter }}
        options={expeditions || []}
        onChange={handleInputChange('expedition')}
        value={values.expedition}
        size="small"
        autoHighlight
        getOptionLabel={(option) => option.name}
        filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Expedition"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Autocomplete
        PopperComponent={PopperMy}
        autoSelect
        style={{ width: 150 }}
        classes={{ root: classes.filter }}
        options={paymentModes || []}
        onChange={handleInputChange('paymentMode')}
        value={values.paymentMode}
        size="small"
        autoHighlight
        getOptionLabel={(option) => option.name}
        filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Mode de paiment"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Autocomplete
        PopperComponent={PopperMy}
        autoSelect
        style={{ width: 150 }}
        classes={{ root: classes.filter }}
        options={cyties || []}
        onChange={handleInputChange('city')}
        value={values.city}
        size="small"
        autoHighlight
        getOptionLabel={(option) => option}
        filterOptions={(options, v) => options.filter((option) => option?.toLowerCase().includes(v.inputValue.toLowerCase()))}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Ville"
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      {type && (
        <Autocomplete
          PopperComponent={PopperMy}
          autoSelect
          style={{ width: 150 }}
          classes={{ root: classes.filter }}
          options={TYPES_COMMANDES || []}
          onChange={handleInputChange('type')}
          value={values.type}
          size="small"
          autoHighlight
          getOptionLabel={(option) => option.label}
          filterOptions={(options, v) => options.filter((option) => option.label?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type de pièce"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      )}
      {product && (
        <Autocomplete
          autoSelect
          style={{ width: 150 }}
          classes={{ root: classes.filter }}
          options={STATUSLIST || []}
          onChange={handleInputChange('status')}
          value={values.status}
          size="small"
          autoHighlight
          getOptionLabel={(option) => option.value}
          filterOptions={(options, v) => options.filter((option) => option.value.toLowerCase().includes(v.inputValue.toLowerCase()))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Statut"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      )}
      {!product && (
        <Autocomplete
          autoSelect
          style={{ width: 300 }}
          classes={{ root: classes.filter }}
          options={products || []}
          onChange={handleInputChange('product')}
          value={values.product}
          size="small"
          autoHighlight
          getOptionLabel={(option) => option.reference}
          ListboxComponent={ListboxComponent}
          filterOptions={(options, v) => options.filter((option) => option.reference.toLowerCase().includes(v.inputValue.toLowerCase()))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Produit"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      )}
      <DatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        id="startDate"
        label="Début"
        className={classes.date}
        value={values.startDate || null}
        onChange={handleInputChange('startDate')}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
      <DatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        id="endDate"
        label="Fin"
        className={classes.date}
        value={values.endDate || null}
        onChange={handleInputChange('endDate')}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
      <IconButton color="primary" onClick={onRefresh} size="large">
        <RefreshIcon />
      </IconButton>
    </Box>
  );
};

export default Filters;
