import {
  Card, CardActionArea, Tooltip, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  root: {
    width: 238,
    height: 340,
    margin: '10px 10px 10px 0',
  },
  media: {
    height: 131,
    width: '100%',
  },
});
const Challenge = ({ objective = { intitule: 'MARS 2022', ca: 10000000 } }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea style={{ height: '100%' }}>
        <Tooltip title={objective.intitule}>
          <Typography
            style={{
              fontSize: 17,
              fontWeight: 'bold',
              marginBottom: 100,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textAlign: 'center',
            }}
          >
            {objective.intitule}
          </Typography>
        </Tooltip>
        <Tooltip title={objective.ca}>
          <Typography
            style={{
              color: '#566497',
              fontSize: 15,
              textAlign: 'center',
            }}
          >
            {objective.ca.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, '$& ')}
            {' '}
            MAD
          </Typography>
        </Tooltip>
      </CardActionArea>
    </Card>
  );
};

export default Challenge;
