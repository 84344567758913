import {
  secureGet, securePut, secureDelete, securePost,
} from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getUsers = async (sortParams, filters = {}) => {
  let url = `/ci/api/user?page=${sortParams.page}&size=${sortParams.size}${
    sortParams.sort ? `&sort=${sortParams.sort}` : ''
  }`;
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      url += Array.isArray(filters[key])
        ? filters[key].reduce((prev, curr) => `${prev}&${key}=${curr}`, '')
        : `&${key}=${filters[key]}`;
    }
  });
  const { data } = await secureGet(url);
  return data;
};

export const editUser = async (body) => {
  const { data } = await securePut('/ci/api/user/add', body);
  return data;
};

export const getDevices = async () => {
  const { data } = await secureGet('/ci/api/device');
  return data;
};

export const getCarts = async (sortParams, filters = {}) => {
  let url = `/ci/api/carts?page=${sortParams.page}&size=${sortParams.size}${
    sortParams.sort ? `&sort=${sortParams.sort}` : ''
  }`;
  Object.keys(filters).forEach((key) => {
    if (filters[key] || filters[key] === 0) {
      url += Array.isArray(filters[key])
        ? filters[key].reduce((prev, curr) => `${prev}&${key}=${curr}`, '')
        : `&${key}=${filters[key]}`;
    }
  });
  const { data } = await secureGet(url);
  return data;
};

export const updateCart = async (cart) => {
  const { data } = await securePut('/ci/api/carts', cart);
  return data;
};

export const deleteDevice = async (id) => {
  const { data } = await secureDelete(`/ci/api/device/delete/${id}`);
  return data;
};

export const getCommerciaux = async () => {
  const url = '/ci/api/GetCommerciaux';
  const { data } = await securePost(url);
  return data;
};

export const addDevice = async (device) => {
  const { data } = await securePost('/ci/api/device/add', device);
  return data;
};

export const updateClient = async (client) => {
  const { data } = await securePut('/ci/api/client/update', client);
  return data;
};

export const updateExtra = async ({ eF, productId }) => {
  const { data } = await securePost('/ci/api/product/extra-fields', { extraFields: eF, id: productId });
  return data;
};

export const updateOil = async ({ isOil, productRefs }) => {
  const time = new Date().getTime() + 2 * 60000;
  const { data } = await securePost('/ci/api/product/change-oil', { oil: !!isOil, productRefs, timestamp: time });
  return data;
};
