import React from 'react';
import { Box, Button } from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const PaginationActions = ({
  indexCard, classes, prevElem, nextElem, isNextPage, cardPerSlide, size,
}) => {
  const isNext = isNextPage
    ? isNextPage(indexCard + cardPerSlide >= size)
    : indexCard + cardPerSlide >= size;

  return (
    <Box style={{ display: 'flex', rowGap: 10 }} className={classes.root}>
      <Button
        variant="contained"
        className={indexCard === 0 ? classes.nextButtonDisabled : classes.nextButtonEnabled}
        style={{ marginRight: 16 }}
        onClick={prevElem}
        disabled={indexCard === 0}
      >
        <ChevronLeftIcon />
        {indexCard === 0}
      </Button>
      <Button
        variant="contained"
        className={
                  isNext
                    ? classes.nextButtonDisabled
                    : classes.nextButtonEnabled
              }
        disabled={isNext}
        onClick={nextElem}
      >
        <ChevronRightIcon />
      </Button>
    </Box>
  );
};

export default PaginationActions;
