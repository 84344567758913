import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  notAssigned: {
    color: '#2188CC',
  },
  NotAssignedStatus: {
    background: '#EDF1F2',
    color: '#888888',
  },
  awaitingPicking: {
    background: '#FFECCC',
    color: '#CE8500',
  },
  awaitingValidation: {
    background: '#FFE0E0',
    color: '#E24D4D',
  },
  pickingInprogress: {
    background: '#E6E6F2',
    color: '#4A4AFF',
  },
  closed: {
    background: '#CDFFCD',
    color: '#007F00',
  },
  invoiced: {
    background: '#F8E8FB',
    color: '#CE66DF',
  },
  manque: {
    background: '#F8E8FB',
    color: '#CE66DF',
  },
}));

export default useStyles;
