import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import UserContext from 'Shared/UserContext';
import { getCLientByCode } from 'Components/Client/ClientServices';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import Identification from 'Components/Client/Identification';
import LegalInformation from 'Components/Client/LegalInformation';
import FinancialSituation from 'Components/Client/FinancialSituation';
import Settings from 'Components/Client/Settings';
import Contact from 'Components/Client/Contact';
import Note from 'Components/Client/Note';
import CloseIcon from '@mui/icons-material/Close';
import { defaultMessage } from '../RecoveryServices';
import useStyles from './style';

const RecoveryView = ({ handleClose, clientCode: id }) => {
  const {
    state: { user },
  } = useContext(UserContext);
  const isCommercialOrSales = ['COMMERCIAL', 'SALES'].includes(user.role);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const classes = useStyles();
  const getClientData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getCLientByCode(id);
      setClient(data);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [id, getCLientByCode, setLoading, setClient]);
  useEffect(() => {
    getClientData();
  }, [getClientData]);
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        open
        PaperProps={{
          style: {
            background: '#F9FAFC 0% 0% no-repeat padding-box',
            border: '1px solid #C5C5C5',
            borderRadius: 24,
          },
        }}
        BackdropProps={{
          style: {
            background: '#245283 0% 0% no-repeat padding-box',
            boxShadow: '0px 12px 26px #0000000D',
            opacity: 0.67,
          },
        }}
      >
        <DialogContent dividers style={{ padding: '30px 30px' }}>
          <Paper elevation={0} classes={{ root: classes.paper }}>
            <Box style={{ display: 'flex' }}>
              <Box style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <Typography
                  display="inline"
                  style={{ font: 'normal normal 600 26px Montserrat', color: '#000000' }}
                >
                  {client?.name}
                </Typography>
                <Chip
                  label={client?.zone}
                  style={{
                    background: ' #007BC6 0% 0% no-repeat padding-box',
                    borderRadius: 24,
                    font: 'normal normal 600 14px Montserrat',
                    color: '#FFFFFF',
                    textTransform: 'uppercase',
                    marginLeft: 12,
                  }}
                />
              </Box>
              <IconButton
                style={{
                  background: '#C5C5C5 0% 0% no-repeat padding-box',
                  borderRadius: 24,
                  opacity: 0.53,
                  height: 39,
                  width: 39,
                }}
                size="small"
                onClick={handleClose}
              >
                <CloseIcon style={{ color: '#707070' }} />
              </IconButton>
            </Box>
            <Typography
              style={{
                font: 'normal normal 600 20px Montserrat',
                color: '#000000',
                textTransform: 'capitalize',
                marginBottom: 16,
              }}
            >
              Client Code:
              {' '}
              {id}
            </Typography>
            <div className={classes.container}>
              <Tabs
                value={tab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
                size="small"
              >
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  label="Identification"
                  value={0}
                />
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  label="Informations légales"
                  value={1}
                />
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  label="Contacts"
                  value={2}
                />
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  label="Situation financière"
                  value={3}
                />
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  label="Paramètres"
                  value={4}
                />
                {!isCommercialOrSales && (
                  <Tab
                    classes={{
                      root: classes.tab,
                      selected: classes.tabSelected,
                      wrapper: classes.tabWrapper,
                    }}
                    label="Notes"
                    value={5}
                  />
                )}
              </Tabs>
            </div>
            <Box
              style={{
                background: tab === 2 ? 'transparent' : '#F7F7F7 0% 0% no-repeat padding-box',
                borderRadius: 12,
              }}
            >
              {tab === 0 && <Identification client={client} getClient={getClientData} />}
              {tab === 1 && <LegalInformation client={client} getClient={getClientData} />}
              {tab === 3 && <FinancialSituation client={client} />}
              {tab === 4 && <Settings client={client} getClient={getClientData} />}
              {tab === 2 && <Contact client={client} getClient={getClientData} />}
              {tab === 5 && !isCommercialOrSales && (
                <Note client={client} getClient={getClientData} />
              )}
            </Box>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RecoveryView;
