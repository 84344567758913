export const TYPES = ['FA',
  'MF',
  'SPECIALE',
];
export const PAYMENT_STATUS = [
  { value: 'payed', label: 'Réglé' },
  { value: 'not_payed', label: 'Non Réglée' },
  { value: 'partially_payed', label: 'Partiellement réglée' },
];
export const Fields = [
  {
    type: 'text',
    id: 'code',
    label: 'Code client',
    disabled: true,
  },
  {
    type: 'text',
    id: 'name',
    label: 'Name client',
  },
  {
    type: 'text',
    id: 'address',
    label: 'Adresse',
  },
  {
    type: 'autocomplete',
    id: 'city',
    label: 'Ville',
    listName: 'cities',
    filter: (option, value) => option
      ?.toLowerCase().includes(value),
    optionLabel: (option) => option,
  },
  {
    type: 'autocomplete',
    id: 'zone',
    label: 'Zone',
    listName: 'areas',
    filter: (option, value) => option
      ?.toLowerCase().includes(value),
    optionLabel: (option) => option,

  },
  {
    type: 'text',
    id: 'phone',
    label: 'Téléphone',
  },
  {
    type: 'text',
    id: 'email',
    label: 'Email',
  },
  {
    type: 'autocomplete',
    id: 'expedition',
    label: 'Moyen d’expédition par défaut',
    listName: 'expeditions',
    filter: (option, value) => option.name?.toLowerCase().includes(value),
    optionLabel: (option) => option.name,
  },
  {
    type: 'autocomplete',
    id: 'payment',
    label: 'Mode de paiement par défaut',
    listName: 'paymentModes',
    filter: (option, value) => option.name?.toLowerCase().includes(value),
    optionLabel: (option) => option.name,
  },
  {
    type: 'autocomplete',
    id: 'type',
    label: 'Souche par défaut',
    listName: 'types',
    filter: (option, value) => option?.toLowerCase().includes(value),
    optionLabel: (option) => option,
  },
  {
    type: 'text',
    id: 'ice',
    label: 'ICE',
  },
  {
    type: 'text',
    id: 'rc',
    label: 'Identifiant fiscal',
  },
  {
    type: 'text',
    id: 'patente',
    label: 'Patente',
  },
];
