import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Grid, IconButton, Typography,
} from '@mui/material';

export const formatDate = (date) => [date.getDate(), date.getMonth() + 1, date.getFullYear()].join('/');

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  color: '#727CA2',
  fontSize: '12px',
  fontWeight: 500,
  background: '#F8F8FF',
  borderBottom: 'none',
  textTransform: 'uppercase',
};
const defaultColumnStyle = { color: '#333D66', fontSize: '14px' };
export const columns = (onHandleRemoveAnnouce) => ([
  {
    id: 'title',
    label: 'Titre',
    style: TitleStyle,
    order: 'title',
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'startDate',
    label: 'Date début',
    style: TitleStyle,
    order: 'startDate',
    render:
        ({ value }) => (
          <Typography sx={defaultColumnStyle}>
            {formatDate(new Date(value))}
          </Typography>
        ),
  },
  {
    id: 'endDate',
    label: 'Date fin',
    style: TitleStyle,
    order: 'endDate',
    render:
        ({ value }) => (
          <Typography sx={defaultColumnStyle}>
            {formatDate(new Date(value))}
          </Typography>
        ),
  },
  {
    id: 'type',
    label: 'Type',
    style: TitleStyle,
    order: 'type',
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'remise',
    label: 'Taux de remise',
    style: TitleStyle,
    order: 'remise',
    // eslint-disable-next-line prefer-template
    render: ({ value }) => <Typography sx={{ ...defaultColumnStyle, color: '#27AE60', fontWeight: 'bold' }}>{value && '-' + value + '%'}</Typography>,
  },
  {
    id: 'action',
    label: 'Actions',
    style: TitleStyle,
    render: ({ row }) => (
      <Grid direction="row" spacing={1}>
        <IconButton onClick={onHandleRemoveAnnouce(row.id)} aria-label="delete" size="large">
          <DeleteIcon />
        </IconButton>
      </Grid>
    ),
  },
]);
