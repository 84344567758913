import axios from 'axios';
import history from './history';

export const baseURL = '/';
const api = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  // timeout: 5000,
});

export const getToken = () => new Promise((resolve) => {
  resolve(localStorage.getItem('token'));
});

const checkNotAuthored = async (err) => {
  if (err.response?.status === 403 || err.response?.status === 401) {
    history.push('/login');
  } else {
    throw err;
  }
};

export const secureGet = async (url, config = {}, params = {}) => {
  const token = await getToken();
  const { headers, ...restConfig } = config;
  const conf = {
    headers: {
      ...headers,
      Authorization: token,
    },
    ...restConfig,
    params,
  };
  return api
    .get(`${url}${url.includes('?') ? '&' : '?'}token=${token}`, conf)
    .catch(checkNotAuthored);
};

export const secureDelete = async (url) => {
  const token = await getToken();
  return api
    .delete(`${url}${url.includes('?') ? '&' : '?'}token=${token}`, {
      headers: { Authorization: token },
    })
    .catch(checkNotAuthored);
};

export const securePost = async (url, data, config = {}) => {
  const token = await getToken();
  const { headers, ...restConfig } = config;
  const conf = {
    headers: {
      ...headers,
      Authorization: token,
    },
    ...restConfig,
  };
  return api
    .post(`${url}${url.includes('?') ? '&' : '?'}token=${token}`, data, conf)
    .catch(checkNotAuthored);
};

export const securePatch = async (url, data) => {
  const token = await getToken();
  return api
    .patch(`${url}${url.includes('?') ? '&' : '?'}token=${token}`, data, {
      headers: { Authorization: token },
    })
    .catch(checkNotAuthored);
};

export const securePut = async (url, data) => {
  const token = await getToken();
  return api
    .put(`${url}${url.includes('?') ? '&' : '?'}token=${token}`, data, {
      headers: { Authorization: token },
    })
    .catch(checkNotAuthored);
};

export default api;
