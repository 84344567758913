import React, { useRef } from 'react';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CalculateIcon from '@mui/icons-material/Calculate';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';

import {
  Popper,
  Fade,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import useOnClickOutside from 'Shared/Hooks/useClickOutSide';
import { makeStyles } from '@mui/styles';

const useStylesActions = makeStyles(() => ({
  action: {
    display: 'flex', padding: '10px 15px', cursor: 'pointer', margin: '3px 0',
  },
  actionLabel: { marginLeft: 5, color: '#38485C', font: 'normal 600 16px Montserrat' },
}));
const ActionsDocuments = ({
  isOpen, onHandleDelete, onClose, anchorEl, onHandleCalculate, type, onConvertToBL,
}) => {
  const classes = useStylesActions();
  const refActions = useRef();
  useOnClickOutside(refActions, (e) => onClose({})(e));

  if (!isOpen) return null;
  return (
    <Popper
      sx={{ zIndex: 1200 }}
      open={isOpen}
      anchorEl={anchorEl}
      placement="bottom-end"
      transition
      ref={refActions}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <Box onClick={onClose} className={classes.action}>
              <RemoveRedEyeOutlinedIcon />
              <Typography className={classes.actionLabel}>Consulter</Typography>
            </Box>
            <Box onClick={onHandleCalculate} className={classes.action}>
              <CalculateIcon />
              <Typography className={classes.actionLabel}>Calculer...</Typography>
            </Box>
            <Box onClick={onClose} className={classes.action}>
              <EditOutlinedIcon />
              <Typography className={classes.actionLabel}>Modifier</Typography>
            </Box>
            <Box className={classes.action} sx={{ color: '#F86060' }} onClick={onHandleDelete}>
              <DeleteForeverOutlinedIcon />
              <Typography className={classes.actionLabel} style={{ color: '#F86060' }}>
                Supprimer
              </Typography>
            </Box>
            {type === 11 && (
            <Box onClick={onConvertToBL} className={classes.action}>
              <ChangeCircleOutlinedIcon />
              <Typography className={classes.actionLabel}>Converte to BL</Typography>
            </Box>
            )}
          </Paper>
        </Fade>
      )}

    </Popper>
  );
};

export default ActionsDocuments;
