import React, { useContext, useMemo } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import ReferencialContext from 'Shared/ReferencialContext';
import RelatedProduct from '../RelatedProduct';
import useStyles from './styles';

const CopimaRelatedProducts = ({ tecdocRef }) => {
  const {
    state: { referencial },
  } = useContext(ReferencialContext);
  const classes = useStyles();

  const relatedProducts = useMemo(() => {
    const { products } = referencial;
    return products?.filter(
      (po) => po.refT?.toLowerCase().includes(tecdocRef)
      || po.reference?.toLowerCase().includes(tecdocRef),
    ) || [];
  }, [referencial, tecdocRef]);

  return (
    <Box className={classes.relatedContainer}>
      {relatedProducts.map((po) => (
        <RelatedProduct
          product={po}
          key={po?.id}
          handleClick={() => null}
        />
      ))}
      {relatedProducts.length === 0 && (
      <Box style={{ width: '100%' }}>
        <Typography style={{ textAlign: 'center' }}>
          Pas de produits équivalents
        </Typography>
      </Box>
      )}
    </Box>
  );
};

export default CopimaRelatedProducts;
