import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  filter: {
    marginRight: 16,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    width: 200,
  },
  notched: {
    border: 'none',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
}));

export default useStyles;
