import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import { toast } from 'react-toastify';
import ButtonApp from 'Shared/Button';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useHistory } from 'react-router-dom';
import DuplicateRowPopup from './DuplicateRowPopup';
import { defaultMessage, getExportProposition, importProductOrderQuantitiesByFile } from '../PropositionServices';

const options = ['Upload', 'Download'];
const optionsAdd = ['Ajouter Un Produit', 'Ajouter Un Produit Manuel'];

export default function ActionButton({
  id, name, classes, isPropositionValidated, onHandleNewProduct,
}) {
  const [open, setOpen] = React.useState(false);
  const [openDuplicate, setOpenDuplicate] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const anchorRef = React.useRef(null);
  const addRef = React.useRef(null);
  const file = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const history = useHistory();

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleFireUpload = () => {
    file.current.click();
  };
  const onHandleUpload = async (event) => {
    event.stopPropagation();
    const f = event.target.files[0];
    try {
      const data = await importProductOrderQuantitiesByFile(f, id);
      if (data.statusCode === '400' && data.statusLabel === 'Duplicated reference') {
        setOpenDuplicate(true);
      } else {
        toast.success('Fichier importé avec succès');
      }
    } catch (error) {
      toast.error(error);
    } finally {
      file.current.value = '';
    }
  };

  const DownloadProposition = async (event) => {
    event.stopPropagation();
    try {
      await getExportProposition(id, name);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    if (index === 1) {
      DownloadProposition(event);
    }
    if (index === 0) {
      handleFireUpload();
    }
    setOpen(false);
  };

  const onOpenAdd = () => {
    setOpenAdd(!openAdd);
  };
  const handleMenuItemAdd = (event, index) => {
    if (index === 0) {
      history.push(`/catalog?proposition-id=${id}&proposition-name=${name}`);
    }
    if (index === 1) {
      onHandleNewProduct();
    }
    onOpenAdd();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCloseAdd = (event) => {
    if (addRef.current && addRef.current.contains(event.target)) {
      return;
    }
    onOpenAdd();
  };

  return (
    <>
      {openDuplicate && <DuplicateRowPopup onClose={() => setOpenDuplicate(false)} />}
      <input type="file" ref={file} style={{ display: 'none' }} onChange={onHandleUpload} />
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          <ButtonGroup
            variant="outlined"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
            size="small"
            style={{
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              boxShadow: '0px 4px 10px #108CD629',
              border: '1px solid #138FD9',
              borderRadius: 12,
              opacity: 1,
            }}
          >
            <Button
              style={{
                borderLeft: 'none',
                borderTop: 'none',
                borderBottom: 'none',
                fontSize: '1rem',
                padding: '4px 10px',
              }}
              onClick={handleClick}
              size="small"
            >
              <CloudQueueIcon style={{ color: '#138FD9' }} />
            </Button>
            <Button
              style={{
                border: 'none',
                fontSize: '0.7rem',
                padding: '4px 8px',
                position: 'relative',
              }}
              color="primary"
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <Box
                style={{
                  width: 1,
                  position: 'absolute',
                  left: 0,
                  height: '82%',
                  top: 3,
                  backgroundColor: '#e4e4e4',
                }}
              />
              <ArrowDropDownIcon style={{ color: '#138FD9' }} />
            </Button>
          </ButtonGroup>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          disabled={index === 2}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
      <Box className={classes.btn} ref={addRef}>
        <ButtonApp
          label="Ajouter"
          startIcon={<AddIcon />}
          endIcon={<ExpandMoreIcon />}
          onClick={onOpenAdd}
          disabled={isPropositionValidated}
        />
      </Box>
      <Popper
        open={openAdd}
        anchorEl={addRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseAdd}>
                <MenuList id="split-button-menu">
                  {optionsAdd.map((option, index) => (
                    <MenuItem
                      key={option}
                      // selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemAdd(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
