import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { format } from 'date-fns';
import useStyles from './style';

const Note = ({
  classes,
  firstName,
  lastName,
  createdOn,
  source,
  anomaly,
  comment,
  isSubNote = false,
}) => (
  <>
    {isSubNote ? <Divider className={classes.divNote} /> : null}
    <Box className={classes.header}>
      <Typography className={classes.addedBy}>{`${firstName} ${lastName}`}</Typography>
      <Typography className={classes.addedBy} style={{ color: '#818181' }}>
        {' '}
        {`- ${format(new Date(createdOn), 'dd/MM/yyyy')}`}
      </Typography>
    </Box>
    { isSubNote ? null : <Typography className={classes.typeAno}>{`${source} (${anomaly})`}</Typography>}
    <Typography className={classes.desc}>{comment}</Typography>
  </>
);

const Anomaly = ({
  id, firstName, lastName, createdOn, source, anomaly, comment, onHandleAddNote, anomalies = [],
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Note
        firstName={firstName}
        lastName={lastName}
        createdOn={createdOn}
        source={source}
        anomaly={anomaly}
        comment={comment}
        classes={classes}
      />
      {anomalies?.map((note) => (<Note {...note} classes={classes} isSubNote />))}
      <Typography
        onClick={() => onHandleAddNote(id, source, anomaly)}
        className={classes.addNote}
      >
        +AJOUTER NOTE
      </Typography>
    </Box>
  );
};

export default Anomaly;
