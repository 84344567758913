import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  rootInput: {
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    border: '1px solid #007BC6',
    borderRadius: 12,
  },
  input: {
    border: 'none',
    font: 'normal normal 500 16px Montserrat',
    color: '#000000',
  },
  notchedOutline: {
    border: 'none',
  },
  label: {
    font: 'normal normal 600 12px Montserrat',
    color: '#007BC6',
    textTransform: 'uppercase',
  },
  scrollPaper: {
    height: 'fit-content',
    alignItems: 'flex-start',
  },
}));

export default useStyles;
