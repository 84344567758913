/* eslint-disable max-len */
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Box, Popper, TextField, Autocomplete,
} from '@mui/material';
import useForm from 'Shared/Hooks/useForm';
import { makeStyles } from '@mui/styles';
import DialogCmp from 'Shared/Dialog';
import { columns, roles } from './constans';
import { defaultMessage, getUsers, editUser } from '../SettingsServices';
import { getCommerciaux } from '../../Recovery/RecoveryServices';

const useStyles = makeStyles(() => ({
  filter: {
    marginRight: 16,
    flexGrow: 1,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
  },
  notched: {
    border: 'none',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  filterZone: {
    display: 'flex',
    background: '#E9EBED 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    padding: '10px 7px',
    flexDirection: 'column',
  },
}));

const PopperMy = (props) => <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />;
const UserEdit = ({
  open, handleClose, user, commercials,
}) => {
  const { values, onChange, setValues } = useForm({});
  const classes = useStyles();
  useEffect(() => {
    if (commercials) {
      const commercial = commercials.find(({ id }) => (id === user.sellerId));
      setValues({ ...user, seller: commercial });
    }
  }, [user, commercials]);
  const saveEditedUser = useCallback(
    async (body) => {
      try {
        // setLoading(true);
        await editUser(body);
        toast.success('Utilisateur modifer avec succès');
      } catch (e) {
        if (e.message !== 'Cancel') {
          const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
          toast.error(statusLabel);
        }
      }
    },
    [],
  );

  const onHandleSubmit = () => {
    saveEditedUser({ ...values, sellerId: values.seller?.id });
    handleClose(true);
  };
  const onChangeCommercial = (e, value) => {
    onChange({ target: { name: 'seller', value } });
  };
  const onChangeRole = (e, value) => {
    onChange({ target: { name: 'role', value } });
  };
  return (
    <DialogCmp
      handleClose={handleClose}
      open={open}
      title="Modifier Un Utilisateur"
      onHandleSubmit={onHandleSubmit}
    >
      <Box className={classes.filterZone}>
        <Box style={{ display: 'flex' }}>
          <TextField
            variant="outlined"
            onChange={onChange}
            value={values.firstName}
            name="firstName"
            label="Prénom"
            size="small"
            className={classes.filter}
            InputProps={{
              classes: { notchedOutline: classes.notched },
            }}
            InputLabelProps={{
              classes: { root: classes.label },
            }}
          />
          <TextField
            variant="outlined"
            onChange={onChange}
            value={values.lastName}
            name="lastName"
            label="Nom"
            size="small"
            className={classes.filter}
            InputProps={{
              classes: { notchedOutline: classes.notched },
            }}
            InputLabelProps={{
              classes: { root: classes.label },
            }}
          />
          <TextField
            variant="outlined"
            onChange={onChange}
            value={values.username}
            name="username"
            label="Nom d'utilisateur"
            size="small"
            className={classes.filter}
            InputProps={{
              classes: { notchedOutline: classes.notched },
            }}
            InputLabelProps={{
              classes: { root: classes.label },
            }}
          />
        </Box>
        <Box style={{ marginTop: '20px', display: 'flex' }}>
          <TextField
            variant="outlined"
            onChange={onChange}
            value={values.email}
            name="email"
            label="Email"
            size="small"
            className={classes.filter}
            InputProps={{
              classes: { notchedOutline: classes.notched },
            }}
            InputLabelProps={{
              classes: { root: classes.label },
            }}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            autoSelect
            classes={{ root: classes.filter }}
            style={{ width: 150 }}
            options={commercials || []}
            onChange={onChangeCommercial}
            value={values.seller}
            size="small"
            autoHighlight
            getOptionLabel={(option) => `${option.name}`}
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Commercial"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            autoSelect
            classes={{ root: classes.filter }}
            style={{ width: 150 }}
            options={roles}
            onChange={onChangeRole}
            value={values.role}
            size="small"
            autoHighlight
              // getOptionLabel={(option) => `${option.name}`}
            filterOptions={(options, v) => options.filter((option) => option?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Service"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
      </Box>
    </DialogCmp>
  );
};

const Users = () => {
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [userToEdit, setUserToEdit] = useState({});
  const [commercials, setCommercials] = useState([]);
  useEffect(async () => {
    try {
      const data = await getCommerciaux();
      setCommercials(data);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    }
  }, []);
  const getUserList = useCallback(
    async (sort) => {
      try {
        setLoading(true);
        const data = await getUsers(sort);
        setUserData(data);
      } catch (e) {
        if (e.message !== 'Cancel') {
          const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
          toast.error(statusLabel);
        }
      } finally {
        setLoading(false);
      }
    },
    [setUserData, setLoading],
  );
  const onHandleEdit = (user) => {
    setUserToEdit(user);
    setOpenEdit(true);
  };
  const onHandleClose = (shouldUpdate) => {
    setOpenEdit(false);
    setUserToEdit({});
    if (shouldUpdate) {
      getUserList({ page: 0, size: 10 });
    }
  };
  return (
    <Box marginTop={3}>
      {loading && <GlobalLoader size="100vh" />}
      <UserEdit handleClose={onHandleClose} open={openEdit} user={userToEdit} commercials={commercials} />
      <EnhancedTable
        getData={getUserList}
        rows={userData?.content || []}
        headCells={columns(onHandleEdit)}
        count={userData?.totalElements}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </Box>
  );
};

export default Users;
