import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import useStyles from './style';

const inNullOrUndefined = (value) => value === null || value === undefined;

export const DateCopima = ({
  disabled, label, value, name, handleChange, styleLabelDate, clearable, styleInput = {}, ...rest
}) => {
  const classes = useStyles();

  return (
    <DatePicker
      disabled={disabled}
      size="small"
      label={label}
      value={value}
      slotProps={{
        field: { clearable: !inNullOrUndefined(clearable) ? clearable : true },
        textField: {
          className: classes.date,
          style: { maxWidth: 200, ...styleInput },
          size: 'small',
          InputProps: {
            classes: { notchedOutline: classes.notched },
          },
        },
      }}
      name={name}
      format="dd/MM/yyyy"
      onChange={handleChange}
      views={['year', 'month', 'day']}
      {...rest}
    />
  );
};

const RangeDate = ({
  text, value, handleChange, disabled = false, noMarginRightEnd = false, styleInput = {},
}) => (
  <>
    <DateCopima
      disabled={disabled}
      label={text.start}
      value={value.start}
      maxDate={value.end || new Date()}
      name="start"
      clearable
      onChange={(date) => handleChange(date, true)}
      styleInput={styleInput}
    />
    <DateCopima
      disabled={disabled}
      label={text.end}
      value={value.end}
      minDate={value.start || new Date()}
      name="end"
      clearable
      onChange={(date) => handleChange(date, false)}
      styleInput={noMarginRightEnd ? { marginRight: 0 } : styleInput}
    />
  </>
);

export default RangeDate;
