import { addProductToPropositions, getPropositionProductIds } from 'Components/Proposition/PropositionServices';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useMatchPropositionToProducts = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const propositionId = queryParams.get('proposition-id');
  const propositionName = queryParams.get('proposition-name');

  useEffect(async () => {
    if (propositionId) {
      await getPropositionProductIds(propositionId, setSelectedIds);
    }
  }, [propositionId]);

  const submitSelectedIds = useCallback(async (callback, params) => {
    const { productIds, oldProductIds } = params;
    const onlyNewOnes = productIds.filter((id) => !oldProductIds.includes(id));
    if (onlyNewOnes.length === 0) {
      return;
    }
    const productQuantities = onlyNewOnes.map((productId) => ({ productId, quantity: 1 }));
    setSelectedIds([]);
    await addProductToPropositions(propositionId, productQuantities);
    if (typeof callback === 'function') {
      callback();
    }
  }, []);

  const isPropositionToProductsMode = !!propositionName && !!propositionId;

  return [selectedIds, submitSelectedIds, isPropositionToProductsMode, propositionName];
};

export default useMatchPropositionToProducts;
