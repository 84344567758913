import { secureGet, securePost } from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getAnomalies = async (orderReference, pieceBL, pieceBC) => {
  const url = `/ci/api/anomaly/${orderReference}?pieceBL=${pieceBL}&pieceBC=${pieceBC}`;
  const { data } = await secureGet(url);
  return data;
};

export const addAnomaly = async (anomaly, orderId, orderReference, parentId = -1) => {
  const { data } = await securePost(`ci/api/anomaly/${orderId}/${orderReference}/${parentId}`, anomaly);
  return data;
};
