import React from 'react';
import { Typography } from '@mui/material';

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};
export const columns = [
  {
    id: 'name',
    label: 'Intitulé client',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>{value}</Typography>
    ),
    order: true,
  },
  {
    id: 'code',
    label: 'Code Client',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>{value}</Typography>
    ),
    order: true,
  },
  {
    id: 'city',
    label: 'Ville',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    order: true,
  },
  {
    id: 'expedition',
    label: 'Transport',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    order: true,
  },
  {
    id: 'payment',
    label: 'Mode de règlement',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    order: true,
  },
  {
    id: 'encours',
    label: 'Encours Pièces moteur',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    order: true,
  },
  {
    id: 'encoursHuile',
    label: 'Encours Huile',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    order: true,

  },
];
