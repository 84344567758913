import { Box, IconButton } from '@mui/material';
import React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const activeStyle = {
  color: 'white',
  background:
        'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
  boxShadow: '0px 4px 10px #108CD650',
  borderRadius: 12,
};
const inactiveStyle = {
  background: '#D7DFE4 0% 0% no-repeat padding-box',
  borderRadius: 12,
  color: '#286183',
};
const PageActions = ({ page, setPage, isLast }) => (
  <Box sx={{ textAlign: 'right', mt: 1, mb: 1.5 }}>
    <IconButton
      color="primary"
      style={{ ...(page === 0 ? inactiveStyle : activeStyle), marginRight: 16 }}
      component="span"
      size="small"
      onClick={() => setPage(page - 1)}
      disabled={page === 0}
    >
      <KeyboardArrowLeftIcon />
    </IconButton>
    <IconButton
      color="primary"
      style={isLast ? inactiveStyle : activeStyle}
      component="span"
      size="small"
      onClick={() => setPage(page + 1)}
      disabled={isLast}
    >
      <KeyboardArrowRightIcon />
    </IconButton>
  </Box>
);

export default PageActions;
