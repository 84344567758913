import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '1px solid #C5C5C5',
    borderRadius: '12px',
    opacity: 1,
    height: 80,
    padding: 15,
    marginBottom: 10,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  addedBy: {
    font: 'normal normal 600 18px/26px Montserrat',
    letterSpacing: 0,
    color: '#007BC6',
    textTransform: 'capitalize',
    flexGrow: 1,
  },
  time: {
    font: 'normal normal 600 12px Montserrat',
    letterSpacing: 0,
    color: '#697585',
  },
  comment: {
    font: 'normal normal medium 18px/26px Montserrat',
    color: '#000000',
    textTransform: 'capitalize',
  },
}));

export default useStyles;
