import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  item: {
    margin: '5px 0',
    padding: '5px 0',
    cursor: 'pointer',
    '&:hover': { background: 'rgba(50, 50, 50, 0.1)' },
    display: 'flex',
  },
  paper: {
    padding: '10px 20px',
    width: '250px',
  },
}));

export default useStyles;
