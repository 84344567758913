import React, { useState } from 'react';
import { Paper, Tab, Tabs } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SageOrders from './SageOrders';
import SalesOrders from './SalesOrders';
import SalesBlockedOrders from './SalesOrders/SalesBlockedOrders';
import useStyles from './style';
import DeletedSalesOrders from './DeletedSalesOrders';

const OrderList = () => {
  const { state } = useLocation();
  const [tab, setTab] = useState(state ? state.tab : 0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const classes = useStyles();
  return (
    <Paper elevation={0} classes={{ root: classes.paper }}>
      <div className={classes.container}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          classes={{ root: classes.tabs }}
          TabIndicatorProps={{ style: { backgroundColor: '#2188CC' } }}
        >
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            label="Mes commandes"
            value={0}
          />
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            label="Mes commandes brouillons"
            value={1}
          />
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            label="Mes commandes annulées"
            value={2}
          />
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            label="Mes commandes bloquées"
            value={3}
          />
        </Tabs>
      </div>
      {tab === 0 && <SageOrders />}
      {tab === 1 && <SalesOrders />}
      {tab === 2 && <DeletedSalesOrders />}
      {tab === 3 && <SalesBlockedOrders />}
    </Paper>
  );
};

export default OrderList;
