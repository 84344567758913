import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  commentContainer: {
    backgroundColor: '#F9F9F9',
    boxShadow: '0 2px 5px 0 rgba(20,27,56,0.1)',
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  commentList: {
    maxHeight: 200,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
}));

export default useStyles;
