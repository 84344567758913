import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ButtonApp from 'Shared/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FilePresentOutlinedIcon from '@mui/icons-material/FileCopy';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';

export const useBasicInputStyles = (
  large, hasError, isRegular,
) => makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    marginTop: '6.5px',
    height: '48px',
    border: '2px dashed #C5C5C5',
    borderRadius: '12px',
    paddingLeft: '14px',
    paddingRight: '14px',
  },
  label: {
    color: '#C5C5C5',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    font: 'normal 300 13px Montserrat',
    display: 'flex',
    alignItems: 'center',
  },
  border: {
    borderColor: hasError ? '#EA2027' : '#CCCCCC',
    borderBottomRightRadius: isRegular && 0,
    borderBottomLeftRadius: isRegular && 0,
  },
}))();
const InputFile = ({
  name, label, large, hasError, onChange, value,
}) => {
  const classes = useBasicInputStyles(large, hasError);
  const onReset = () => {
    // event.stopPropagation();
    onChange({ target: { name, value: null } });
  };
  return (
    <>
      <Box
        component="label"
        for={`file-upload-${name}`}
        className={classes.root}
      >
        <Box className={classes.label}>
          { value ? (
            <>
              <FilePresentOutlinedIcon style={{ color: '#D09830' }} />
              {value}
            </>
          ) : (
            <>
              <ImageOutlinedIcon />
              {label}
            </>
          )}
        </Box>
        <RenderOnDemandThenHide visible={!!value}>
          <ButtonApp
            startIcon={<DeleteIcon />}
            label="Supprimer"
            onClick={onReset}
            style={{
              background: 'transparent',
              boxShadow: 'none',
              color: '#F86060',
              padding: 0,
            }}
          />
        </RenderOnDemandThenHide>
      </Box>
      <input name={name} onChange={onChange} hidden id={`file-upload-${name}`} type="file" />
    </>
  );
};

export default InputFile;
