import {
  secureDelete, secureGet, securePost, securePut,
} from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getAnnoncementsApi = async (search) => {
  const { data } = await secureGet(`/ci/api/announcements${search ? `?title=${search}` : ''}`);
  return data;
};

export const addAnnoncementsApi = async (body) => {
  const { data } = await securePut('/ci/api/announcements/add', body);
  return data;
};

export const addAttachment = async (file) => {
  const bodyFormData = new FormData();
  bodyFormData.set('attachment', file);

  const { data } = await securePost('/ci/api/do_upload', bodyFormData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }, false);
  return data;
};
export const deleteAnnouncementApi = async (id) => {
  const { data } = await secureDelete(`/ci/api/announcements/delete/${id}`);
  return data;
};
