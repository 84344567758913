import React from 'react';
import Announcement from './Announcement';

const Root = () => (
  <div style={{ height: '100vh' }}>
    <div style={{
      padding: '20px 50px',
      overflowY: 'auto',
    }}
    >
      <Announcement />
    </div>
  </div>
);

export default Root;
