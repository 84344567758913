import { makeStyles } from '@mui/styles';

export const useStylesRoot = makeStyles(() => ({
  root: {
    width: '380px',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 2000,
    height: '100%',
    background: '#FFFFFF',
    boxShadow: '0px 50px 32px #0000001C',
    padding: '15px 20px 0 20px',
  },
  title: {
    font: 'normal normal 600 20px Montserrat',
    alignSelf: 'center',
  },
  header: { display: 'flex', justifyContent: 'space-between' },
  notes: {
    height: 'calc(100vh - 115px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  newNote: { display: 'flex', flexDirection: 'column' },
  selectors: { marginBottom: 17 },
  sourceAnomaly: {
    color: '#007BC6', font: 'normal 600 18px Montserrat', opacity: 0.88, marginBottom: 9,
  },
  anomalyComment: {
    font: 'medium 16px Montserrat',
    marginTop: 3,
    padding: 12,
    border: '1px solid #C5C5C5',
    borderRadius: 12,
  },
}));

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #C5C5C5',
    borderRadius: '12px',
    padding: 10,
    marginBottom: 12,
  },
  header: { display: 'flex' },
  addedBy: {
    font: 'normal normal 600 15px Montserrat',
    color: '#007BC6',
  },
  typeAno: {
    font: 'normal normal 600 15px Montserrat',
  },
  desc: { font: 'normal normal medium 14px Montserrat', marginTop: 10 },
  addNote: {
    color: '#007BC6', marginTop: 7, fontSize: 9, cursor: 'pointer',
  },
  divNote: { marginTop: 6, marginBottom: 6 },
}));

export default useStyles;
