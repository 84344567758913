import { Box, Typography } from '@mui/material';
import DialogCmp from 'Shared/Dialog';
import React from 'react';
import InvoiceDetails from './InvoiceDetails';

const VentilationDetail = ({
  totalTTC, totalDocument, getAllDetails,
  isInvoiceDetailsOpen, onOpenInvoiceDetails, id, isInvoiceLoading, invoices,
}) => (
  <DialogCmp
    title={(
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginRight: '8px',
      }}
      >
        <Typography sx={{ font: 'normal 600 26px Montserrat' }}>N° document</Typography>
        <Typography sx={{
          padding: '5px 15px',
          paddingTop: '8px',
          background: '#007BC6',
          borderRadius: '40px',
          color: '#FFF',
          font: 'normal 600 14px Montserrat',
        }}
        >
          {`${totalTTC} Dh`}
        </Typography>
      </Box>
        )}
    subTitle={(
      <Typography sx={{ color: 'black', font: 'normal 600 16px Montserrat' }}>
        {`Total Frais ${totalDocument} Dh - ${document.fournisseur?.name}`}
      </Typography>
        )}
    endIcon={false}
    fullWidth={false}
    open={isInvoiceDetailsOpen}
    handleClose={onOpenInvoiceDetails}
    onHandleSubmit={onOpenInvoiceDetails}
    styleKo={{ display: 'none' }}
    styleOk={{ display: 'none' }}
  >
    <InvoiceDetails
      loading={isInvoiceLoading}
      invoices={invoices}
      totalFrais={totalDocument}
      totalTTC={totalTTC}
      folderId={id}
      folderName={document.folderCode || document.reference}
      callbackInvoices={getAllDetails}
    />
  </DialogCmp>
);

export default VentilationDetail;
