import { Box, Typography } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import ButtonApp from 'Shared/Button';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import { useStylesArticle } from '../style';

const FormBox = ({
  children, title, action, handleAction, handleAdd, isSearchOpen = false, isTop = false,
}) => {
  const classes = useStylesArticle();
  const btnStyle = {
    background: 'transparent',
    boxShadow: 'none',
    color: '#1793dd',
    padding: 0,
  };
  return (
    <Box className={classes.box} style={isTop ? { marginTop: -35 } : {}}>
      <Box className={classes.boxHeader}>
        <Typography className={classes.boxTitle}>{title}</Typography>
        <RenderOnDemandThenHide visible={!!action}>
          {!isSearchOpen ? (
            <ButtonApp
              startIcon={<AddIcon />}
              label={action}
              onClick={handleAction}
              style={btnStyle}
            />
          ) : (
            <Box className={classes.boxAction}>
              <ButtonApp startIcon={<CloseIcon />} label="Annuler" onClick={handleAction} style={{ ...btnStyle, color: '#767676' }} />
              <ButtonApp startIcon={<SaveIcon />} label="Enregistrer" onClick={handleAdd} style={{ ...btnStyle, color: '#00B894', marginLeft: 5 }} />
            </Box>
          )}
        </RenderOnDemandThenHide>
      </Box>
      {children}
    </Box>
  );
};

export default FormBox;
