import {
  Box, Paper, Tab, Tabs,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from 'Shared/UserContext';
import Products from './Products/Products';
import useStyles from './style';
import BuyerSettings from './BuyerSettings';
import SupplierSettings from './SupplierSettings';

const ProductSettings = () => {
  const { state } = useLocation();
  const {
    state: { user },
  } = useContext(UserContext);
  const isBUYER = user.role === 'BUYER';
  const isAdmin = user.role === 'ADMIN';
  const defaultTab = isBUYER ? 1 : 0;
  const [tab, setTab] = useState(state ? state.tab : defaultTab);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const classes = useStyles();
  return (
    <Box style={{ height: '100vh', overflow: 'auto' }}>
      <Paper elevation={0} classes={{ root: classes.paper }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
        >
          {isAdmin && (
            <Tab
              classes={{
                root: classes.tab,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              label="Produits"
              value={0}
            />
          )}
          {isBUYER && (
            <Tab
              classes={{
                root: classes.tab,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              label="Paramètre Produits"
              value={1}
            />
          )}
          {isBUYER && (
            <Tab
              classes={{
                root: classes.tab,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              label="Paramètre Fournisseur"
              value={2}
            />
          )}
        </Tabs>
        {tab === 0 && isAdmin && <Products />}
        {tab === 1 && isBUYER && <BuyerSettings />}
        {tab === 2 && isBUYER && <SupplierSettings />}
      </Paper>
    </Box>
  );
};

export default ProductSettings;
