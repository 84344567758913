import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  inputStyle: {
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    border: '1px solid #007BC6',
    borderRadius: '12px',
    '& fieldset': { border: 'none' },
  },
  labelStyle: {
    font: 'normal normal 600 12px Montserrat',
    color: '#007BC6',
    textTransform: 'uppercase',
    backgroundColor: '#f7f7f7',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
