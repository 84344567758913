import { Button, Grid, Typography } from '@mui/material';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext from 'Shared/ReferencialContext';
import RenderInput from 'Shared/RenderInput';
import { TYPES_COMMANDES } from 'Shared/constants/order';
import { Fields } from './constants';
import { defaultMessage, editOrder, getCommerciaux } from '../RecoveryServices';
import { refreshOrders } from '../action';
import ControleOrdersContext from '../context';

const OrderEdit = ({ order }) => {
  const {
    state: {
      referencial: { clients: clientList, expeditions, paymentModes },
    },
  } = useContext(ReferencialContext);
  const {
    dispatch,
  } = useContext(ControleOrdersContext);
  const history = useHistory();
  const { state: order_ } = useLocation();
  const currentOrder = useMemo(() => order || order_, [order, order_]);
  const [commercials, setCommercials] = useState([]);
  const [values, setValues] = useState(currentOrder);

  const [loading, setLoading] = useState(false);
  const handleInputChange = (prop) => (event, value) => {
    setValues({ ...values, [prop]: event instanceof Date ? event : value || event?.target.value });
  };
  useEffect(async () => {
    const data = await getCommerciaux();
    setCommercials(data);
  }, []);
  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      expedition:
        typeof currentOrder.expedition === 'string'
          ? (expeditions || []).find((e) => e.name === currentOrder.expedition)
          : currentOrder.expedition,
      payment:
        typeof currentOrder.payment === 'string'
          ? (paymentModes || []).find((e) => e.name === currentOrder.payment)
          : currentOrder.payment,
    }));
  }, [expeditions, paymentModes, currentOrder, setValues]);
  const lists = useMemo(
    () => ({
      expeditions: expeditions || [],
      paymentModes: paymentModes || [],
      sales: commercials || [],
      types: TYPES_COMMANDES,
    }),
    [clientList, expeditions, paymentModes],
  );
  const handleEdit = async () => {
    try {
      setLoading(true);
      await editOrder({
        ...values,
        id: values.reference,
        commercial: values.commercial.id,
        expedition: values.expedition?.code,
        paymentMode: values.paymentMode?.code,
      });
      toast.success('Commande modifié avec succès');
      history.push('/controle-orders');
      dispatch(refreshOrders(true));
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Grid container spacing={2} style={{ padding: order ? 0 : '20px', margin: 0, width: '100%' }}>
        {Fields.map((field) => (
          <Grid xs={order ? 3 : 6} item>
            <RenderInput
              input={
                field.type === 'autocomplete'
                  ? { ...field, [field.listName]: lists[field.listName] }
                  : field
              }
              value={values[field.id]}
              handleChange={handleInputChange(field.id)}
            />
            {field.type === 'text' && values[field.id]?.length > 255 && (
              <Typography style={{ color: 'red', fontSize: 11 }}>
                Attention, vous avez atteint la limite de 255 caractères.
              </Typography>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ padding: '20px', margin: 0, width: '100%' }}
        direction="row-reverse"
      >
        <Button variant="contained" color="primary" onClick={handleEdit}>
          Modifier
        </Button>
      </Grid>
    </>
  );
};

export default OrderEdit;
