import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  paper: {
    margin: '20px 10px 20px 25px',
    padding: 0,
    width: 700,
    background: 'transparent',
  },
  kpiContainer: {
    margin: '24px 104px',
  },
  tabs: {
    margin: '0 5px 30px 5px',
  },
  tab: {
    marginRight: 67,
    font: 'normal normal 600 14px Montserrat',
    color: '#707070',
    textTransform: 'capitalize',
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'left',
  },
  container: {
    background: '#E9EBED 0% 0% no-repeat padding-box',
    borderRadius: 12,
    padding: 2,
    marginBottom: 16,
  },
  tabSelected: {
    color: '#38485C !important',
    background: '#FFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
  },
}));

export default useStyles;
