import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './style';

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    headCells,
    expandColumn,
    expandedRows,
    headClass,
  } = props;
  const classess = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className={headClass}>
      <TableRow style={{ borderRadius: 12, overflow: 'hidden' }}>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.order ? order : false}
            classes={{ stickyHeader: classess.stickyHeader }}
            style={headCell.parentStyle || headCell.style}
            onClick={headCell.onClick ? headCell.onClick : null}
          >
            {headCell.renderHeader ? (
              headCell.renderHeader({ expandColumn, index, expandedRows })
            ) : (
              <>
                {headCell.order && (
                  <TableSortLabel
                    active={orderBy === headCell.order}
                    direction={orderBy === headCell.order ? order : 'asc'}
                    onClick={createSortHandler(
                      typeof headCell.order === 'string' ? headCell.order : headCell.id,
                    )}
                    className={classes.title}
                  >
                    {headCell.label}
                  </TableSortLabel>
                )}
                <span className={classes.title}>{!headCell.order && headCell.label}</span>
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;
