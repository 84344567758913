import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  paper: {
    margin: '20px 10px 20px 25px',
    padding: 0,
    background: 'transparent',
  },
  kpiContainer: {
    margin: '24px 104px',
  },
  tabs: {
    margin: '0 5px 30px 5px',
    font: 'normal normal 600 13px Montserrat',
  },
  tab: {
    textTransform: 'initial',
    color: '#2F2F2F',
    padding: '0 30px',
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'left',
  },
  container: {
    margin: '0 24px',
  },
  tabSelected: {
    color: '#2188CC !important',
    background: '#E9EBED 0% 0% no-repeat padding-box',
    borderRadius: '12px 12px 0px 0px',
  },
}));

export default useStyles;
