import React, { useState } from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
// import { getArticles } from '../ExternSearchServices';
import DEFAULT_PRODUCT from 'Images/default_product.png';

const noFunc = () => null;
const ArticleCard = ({
  articleNumber,
  original,
  onHandleOpenProductDetail = noFunc,
  isRelated = false,
  ...articleOrg
}) => {
  const [article] = useState(articleOrg || {});
  const description = article.desc?.length > 0 ? article.desc[0]?.text?.join(',') : '';
  const srcImg = article.imgs?.length > 0 ? article.imgs[0]?.imageURL200 : null;
  const productName = article.name?.length > 0 ? article.name[0].genericArticleDescription : '';
  const [loading] = useState(false);
  /* useEffect(async () => {
    if (isRelated) {
      setLoading(true);
      try {
        const { articles: articleList } = await getArticles(
          articleNumber,
          undefined,
          undefined,
          {},
          1,
        );
        const uniqueOne = articleList[0];

        setArticle({
          imgs: uniqueOne.images,
          brand: uniqueOne.mfrName,
          name: uniqueOne.genericArticles,
          desc: uniqueOne.articleText,
          original: uniqueOne,
        });
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }, [articleNumber, isRelated]); */

  const onHandleClick = () => {
    onHandleOpenProductDetail({
      reference: !original ? article.original.articleNumber : original.articleNumber,
      des: article.name?.length > 0 ? article.name[0].genericArticleDescription : '',
      img: article.imgs?.length > 0 ? article.imgs[0]?.imageURL400 : null,
      brandName: article.brand,
      oemNumbers: !original ? article.original.oemNumbers : original.oemNumbers,
    });
  };
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 12px 26px #0000000D',
        borderRadius: '12px',
        margin: '10px',
        width: '280px',
        flexShrink: 0,
        cursor: 'pointer',
        position: 'relative',
        paddingBottom: 13,
      }}
      onClick={onHandleClick}
    >
      {isRelated && loading && <GlobalLoader size="100%" style={{ position: 'absolute' }} />}
      <Box style={{ flexGrow: 1 }}>
        <Box style={{ height: '170px' }}>
          <CardMedia
            component="img"
            src={srcImg || DEFAULT_PRODUCT}
            style={{
              width: 'unset',
              margin: 'auto',
              marginTop: 5,
              marginBottom: 5,
              maxHeight: 170,
            }}
          />
        </Box>
        <Box style={{
          display: 'flex', justifyContent: 'space-between', padding: 16, paddingTop: 0,
        }}
        >
          <Typography style={{ color: '#007BC6', textTransform: 'uppercase', font: 'Montserrat' }}>
            {article.category}
          </Typography>
          <Typography style={{ color: '#007BC6', textTransform: 'uppercase', font: 'Montserrat' }}>
            {article.brand}
          </Typography>
        </Box>
        <Typography
          style={{
            color: '#000000',
            textTransform: 'uppercase',
            padding: 16,
            paddingBottom: 2,
            font: 'bold 14px Montserrat',
          }}
        >
          {original?.articleNumber || article?.original?.articleNumber}
        </Typography>
        <Typography
          style={{
            color: '#000000',
            textTransform: 'uppercase',
            padding: 16,
            paddingTop: 2,
            font: 'normal 14px Montserrat',
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
          }}
        >
          {productName}
        </Typography>
        {false && (
          <Typography
            style={{
              color: '#000000',
              textTransform: 'uppercase',
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              padding: 16,
              paddingTop: 0,
              font: 'bold 12px Montserrat',
            }}
            variant="body1"
          >
            {description}
          </Typography>
        )}
        <Box style={{ borderTop: '1px solid #E7E7E7', width: '100%', margin: '10px 0' }} />
      </Box>
      <Box style={{ display: 'flex', cursor: 'pointer', justifyContent: 'center' }}>
        <AddShoppingCartIcon fontSize="small" style={{ color: '#38485C', marginRight: '5px' }} />
        <Typography
          style={{
            color: '#38485C',
            paddingTop: '1.5px',
            paddingBotom: '1.5px',
            textTransform: 'capitalize',
            font: 'normal 12px Montserrat',
          }}
        >
          Demander Un Achat
        </Typography>
      </Box>
    </Box>
  );
};

export default ArticleCard;
