import React from 'react';
import { Box, Typography } from '@mui/material';

const HeaderControlDetail = ({ order }) => (
  <Box
    style={{
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 12px 26px #0000000D',
      border: '1px solid #007BC6',
      borderRadius: '12px',
      height: '75px',
      padding: '19px 22px',
      boxSizing: 'border-box',
      display: 'flex',
      flexGrow: '1',
    }}
  >
    <Box style={{ flexGrow: 1, borderRight: '1px solid #007BC6' }}>
      <Typography
        style={{
          font: 'normal normal 600 12px/15px Montserrat',
          color: '#007BC6',
          textTransform: 'uppercase',
        }}
      >
        CONTROLLÉ PAR
      </Typography>
      <Typography
        style={{
          font: 'normal normal 600 17px/20px Montserrat',
          color: '#000000',
          textTransform: 'capitalize',
        }}
      >
        {order?.controlledBy
          ? `${order?.controlledBy?.firstName} ${order?.controlledBy?.lastName}`
          : ''}
      </Typography>
    </Box>
    <Box style={{ flexGrow: 1, borderRight: '1px solid #007BC6' }}>
      <Typography
        style={{
          font: 'normal normal 600 12px/15px Montserrat',
          color: '#007BC6',
          textTransform: 'uppercase',
          padding: '0 10px',
        }}
      >
        PIQUÉE PAR
      </Typography>
      <Typography
        style={{
          font: 'normal normal 600 17px/20px Montserrat',
          color: '#000000',
          textTransform: 'capitalize',
          padding: '0 10px',
        }}
      >
        {order?.picker}
      </Typography>
    </Box>
    <Box style={{ flexGrow: 1, borderRight: '1px solid #007BC6', padding: '0 10px' }}>
      <Typography
        style={{
          font: 'normal normal 600 12px/15px Montserrat',
          color: '#007BC6',
          textTransform: 'uppercase',
        }}
      >
        FACTURÉE PAR
      </Typography>
      <Typography
        style={{
          font: 'normal normal 600 17px/20px Montserrat',
          color: '#000000',
          textTransform: 'capitalize',
        }}
      >
        {order?.biller}
      </Typography>
    </Box>
    <Box style={{ flexGrow: 1, borderRight: '1px solid #007BC6', padding: '0 10px' }}>
      <Typography
        style={{
          font: 'normal normal 600 12px/15px Montserrat',
          color: '#007BC6',
          textTransform: 'uppercase',
        }}
      >
        DATE
      </Typography>
      <Typography
        style={{
          font: 'normal normal 600 17px/20px Montserrat',
          color: '#000000',
          textTransform: 'capitalize',
        }}
      >
        {order?.date && new Date(order?.date).toLocaleDateString('en-GB')}
      </Typography>
    </Box>
    <Box style={{ flexGrow: 1, padding: '0 10px' }}>
      <Typography
        style={{
          font: 'normal normal 600 12px/15px Montserrat',
          color: '#007BC6',
          textTransform: 'uppercase',
        }}
      >
        DEPOT
      </Typography>
      <Typography
        style={{
          font: 'normal normal 600 17px/20px Montserrat',
          color: '#000000',
          textTransform: 'capitalize',
        }}
      >
        {order?.repoId === 1 ? 'Bouskoura' : 'Derb Omar'}
      </Typography>
    </Box>
  </Box>
);

export default HeaderControlDetail;
