import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog({
  title, content, okLabelBtn, koLabelBtn, handleClose, handleClick, okIcon, okStyle = {},
}) {
  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
        </DialogContent>
        <DialogActions style={{ paddingRight: '37px', paddingBottom: '30px' }}>
          <Button onClick={handleClose} style={{ color: 'black' }} color="primary">
            { koLabelBtn || 'Non'}
          </Button>
          <Button onClick={handleClick} color="primary" style={okStyle} endIcon={!!okIcon && okIcon} autoFocus>
            { okLabelBtn || 'Oui' }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
