import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  input: {
    font: 'normal normal 600 26px Montserrat',
    padding: '4px 14px',
  },
  rootQuantity: {
    borderRadius: 14,
  },
}));

export default useStyles;
