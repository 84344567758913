import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

const FinancialSituation = ({ client }) => (
  <Box style={{ height: 330, width: '100%' }}>
    <Grid container spacing={2} style={{ padding: '20px', margin: 0, width: '100%' }}>
      <Grid xs={6} item>
        <Typography
          display="block"
          style={{
            font: 'normal normal 600 13px Montserrat',
            color: '#007BC6',
            textTransform: 'uppercase',
          }}
        >
          - Encours PM
        </Typography>
        <Typography
          style={{
            font: 'normal normal 600 18px Montserrat',
            color: '#000000',
            textTransform: 'capitalize',
          }}
        >
          {client.encours}
        </Typography>
      </Grid>
      <Grid xs={6} item>
        <Typography
          style={{
            font: 'normal normal 600 13px Montserrat',
            color: '#007BC6',
            textTransform: 'uppercase',
          }}
        >
          - Encours huile
        </Typography>
        <Typography
          style={{
            font: 'normal normal 600 18px Montserrat',
            color: '#000000',
            textTransform: 'capitalize',
          }}
        >
          {client.encoursHuile}
        </Typography>
      </Grid>
      <Grid xs={6} item>
        <Typography
          style={{
            font: 'normal normal 600 13px Montserrat',
            color: '#007BC6',
            textTransform: 'uppercase',
          }}
        >
          - Blocage impayé
        </Typography>
        <Typography
          style={{
            font: 'normal normal 600 18px Montserrat',
            color: '#000000',
            textTransform: 'capitalize',
          }}
        >
          {client.impaye !== 'Automatique' ? 'Oui' : 'Non'}
        </Typography>
      </Grid>
      <Grid xs={6} item>
        <Typography
          style={{
            font: 'normal normal 600 13px Montserrat',
            color: '#007BC6',
            textTransform: 'uppercase',
          }}
        >
          - Blocage relevés non réglés
        </Typography>
        <Typography
          style={{
            font: 'normal normal 600 18px Montserrat',
            color: '#000000',
            textTransform: 'capitalize',
          }}
        >
          {client.releve !== 'Automatique' ? 'Oui' : 'Non'}
        </Typography>
      </Grid>
      <Grid xs={6} item>
        <Typography
          style={{
            font: 'normal normal 600 13px Montserrat',
            color: '#007BC6',
            textTransform: 'uppercase',
          }}
        >
          - Blocage relevés non réglés
        </Typography>
        <Typography
          style={{
            font: 'normal normal 600 18px Montserrat',
            color: '#000000',
            textTransform: 'capitalize',
          }}
        >
          {client.releve !== 'Automatique' ? 'Oui' : 'Non'}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

export default FinancialSituation;
