/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import Layout from 'Shared/Layout';
import { authUrl } from 'Utils/authConfig';
import Referencial from 'Shared/Referencial';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const renderPage = (props) => {
    const token = localStorage.getItem('token');
    const roleStore = localStorage.getItem('user');
    const role = roleStore == null ? null : JSON.parse(roleStore).role;
    const PERMITTED_ROLES = [
      'ADMIN',
      'SALES',
      'COMMERCIAL',
      'RECOUVREMENT',
      'SAV',
      'REGLEMENT',
      'BUYER',
      'IMPORTER',
      'SELLER_SPECIAL',
    ];
    if (!token || role == null || !PERMITTED_ROLES.includes(role)) {
      window.location.href = authUrl;
      return null;
    }
    return (
      <Referencial>
        <Layout>
          <Component {...rest} {...props} />
        </Layout>
      </Referencial>
    );
  };
  return <Route {...rest} render={renderPage} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ProtectedRoute;
