/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { Button, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { format } from 'date-fns';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext from 'Shared/ReferencialContext';
import RenderInput from 'Shared/RenderInput';
import {
  addCLientContact, addNote, defaultMessage, deleteCLientContact, editCLient,
} from '../ClientServices';
import {
  BLOCKING, CONTACTS, NOTESTYPE, TYPES,
} from '../constants';
import { ExtraFields } from '../Contact/constants';

const Edit = ({
  fields, handleViewMode, client, currentMode, codeClient, name,
}) => {
  const {
    state: {
      referencial: { clients: clientList, expeditions, paymentModes },
    },
  } = useContext(ReferencialContext);
  const [values, setValues] = useState(client);
  const [contact, setContact] = useState({
    firstName: '', lastName: '', type: '', value: '',
  });
  const [contactExtra, setContactExtra] = useState([{}]);
  const [extraFields, setExtraFields] = useState([...ExtraFields]);
  const [loading, setLoading] = useState(false);
  const handleInputChange = (prop) => (event, value) => {
    if (typeof event?.target === 'object' && event?.target.type === 'file') {
      setValues({ ...values, [prop]: event?.target.files[0] });
      return;
    }

    if (['addContact', 'editContact'].includes(currentMode)) {
      setContact({ ...contact, [prop]: event instanceof Date ? event : value || event?.target.value });
    } else {
      setValues({ ...values, [prop]: event instanceof Date ? event : value || event?.target.value });
    }
  };

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      expedition: typeof client.expedition === 'string' ? (expeditions || []).find((e) => e.name === client.expedition) : client.expedition,
      payment: typeof client.payment === 'string' ? (paymentModes || []).find((e) => e.name === client.payment) : client.payment,
    }));
  }, [expeditions, paymentModes]);

  const lists = useMemo(() => ({
    cities: (clientList || []).map((c) => c.city).unique(),
    areas: (clientList || []).map((c) => c.zone).unique(),
    expeditions: expeditions || [],
    paymentModes: paymentModes || [],
    types: TYPES,
    blockings: BLOCKING,
    contacts: CONTACTS,
    noteType: NOTESTYPE,
  }),
  [clientList, expeditions, paymentModes]);
  const requestApi = (query) => {
    switch (query) {
      case 'addContact':
        // eslint-disable-next-line no-case-declarations
        const { /* type, value, */ ...restContact } = contact;
        // eslint-disable-next-line no-case-declarations
        let others = {};
        contactExtra.forEach((item) => {
          others = { ...others, [item.type.toLocaleLowerCase()]: item.value };
        });
        return addCLientContact({
          ...restContact,
          ...others,
          // [type.toLocaleLowerCase()]: value,
          clientCode: values.code,
        });
      case 'editContact':
        // eslint-disable-next-line no-case-declarations
        let othersx = {};
        contactExtra.forEach((item) => {
          othersx = { ...othersx, [item.type.toLocaleLowerCase()]: item.value };
        });
        return addCLientContact({
          ...contact,
          ...othersx,
          clientCode: codeClient,
        });

      case 'deleteContact':
        return deleteCLientContact(values?.code);

      case 'addNote':
        // eslint-disable-next-line no-case-declarations
        const formData = new FormData();
        if (values.file) { formData.append('attachment', values.file); }
        if (values.comment) { formData.append('comment', values.comment); }
        formData.append('clientCode', values.code);
        if (values.noteId) { formData.append('id', values.noteId); }
        return addNote(formData);

      default:
        return editCLient({
          ...values,
          expeditionCode: values.expedition?.code,
          paymentCode: values.payment?.code,
          blocage: name === 'setting' ? values.blocage === 'Automatique' ? 0 : 1 : values.blocage,
          blocageTerme: name === 'setting' && values.blocageTerme ? format(new Date(values.blocageTerme), "yyyy-MM-dd'T'HH:mm:ssXX") : values.blocageTerme,
        });
    }
  };

  const handleEdit = async () => {
    try {
      setLoading(true);
      await requestApi(currentMode);
      toast.success('Client modifié avec succès');
      handleViewMode();
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };

  const addExtraFields = () => {
    // const key = extraFields.length;
    setContactExtra([...contactExtra, {}]);
    setExtraFields([...extraFields, ...ExtraFields]);
  };
  /* const removeExtraFields = (key) => {
    setExtraFields(extraFields.filter((item) => item.key === key))
    // todo remove from contact
  } */
  const handleExtraInputChange = (prop, index) => (event, value) => {
    contactExtra[index] = { ...contactExtra[index], [prop]: value || typeof event === 'object' ? event?.target.value : event };
    setContactExtra([...contactExtra]);
  };

  useEffect(() => {
    if (currentMode === 'editContact') {
      setContact(client);
      const extContr = [];
      const extField = [];
      CONTACTS.forEach((field) => {
        if (client[field.toLocaleLowerCase()]) {
          extContr.push({ type: field, value: client[field.toLocaleLowerCase()] });
          extField.push(...ExtraFields);
        }
      });
      setContactExtra(extContr);
      setExtraFields(extField);
    }
  }, [currentMode]);

  const handleDeleteContactItem = (removedIndex) => {
    setContactExtra(contactExtra.filter((item, index) => {
      if (index !== removedIndex) {
        return true;
      }
      setContact({ ...contact, [item.type.toLocaleLowerCase()]: '' });
      return false;
    }));
    setExtraFields(extraFields.splice(0, extraFields.length - 2));
  };

  const fieldsNote = fields.filter((field) => (
    field.type === 'autocomplete'
    || (values?.noteType === NOTESTYPE[0] && field.type === 'text')
    || (values?.noteType === NOTESTYPE[1] && field.type === 'file')
  )).map((field) => (field.type === 'autocomplete' && values?.noteId ? { ...field, disabled: true } : field));

  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Grid container spacing={2} style={{ padding: '20px', margin: 0, width: '100%' }}>
        { (['addNote', 'editNote'].includes(currentMode) ? fieldsNote : fields).map((field) => (
          <Grid xs={6} item>
            <RenderInput
              input={{ ...(field.type === 'autocomplete' ? { ...field, [field.listName]: lists[field.listName] } : field), disabled: !field.disabled ? false : typeof field.disabled === 'function' ? field.disabled(values) : field.disabled }}
              value={['addContact', 'editContact'].includes(currentMode) ? contact[field.id] : values[field.id]}
              handleChange={handleInputChange(field.id)}
            />
          </Grid>
        ))}
        {['addContact', 'editContact'].includes(currentMode) && extraFields.map((field, index) => (
          <>
            <Grid xs={index % 2 !== 0 ? 5 : 6} item>
              <RenderInput
                input={field.type === 'autocomplete' ? { ...field, [field.listName]: lists[field.listName] } : field}
                value={contactExtra[Math.floor(index / 2)] && contactExtra[Math.floor(index / 2)][field.id]}
                handleChange={handleExtraInputChange(field.id, Math.floor(index / 2))}
              />
            </Grid>
            {index % 2 !== 0 && (
            <Grid xs={1} item>
              <IconButton
                aria-label="remove"
                onClick={() => handleDeleteContactItem(Math.floor(index / 2))}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            )}
          </>
        ))}
      </Grid>
      {['addContact', 'editContact'].includes(currentMode) && (
      <Grid container spacing={2} style={{ padding: '20px', margin: 0 }}>
        <Button disabled={extraFields.length >= 2 * 4} variant="contained" color="secondary" onClick={addExtraFields}>+ Moyen de contact</Button>
      </Grid>
      )}
      <Grid container spacing={2} style={{ padding: '20px', margin: 0, width: '100%' }} direction="row-reverse">
        <Grid item>
          <Button variant="contained" onClick={handleViewMode}>Annuler</Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleEdit}>Valider</Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Edit;
