/* eslint-disable max-len */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Grid, Popper, TextField, Autocomplete,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DatePicker from '@mui/lab/DatePicker';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext from 'Shared/ReferencialContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { defaultMessage, getCommerciaux } from '../SettingsServices';
import { STATUS } from './constants';

const PopperMy = (props) => (
  <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />
);

const useStyles = makeStyles(() => ({
  filter: {
    marginRight: 16,
    flexGrow: 1,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
  },
  date: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    height: 40,
  },
  notched: {
    border: 'none',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  formControlDate: {
    marginTop: '4px !important',
    marginLeft: 10,
  },
  labelDate: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
    top: -13,
    left: 18,
  },
  icon: {
    color: '#0681cc !important',
    fontSize: 22,
  },
  searchArea: {
    backgroundColor: '#E9EBED',
    padding: '16px',
    borderRadius: '0px 0px 12px 12px',
    display: 'flex',
    marginBottom: 15,
  },
}));

const Filters = ({ values, handleInputChange }) => {
  const classes = useStyles();
  const [commercials, setCommercials] = useState([]);
  const {
    state: {
      referencial: { clients = [] },
    },
  } = useContext(ReferencialContext);
  const [loading, setLoading] = useState(false);
  const getCommerciauxData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getCommerciaux();
      setCommercials(data);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setCommercials]);
  useEffect(() => {
    getCommerciauxData();
  }, [getCommerciauxData]);
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Grid container className={classes.searchArea} spacing={1} alignItems="center">
        <Grid item xs={3}>
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            fullWidth
            options={clients || []}
            onChange={handleInputChange('client')}
            value={values.client}
            size="small"
            autoHighlight
            getOptionLabel={(option) => `${option.code} : ${option.name}`}
            filterOptions={(options, v) => options.filter(
              (option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase())
                  || option.code?.toLowerCase().includes(v.inputValue.toLowerCase()),
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Client"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            fullWidth
            options={commercials || []}
            onChange={handleInputChange('commercial')}
            value={values.commercial}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Commerciale"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            fullWidth
            options={STATUS}
            onChange={handleInputChange('treated')}
            value={values.treated}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.label}
            filterOptions={(options, v) => options.filter((option) => option.label?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Statut"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
        </Grid>
        <Grid item xs={2} style={{ paddingRight: 5 }}>
          <DatePicker
            clearable
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            id="startDate"
            label="Début"
            className={classes.date}
            value={values.startDate || null}
            onChange={handleInputChange('startDate')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputProps={{
              disableUnderline: true,
              classes: { formControl: classes.formControlDate },
            }}
            InputLabelProps={{
              classes: { root: classes.labelDate },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={2} style={{ paddingLeft: 5 }}>
          <DatePicker
            fullWidth
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            id="endDate"
            label="Fin"
            className={classes.date}
            value={values.endDate || null}
            onChange={handleInputChange('endDate')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputProps={{
              disableUnderline: true,
              classes: { formControl: classes.formControlDate },
            }}
            InputLabelProps={{
              classes: { root: classes.labelDate },
            }}
            clearable
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Filters;
