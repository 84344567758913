import React from 'react';
import { Button, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};

export const BLOCKAGE_MANUEL = ['Automatique', 'Bloqué'];
export const BLOCKAGE_IMPAYE = ['Automatique', 'Bloqué par Systéme'];
export const BLOCKAGE_RELEVE = ['Automatique', 'Bloqué par Systéme'];
export const BLOCKAGE_RELEVE_H = ['Automatique', 'Bloqué par Systéme', 'Bloquer Manuel'];

export const mapBlockageManuelTOValue = (v) => {
  switch (v) {
    case 1:
      return 'Bloqué';
    case 0:
      return 'Automatique';
    default:
      return v;
  }
};
export const mapValueTOBlockage = (v) => {
  switch (v) {
    case 'Bloqué':
      return 1;
    case 'Automatique':
      return 1;
    default:
      return v;
  }
};
/* eslint-disable import/prefer-default-export */
export const columns = (onHandleEdit) => [
  {
    id: 'code',
    label: 'CODE CLIENT',
    style: TitleStyle,
    render: ({ row, value }) => (<Typography style={defaultColumnStyle}>{`${value} - ${row.name}`}</Typography>),
  },
  {
    id: 'encours',
    label: 'ENCOURS',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'encoursHuile',
    label: 'ENCOURS HUILE',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'nbrReleveAuthorise',
    label: 'NOMBRE DE RELEVÉ AUTORISÉ',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'blocked',
    label: 'BLOCKAGE MANNUEL',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>{value !== 1 ? 'Non' : 'Oui'}</Typography>
    ),
  },
  {
    id: 'impaye',
    label: 'BLOCKAGE IMPAYÉ',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'releve',
    label: 'BLOCKAGE RELEVÉ',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'huile',
    label: 'BLOCKAGE HUILE',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    render: ({ row }) => (
      <Button
        variant="outlined"
        color="primary"
        style={{
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          border: '1px solid #38485C',
          borderRadius: '12px',
          width: 30,
          padding: 5,
          minWidth: 'unset',
        }}
        onClick={() => onHandleEdit(row)}
      >
        <EditIcon fontSize="small" style={{ color: '#38485C' }} />
      </Button>
    ),
  },
];
