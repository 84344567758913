/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable max-len */
import {
  Box, Popper, TextField, Typography, Autocomplete,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, {
  useEffect, useContext, useMemo, useCallback, useState, useRef,
} from 'react';

import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext from 'Shared/ReferencialContext';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import { format } from 'date-fns';
import { fr } from 'date-fns/esm/locale';
import { getDatesInRange } from 'Utils/ulilities';
import ConnectedStore from 'Shared/ConnectedStore';
import { useHistory } from 'react-router-dom';
import AppendProductsToProposition from 'Components/Proposition/Add/Append';
import ButtonApp from 'Shared/Button';
import { DateCopima } from 'Shared/RangeDate';
import useStyles from './style';
import { defaultMessage, getProductDetails } from '../CatalogServices';
import {
  STOCK_STATUS,
  TitleStyle,
  columns,
  defaultColumnStyle,
  findStartAndEndDate,
} from './constants';
import './styles.css';
import ProductContext, { Provider, initialState } from './Context';
import { updateColumnIds } from './actions';
import ProductReducer from './reducer';
import useMatchPropositionToProducts from './useMatchPropositionToProducts';

const initFilters = {
  brand: [],
  family1: [],
  family2: [],
  family3: [],
  family4: [],
};
const PopperMy = (props) => <Popper {...props} style={{ width: 400 }} placement="bottom-start" />;
const ProductsTable = () => {
  const classes = useStyles();
  const {
    state: { referencial },
  } = useContext(ReferencialContext);
  const { brands } = referencial;
  const families1 = useMemo(() => {
    const { families = [] } = referencial;
    return families?.filter((family) => family.familyLevel === 1);
  }, [referencial.families]);
  const families2 = useMemo(() => {
    const { families = [] } = referencial;
    return families?.filter((family) => family.familyLevel === 2);
  }, [referencial.families]);
  const families3 = useMemo(() => {
    const { families = [] } = referencial;
    return families?.filter((family) => family.familyLevel === 3);
  }, [referencial.families]);
  const families4 = useMemo(() => {
    const { families = [] } = referencial;
    return families?.filter((family) => family.familyLevel === 4);
  }, [referencial.families]);
  const [values, setValues] = React.useState(initFilters);
  const [sort] = React.useState('');
  const [order] = React.useState('asc');
  const [search, setSearch] = React.useState(null);
  const [filtredBrands, setFiltredBrands] = React.useState();
  const [allChecked, setAllChecked] = React.useState(false);
  const [filtredFamilies1, setFiltredFamilies1] = React.useState();
  const [filtredFalimies2, setFiltredFalimies2] = React.useState();
  const [filtredFamilies3, setFiltredFamilies3] = React.useState();
  const [filtredFamilies4, setFiltredFamilies4] = React.useState();
  const [selectedList, setSelectedList] = React.useState([]);
  const [unSelectedList, setUnSelectedList] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]);
  const [productList, setProductList] = React.useState();
  const [productDetailList, setProductDetailList] = React.useState([]);
  const [openAppendToProposition, setOpenAppendToProposition] = React.useState(false);
  const history = useHistory();
  const regularColumns = useMemo(() => {
    const [action, ...rest] = columns();
    return [
      {
        ...action,
        onClick: (event) => {
          setProductDetailList((prev) => prev.map((p) => ({
            ...p,
            checked: event.target.checked,
          })));
          setAllChecked(event.target.checked);
          setUnSelectedList([]);
          setSelectedList([]);
        },
      },
      ...rest,
    ];
  }, [setAllChecked]);
  const {
    state: { expandedColumnsIds },
    dispatch,
  } = useContext(ProductContext);
  const [columnList, setColumnList] = useState(regularColumns);
  const [oldSelectedIds, updateProposition, isPropositionToProductsMode, propositionName] = useMatchPropositionToProducts({ setSelectedList });
  useEffect(() => {
    setSelectedList(oldSelectedIds);
  }, [oldSelectedIds]);
  useEffect(() => {
    if (!productList && referencial.products) {
      setProductList(referencial.products.filter((p) => p.iA));
    }
  }, [referencial.products, setProductList, productList]);
  useEffect(() => {
    if (!filtredFamilies1 && families1 && families1.length) {
      setFiltredFamilies1(families1);
    }
  }, [families1, setFiltredFamilies1, filtredFamilies1]);
  useEffect(() => {
    if (!filtredFalimies2 && families2 && families2.length) {
      setFiltredFalimies2(families2);
    }
  }, [families2, setFiltredFalimies2, filtredFalimies2]);
  useEffect(() => {
    if (!filtredFamilies3 && families3 && families3.length) {
      setFiltredFamilies3(families3);
    }
  }, [families3, setFiltredFamilies3, filtredFamilies3]);
  useEffect(() => {
    if (!filtredFamilies4 && families4 && families4.length) {
      setFiltredFamilies4(families4);
    }
  }, [families4, setFiltredFamilies4, filtredFamilies4]);
  useEffect(() => {
    if (!filtredBrands && brands && brands.length) {
      setFiltredBrands(brands);
    }
  }, [brands, setFiltredBrands, filtredBrands]);
  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };
  const handleInputChange = (prop, fIds) => (event, value) => {
    let toChange = null;
    if (search) {
      setSearch('');
    }
    if (prop.includes('family')) {
      const reset = fIds
        ? fIds.reduce((prev, curr) => ({ ...prev, [`family${curr}`]: [] }), {})
        : {};
      toChange = {
        ...values,
        // eslint-disable-next-line no-nested-ternary
        [prop]: value || [],
        ...reset,
      };
      const { products = [] } = referencial;
      const cloneArr = [...products.filter((p) => p.iA)];
      const {
        brand, family4, family1, family2, family3, stockStatus,
      } = toChange;
      const productArr = cloneArr.filter(
        (po) => (!brand.length || brand.map((b) => b.id).includes(po.bId))
          && (!family4.length || family4.map((f4) => f4.id).includes(po.fId))
          && (!family3.length || family3.map((f3) => f3.id).includes(po.fThree))
          && (!family2.length || family2.map((f2) => f2.id).includes(po.fTwo))
          && (!family1.length || family1.map((f1) => f1.id).includes(po.fOne))
          && (!stockStatus || stockStatus.filter(po.sD + po.sB)),
      );
      setFiltredBrands(
        toChange.family1.length
          ? brands.filter((b) => productArr.map((po) => po.bId).includes(b.id))
          : brands,
      );
      setProductList(productArr);
      let filtredFamilyList2 = null;
      let filtredFamilyList3 = null;
      let filtredFamilyList4 = null;
      const familyIds = toChange.brand.length
        ? [
          ...productArr.filter((po) => po.fId).map((po) => po.fId),
          ...productArr.filter((po) => po.fOne).map((po) => po.fOne),
          ...productArr.filter((po) => po.fThree).map((po) => po.fThree),
          ...productArr.filter((po) => po.fTwo).map((po) => po.fTwo),
        ].unique()
        : [];
      if (prop === 'family1') {
        filtredFamilyList2 = families2.filter(
          (family) => (!toChange.family1.length
              || toChange.family1.map((f1) => f1.id).includes(family.parentId))
            && (!toChange.brand.length || familyIds.includes(family.id)),
        );
        setFiltredFalimies2(filtredFamilyList2);
      }
      if (['family1', 'family2'].includes(prop)) {
        filtredFamilyList3 = families3.filter(
          (family) => ((filtredFamilyList2 || filtredFalimies2).map((f) => f.id).includes(family.parentId)
              || (families1.map((f) => f.id).includes(family.parentId)
                && (!toChange.family1.length
                  || toChange.family1.map((f1) => f1.id).includes(family.parentId))))
            && (!toChange.family2.length
              || toChange.family2.map((f2) => f2.id).includes(family.parentId))
            && (!toChange.brand.length || familyIds.includes(family.id)),
        );
        setFiltredFamilies3(filtredFamilyList3);
      }
      if (['family3', 'family2', 'family1'].includes(prop)) {
        filtredFamilyList4 = families4.filter(
          (family) => ((filtredFamilyList3 || filtredFamilies3).map((f) => f.id).includes(family.parentId)
              || (families1.map((f) => f.id).includes(family.parentId)
                && (!toChange.family1.length
                  || toChange.family1.map((f1) => f1.id).includes(family.parentId)))
              || ((filtredFamilyList2 || filtredFalimies2)
                .map((f) => f.id)
                .includes(family.parentId)
                && (!toChange.family2.length
                  || toChange.family2.map((f2) => f2.id).includes(family.parentId))))
            && (!toChange.family3.length
              || toChange.family3.map((f3) => f3.id).includes(family.parentId))
            && (!toChange.brand.length || familyIds.includes(family.id)),
        );
        setFiltredFamilies4(filtredFamilyList4);
      }
    } else {
      toChange = {
        ...values,
        // eslint-disable-next-line no-nested-ternary
        [prop]: !['startDate', 'endDate'].includes(prop)
          ? value || prop === 'stockStatus'
            ? value
            : []
          : event || null,
      };
      if (!['startDate', 'endDate'].includes(prop)) {
        const { products = [] } = referencial;
        const cloneArr = [...products.filter((p) => p.iA)];
        const {
          brand, family4, family1, family2, family3, stockStatus,
        } = toChange;
        const productArr = cloneArr.filter(
          (po) => (!brand.length || brand.map((b) => b.id).includes(po.bId))
            && (!family4.length || family4.map((f4) => f4.id).includes(po.fId))
            && (!family3.length || family3.map((f3) => f3.id).includes(po.fThree))
            && (!family2.length || family2.map((f2) => f2.id).includes(po.fTwo))
            && (!family1.length || family1.map((f1) => f1.id).includes(po.fOne))
            && (!stockStatus || stockStatus.filter(po.sD + po.sB)),
        );
        setProductList(productArr);
        setFiltredBrands(brands.filter((b) => productArr.map((po) => po.bId).includes(b.id)));
        const familyIds = toChange.brand.length
          ? [
            ...productArr.filter((po) => po.fId).map((po) => po.fId),
            ...productArr.filter((po) => po.fOne).map((po) => po.fOne),
            ...productArr.filter((po) => po.fThree).map((po) => po.fThree),
            ...productArr.filter((po) => po.fTwo).map((po) => po.fTwo),
          ].unique()
          : [];
        const filtredFamilyList1 = toChange.brand.length
          ? families1.filter((f) => familyIds.includes(f.id))
          : families1;
        if (toChange.family1.length) {
          toChange = {
            ...toChange,
            family1: toChange.family1.filter((f) => filtredFamilyList1.map((f1) => f1.id).includes(f.id)),
          };
        }
        setFiltredFamilies1(filtredFamilyList1);

        const filtredFamilyList2 = families2.filter(
          (family) => (!toChange.family1.length
              || toChange.family1.map((f1) => f1.id).includes(family.parentId))
            && (!toChange.brand.length || familyIds.includes(family.id)),
        );
        if (toChange.family2.length) {
          toChange = {
            ...toChange,
            family2: toChange.family2.filter((f) => filtredFamilyList2.map((f2) => f2.id).includes(f.id)),
          };
        }
        setFiltredFalimies2(filtredFamilyList2);
        const filtredFamilyList3 = families3.filter(
          (family) => ((filtredFamilyList2 || filtredFalimies2).map((f) => f.id).includes(family.parentId)
              || (families1.map((f) => f.id).includes(family.parentId)
                && (!toChange.family1.length
                  || toChange.family1.map((f1) => f1.id).includes(family.parentId))))
            && (!toChange.family2.length
              || toChange.family2.map((f2) => f2.id).includes(family.parentId))
            && (!toChange.brand.length || familyIds.includes(family.id)),
        );
        if (toChange.family3.length) {
          toChange = {
            ...toChange,
            family3: toChange.family3.filter((f) => filtredFamilyList3.map((f3) => f3.id).includes(f.id)),
          };
        }
        setFiltredFamilies3(filtredFamilyList3);
        const filtredFamilyList4 = families4.filter(
          (family) => ((filtredFamilyList3 || filtredFamilies3).map((f) => f.id).includes(family.parentId)
              || (families1.map((f) => f.id).includes(family.parentId)
                && (!toChange.family1.length
                  || toChange.family1.map((f1) => f1.id).includes(family.parentId)))
              || ((filtredFamilyList2 || filtredFalimies2)
                .map((f) => f.id)
                .includes(family.parentId)
                && (!toChange.family2.length
                  || toChange.family2.map((f2) => f2.id).includes(family.parentId))))
            && (!toChange.family3.length
              || toChange.family3.map((f3) => f3.id).includes(family.parentId))
            && (!toChange.brand.length || familyIds.includes(family.id)),
        );
        if (toChange.family4.length) {
          toChange = {
            ...toChange,
            family4: toChange.family4.filter((f) => filtredFamilyList4.map((f4) => f4.id).includes(f.id)),
          };
        }
        setFiltredFamilies4(filtredFamilyList4);
      }
    }
    if (event?.toString() !== 'Invalid Date') {
      setValues(toChange);
    }
  };

  const productsFiltred = useMemo(() => {
    const produits = (productList || []).filter(
      (po) => !search
        || (po.reference && po.reference.toLowerCase().includes(search.toLowerCase()))
        || (po.des && po.des.toLowerCase().includes(search.toLowerCase()))
        || (po.e && po.e.toLowerCase().includes(search.toLowerCase()))
        || (po.eF && po.eF.toLowerCase().includes(search.toLowerCase()))
        || (po.d && po.d.toLowerCase().includes(search.toLowerCase()))
        || (po.dimensions2 && po.dimensions2.toLowerCase().includes(search.toLowerCase()))
        || (po.rO && po.rO.toLowerCase().includes(search.toLowerCase())),
    );
    return !sort
      ? produits
      : produits.sort((a, b) => {
        if (sort === 'price') {
          return order === 'asc' ? a.p - b.p : b.p - a.p;
        }
        if (sort === 'stock') {
          return order === 'asc' ? a.sB + a.sD - (b.sB + b.sD) : b.sB + b.sD - (a.sB + a.sD);
        }
        return order === 'asc'
          ? a.reference.localeCompare(b.reference)
          : b.reference.localeCompare(a.reference);
      });
  }, [productList, search, sort, order]);
  const expandColumnByIds = useCallback(() => {
    let columnListClone = [...regularColumns];
    let finalExpandedList = [];
    expandedColumnsIds.forEach((id) => {
      const index = columnListClone.map((c) => c.id).indexOf(id);
      const prefix = id === 'buyedCount' ? 'first' : 'last';
      const { startDate, endDate } = findStartAndEndDate(values);
      const newColumns = new Set();
      let expandedRowList = [];
      const unique = Date.now();
      productDetailList.forEach((pd) => {
        let expandedRow = {};
        const list = prefix === 'first' ? pd.buyedList : pd.saledList;
        list
          .filter((b) => b.productId === pd.productId)
          .forEach((e) => {
            const key = `${prefix}-${unique}-${format(new Date(e.date), 'MMM yy', {
              locale: fr,
            })}`;
            newColumns.add(new Date(e.date));
            expandedRow = {
              ...expandedRow,
              [key]: expandedRow[key] ? expandedRow[key] + e.quantity : e.quantity,
            };
          });
        expandedRowList = [...expandedRowList, expandedRow];
      });
      if (finalExpandedList.length > 0) {
        finalExpandedList = finalExpandedList.map((e, i) => ({ ...e, ...expandedRowList[i] }));
      } else {
        finalExpandedList = [...expandedRowList];
      }
      const newColumnList = [
        ...new Set(
          getDatesInRange(startDate, endDate).map((col) => format(new Date(col), 'MMM yy', { locale: fr })),
        ),
      ].map((c) => ({
        id: `${prefix}-${unique}-${c}`,
        label: c,
        style: { ...TitleStyle, textAlign: 'center' },
        expandIndex: prefix,
        render: ({ expandedRowValue }) => (
          <Typography
            style={{ ...defaultColumnStyle, width: 50, textAlign: 'center' }}
            display="inline"
          >
            {expandedRowValue}
          </Typography>
        ),
      }));
      columnListClone = [
        ...columnListClone.slice(0, index + 1),
        ...newColumnList,
        ...columnListClone.slice(index + 1, columnListClone.length),
      ];
    });
    setExpandedRows(finalExpandedList);
    setColumnList(columnListClone);
  }, [
    setColumnList,
    setExpandedRows,
    regularColumns,
    productDetailList,
    values,
    expandedColumnsIds,
  ]);
  const expandColumnByIdsRef = useRef(expandColumnByIds);

  const getProductsDetails = useCallback(
    async ({ page, size }) => {
      if (expandColumnByIds !== expandColumnByIdsRef.current) {
        expandColumnByIdsRef.current = expandColumnByIds;
      } else if (productsFiltred?.length > 0) {
        setExpandedRows([]);
        setColumnList(regularColumns);
        setLoading(true);
        try {
          const productDetailsList = await getProductDetails({
            ids: productsFiltred.map((p) => p.productId).slice(page * size, (page + 1) * size),
            startDate: values.startDate
              ? format(
                values.startDate instanceof Date ? values.startDate : new Date(values.startDate),
                'yyyy-MM-dd',
              )
              : undefined,
            endDate: values.endDate
              ? format(
                values.endDate instanceof Date ? values.endDate : new Date(values.endDate),
                'yyyy-MM-dd',
              )
              : undefined,
          });
          setProductDetailList(
            productsFiltred?.slice(page * size, (page + 1) * size).map((p) => {
              const productDetails = productDetailsList.find((pd) => pd.id === p.productId);
              const { startDate, endDate } = findStartAndEndDate(values);
              const isAlreadyChecked = selectedList.includes(p.reference);
              const isAlreadyUnChecked = unSelectedList.includes(p.reference);
              const checked = isAlreadyChecked || isAlreadyUnChecked
                ? !!isAlreadyChecked
                : p.checked === undefined
                  ? allChecked
                  : p.checked;
              return {
                ...p,
                ...productDetails,
                stock: p.sD + p.sB,
                checked,
                buyedCount: productDetails.buyedList
                  ?.filter(
                    (b) => b.productId === p.productId
                      && startDate <= new Date(b.date)
                      && endDate >= new Date(b.date),
                  )
                  .reduce((prev, curr) => prev + curr.quantity, 0),
                saledCount: productDetails.saledList
                  ?.filter(
                    (b) => b.productId === p.productId
                      && startDate <= new Date(b.date)
                      && endDate >= new Date(b.date),
                  )
                  .reduce((prev, curr) => prev + curr.quantity, 0),
                checkProduct: (event) => {
                  const { checked: check } = event.target;
                  setProductDetailList((prev) => prev.map((e) => {
                    if (p.reference === e.reference) {
                      return { ...e, checked: check };
                    }
                    return e;
                  }));
                  if (check) {
                    setSelectedList((prev) => [...prev, p.reference].unique());
                    setUnSelectedList((prev) => {
                      prev.splice(p.reference, 1);
                      return prev;
                    });
                  } else {
                    setUnSelectedList((prev) => [...prev, p.reference].unique());
                    setSelectedList((prev) => {
                      prev.splice(p.reference, 1);
                      return prev;
                    });
                  }
                },
              };
            }),
          );
          expandColumnByIds();
        } catch (e) {
          const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
          toast.error(statusLabel);
        } finally {
          setLoading(false);
        }
      }
    },
    [
      productsFiltred,
      values.startDate,
      values.endDate,
      allChecked,
      regularColumns,
      selectedList,
      unSelectedList,
      expandColumnByIds,
    ],
  );

  const expandColumn = (index, expand) => {
    const prefix = index === 6 ? 'first' : 'last';
    if (expand) {
      dispatch(updateColumnIds([...expandedColumnsIds, columnList[index].id].unique()));
      const { startDate, endDate } = findStartAndEndDate(values);
      const newColumns = new Set();
      let expandedRowList = [];
      const unique = Date.now();
      productDetailList.forEach((pd) => {
        let expandedRow = {};
        const list = prefix === 'first' ? pd.buyedList : pd.saledList;
        list
          .filter((b) => b.productId === pd.productId)
          .forEach((e) => {
            const key = `${prefix}-${unique}-${format(new Date(e.date), 'MMM yy', { locale: fr })}`;
            newColumns.add(new Date(e.date));
            expandedRow = {
              ...expandedRow,
              [key]: expandedRow[key] ? expandedRow[key] + e.quantity : e.quantity,
            };
          });
        expandedRowList = [...expandedRowList, expandedRow];
      });
      const newColumnList = [
        ...new Set(
          getDatesInRange(startDate, endDate).map((col) => format(new Date(col), 'MMM yy', { locale: fr })),
        ),
      ].map((c) => ({
        id: `${prefix}-${unique}-${c}`,
        label: c,
        style: { ...TitleStyle, textAlign: 'center' },
        expandIndex: prefix,
        render: ({ expandedRowValue }) => (
          <Typography
            style={{ ...defaultColumnStyle, width: 50, textAlign: 'center' }}
            display="inline"
          >
            {expandedRowValue}
          </Typography>
        ),
      }));
      if (expandedRows.length > 0) {
        setExpandedRows(expandedRows.map((e, i) => ({ ...e, ...expandedRowList[i] })));
      } else {
        setExpandedRows([...expandedRowList]);
      }
      setColumnList([
        ...columnList.slice(0, index + 1),
        ...newColumnList,
        ...columnList.slice(index + 1, columnList.length),
      ]);
    } else {
      const newExpandedList = [...expandedColumnsIds];
      newExpandedList.splice(columnList[index].id, 1);
      dispatch(updateColumnIds(newExpandedList));
      setColumnList(columnList.filter((c) => c.expandIndex !== prefix));
      const expandedRowList = [...expandedRows];
      setExpandedRows(
        expandedRowList
          .map((e) => {
            const clonseE = { ...e };
            Object.keys(e).forEach((k) => {
              if (k.startsWith(prefix)) {
                delete clonseE[k];
              }
            });
            return clonseE;
          })
          .filter((e) => Object.keys(e).length !== 0),
      );
    }
  };
  const countSelected = useMemo(
    () => (allChecked ? productsFiltred?.length - unSelectedList?.length : selectedList?.length),
    [unSelectedList, selectedList, productsFiltred, allChecked],
  );
  /**
   * Handles the action when a new proposition is triggered.
   */
  const onHandleNewProposition = () => {
    const productIds = productList.filter(
      (po) => (selectedList.includes(po.reference)),
    ).map((po) => po.productId);
    if (allChecked) {
      const allProducts = productsFiltred
        .filter((po) => !unSelectedList.includes(po.reference));
      history.push('proposition/add', {
        checkOptions: {
          selectedList: allProducts.map((po) => po.reference),
          unSelectedList,
          allChecked,
          productIds: allProducts.map((po) => po.productId),
        },
        datesFilter: { startDate: values.startDate, endDate: values.endDate },
      });
      return;
    }
    history.push('proposition/add', {
      checkOptions: {
        selectedList, unSelectedList, allChecked, productIds,
      },
      datesFilter: { startDate: values.startDate, endDate: values.endDate },
    });
  };
  const onEndAppend = () => {
    setOpenAppendToProposition(false);
  };
  const checkParams = useMemo(() => {
    const productIds = productList?.filter(
      (po) => (selectedList.includes(po.reference)),
    ).map((po) => po.productId);

    const oldProductIds = productList?.filter(
      (po) => (oldSelectedIds.includes(po.reference)),
    ).map((po) => po.productId);

    if (allChecked) {
      const allProducts = productsFiltred
        .filter((po) => !unSelectedList.includes(po.reference));
      return {
        selectedList: allProducts.map((po) => po.reference),
        unSelectedList,
        allChecked: false,
        productIds: allProducts.map((po) => po.productId),
        oldProductIds,
      };
    }
    return {
      selectedList, unSelectedList, allChecked, productIds, oldProductIds,
    };
  }, [selectedList, productList, unSelectedList, allChecked, oldSelectedIds, productsFiltred]);

  useEffect(() => {
    getProductsDetails({ size: 10, page: 0 });
  }, [values]);
  return (
    <Box className="catalog_buyer">
      {loading && <GlobalLoader size="100vh" />}
      {!isPropositionToProductsMode && countSelected > 0 && (
        <Box className={classes.proposition}>
          <Typography style={{ flexGrow: 1 }}>
            {countSelected}
            {' '}
            ÉLÉMENTS SÉLECTIONNÉS
          </Typography>
          <Box className={classes.proposition}>
            <ButtonApp
              onClick={onHandleNewProposition}
              style={{ marginRight: 15, height: 40 }}
              label="Ajouter a une nouvelle proposition"
            />
            <ButtonApp
              onClick={() => setOpenAppendToProposition(true)}
              label="Ajouter a une proposition"
              style={{ height: 40 }}
            />
          </Box>
        </Box>
      )}
      {isPropositionToProductsMode ? (
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ font: 'normal 600 24px Montserrat' }}>Ajouter un produit</Typography>
          <Typography sx={{ font: 'normal 600 18px Montserrat' }}>{`Proposition: ${propositionName}`}</Typography>
        </Box>
      ) : null}
      <AppendProductsToProposition
        open={openAppendToProposition}
        onHandleClose={onEndAppend}
        params={checkParams}
        datesFilter={{ startDate: values.startDate, endDate: values.endDate }}
      />
      <Box className={classes.filterContainer}>
        <Box className={classes.filterZone}>
          {false && (
            <TextField
              variant="outlined"
              onChange={handleChangeSearch}
              value={search}
              label="chercher"
              size="small"
              className={classes.filter}
              style={{ width: 200 }}
              InputProps={{
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={filtredBrands || []}
            onChange={handleInputChange('brand')}
            value={values.brand}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            ListboxComponent={ListboxComponent}
            multiple
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Marques"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={filtredFamilies1 || []}
            onChange={handleInputChange('family1', [2, 3, 4])}
            value={values.family1}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            ListboxComponent={ListboxComponent}
            multiple
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Famille 1"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={filtredFalimies2 || []}
            onChange={handleInputChange('family2', [3, 4])}
            value={values.family2}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            ListboxComponent={ListboxComponent}
            multiple
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Famille 2"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={filtredFamilies3 || []}
            onChange={handleInputChange('family3', [4])}
            value={values.family3}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            ListboxComponent={ListboxComponent}
            multiple
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Famille 3"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={filtredFamilies4 || []}
            onChange={handleInputChange('family4')}
            value={values.family4}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            ListboxComponent={ListboxComponent}
            multiple
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Famille 4"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
          <Box style={{ height: 40, width: 170 }}>
            <DateCopima
              id="startDate"
              label="Début"
              value={values.startDate || null}
              clearable={false}
              onChange={handleInputChange('startDate')}
            />
          </Box>
          <Box style={{ height: 40, width: 170 }}>
            <DateCopima
              variant="inline"
              id="endDate"
              label="Fin"
              value={values.endDate || null}
              onChange={handleInputChange('endDate')}
              clearable={false}
            />
          </Box>
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            options={STOCK_STATUS || []}
            onChange={handleInputChange('stockStatus')}
            value={values.stockStatus}
            size="small"
            style={{ height: '100%' }}
            autoHighlight
            getOptionLabel={(option) => option.label}
            ListboxComponent={ListboxComponent}
            filterOptions={(options, v) => options.filter((option) => option.label?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Stock Statut"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            renderOption={(props, option) => (
              <Typography style={{ color: option?.color }} {...props}>{option.label}</Typography>
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
        </Box>
      </Box>
      <EnhancedTable
        getData={getProductsDetails}
        rows={productDetailList}
        headCells={columnList}
        count={productsFiltred.length}
        rowsPerPageOptions={[10, 15, 20]}
        expandColumn={expandColumn}
        expandedRows={expandedRows}
        regularColumns={regularColumns}
      />
      { isPropositionToProductsMode ? (
        <Box className={classes.proposition}>
          <ButtonApp
            onClick={history.goBack}
            style={{
              marginRight: 15, height: 40, background: '#FFF', color: 'black', boxShadow: 'none',
            }}
            label="Annuler"
          />
          <ButtonApp
            disabled={oldSelectedIds.length === 0}
            onClick={() => updateProposition(history.goBack, checkParams)}
            label="Enregistré"
            style={{ height: 40 }}
          />
        </Box>
      ) : null }
    </Box>
  );
};

export default ConnectedStore(ProductsTable, ProductReducer, initialState, Provider);
