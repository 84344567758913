import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 10px 20px 25px',
    overflowY: 'auto',
    height: 'calc(100vh - 104px)',
  },
  proposition: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#FFFFFF',
    boxShadow: '0px 12px 26px #0000000D',
    borderRadius: '12px',
    padding: '25px',
    margin: '9px 0',
    cursor: 'pointer',
  },
  propositionTitle: {
    textTransform: 'capitalize',
    font: 'normal normal 600 15px Montserrat',
    margin: 'auto 0',
  },
  propositionSubTitle: {
    font: 'normal normal 600 9px Montserrat',
    color: '#007BC6',
  },
  propositionStatus: {
    background: '#00B894 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 10px #108CD650',
    borderRadius: '20px',
    margin: 'auto 0',
    color: '#fff',
    font: 'normal normal 600 11px Montserrat',
    padding: '3px 6px',
  },
  propositionCreated: {
    background: '#707070 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 10px #48484850',
  },
  propositionBlue: {
    color: '#007BC6',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  filterZone: {
    display: 'flex',
    background: '#E9EBED 0% 0% no-repeat padding-box',
    borderRadius: 12,
    padding: '10px 7px',
    flexGrow: 1,
  },
  filter: {
    marginRight: 16,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
  },
  notched: {
    border: 'none',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  input: {
    '&::placeholder': {
      font: 'normal normal 400 14px/18px Montserrat',
      color: '#AFB0B2',
      fontSize: 14,
      opacity: 1,
      width: 70,
    },
  },
  formControlDate: {
    marginTop: '4px !important',
    marginLeft: 10,
  },
  labelDate: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
    top: -13,
    left: 18,
  },
  date: {
    marginRight: 16,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    flexGrow: 1,
  },
  pagination: {
    marginTop: '20px',
    justifyContent: 'end',
    display: 'flex',
  },
  actions: {
    position: 'relative',
  },
  actionsPop: { position: 'absolute', right: 0, zIndex: 1 },
  successMsg: {
    marginTop: '20px',
    textAlign: 'center',
    font: 'normal 600 18px Montserrat',
  },
  successCheck: {
    borderRadius: '50%',
    width: '65px',
    height: '65px',
    backgroundColor: '#00B894',
    margin: 'auto',
    color: '#FFF',
    display: 'flex',
  },
}));

export default useStyles;
