import { useState } from 'react';
import FiltersUtils from 'Utils/FiltersUtils';
import { filtersName } from './constants';

const useAppliedFilter = () => {
  const savedFilters = FiltersUtils.getSavedFilters(filtersName);
  const INIT_FILTER = savedFilters || {
    client: [],
    expedition: [],
    paymentMode: [],
    city: [],
    zone: [],
    commercial: [],
    sort: {},
  };
  const [values, setValues] = useState(INIT_FILTER);
  const [appliedFilter, setAppliedFilter] = useState(INIT_FILTER);

  const handleRefresh = () => {
    setValues(INIT_FILTER);
    setAppliedFilter(INIT_FILTER);
  };

  const onHandleSubmitFilters = () => {
    setAppliedFilter(values);
  };
  return {
    handleRefresh,
    values,
    setValues,
    appliedFilter,
    onHandleSubmitFilters,
  };
};

export default useAppliedFilter;
