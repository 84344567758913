/* eslint-disable max-len */
import React, { useContext, useMemo } from 'react';
import {
  Grid, Popper, TextField, Typography, Box, Autocomplete,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReferencialContext from 'Shared/ReferencialContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SwitchCmp from 'Shared/SwitchCmp';
import { TYPES } from './constants';

const PopperMy = (props) => (
  <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />
);

const useStyles = makeStyles(() => ({
  filter: {
    marginRight: 16,
    flexGrow: 1,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
  },
  date: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    height: 40,
  },
  notched: {
    border: 'none',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  formControlDate: {
    marginTop: '4px !important',
    marginLeft: 10,
  },
  labelDate: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
    top: -13,
    left: 18,
  },
  icon: {
    color: '#0681cc !important',
    fontSize: 22,
  },
  searchArea: {
    backgroundColor: '#E9EBED',
    padding: '16px',
    borderRadius: '0px 0px 12px 12px',
    display: 'flex',
    marginBottom: 15,
  },
}));

const Filters = ({
  values, handleInputChange, onCheckAllHuile, countSelectedItem = 0, isSelected = false,
}) => {
  const classes = useStyles();
  const {
    state: {
      referencial: { brands = [], families = [] },
    },
  } = useContext(ReferencialContext);
  const families1 = useMemo(() => families?.filter((family) => family.familyLevel === 4), [
    families,
  ]);
  return (
    <>
      <Grid container className={classes.searchArea} spacing={1} alignItems="center">
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            onChange={handleInputChange('search')}
            value={values.search}
            label="Chercher"
            size="small"
            className={classes.filter}
            style={{ width: '100%' }}
            InputProps={{
              classes: { notchedOutline: classes.notched },
            }}
            InputLabelProps={{
              classes: { root: classes.label },
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            fullWidth
            options={brands || []}
            onChange={handleInputChange('brand')}
            value={values.brand}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Marque"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            fullWidth
            options={families1 || []}
            onChange={handleInputChange('family')}
            value={values.family}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.name}
            filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Famille"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.filter }}
            fullWidth
            options={TYPES}
            onChange={handleInputChange('huile')}
            value={values.huile}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option.label}
            filterOptions={(options, v) => options.filter((option) => option.label?.toLowerCase().includes(v.inputValue.toLowerCase()))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Huile"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...params.InputProps,
                  classes: { notchedOutline: classes.notched },
                }}
                InputLabelProps={{
                  classes: { root: classes.label },
                }}
              />
            )}
            popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          />
        </Grid>
        <Grid item xs={3}>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography style={{
              font: 'normal normal bold 12px Montserrat',
              color: '#007BC6',
            }}
            >
              {`${countSelectedItem} ELEMENT SELECTION`}
            </Typography>
            <SwitchCmp
              checked={isSelected}
              onChange={onCheckAllHuile}
              name="checkedB"
              disabled={countSelectedItem === 0}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Filters;
