import useForm from 'Shared/Hooks/useForm';
import { toast } from 'react-toastify';
import { addManualProductToPropositions } from '../PropositionServices';

const useNewManualProduct = (init, onClose) => {
  const { values, onChange, reset } = useForm(init);
  const onChangeQuantity = (name, nextValue) => (event, value) => {
    const newValue = nextValue || value || event.target.value;
    onChange({ target: { name, value: newValue } });
  };

  const canSubmitManualProduct = values.productRef?.length > 0
  && values.productName?.length > 0 && values.quantity > 0;
  const onSubmitManualProduct = async (propositionId) => {
    if (!canSubmitManualProduct) {
      return;
    }
    await addManualProductToPropositions(propositionId,
      { name: values.productName, quantity: values.quantity, reference: values.productRef });
    reset(init);
    onClose();
    toast.success('Produit est ajouté evec succès.');
  };
  return [values, onChange, onChangeQuantity, canSubmitManualProduct, onSubmitManualProduct];
};

export default useNewManualProduct;
