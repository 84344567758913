import { Box } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import ReferencialContext from 'Shared/ReferencialContext';
import RenderInput from 'Shared/RenderInput';
import { PAYEMENT_STATUSES } from '../constants';
import {
  BANKS, Fields, PAYMENT_NATURES,
} from './constants';

const Filters = ({ values, handleInputChange }) => {
  const {
    state: {
      referencial: { clients },
    },
  } = useContext(ReferencialContext);
  const lists = useMemo(() => ({
    clients: clients || [],
    paymentNatures: PAYMENT_NATURES,
    banks: BANKS,
    paymentStatusList: PAYEMENT_STATUSES,
  }),
  [clients]);
  return (
    <Box style={{
      display: 'flex', flexWrap: 'wrap', gap: '10px', padding: '0 10px',
    }}
    >
      {Fields.map((field) => (
        <RenderInput
          key={field.id}
          input={(field.type === 'autocomplete' ? { ...field, [field.listName]: lists[field.listName] } : field)}
          value={values[field.id]}
          handleChange={handleInputChange(field.id)}
          fullWidth={false}
        />
      ))}
    </Box>
  );
};

export default Filters;
