import {
  Box, IconButton, Typography, Chip, Button, Tooltip,
} from '@mui/material';
import React, { useMemo, useCallback, useState } from 'react';
import EnhancedTable from 'Shared/EnhancedTable';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import {
  defaultMessage,
  downloadBC,
  setNoAnomalyOrder,
} from 'Components/ControleOrders/RecoveryServices';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CardMedia from '@mui/material/CardMedia';
import SaveIcon from '@mui/icons-material/Save';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RecoveryView from 'Components/Recovery/RecoveryView';
import Pointer from 'Images/pointer.svg';
import DoneIcon from '@mui/icons-material/Done';
import OrderEdit from './Edit';
import { columnOrderControlDetail } from '../constants';
import useStyles from '../style';
import HeaderControlDetail from './HeaderControlDetail';
import FeatherDownload from './feather-download.png';
import FeatherEye from './feather-eye.png';
import Anomalies from '../Anomalies';

const formatDate = (date) => `${[date.getDate(), date.getMonth() + 1, date.getFullYear()].join('/')} ${[
  date.getHours(),
  date.getMinutes(),
].join(':')} `;
const labelStyle = {
  font: 'normal normal 600 14px Montserrat',
  color: '#007BC6',
  textTransform: 'uppercase',
};
const ValueStyle = {
  font: 'normal normal 600 20px Montserrat',
  color: '#000000',
  textTransform: 'capitalize',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};
const OrderControlDetail = () => {
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState('');
  const history = useHistory();
  const [isAnomaliesOpen, setIsAnomaliesOpen] = useState(false);
  const [openClientDetail, setOpenClientDetail] = useState(false);
  const {
    state: { order },
  } = useLocation();
  const [noAnomaly, setNoAnomaly] = useState(order?.noAnomaly);
  const [update, setUpdate] = useState(false);
  const onCloseAnomalies = () => {
    setIsAnomaliesOpen(!isAnomaliesOpen);
  };

  const printBC = useCallback(
    async (ref) => {
      try {
        setLoading(true);
        await downloadBC(ref);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );
  const handleNoAnomaly = useCallback(async () => {
    try {
      setLoading(true);
      await setNoAnomalyOrder(order?.currentReference);
      setNoAnomaly(true);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setNoAnomaly, order]);
  const details = useMemo(
    () => order?.details
      .map((d) => ({
        reliquat: d.reliquat,
        reference: d.product.reference,
        designation: d.product.des,
        pickedQuantity: d.pickedQuantity,
        waitingQuantity: d.waitingQuantity,
        inspectedQuantity: d.inspectedQuantity,
        totalQuantity: d.pickedQuantity + d.waitingQuantity,
        statusOps: d.status,
        qteBC: d.reliquat ? d.quantity : d.quantityBc,
        priceBC: d.reliquat
          ? (d.prixUnitaire * 1.2)?.toFixed(2)
          : (d.prixUnitaireBc * 1.2)?.toFixed(2),
        remiseBC: d.reliquat ? d.remise : d.remiseBc,
        qteBL: d.reliquat ? '' : d.quantity,
        remiseBL: d.reliquat ? '' : d.remise,
        priceBL: d.reliquat ? '' : (d.montantTtc / (1 - d.remise / 100) / d.quantity)?.toFixed(2),
      }))
      .sort((a, b) => (sort === 'asc' ? a.reference.localeCompare(b.reference) : b.reference.localeCompare(a.reference))),
    // a.reliquat - b.reliquat
    [order, sort],
  );

  // eslint-disable-next-line no-unused-vars
  const updateSort = ({ page, size, sort: sortValue }) => {
    if (sortValue) {
      setSort(sortValue?.includes('asc') ? 'asc' : 'desc');
    }
  };

  const editOrder = order
    ? {
      reference: order.ref,
      commercial: {
        ...order.details[0].commercial,
        name: `${order.details[0].commercial.firstName} ${order.details[0].commercial.lastName}`,
      },
      packageNumber: order.colisage,
      expedition: order.expedition,
      paymentMode: { name: order.paymentMode?.name },
      declarationNum: order.numExpedition,
      packedBy: '',
      currentReference: order.currentReference,
      orderReference: order.ref,
    }
    : {};
  const classes = useStyles();
  const onHandleClientDetails = () => setOpenClientDetail(!openClientDetail);
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      {openClientDetail && (
        <RecoveryView handleClose={onHandleClientDetails} clientCode={order?.client?.code} />
      )}
      <Box style={{ display: 'flex' }}>
        <IconButton
          style={{ alignSelf: 'flex-start' }}
          onClick={() => history.push('/controle-orders', { tab: 3 })}
          size="large"
        >
          <ArrowBackIcon style={{ color: '#000' }} size="large" />
        </IconButton>
        <Box style={{ flexGrow: 1 }}>
          <Box
            style={{
              display: 'flex',
              columnGap: '10px',
              alignItems: 'center',
              paddingRight: '25px',
            }}
          >
            <Box sx={{ mb: '12px' }}>
              <Box
                style={{
                  display: 'flex',
                  columnGap: '10px',
                  alignItems: 'center',
                }}
              >
                <Typography
                  style={{
                    font: 'normal normal 600 29px Montserrat',
                    color: '#000000',
                    textTransform: 'capitalize',
                  }}
                >
                  {order?.reference}
                </Typography>
                {order?.client?.zone && (
                  <Chip
                    style={{
                      font: 'normal normal 600 18px/22px Montserrat',
                      color: '#FFFFFF',
                      textTransform: 'uppercase',
                      backgroundColor: '#007BC6',
                      borderRadius: '24px',
                    }}
                    label={order?.client?.zone}
                  />
                )}
              </Box>
              <Box sx={{ cursor: 'pointer' }} onClick={onHandleClientDetails}>
                <Typography className={classes.clientInfo}>{order?.client?.name}</Typography>
                <Typography className={classes.clientInfo}>
                  {`${order?.client?.city} - `}
                  <span style={{ textDecoration: 'underline' }}>{order?.client?.code}</span>
                </Typography>
              </Box>
            </Box>
            <HeaderControlDetail order={order} />
          </Box>
          <Box style={{ display: 'flex', columnGap: '8px' }}>
            <Box style={{ backgroundColor: '#51a4d8', padding: '12px 14px', borderRadius: '12px' }}>
              <Typography
                display="inline"
                style={{ font: 'normal normal 600 21px/9px Montserrat', color: '#FFFFFF' }}
              >
                {order?.amount}
              </Typography>
              <Typography
                display="inline"
                style={{ font: 'normal normal 600 7px/9px Montserrat', color: '#FFFFFF' }}
              >
                TTC
              </Typography>
            </Box>
            <Tooltip title="Télécharger document" placement="top">
              <IconButton
                style={{
                  background:
                    'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
                  boxShadow: '0px 4px 10px #108CD650',
                  borderRadius: '12px',
                  height: '40px',
                  width: '40px',
                }}
                size="large"
              >
                <CardMedia
                  style={{ height: '18px', width: 'auto' }}
                  component="img"
                  src={FeatherDownload}
                  alt="download"
                />
              </IconButton>
            </Tooltip>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              {!noAnomaly && (
                <Tooltip title="Contrôlé document" placement="top">
                  <IconButton
                    variant="contained"
                    size="small"
                    style={{
                      background:
                        'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
                      boxShadow: '0px 4px 10px #108CD650',
                      borderRadius: 12,
                      width: '40px',
                      height: '40px',
                    }}
                    onClick={handleNoAnomaly}
                  >
                    <CardMedia
                      component="img"
                      src={Pointer}
                      style={{ transform: 'rotate(-45deg)', height: 25, width: 20 }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {noAnomaly && (
                <Tooltip title="Contrôlé document" placement="top">
                  <IconButton
                    variant="contained"
                    size="small"
                    style={{
                      background: '#00B894 0% 0% no-repeat padding-box',
                      boxShadow: '0px 4px 10px #108CD650',
                      border: '1px solid #00B894',
                      borderRadius: 12,
                      width: '40px',
                      height: '40px',
                    }}
                  >
                    <DoneIcon style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Tooltip title="Visualisé model pickage" placement="top">
              <IconButton
                style={{
                  background: '#3B485A 0% 0% no-repeat padding-box',
                  boxShadow: '0px 4px 10px #108CD650',
                  borderRadius: '12px',
                  height: '40px',
                  width: '40px',
                }}
                onClick={() => printBC(order?.currentReference)}
                size="large"
              >
                <CardMedia
                  style={{ height: '18px', width: 'auto' }}
                  component="img"
                  src={FeatherEye}
                  alt="view"
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Liste des annomalies" placement="top">
              <IconButton
                style={{
                  background: '#E4C75C 0% 0% no-repeat padding-box',
                  borderRadius: '12px',
                  height: '40px',
                  width: '70px',
                }}
                onClick={onCloseAnomalies}
                size="large"
              >
                <ErrorOutlineIcon style={{ height: '26px', width: 'auto', color: '#FFF' }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box style={{ display: 'flex', paddingTop: '30px', marginBottom: '26px' }}>
            <Box style={{ width: '16.66%' }}>
              <Typography style={labelStyle}>N° FACTURE:</Typography>
              <Typography style={ValueStyle}>{order?.currentReference}</Typography>
            </Box>
            <Box style={{ width: '16.66%' }}>
              <Typography style={labelStyle}>N° BL:</Typography>
              <Typography style={ValueStyle}>
                {order?.type === 3 ? order?.currentReference : order?.pieceBl}
              </Typography>
            </Box>
            <Box style={{ width: '16.66%' }}>
              <Typography style={labelStyle}>DATE DU CONTRÔLE</Typography>
              <Typography style={ValueStyle}>
                {order?.controlledAt ? formatDate(new Date(order.controlledAt)) : '-'}
              </Typography>
            </Box>
            <Box style={{ width: '16.66%' }}>
              <Typography style={labelStyle}>IMPRIMER LE:</Typography>
              <Typography style={ValueStyle}>
                {order?.printedDate ? format(new Date(order.printedDate), 'dd/MM/yyyy hh:mm') : '-'}
              </Typography>
            </Box>
            <Box style={{ width: '16.66%' }}>
              <Typography style={labelStyle}>DATE BC:</Typography>
              <Typography style={ValueStyle}>
                {(order?.details.length > 0 && order?.details[0].dateBc)
                  ? format(new Date(order?.details[0].dateBc), 'dd/MM/yyyy HH:mm')
                  : '-'}
              </Typography>
            </Box>
            <Box style={{ width: '16.66%' }}>
              <Typography style={labelStyle}>DATE BL:</Typography>
              <Typography style={ValueStyle}>
                {(order?.details.length > 0 && order?.details[0].dateBl)
                  ? format(new Date(order?.details[0].dateBl), 'dd/MM/yyyy HH:mm')
                  : '-'}
              </Typography>
            </Box>
          </Box>
          {editOrder && (
            <OrderEdit update={update} resetUpdate={() => setUpdate(false)} order={editOrder} />
          )}
          <EnhancedTable
            rows={details}
            headCells={columnOrderControlDetail}
            getData={updateSort}
            count={details.length}
            rowsPerPageOptions={[10, 15, 20]}
            containerClass={classes.TableContainer}
            headClass={classes.headClass}
          />
          <Box
            style={{
              display: 'flex',
              columnGap: '19px',
              flexDirection: 'row-reverse',
              paddingRight: '53px',
            }}
          >
            <Button
              variant="contained"
              style={{
                background:
                  'transparent linear-gradient(248deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
                boxShadow: '0px 4px 10px #108CD650',
                borderRadius: '12px',
                font: 'normal normal 800 15px Montserrat',
                color: '#FFFFFF',
                height: '48px',
              }}
              endIcon={<SaveIcon />}
              onClick={() => setUpdate(true)}
            >
              ENREGISTRÉ
            </Button>
            <Button
              variant="contained"
              style={{
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                borderRadius: '12px',
                font: 'normal normal 800 15px Montserrat',
                color: '#38485C',
                height: '48px',
                boxShadow: 'none',
              }}
              onClick={() => history.goBack()}
            >
              ANNULER
            </Button>
          </Box>
        </Box>
      </Box>
      {isAnomaliesOpen
        ? (
          <Anomalies
            handleClose={onCloseAnomalies}
            orderReference={order.currentReference}
            pieceBL={order.pieceBl}
            pieceBC={order.pieceBc}
            orderId={order.id}
          />
        )
        : null}
    </>
  );
};
export default OrderControlDetail;
