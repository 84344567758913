/* eslint-disable object-curly-newline */
import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useOnClickOutside from 'Shared/Hooks/useClickOutSide';
import CloseIcon from '@mui/icons-material/Close';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { setOpenTrace } from './traceabilitySlice';
import TraceabilityIteams from './TraceabilityItems';
import { fields } from './constants';
import { makeSelectLoaderState, makeSelectOpenState } from './selectors';
import ProductTrace from './Trace/ProductTrace';
import DocummentAchatTrace from './Trace/DocummentAchatTrace';
import PropositionTrace from './Trace/PropositionTrace';
import ClientTrace from './Trace/ClientTrace';
import ControleTrace from './Trace/ControleTrace';

const Traceability = ({ userRole }) => {
  const openComment = useSelector(makeSelectOpenState());
  const isLoading = useSelector(makeSelectLoaderState());
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [selectedTap, setSelectedTab] = useState(null);
  const permitedTopics = useMemo(
    () => fields.filter((item) => item.roles.includes(userRole)),
    [userRole],
  );
  useEffect(() => {
    if (permitedTopics.length > 0) {
      setSelectedTab(permitedTopics[0].key);
    }
  }, [permitedTopics]);

  const onCloseTrace = (e) => {
    e.stopPropagation();
    dispatch(setOpenTrace());
  };
  useOnClickOutside(ref, onCloseTrace);

  const onChangeTab = (key) => () => {
    setSelectedTab(key);
  };

  if (!openComment) {
    return null;
  }
  return (
    <Box
      ref={ref}
      sx={{
        width: '500px',
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 2000,
        mb: 3,
        height: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 50px 32px #0000001C',
        padding: '0px 20px 0 20px',
        overflow: 'auto',
        '::-webkit-scrollbar': { width: '10px' },
        '::-webkit-scrollbar-track': { background: '#f1f1f1' },
        '::-webkit-scrollbar-thumb': { background: '#888' },
        '::-webkit-scrollbar-thumb:hover': { background: '#555' },
      }}
      style={{ zIndex: 1300 }}
    >
      { isLoading && <GlobalLoader style={{ position: 'absolute', height: '100%' }} /> }
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1.9, mb: 2 }}>
        <Typography sx={{ color: '#38485C', font: 'normal 600 19px Montserrat' }}>Traçabilités</Typography>
        <IconButton onClick={onCloseTrace}>
          <CloseIcon />
        </IconButton>
      </Box>
      <TraceabilityIteams
        fields={permitedTopics}
        selected={selectedTap}
        onChangeTab={onChangeTab}
      />
      {selectedTap === fields[0].key && <ProductTrace />}
      {selectedTap === fields[1].key && <DocummentAchatTrace />}
      {selectedTap === fields[2].key && <PropositionTrace />}
      {selectedTap === fields[3].key && <ClientTrace />}
      {selectedTap === fields[4].key && <ControleTrace />}
    </Box>
  );
};

export default Traceability;
