import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import {
  defaultMessage,
  linkProductSettings,
} from 'Components/ProductSettings/ProductSettingsServices';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { toast } from 'react-toastify';
import ConfirmDialog from 'Shared/ConfirmDialog';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext, { setReferencial } from 'Shared/ReferencialContext';
import { columns } from './constants';

const RelatedProduct = ({ handleClose, reference, linkedReference }) => {
  const {
    dispatch,
    state: { referencial },
  } = useContext(ReferencialContext);
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [search, setSearch] = React.useState(null);
  const [selectedRef, setSelectedRef] = React.useState(linkedReference);
  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };
  useEffect(() => {}, []);
  const linkProduct = async () => {
    const product = productList.find((p) => p.reference === selectedRef);
    if (product != null) {
      setLoading(true);
      try {
        await linkProductSettings(reference, product?.reference);
        toast.success('le Produit a été bien lié');
        const { products } = referencial;
        const altredProducts = products.map((p) => {
          const pr = { ...p };
          if (p.reference === reference) {
            pr.linkedReference = product?.reference;
          }
          return pr;
        });
        dispatch(setReferencial({ ...referencial, products: altredProducts }));
        handleClose();
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
        setOpenConfirmModal(false);
      }
    }
  };
  const handleLinkproduct = () => {
    const product = referencial.products.find((p) => p.reference === reference);
    const linkedProduct = referencial.products.find((p) => p.reference === selectedRef);
    if (
      linkedProduct.salesCondition === 'GAME'
      && product.salesCondition === 'GAME'
      && product.gameQuantity !== linkedProduct.gameQuantity
    ) {
      setOpenConfirmModal(true);
    } else {
      linkProduct();
    }
  };
  const handleSelectProduct = useCallback(
    (ref) => {
      setSelectedRef(ref);
    },
    [setSelectedRef],
  );
  useEffect(() => {
    if (referencial.products) {
      setProductList(
        referencial.products
          .filter((p) => p.iA && p.reference !== reference)
          .filter(
            (po) => !search
              || (po.reference && po.reference.toLowerCase().includes(search.toLowerCase()))
              || (po.des && po.des.toLowerCase().includes(search.toLowerCase()))
              || (po.e && po.e.toLowerCase().includes(search.toLowerCase()))
              || (po.eF && po.eF.toLowerCase().includes(search.toLowerCase()))
              || (po.d && po.d.toLowerCase().includes(search.toLowerCase()))
              || (po.dimensions2 && po.dimensions2.toLowerCase().includes(search.toLowerCase()))
              || (po.rO && po.rO.toLowerCase().includes(search.toLowerCase())),
          )
          .map((p) => ({
            ...p,
            handleSelectProduct,
            selected: p.reference === selectedRef,
          }))
          .sort((a, b) => {
            if (a.selected) return -1;
            if (b.selected) return 1;
            return 0;
          }),
      );
    }
  }, [referencial.products, setProductList, search, handleSelectProduct, selectedRef, reference]);

  return (
    <>
      {openConfirmModal && (
      <ConfirmDialog
        content="Le produit sélectionné a un jeu différent du produit à configurer, voulez-vous vraiment continuer ?"
        title="Liason"
        handleClick={linkProduct}
        handleClose={() => setOpenConfirmModal(false)}
      />
      )}
      {loading && <GlobalLoader size="100vh" />}
      <Dialog open onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle id="alert-dialog-title">Lier un produit</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            onChange={handleChangeSearch}
            value={search}
            label="chercher"
            size="small"
            style={{
              marginRight: 16,
              flexGrow: 1,
              marginBottom: 10,
              width: 200,
            }}
          />
          <EnhancedTable
            staticData
            rows={productList}
            headCells={columns}
            count={productList.length}
            rowsPerPageOptions={[5, 10, 20]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Annuler
          </Button>
          <Button
            disabled={!selectedRef}
            onClick={handleLinkproduct}
            variant="contained"
            color="primary"
            autoFocus
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RelatedProduct;
