/* eslint-disable max-len */
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';

const DuplicateRowPopup = ({ onClose }) => (
  <Dialog open onClose={onClose}>
    <DialogContent>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '114px',
          height: '114px',
          background: '#F86060',
          borderRadius: '50%',
        }}
        >
          <BlockIcon sx={{ fontSize: '40px', color: '#FFF' }} />
        </Box>

      </Box>
      <Typography sx={{
        textAlign: 'center',
        font: 'normal normal 500 20px/32px Montserrat',
        color: '#000000',
        marginTop: '20px',
      }}
      >
        Nous avons trouvé une référence en double dans votre dossier de proposition. Veuillez supprimer la référence en double avant de continuer
      </Typography>
    </DialogContent>
    <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        sx={{
          background: '#FFFFFF',
          boxShadow: '0px 4px 10px #BEBEBE50',
          border: '1px solid #EBEDED',
          borderRadius: '12px',
        }}
        onClick={onClose}
        color="primary"
      >
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

const App = () => {
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="App">
      <DuplicateRowPopup open={showPopup} onClose={handleClosePopup} />
      {/* Rest of your application */}
    </div>
  );
};

export default App;
