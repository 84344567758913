import { Box } from '@mui/material';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ConnectedStore from 'Shared/ConnectedStore';
import OrderEdit from './Orders/OrderEdit';
import RecoveryListTabs from './RecoveryListTabs/RecoveryListTabs';
import { Provider, initialState } from './context';
import ControleOrderReducer from './reducers';
import OrderControlDetail from './OrdersControl/OrderControlDetail';

const Recovery = ({ path }) => (
  <div>
    <Box style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}>
      <Switch>
        <Route exact path={`${path}/`} component={RecoveryListTabs} />
        <Route path={`${path}/detail`} component={OrderControlDetail} />
        <Route exact path={`${path}/edit`} component={OrderEdit} />
      </Switch>
    </Box>
  </div>
);

export default ConnectedStore(Recovery, ControleOrderReducer, initialState, Provider);
