import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import UserContext from 'Shared/UserContext';
import {
  deleteCLientContact,
} from '../ClientServices';
import Edit from '../Edit';
import View from '../View';
import { Fields } from './constants';

const Contact = ({ client, getClient }) => {
  const {
    state: { user },
  } = useContext(UserContext);
  const isRECOUVREMENT = user.role === 'RECOUVREMENT';
  const [editMode, setEditMode] = useState(false);
  const [editedContact, setEditedContact] = useState(null);
  const toEditMode = () => {
    setEditMode(true);
  };
  const toViewMode = () => {
    setEditMode(false);
    setEditedContact(null);
  };
  useEffect(() => {
    if (!editMode) {
      getClient();
    }
  }, [editMode]);

  const handleDelete = async (data) => {
    await deleteCLientContact(data.id);
    getClient();
  };

  const handleEdit = ({ id }) => {
    const aa = client?.contacts.find((item) => item.id === id);
    setEditedContact(aa);
    toEditMode();
  };

  return (
    <>
      {editMode && <Edit fields={Fields} handleViewMode={toViewMode} client={editedContact || client} codeClient={client?.code} currentMode={editedContact ? 'editContact' : 'addContact'} />}
      {!editMode && isRECOUVREMENT && (
      <div style={{ padding: '20px', margin: 0 }}>
        <Button style={{ float: 'right' }} variant="contained" color="primary" onClick={toEditMode}>Nouveau contact</Button>
      </div>
      )}
      <View fields={Fields} client={client} handleEditMode={toEditMode} currentMode="contactMode" handleDelete={handleDelete} handleEdit={handleEdit} />
    </>
  );
};

export default Contact;
