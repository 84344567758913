import React from 'react';

function BoxIcon() {
  return (
    <svg width="33" height="27" viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.9098 9.76596L29.8716 4.04177C29.7685 3.84761 29.5929 3.71399 29.3939 3.65915L29.3903 3.65794L16.7332 0.241465C16.6016 0.206045 16.463 0.205843 16.3313 0.241196L3.61057 3.65767L3.60674 3.65895C3.40739 3.71366 3.2315 3.8474 3.12834 4.04177L0.0901859 9.76596C-0.00881413 9.95254 -0.0271624 10.1715 0.0393753 10.3719C0.105913 10.5723 0.251624 10.7369 0.442567 10.8271L3.08599 12.0766V19.8961C3.08599 20.1928 3.25577 20.4633 3.52299 20.5921L16.1959 26.7083C16.3023 26.7598 16.4172 26.7852 16.5317 26.7852C16.6454 26.7852 16.7583 26.759 16.8629 26.7094L16.8677 26.7083L29.5406 20.592C29.8078 20.4631 29.9776 20.1927 29.9776 19.896V12.0773C29.9776 12.0672 29.9764 12.0573 29.9761 12.0474L32.5575 10.8271C32.7484 10.7369 32.8941 10.5724 32.9607 10.372C33.0271 10.1714 33.0088 9.95261 32.9098 9.76596ZM16.5312 1.7881L26.6522 4.51997L16.5 8.28251L6.35266 4.52185L16.5312 1.7881ZM4.17667 5.36392L15.4607 9.54592L12.9754 15.0417L1.83522 9.77558L4.17667 5.36392ZM4.63182 19.4109V12.8074L13.0254 16.7751C13.1298 16.8245 13.2427 16.8493 13.3558 16.8493C13.4459 16.8493 13.5362 16.8336 13.6225 16.8019C13.817 16.7304 13.9747 16.5837 14.0601 16.3949L15.7589 12.6381V24.7811L4.63182 19.4109ZM28.4317 19.4109L17.3046 24.7811V12.7786L18.9399 16.3949C19.0253 16.5837 19.1829 16.7303 19.3775 16.8019C19.4637 16.8336 19.5541 16.8493 19.6442 16.8493C19.7574 16.8493 19.8702 16.8245 19.9745 16.7751L28.4317 12.7774V19.4109ZM20.0245 15.0417L17.5393 9.54585L28.8232 5.36386L31.1648 9.77551L20.0245 15.0417Z" fill="#888888" />
    </svg>
  );
}
function ClockIcon() {
  return (
    <svg width="29" height="33" viewBox="0 0 29 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.0795 10.1436L26.3733 8.84972C26.8761 8.34693 26.8761 7.5317 26.3733 7.02917C25.8705 6.52638 25.0555 6.52638 24.5527 7.02917L23.2589 8.32301C21.0881 6.5143 18.4503 5.42162 15.6366 5.16582V2.57485H16.8811C17.5921 2.57485 18.1684 1.9983 18.1684 1.2873C18.1684 0.576302 17.5921 0 16.8811 0H11.8172C11.1062 0 10.5299 0.576302 10.5299 1.2873C10.5299 1.9983 11.1062 2.57485 11.8172 2.57485H13.0617V5.16582C6.0182 5.80632 0.402466 11.7234 0.402466 19.0532C0.402466 26.7614 6.64007 33 14.3493 33C22.0572 33 28.2958 26.7624 28.2958 19.0532C28.2958 15.7606 27.1618 12.6424 25.0795 10.1436ZM14.349 30.4251C8.07868 30.4251 2.97707 25.3238 2.97707 19.0532C2.97707 12.7829 8.07868 7.6815 14.349 7.6815C20.6196 7.6815 25.721 12.7829 25.721 19.0532C25.721 25.3238 20.6196 30.4251 14.349 30.4251ZM20.3152 13.0873C20.818 13.59 20.818 14.4053 20.3152 14.9078L15.2594 19.9636C14.7566 20.4664 13.9414 20.4664 13.4389 19.9636C12.9361 19.4608 12.9361 18.6456 13.4389 18.1431L18.4944 13.0873C18.9972 12.5845 19.8124 12.5845 20.3152 13.0873Z" fill="#ED9747" fillOpacity="0.45" />
    </svg>
  );
} function WaitingIcon() {
  return (
    <svg width="31" height="33" viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.017 25.2944L23.4676 25.3351L23.4633 21.7577L21.9347 21.7788L21.96 26.8813L27.0346 26.8231L27.017 25.2944Z" fill="#EC6A58" fillOpacity="0.45" />
      <path d="M28.5409 20.259V8.23904L14.2704 0L0 8.23904V24.7171L14.2704 32.9562L18.0667 30.7644C19.4151 32.1428 21.2944 33 23.3704 33C27.4628 33 30.7924 29.6705 30.7924 25.578C30.7924 23.4941 29.9287 21.6084 28.5409 20.259ZM14.2704 2.23562L25.6367 8.79792L20.9217 11.5202L9.55543 4.95786L14.2704 2.23562ZM13.3024 30.1615L1.93617 23.5993V10.4747L13.3024 17.037V30.1615ZM2.90426 8.79792L7.61926 6.07567L18.9855 12.638L14.2704 15.3602L2.90426 8.79792ZM15.2385 30.1615V17.037L26.6047 10.4747V18.8988C25.6266 18.4232 24.5291 18.1561 23.3704 18.1561C19.2779 18.1561 15.9484 21.4856 15.9484 25.5781C15.9484 26.8943 16.2932 28.1313 16.8966 29.2043L15.2385 30.1615ZM23.3704 31.0638C20.3455 31.0638 17.8846 28.6029 17.8846 25.578C17.8846 22.5532 20.3455 20.0922 23.3704 20.0922C26.3952 20.0922 28.8562 22.5532 28.8562 25.578C28.8562 28.6029 26.3953 31.0638 23.3704 31.0638Z" fill="#EC6A58" fillOpacity="0.45" />
    </svg>

  );
}
function InvoiceIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.45" clipPath="url(#clip0)">
        <path d="M29.1098 1.38152C28.9997 1.31357 28.8728 1.2774 28.7435 1.2774H7.85572C6.05996 1.2774 4.59901 2.7241 4.59901 4.49713L4.47626 22.4847H0.701428C0.313999 22.4847 0 22.7987 0 23.1861V26.4266C0.00246596 28.8063 1.93085 30.7347 4.3105 30.7374C4.35872 30.7374 4.40722 30.7325 4.45462 30.7226H21.8821C21.9859 30.73 22.0906 30.7349 22.1961 30.7349C24.6127 30.7305 26.5696 28.7709 26.5704 26.3543V15.8394H31.2985C31.6859 15.8394 31.9999 15.5254 31.9999 15.138V4.72564C31.9914 3.05125 30.7653 1.63223 29.1098 1.38152ZM1.40286 26.4269V23.8878H17.8218V26.3546C17.8209 27.4536 18.2344 28.5123 18.9799 29.3197H4.2146C4.18144 29.32 4.14856 29.3225 4.11596 29.3274C2.59008 29.2236 1.40477 27.9563 1.40286 26.4269ZM25.1676 4.72564V26.3543C25.1662 27.1385 24.8547 27.8898 24.3009 28.4446C23.7707 28.9885 23.0504 29.306 22.2914 29.3302C22.2536 29.3236 22.2153 29.3203 22.1766 29.32H21.93C20.4022 29.1696 19.2348 27.89 19.2246 26.3548V23.1861C19.2246 22.7989 18.9106 22.4847 18.5232 22.4847H5.87912L6.00187 4.50206C6.01201 3.48773 6.84112 2.67259 7.85572 2.68026H25.8643C25.4125 3.26634 25.1673 3.98558 25.1676 4.72564ZM30.5971 14.4366H26.5704V4.72564C26.5888 3.62664 27.4848 2.74575 28.5837 2.74575C29.6827 2.74575 30.5787 3.62664 30.5971 4.72564V14.4366Z" fill="#73CF76" />
        <path d="M9.00538 8.87639H15.6487C16.0361 8.87639 16.3501 8.56239 16.3501 8.17496C16.3501 7.78753 16.0361 7.47353 15.6487 7.47353H9.00538C8.61795 7.47353 8.30396 7.78753 8.30396 8.17496C8.30396 8.56239 8.61795 8.87639 9.00538 8.87639Z" fill="#73CF76" />
        <path d="M21.3977 12.4887H9.00538C8.61795 12.4887 8.30396 12.8027 8.30396 13.1902C8.30396 13.5773 8.61795 13.8916 9.00538 13.8916H21.3977C21.7851 13.8916 22.0991 13.5773 22.0991 13.1902C22.0991 12.8027 21.7851 12.4887 21.3977 12.4887Z" fill="#73CF76" />
        <path d="M21.3977 17.5037H9.00538C8.61795 17.5037 8.30396 17.818 8.30396 18.2051C8.30396 18.5925 8.61795 18.9065 9.00538 18.9065H21.3977C21.7851 18.9065 22.0991 18.5925 22.0991 18.2051C22.0991 17.818 21.7851 17.5037 21.3977 17.5037Z" fill="#73CF76" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
function ClosedIcon() {
  return (
    <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.45">
        <path d="M30.0412 20.259V8.23904L15.7707 0L1.50024 8.23904V24.7171L15.7707 32.9562L19.567 30.7644C20.9153 32.1428 22.7947 33 24.8706 33C28.9631 33 32.2926 29.6705 32.2926 25.578C32.2926 23.4941 31.4289 21.6084 30.0412 20.259ZM15.7707 2.23562L27.1369 8.79792L22.4219 11.5202L11.0557 4.95786L15.7707 2.23562ZM14.8026 30.1615L3.43642 23.5993V10.4747L14.8026 17.037V30.1615ZM4.4045 8.79792L9.11951 6.07567L20.4857 12.638L15.7707 15.3602L4.4045 8.79792ZM16.7388 30.1615V17.037L28.1049 10.4747V18.8988C27.1268 18.4232 26.0294 18.1561 24.8706 18.1561C20.7782 18.1561 17.4487 21.4856 17.4487 25.5781C17.4487 26.8943 17.7935 28.1313 18.3969 29.2043L16.7388 30.1615ZM24.8706 31.0638C21.8458 31.0638 19.3848 28.6029 19.3848 25.578C19.3848 22.5532 21.8458 20.0922 24.8706 20.0922C27.8955 20.0922 30.3564 22.5532 30.3564 25.578C30.3564 28.6029 27.8955 31.0638 24.8706 31.0638Z" fill="#CE66DF" />
        <path d="M27.163 22.7656L23.993 25.9531L22.573 24.5476L21.2109 25.9235L24.0037 28.6881L28.5358 24.1309L27.163 22.7656Z" fill="#CE66DF" />
      </g>
    </svg>
  );
}
function InprogressIcon() {
  return (
    <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path d="M26.8897 4.03476L28.4882 3.83495C29.2036 3.74472 29.7128 3.09374 29.629 2.37187C29.5452 1.708 29.0038 1.16659 28.1659 1.23105L23.3577 1.83046C22.6423 1.9207 22.1331 2.57167 22.2169 3.2871L22.8098 8.15976C22.9001 8.87519 23.551 9.39081 24.2665 9.30058C24.9819 9.21034 25.4975 8.55937 25.4073 7.83749L25.1882 6.02636C29.7063 9.37792 31.5819 15.1916 29.8481 20.5734C28.1143 25.9553 23.0225 29.6484 17.3958 29.6613C16.5643 29.6613 16.0358 30.2156 16.0165 30.9375C16.0036 31.6594 16.5192 32.2781 17.3958 32.2781C24.1505 32.2781 30.2671 27.8373 32.3425 21.3727C34.405 14.9853 32.2136 8.06952 26.8897 4.03476Z" fill="#4A4AFF" fillOpacity="0.45" />
        <path d="M11.8205 24.8338C11.7303 24.1184 11.0793 23.6027 10.3639 23.693C9.64848 23.7832 9.13285 24.4342 9.22309 25.1561L9.44223 26.9672C4.92407 23.6156 3.04848 17.802 4.78227 12.4201C6.51606 7.03828 11.6079 3.34512 17.2346 3.33223C18.066 3.33223 18.5946 2.77793 18.6139 2.05606C18.6268 1.33418 18.1112 0.715431 17.2346 0.715431C10.4864 0.708985 4.36977 5.1498 2.29438 11.6145C0.231886 18.0146 2.42329 24.9305 7.74711 28.9652L6.14868 29.165C5.43325 29.2553 4.92407 29.9063 5.00786 30.6281C5.09165 31.292 5.63305 31.8334 6.47094 31.7689L11.2791 31.1695C11.9946 31.0793 12.5038 30.4283 12.42 29.7129L11.8205 24.8338Z" fill="#4A4AFF" fillOpacity="0.45" />
        <g clipPath="url(#clip1)">
          <path d="M25.2097 12.6969L18.1444 9.11696L18.1415 9.11549C17.8269 8.95887 17.453 8.96237 17.1414 9.12487L14.1111 10.7331C14.0865 10.7436 14.0628 10.7562 14.0402 10.7708L10.4089 12.698C10.0446 12.8887 9.81836 13.2624 9.81836 13.6737V20.3262C9.81836 20.7375 10.0446 21.1112 10.4089 21.3019L17.1384 24.8735L17.1414 24.8751C17.3004 24.958 17.4755 24.9995 17.6507 24.9995C17.819 24.9995 17.9873 24.9612 18.1415 24.8845L25.2097 21.3031C25.5852 21.1153 25.8184 20.7381 25.8184 20.3181V13.6819C25.8184 13.2619 25.5852 12.8846 25.2097 12.6969ZM17.5758 9.95527C17.6217 9.93171 17.6765 9.93124 17.7228 9.95396L24.3362 13.3049L21.7623 14.5863L15.2967 11.1648L17.5758 9.95527ZM17.1754 23.8322L10.8468 20.4733L10.8438 20.4717C10.7894 20.4433 10.7555 20.3876 10.7555 20.3262V14.0966L17.1754 17.4442V23.8322ZM17.6489 16.6342L11.2644 13.305L14.2964 11.6958L20.73 15.1003L17.6489 16.6342ZM24.8811 20.3181C24.8812 20.3808 24.8463 20.4371 24.7902 20.465L18.1125 23.8485V17.4502L21.1871 15.9196V17.5244C21.1871 17.7832 21.3969 17.993 21.6557 17.993C21.9145 17.993 22.1243 17.7832 22.1243 17.5244V15.453L24.8811 14.0805V20.3181Z" fill="#4A4AFF" fillOpacity="0.45" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="33" height="33" fill="white" transform="translate(0.818359)" />
        </clipPath>
        <clipPath id="clip1">
          <rect width="16" height="16" fill="white" transform="translate(9.81836 9)" />
        </clipPath>
      </defs>
    </svg>

  );
}

export {
  BoxIcon, ClockIcon, InprogressIcon, ClosedIcon, InvoiceIcon, WaitingIcon,
};
