import { secureGet } from 'config/apiConfig';
import { groupBy } from 'lodash';

export const defaultMessage = 'Erreur système';
export const getArticles = async (
  search,
  familyId,
  mfrId,
  conf,
  type = 0,
  size = 100,
  page = 1,
  carId = null,
  familyCarId = null,
) => {
  const url = `/ci/api/tecdoc/articles?${carId ? `carId=${carId}&` : ''}${familyCarId ? `familyId=${familyCarId}&` : ''}searchQuery=${
    search}&searchType=${type}${
    familyId ? `&articleId=${familyId}` : ''
  }${mfrId ? `&mfrId=${mfrId}` : ''}${size ? `&size=${size}` : ''}${
    page >= 0 ? `&page=${page}` : ''
  }`;
  const { data } = await secureGet(url, conf);
  return data;
};
export const getArticlesByCarFamily = async (carIdFamiliId) => {
  const url = `/ci/api/tecdoc/articles?${carIdFamiliId}&page=1&size=1000`;
  const { data } = await secureGet(url);
  return data;
};
export const getVehicules = async (vin, conf) => {
  const url = `/ci/api/tecdoc/vehicules?vin=${vin}`;
  const { data } = await secureGet(url, conf);
  return data || [];
};

const getAndClear = (groupLevel) => {
  const orphan = groupLevel.undefined;
  const parentZero = groupLevel['0'];
  if (orphan) {
    // eslint-disable-next-line no-param-reassign
    delete groupLevel.undefined;
    if (parentZero) {
      // eslint-disable-next-line no-param-reassign
      delete groupLevel['0'];
      return [...orphan, ...parentZero];
    }
    return orphan;
  }
  // eslint-disable-next-line no-param-reassign
  delete groupLevel['0'];
  return parentZero || [];
};

const findSubFamilies = (families, grouplevel1) => {
  if (Object.keys(grouplevel1).length === 0) {
    return;
  }
  families.forEach((family) => {
    const subFamilies = grouplevel1[family.assemblyGroupNodeId];
    if (subFamilies !== undefined) {
      // eslint-disable-next-line no-param-reassign
      delete grouplevel1[family.assemblyGroupNodeId];
      findSubFamilies(subFamilies, grouplevel1);
      // eslint-disable-next-line no-param-reassign
      family.subFamilies = subFamilies;
    }
  });
};

export const getCarFamilies = async (carId) => {
  const url = `/ci/api/tecdoc/families?carId=${carId}`;
  const { data } = await secureGet(url);
  const grpLevel1 = groupBy(data, 'parentNodeId');
  const families = getAndClear(grpLevel1);
  findSubFamilies(families, grpLevel1);
  return families || [];
};

export const getAllFamilies = async () => {
  const url = '/ci/api/tecdoc/families';
  const { data } = await secureGet(url);
  return data || [];
};

export const getAllBrands = async () => {
  const url = '/ci/api/tecdoc/brands';
  const { data } = await secureGet(url);
  return data || [];
};

export const getVehiculesByArticle = async (refTecDoc, page = 0) => {
  const url = `/ci/api/tecdoc/vehicules/${refTecDoc}/${page}`;
  const { data } = await secureGet(url);
  return data || [];
};
