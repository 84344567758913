import { Box, IconButton, Typography } from '@mui/material';
import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import { defaultMessage } from 'Shared/Referencial/ReferencialService';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import UserContext from 'Shared/UserContext';
import { getObjectives } from '../HomeServices';
import Challenge from '../Challenge';
import Objective from '../Objective';

const activeStyle = {
  color: 'white',
  background:
    'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
  boxShadow: '0px 4px 10px #108CD650',
  borderRadius: 12,
};
const inactiveStyle = {
  background: '#D7DFE4 0% 0% no-repeat padding-box',
  borderRadius: 12,
  color: '#286183',
};
const ObjectiveAndChallenges = () => {
  const {
    state: { user },
  } = useContext(UserContext);
  const [objectives, setObjectives] = useState([]);
  const [cardPerSlide, setCardPerSlide] = useState(0);
  const [indexCard, setIndexCard] = useState(0);
  const contentWidth = useRef(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setCardPerSlide(
        parseInt((contentWidth?.current?.getBoundingClientRect()?.width - 50) / 248, 10),
      );
    });
    resizeObserver.observe(contentWidth?.current);
    return () => resizeObserver.disconnect();
  }, [setCardPerSlide]);

  const nextElem = () => {
    setIndexCard(indexCard + 1);
  };
  const prevElem = () => {
    setIndexCard(indexCard - 1);
  };
  const getObjectivesList = useCallback(async () => {
    try {
      const objectivesList = await getObjectives(user.sellerId);
      setObjectives(objectivesList
        .reduce((res, current) => res.concat([current, current]), []));
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    }
  }, [setObjectives]);

  useEffect(() => {
    getObjectivesList();
  }, [getObjectivesList]);
  return (
    <>
      <Box style={{ display: 'flex' }}>
        <Typography style={{ flexGrow: 1, color: '#000', font: 'normal normal 600 26px Montserrat' }}>
          Challenges et objectifs
        </Typography>
        <Box>
          {indexCard === 0 && (
            <IconButton
              color="primary"
              style={{ ...inactiveStyle, marginRight: 16 }}
              component="span"
              size="small"
              disabled
            >
              <ArrowLeftIcon />
            </IconButton>
          )}
          {indexCard > 0 && (
            <IconButton
              color="primary"
              style={{ ...activeStyle, marginRight: 16 }}
              component="span"
              size="small"
              onClick={prevElem}
            >
              <ArrowLeftIcon />
            </IconButton>
          )}
          {cardPerSlide + indexCard + 1 > objectives.length && (
            <IconButton
              color="primary"
              style={inactiveStyle}
              component="span"
              size="small"
              disabled
            >
              <ArrowRightIcon />
            </IconButton>
          )}
          {cardPerSlide + indexCard + 1 <= objectives.length && (
            <IconButton
              color="primary"
              style={activeStyle}
              component="span"
              size="small"
              onClick={nextElem}
            >
              <ArrowRightIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box style={{ width: '100%', display: 'flex' }} ref={contentWidth}>
        {objectives.slice(indexCard, cardPerSlide + indexCard).map((objective, index) => (
          <>
            {index % 2 === 0 && <Challenge objective={objective} />}
            {(index % 2 !== 0 || (index < 2 && index % 2 !== 0))
              && <Objective objective={objective} />}
          </>
        ))}
      </Box>
    </>
  );
};

export default ObjectiveAndChallenges;
