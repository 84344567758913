import { Box, Typography } from '@mui/material';
import React from 'react';

const TraceField = ({
  isSelected, title, id, onClick,
}) => (
  <Box
    onClick={onClick(id)}
    sx={{
      cursor: 'pointer', background: isSelected ? '#FFF' : '', borderRadius: '12px', width: '100%',
    }}
  >
    <Typography
      sx={{
        color: isSelected ? '#043F88' : '#ACAFC6', font: 'normal bold 11px Montserrat', p: 1.5, textAlign: 'center',
      }}
    >
      {title}
    </Typography>
  </Box>
);

const TraceabilityIteams = ({ fields, selected, onChangeTab }) => (
  <Box sx={{
    background: 'rgba(171, 175, 198, 0.21)',
    p: 1.5,
    pt: 0.9,
    pb: 0.9,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '12px',
    mb: 1,
  }}
  >
    {fields.map((item) => (
      <TraceField
        key={`key-trace-tab-${item.key}`}
        id={item.key}
        isSelected={selected === item.key}
        title={item.title}
        onClick={onChangeTab}
      />
    ))}
  </Box>
);

export default TraceabilityIteams;
