/* eslint-disable max-len */
import { Box, Button } from '@mui/material';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { defaultMessage } from 'Shared/Referencial/ReferencialService';
import ReferencialContext from 'Shared/ReferencialContext';
import UserContext from 'Shared/UserContext';
import { withoutTime } from 'Utils/ulilities';
import Filters from '../Filters';
import { DEFAULT_VALUES, TYPES } from '../NewOrder/constants';
import { getAllBlockedOrders, getBlockedOrders } from '../OrdersService';
import { columns } from './constants';
import NewOrder from '../NewOrder';

const SalesBlockedOrders = () => {
  const {
    state: {
      referencial: { clients, expeditions, paymentModes },
    },
  } = useContext(ReferencialContext);
  const [loading, setLoading] = useState(false);
  const [openNewOrder, setOpenNewOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [blockedOrders, setBlockedOrders] = useState([]);
  const [values, setValues] = useState(DEFAULT_VALUES);
  const handleCloseNewOrderModal = () => {
    setSelectedOrder(null);
    setOpenNewOrder(false);
  };

  const handleOpenNewOrderModal = () => {
    setOpenNewOrder(true);
  };
  const handleEditOrder = (id) => () => {
    setSelectedOrder(
      blockedOrders
        .map((o) => ({
          ...o,
          isblocked: true,
          articles: o.commande.map((c) => ({ ...c.products[0], quantity: c.quantity })),
          type: TYPES.find((t) => t.id === o.souche),
          city: o.city,
          client: (clients || []).find((c) => c.code === o.client_code),
          date: new Date(o.created_on),
          expedition: expeditions.find((e) => e.name === o.expedition),
          paymentMode: paymentModes.find((p) => p.name === o.paymentMode),
          personalInfo: o.comment,
        }))
        .find((o) => o.id === id),
    );
    handleOpenNewOrderModal();
  };
  const handleInputChange = (prop) => (event, value) => {
    setValues({ ...values, [prop]: event instanceof Date ? event : value || event?.target.value });
  };
  const handleRefresh = () => {
    setValues({ ...values });
  };
  const salesOrders = useMemo(
    () => blockedOrders
      .filter(
        (o) => (!values.client || values.client.code === o.client_code)
            && (!values.expedition || values.expedition.name === o.expedition)
            && (!values.paymentMode || values.paymentMode.name === o.paymentMode)
            && (!values.city || values.city === o.city)
            && (!values.product
              || o.commande.map((a) => a.productId).includes(values.product?.reference))
            && (!values.startDate || withoutTime(values.startDate) <= withoutTime(o.created_on))
            && (!values.endDate || withoutTime(values.endDate) >= withoutTime(o.created_on)),
      )
      .map((o) => ({
        amount: o.commande
          ?.reduce((prev, curr) => prev + parseInt(curr.quantity, 10) * curr.products[0]?.p, 0)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$& '),
        client: o.client_name,
        city: o.city,
        date: new Date(o.created_on),
        expedition: o.expedition,
        paymentMode: o.paymentMode,
        personalInfo: o.comment,
        id: o.id,
        reference: o.id,
        type: TYPES.find((t) => t.id === o.souche)?.name,
        isblocked: true,
        error: o.error,
        click: handleEditOrder(o.id),
      })),
    [values, blockedOrders],
  );
  const {
    state: { user },
  } = useContext(UserContext);
  const isCommercialOrSales = ['COMMERCIAL', 'SALES'].includes(user.role);
  const getBlockedOrderList = useCallback(async () => {
    try {
      setLoading(true);
      const data = await (isCommercialOrSales ? getBlockedOrders() : getAllBlockedOrders());
      setBlockedOrders(data.filter((o) => o.treated === 0));
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setBlockedOrders, setLoading, values, isCommercialOrSales]);

  useEffect(() => {
    getBlockedOrderList();
  }, [getBlockedOrderList]);
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      {openNewOrder && <NewOrder handleClose={handleCloseNewOrderModal} order={selectedOrder} />}
      <Box style={{ display: 'flex', padding: 10, alignItems: 'center' }}>
        <Filters
          values={values}
          handleInputChange={handleInputChange}
          handleRefresh={handleRefresh}
        />
        <Button
          variant="contained"
          style={{ minWidth: '230px' }}
          color="primary"
          onClick={handleOpenNewOrderModal}
        >
          Ajouter une commande
        </Button>
      </Box>
      <EnhancedTable
        staticData
        rows={salesOrders}
        headCells={columns}
        count={salesOrders.length}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </>
  );
};

export default SalesBlockedOrders;
