import { useEffect, useState } from 'react';
import useLocalStorage from 'Shared/Hooks/useLocalStorage';
import { addPropositionProductNotes, deltePropositionProductNotes, getPropositionProductNotes } from '../PropositionServices';

const usePropositionProductNotes = () => {
  const [loading, setLoading] = useState(false);
  const [openNotes, setOpenNote] = useState(false);
  const [notes, setNotes] = useState([]);
  const [propositionProductId, setPropositionProductId] = useState(null);
  const [user] = useLocalStorage('user');

  const onHandleNotes = (currentProductProposition = null) => {
    setPropositionProductId(currentProductProposition);
    setOpenNote(!openNotes);
  };

  // get notes
  const getNotes = async () => {
    try {
      setLoading(true);
      const data = await getPropositionProductNotes(propositionProductId);
      setNotes(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (openNotes && propositionProductId) {
      getNotes();
    }
  }, [openNotes, propositionProductId]);

  // save note
  const saveNote = async (comment, parentId) => {
    try {
      setLoading(true);
      const body = { userId: user.id, content: comment, parentId };
      await addPropositionProductNotes(propositionProductId, body);
      getNotes();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // remove note
  const deleteNote = async (noteId) => {
    try {
      setLoading(true);
      await deltePropositionProductNotes(propositionProductId, noteId);
      getNotes();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    isNotesOpen: openNotes,
    onHandleNotes,
    deleteNote,
    saveNote,
    notes,
    noteLoading: loading,
    propositionProductId,
  };
};

export default usePropositionProductNotes;
