import { Box } from '@mui/material';
import Input from 'Shared/Input/v2';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import React, { useMemo } from 'react';
import { TYPES } from './constants';

const NewDiscount = ({
  values, onChange, brands, clients, products, allFamilies, isEdit = false,
}) => {
  const families = useMemo(() => allFamilies
    ?.filter((family) => family.familyLevel === 4), [allFamilies]);

  const onChangeSelect = (name) => (e, value) => {
    onChange({ target: { value, name } });
  };
  return (
    <Box sx={{ background: '#38485c1c', padding: 1.5, borderRadius: 1.5 }}>
      <Box sx={{ display: 'flex' }}>
        <AutoCompleteCmp
          options={TYPES}
          onChange={onChangeSelect('type')}
          value={values.type}
          label="Type"
          getOptionLabel={(label) => (label)}
          isPrimaryTheme
          style={{ width: 200 }}
          disableClearable
          disabled={isEdit}
        />
        {values.type === TYPES[1] ? (
          <AutoCompleteCmp
            options={brands || []}
            onChange={onChangeSelect('brand')}
            value={values.brand}
            label="Marques*"
            isPrimaryTheme
            style={{ width: 200 }}
            disabled={isEdit}
          />
        ) : null }
        {values.type === TYPES[0] ? (
          <AutoCompleteCmp
            options={families || []}
            onChange={onChangeSelect('family')}
            value={values.family}
            label="Famille*"
            isPrimaryTheme
            style={{ width: 200 }}
            disabled={isEdit}
          />
        ) : null }
        {values.type === TYPES[2] ? (
          <AutoCompleteCmp
            options={products || []}
            onChange={onChangeSelect('reference')}
            getOptionLabel={(option) => option.reference}
            filterOptions={
              (options, v) => options.filter((option) => option.reference?.toLowerCase()
                .includes(v.inputValue.toLowerCase()))
            }
            value={values.reference}
            label="Référence*"
            isPrimaryTheme
            style={{ width: 200 }}
            disabled={isEdit}
          />
        ) : null }
        <Input
          label="Qte Min*"
          type="number"
          max={values.maxQte}
          name="minQte"
          onChange={onChange}
          value={values.minQte}
        />
        <Input
          label="Qte Max*"
          type="number"
          max={values.minQte}
          name="maxQte"
          onChange={onChange}
          value={values.maxQte}
        />
      </Box>
      <Box sx={{ display: 'flex', mt: 2, width: '75%' }}>
        <Input
          label="Remise"
          type="number"
          name="discount"
          onChange={onChange}
          value={values.discount}
        />
        <Input
          label="Special Price"
          type="number"
          name="price"
          onChange={onChange}
          value={values.price}
        />
        <AutoCompleteCmp
          options={clients || []}
          onChange={onChangeSelect('client')}
          value={values.client}
          label="Special Client"
          isPrimaryTheme
          style={{ width: 200 }}
        />
      </Box>
    </Box>
  );
};

export default NewDiscount;
