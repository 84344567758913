import {
  Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup,
} from '@mui/material';
import React from 'react';
import Input from 'Shared/Input';
import InputFile from 'Shared/InputFile/InputFile';

const InfoGenerale = ({
  values, onChange, onSubmit, onClose,
}) => (
  <Grid container style={{ mt: 4 }} spacing={4}>
    <Grid item xs={4}>
      <Input
        value={values.title}
        onChange={onChange}
        margin="dense"
        label="Titre de l’annonce:"
        name="title"
      />
    </Grid>
    <Grid item xs={12}>
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">Type de l’annonce:</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="type"
        >
          <FormControlLabel value="NOUVEAUTE" checked={values?.type === 'NOUVEAUTE'} onChange={onChange} control={<Radio />} label="Nouveauté" />
          <FormControlLabel value="PROMOTION" checked={values?.type === 'PROMOTION'} onChange={onChange} control={<Radio />} label="Promotion" />
          <FormControlLabel value="INFORMATION" checked={values?.type === 'INFORMATION'} onChange={onChange} control={<Radio />} label="Information" />
        </RadioGroup>
      </FormControl>
    </Grid>
    <Grid item xs={4}>
      <Input
        value={values.startDate}
        onChange={onChange}
        type="date"
        margin="dense"
        label="Date début:"
        name="startDate"
      />
    </Grid>
    <Grid item xs={4}>
      <Input
        value={values.endDate}
        onChange={onChange}
        type="date"
        margin="dense"
        label="Date fin:"
        name="endDate"
      />
    </Grid>
    <Grid item xs={4} />
    <Grid item xs={4}>
      <Input
        value={values.remise}
        onChange={onChange}
        type="number"
        margin="dense"
        label="Taux de remise:"
        name="remise"
        disabled={values.type !== 'PROMOTION'}
      />
    </Grid>
    <Grid item xs={4}>
      <InputFile
        name="image"
        label="Image de l’annonce:"
        value={values.image?.name || values.image}
        onChange={onChange}
      />
    </Grid>
    <Grid item xs={6} />
    <Grid item xs={3}>
      <Button
        fullWidth
        onClick={onClose}
        variant="contained"
        color="inherit"
        style={{
          border: '2px solid #CCCCCC', color: '#CCCCCC', backgroundColor: '#FFFFFF', textTransform: 'capitalize',
        }}
      >
        Annuler
      </Button>
    </Grid>
    <Grid item xs={3}>
      <Button
        fullWidth
        onClick={onSubmit}
        variant="contained"
        color="primary"
        style={{ textTransform: 'capitalize' }}
      >
        Suivant
      </Button>
    </Grid>
  </Grid>
);

export default InfoGenerale;
