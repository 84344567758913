/* eslint-disable react/void-dom-elements-no-children */
import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {
  Box, CardActions, Divider, Link,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DEFAULT_PRODUCT from 'Images/default_product.png';
import DescriptionIcon from '@mui/icons-material/Description';

const useStyles = makeStyles({
  root: {
    height: 131,
    width: 409,
    margin: '10px 10px 10px 0',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 12px 26px #0000000D',
    borderRadius: 12,
    display: 'flex',
  },

  media: {
    height: 'auto',
    width: '100%',
    maxHeight: '100%',
    borderRadius: 12,
  },
});

function Annoncement({ annoncement }) {
  const classes = useStyles();
  const image = React.createRef();
  return (
    <>
      <Card className={classes.root}>
        <CardActionArea style={{ display: 'flex' }}>
          <Box style={{ position: 'relative', width: '40%', flexShrink: 0 }}>
            <img
              ref={image}
              // eslint-disable-next-line no-return-assign
              onError={() => (image.current.src = DEFAULT_PRODUCT)}
              className={classes.media}
              src={annoncement?.imageUrl?.replace(
                'http://apcc.ma/catalogue',
                'https://media.copima.ma',
              )}
              alt={annoncement?.title}
            />
          </Box>
          <CardContent
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Tooltip title={annoncement?.title}>
              <Typography
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  font: 'normal normal 600 18px Montserrat',
                  color: '#000000',
                }}
              >
                {annoncement?.title}
              </Typography>
            </Tooltip>
            <Tooltip title={annoncement?.description}>
              <Typography
                style={{
                  font: 'normal normal 500 14px Montserrat',
                  color: '#007BC6',
                  textTransform: 'capitalize',
                  marginTop: 6,
                }}
              >
                {annoncement?.description}
              </Typography>
            </Tooltip>
          </CardContent>
        </CardActionArea>
        {false && (
          <CardActions style={{ padding: 16 }}>
            <Box style={{ width: '100%' }}>
              <Divider light />
              <Box>
                <DescriptionIcon
                  style={{
                    color: '#B7BFDB',
                    fontSize: 12,
                    position: 'relative',
                    top: 2,
                    marginRight: 7.4,
                  }}
                />
                <Link
                  href={annoncement?.attachmentUrl}
                  underline="always"
                  style={{ color: '#566497', fontSize: 12 }}
                  target="_blank"
                >
                  lien d&apos;attachement
                </Link>
              </Box>
            </Box>
          </CardActions>
        )}
      </Card>
    </>
  );
}
export default Annoncement;
