/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Popper,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import ReferencialContext from 'Shared/ReferencialContext';
import UserContext from 'Shared/UserContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Quantity from 'Shared/Quantity';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DialogCmp from 'Shared/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { setAppLoadingOff, setAppLoadingOn } from 'appSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { makeSelectIsLoading } from 'selectors';
import { saveDeletedOrders, saveOrders } from '../Actions';
import OrdersContext from '../Context';

import {
  addOrder,
  defaultMessage,
  getAllPaymentModeMapping,
  getCommerciaux,
  getDashboard,
  getPriceRangeOrders,
} from '../OrdersService';

import {
  columns,
  DEFAULT_VALUES,
  DEPOSITS,
  isEmptyOrNull,
  mapFilterCondition,
  mapFilterNameToProperty,
  SEARCH_TYPES,
  TYPES,
} from './constants';
import CustomTable from './CustomTable';
import useStyles from './style';

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 28,
    borderRadius: 12,
    flexGrow: 1,
  },
}))(LinearProgress);

function LinearProgressWithLabel({ value, total, ...props }) {
  return (
    <Box display="flex" alignItems="center" style={{ position: 'relative', flexGrow: 1 }}>
      <Typography
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
          left: 8,
          position: 'absolute',
          font: 'normal normal 600 14px/18px Montserrat',
          color: '#FFF',
          textTransform: 'uppercase',
          zIndex: 1,
        }}
      >
        {value}
      </Typography>
      <BorderLinearProgress
        {...props}
        variant="determinate"
        value={(total > 0 ? value / total : 0) * 100}
      />
      <Typography
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
          right: 8,
          position: 'absolute',
          font: 'normal normal 600 14px/18px Montserrat',
          color: '#38485C',
          textTransform: 'uppercase',
          zIndex: 1,
        }}
      >
        {total}
      </Typography>
    </Box>
  );
}

const PopperMy = (props) => (
  <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />
);
const Messages = ({ messages }) => messages.map((m) => (
  <>
    <Box>
      <FiberManualRecordIcon style={{ fontSize: 10 }} />
      <Typography display="inline" variant="body2">
        {m}
      </Typography>
    </Box>
    <Divider />
  </>
));
const getLinkedReferences = (list, ref) => {
  let references = [ref];
  const { linkedReference } = list.find((a) => a.reference === ref) || {};
  references = linkedReference ? [...references, linkedReference] : references;
  const { reference } = list.find((a) => a.linkedReference === ref) || {};
  references = reference ? [...references, reference] : references;
  return references;
};
const NewOrder = ({ handleClose, order }) => {
  const {
    state: {
      referencial: {
        clients, expeditions, paymentModes, products,
      },
    },
  } = useContext(ReferencialContext);
  const {
    dispatch,
    state: { orders = [], deletedOrders },
  } = useContext(OrdersContext);
  const {
    state: { user },
  } = useContext(UserContext);
  const refQuantity = useRef();
  const inputRef = useRef();
  const [filtredPms, setFiltredPms] = useState(paymentModes);
  const [values, setValues] = useState({ ...DEFAULT_VALUES, deposit: user.affectation });
  const [article, setArticle] = useState({ quantity: 1 });
  const [sellers, setSellers] = useState([]);
  const [uniqueId] = useState(Date.now());
  const [types, setTypes] = useState(TYPES);
  const [authorizedPMs, setAuthorizedPMs] = useState([]);
  const [id, setId] = useState(0);
  const [articles, setArticles] = useState([]);
  const [dashboard, setDashboard] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const isSellerSpecial = user.role === 'SELLER_SPECIAL';
  const dispatchRedux = useDispatch();
  const isLoading = useSelector(makeSelectIsLoading());

  const cyties = useMemo(() => (clients || []).map((c) => c.city).unique(), [clients]);
  const handleInputChangeArticle = (prop, nextValue) => (event, value) => {
    const newValue = nextValue || value || event.target.value;
    const extra = prop === 'reference' && newValue?.salesCondition === 'GAME' && newValue?.gameQuantity
      ? { quantity: newValue?.gameQuantity }
      : {};
    setArticle({ ...article, [prop]: newValue, ...extra });
    if (prop === 'reference') {
      refQuantity.current.focus();
    }
  };
  const handleInputChangeArticleQuantity = (ref, list, prop, index) => (event) => {
    const { value, checked } = event.target;
    /* const rest = a.reference === 'AUTRES' && ['r', 'nP'].includes(prop)
          ? { p: prop === 'r' ? parseInt(a.nP, 10) * (1 - parseInt(value, 10) / 100) : parseInt(value, 10) * (1 - parseInt(a.r, 10) / 100) }
          : {}; */
    const references = getLinkedReferences(list, ref);
    setArticles(
      list.map((a, idx) => {
        if (
          (a.reference === ref && a.reference !== 'AUTRES')
          || (a.reference === 'AUTRES' && index === idx)
          || (['mandatory', 'quantity'].includes(prop)
            && references.includes(a.reference)
            && !references.includes('AUTRES'))
        ) {
          let controlValue = parseFloat(value || 0);
          if (prop === 'r' && controlValue > parseFloat(a.rb)) {
            controlValue = parseFloat(a.rb);
          }
          const product = {
            ...a,
            [prop]: ['quantity', 'p', 'r'].includes(prop)
              ? prop === 'quantity' && a?.salesCondition === 'GAME' && a?.gameQuantity
                ? parseFloat(value) % parseFloat(a?.gameQuantity) === 0
                  ? parseFloat(value)
                  : (Math.floor(parseFloat(value) / parseFloat(a?.gameQuantity))
                      + (value > a.quantity ? 1 : 0))
                    * parseFloat(a?.gameQuantity)
                : controlValue?.toString()
              : value === 'mandatory'
                ? checked
                : value,
          };
          return {
            ...product,
            total: ['quantity', 'p'].includes(prop)
              ? (product.quantity * product.p).toFixed(2)
              : product.total,
            totalNet: ['quantity', 'p', 'r'].includes(prop)
              ? (product.quantity * product.p * ((100.0 - product.r) / 100)).toFixed(2)
              : product.totalNet,
          };
        }
        return a;
      }),
    );
  };

  const fetchAUthorizedsPms = useCallback(async () => {
    try {
      dispatchRedux(setAppLoadingOn());
      const list = await getAllPaymentModeMapping();
      setAuthorizedPMs(list);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      dispatchRedux(setAppLoadingOff());
    }
  }, [setAuthorizedPMs]);

  useEffect(() => {
    fetchAUthorizedsPms();
  }, [fetchAUthorizedsPms]);

  const handleRemoveArticle = (ref, list, index) => () => {
    const references = getLinkedReferences(list, ref);
    setArticles(
      list.filter((a, idx) => (ref !== 'AUTRES' ? !references.includes(a.reference) : index !== idx)),
    );
  };
  const onConfirmExpedition = () => {
    // put tempExpedition to expedition values
    setValues((prev) => ({ ...prev, expedition: prev.tempExpedition, tempExpedition: null }));
    setOpenConfirm(false);
  };
  const handleInputChange = (prop) => (event, value) => {
    const propValue = value || event.target.value;
    let localTypes = null;
    let defaultValue;
    if (prop === 'client') {
      localTypes = TYPES.filter((t) => {
        if (propValue?.type === 'MF') {
          if (t.name === 'DEVIS') {
            defaultValue = t;
          }
          return t.name.includes('DEVIS');
        }
        if (propValue?.type === 'FA') {
          if (t.name === 'FACTURES') {
            defaultValue = t;
          }
          return t.name.includes('FACTURE');
        }
        if (t.name === 'FACTURES') {
          defaultValue = t;
        }
        return true;
      });
      setTypes(localTypes);
      setValues((prev) => ({ ...prev, type: defaultValue }));
    }
    if (prop === 'expedition' && values.expedition?.name === 'SDTM' && !!values.client?.eligibleAuPortPaye) {
      // open confirm and save tempExpedition
      setOpenConfirm(true);
      setValues((prev) => ({ ...prev, tempExpedition: propValue }));
      return;
    }

    const relatedClient = (prev) => (prop === 'client'
      ? {
        expedition: value
          ? (expeditions || []).find((e) => e.name === value.expedition)
          : prev.expedition,
        paymentMode: value
          ? (paymentModes || []).find((e) => e.name === value.payment)
          : prev.paymentMode,
        city: value ? value.city : prev.city,
        type: prev.type
          ? localTypes.find((l) => l.id === prev.type.id)
            ? prev.type
            : null
          : prev.type,
      }
      : {});
    if (prop === 'client' && value) {
      const PM = (paymentModes || []).find((e) => e.name === value.payment);
      const authList = authorizedPMs.filter((p) => p?.name === PM?.name);
      const newFiltredListValues = authList && authList[0]?.authorizeds?.length > 0
        ? authList[0]?.authorizeds.map((a) => ({ ...a, code: a.id }))
        : paymentModes;
      setFiltredPms(newFiltredListValues);
    }
    setValues((prev) => ({
      ...prev,
      ...relatedClient(prev),
      [prop]: propValue,
    }));
    const client = prop === 'client' ? propValue : values.client;
    const type = prop === 'type' ? propValue : values.type;
    if (['client', 'type'].includes(prop) && client) {
      const MESSAGES = [];
      if (client.contrEnc === 2 && type && !type.name.includes('HUILE')) {
        MESSAGES.push(
          'L’encours pièces moteur de ce client ne permet pas de passer la commande. Merci de voir avec le service recouvrement',
        );
      }
      if (client.huile !== 'Automatique' && type && type.name.includes('HUILE')) {
        MESSAGES.push(
          'L’encours Huile de ce client ne permet pas de passer la commande. Merci de voir avec le service recouvrement',
        );
      }
      if (client.impaye !== 'Automatique') {
        MESSAGES.push(
          'Ce client dispose d’un ou plusieurs impayés. Merci de voir avec le service recouvrement',
        );
      }
      if (client.releve !== 'Automatique') {
        MESSAGES.push(
          'Ce client dispose d’un nombre de relevés non réglés dépassé. Merci de voir avec le service recouvrement',
        );
      }
      if (MESSAGES.length) {
        toast(<Messages messages={MESSAGES} />, { type: 'error' });
      }
    }
  };
  const handleChangeSimpleInput = (event) => {
    const { name, value } = event.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  useEffect(() => {
    if (order) {
      setValues({
        expedition: order.expedition,
        client: order.client,
        paymentMode: order.paymentMode,
        city: order.city,
        personalInfo: order.personalInfo,
        type: order.type,
        commercial: order.commercial,
        deposit: !order?.deposit && order?.isblocked ? user?.affectation : order?.deposit,
      });
      setArticles(
        order.articles.map((a) => ({
          ...a,
          handleChange: handleInputChangeArticleQuantity,
          handleRemoveArticle,
        })),
      );
    }
  }, [order, user]);
  useEffect(() => {
    setValues((prevValues) => {
      const exp = (expeditions || []).find((e) => e.code === prevValues.expedition?.code);
      const pym = (paymentModes || []).find((p) => p.code === prevValues.paymentMode?.code);
      const clt = (clients || []).find((cl) => cl.code === prevValues.client?.code);
      return {
        ...prevValues,
        expedition: exp || prevValues.expedition,
        client: clt || prevValues.client,
        paymentMode: pym || prevValues.paymentMode,
      };
    });
    if (products?.length) {
      setArticles((prevArticle) => prevArticle.map((a) => {
        const po = (products.filter((p) => p.iA) || []).find((p) => p.reference === a.reference);
        let product;
        if (po.reference === 'AUTRES') {
          product = {
            ...a,
            ...(po
              ? {
                ...po,
                r: a.r,
                p: a.p,
                des: a.des,
                total: (a.p * a.quantity).toFixed(2),
                totalNet: (a.quantity * a.p * ((100.0 - a.r) / 100)).toFixed(2),
              }
              : {}),
          };
        } else if (a.editRemise || a.editBrutPrice) {
          product = {
            ...a,
            ...(po
              ? {
                ...po,
                r: a.editRemise ? a.r : po.r,
                p: a.editBrutPrice ? a.p : po.p,
                total: (po.p * a.quantity).toFixed(2),
                totalNet: (a.quantity * po.p * ((100.0 - a.r) / 100)).toFixed(2),
              }
              : {}),
          };
        } else {
          product = {
            ...a,
            ...(po
              ? {
                ...po,
                p: a.p,
                r: a.r,
                total: (po.p * a.quantity).toFixed(2),
                totalNet: (a.quantity * po.p * ((100.0 - po.r) / 100)).toFixed(2),
              }
              : {}),
          };
        }
        return {
          ...product,
          handleChange: handleInputChangeArticleQuantity,
          handleRemoveArticle,
        };
      }));
    }
  }, [clients, expeditions, paymentModes]);
  const getDashboardData = useCallback(
    async (clientCode) => {
      try {
        dispatchRedux(setAppLoadingOn());
        const data = await getDashboard(clientCode);
        setDashboard(data);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        dispatchRedux(setAppLoadingOff());
      }
    },
    [setDashboard],
  );
  useEffect(() => {
    if (values.client) {
      getDashboardData(values?.client?.code);
      setArticles((prev) => prev.map((a) => ({
        ...a,
        editRemise: isSellerSpecial || (values.client?.comptant && !a.iH),
        editBrutPrice: isSellerSpecial || (values.client?.comptant && a.iH),
      })));
    }
  }, [getDashboardData, values.client]);

  const getProductRules = async (client, reference) => {
    let rules = [];
    try {
      rules = await getPriceRangeOrders(client, reference);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      dispatchRedux(setAppLoadingOff());
    }
    return rules;
  };
  const getCommerciauxCall = useCallback(
    async (client, reference) => {
      try {
        dispatchRedux(setAppLoadingOn());
        const data = await getCommerciaux(client, reference);
        setSellers(data);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        dispatchRedux(setAppLoadingOff());
      }
    },
    [setSellers],
  );
  useEffect(() => {
    getCommerciauxCall();
  }, [getCommerciauxCall]);
  const addArticle = (ref) => async (event) => {
    if ((['Tab', 'Enter'].includes(event.key) && article.reference) || !ref) {
      if (articles?.length && articles[0].iH !== article.reference.iH) {
        toast.warn('Vous ne pouvez pas ajouter des references Huile et PM dans la meme commande');
      } else if (articles?.length >= 40) {
        toast.warn('Merci de créer une nouvelle commande pour ce client');
      } else {
        const foundArticle = articles.find(
          (a) => a.reference === article.reference.reference && a.reference !== 'AUTRES',
        );
        const defaultQuantity = article.reference?.salesCondition === 'GAME' ? article.reference?.gameQuantity : 1;
        const rules = await getProductRules(values.client?.code, article.reference.reference);
        const quantity = (article.quantity
          ? parseInt(article.quantity, 10) >= 1
            ? parseInt(article.quantity, 10)
            : defaultQuantity
          : defaultQuantity) + (foundArticle ? foundArticle.quantity : 0);
        const rule = rules.length
          ? rules.find((r) => quantity >= r.minQte && quantity <= r.maxQte)
          : null;
        let linkedReference = [];
        if (article.reference.linkedReference) {
          const foundLinkedProduct = products.find(
            (a) => a.reference === article.reference.linkedReference && a.reference !== 'AUTRES',
          );
          const foundLinkedArticle = articles.find(
            (a) => a.reference === foundLinkedProduct.reference && a.reference !== 'AUTRES',
          );
          if (foundLinkedProduct) {
            const linkedRules = await getProductRules(
              values.client?.code,
              foundLinkedProduct.reference,
            );
            const linkedRule = linkedRules.length
              ? linkedRules.find((r) => quantity >= r.minQte && quantity <= r.maxQte)
              : null;
            linkedReference = [
              {
                ...foundLinkedProduct,
                quantity,
                total: (foundLinkedProduct.p * quantity).toFixed(2),
                totalNet: (
                  quantity
                  * foundLinkedProduct.p
                  * ((100.0 - foundLinkedProduct.r) / 100)
                ).toFixed(2),
                handleChange: handleInputChangeArticleQuantity,
                handleRemoveArticle,
                highlight: !!foundLinkedArticle,
                editRemise: isSellerSpecial || (values.client?.comptant && !foundLinkedProduct.iH),
                editBrutPrice: isSellerSpecial || (values.client?.comptant && foundLinkedProduct.iH),
                mandatory: false,
                p: linkedRule?.price ? linkedRule?.price : foundLinkedProduct.p,
                r: linkedRule?.remise ? linkedRule?.remise : foundLinkedProduct.r,
              },
            ];
          }
        }
        setArticles([
          ...articles
            .filter((a) => a.reference !== article.reference.reference || a.reference === 'AUTRES')
            .map((a) => ({ ...a, highlight: false, mandatory: false })),
          {
            ...article.reference,
            quantity,
            total: (article.reference.p * quantity).toFixed(2),
            totalNet: (
              quantity
              * article.reference.p
              * ((100.0 - article.reference.r) / 100)
            ).toFixed(2),
            handleChange: handleInputChangeArticleQuantity,
            handleRemoveArticle,
            highlight: !!foundArticle,
            editRemise: isSellerSpecial || (values.client?.comptant && !article.reference.iH),
            editBrutPrice: isSellerSpecial || (values.client?.comptant && article.reference.iH),
            mandatory: false,
            p: rule?.price ? rule?.price : article.reference.p,
            r:
              article.reference?.reference !== 'AUTRES'
                ? rule?.remise
                  ? rule?.remise
                  : article.reference.r
                : 0,
            pb: article.reference.p,
            rb: article.reference.r,
          },
          ...linkedReference,
        ]);
        setArticle({ quantity: 1 });
        ref?.current?.focus();
        event.preventDefault();
      }
    }
  };
  const isFormValid = useMemo(() => {
    const arrays = Object.keys(DEFAULT_VALUES);
    arrays.splice(arrays.indexOf('personalInfo'), 1);
    if (!isSellerSpecial) {
      arrays.splice(arrays.indexOf('commercial'), 1);
    }
    return articles.length > 0 && arrays.reduce((prev, curr) => prev && !!values[curr], true);
  }, [values, articles, isSellerSpecial]);
  const classes = useStyles();
  const totalNet = useMemo(
    () => articles
      .reduce((prev, curr) => prev + curr.quantity * curr.p * ((100.0 - curr.r) / 100), 0)
      ?.toFixed(2),
    [articles],
  );
  const saveSalesOrders = (normal = true) => {
    let tab = 1;
    const orderId = order ? order.id : id;
    if (
      normal
      && values.client?.comptant
      && (isEmptyOrNull(values.clientName) || isEmptyOrNull(values.ice))
    ) {
      toast.error('Attention ! le Nom de client et ICE est obligatoir.');
      return;
    }
    if (
      values.paymentMode?.code === '3'
      && totalNet > 5000
      && articles.reduce((prev, curr) => prev + curr.quantity, 0) > 1
    ) {
      toast.error(
        'Attention ! le montant ne dois pas dépasse 5000 dh pour les commandes contre remboursement.',
      );
      return;
    }
    if (orderId) {
      const salesOrder = orders.find((o) => o.id === orderId);
      if (salesOrder) {
        dispatch(
          saveOrders(
            orders.map((o) => (o.id === orderId
              ? {
                ...o,
                ...values,
                articles: articles.map((a) => ({
                  ...a,
                  handleChange: undefined,
                  handleRemoveArticle: undefined,
                })),
              }
              : o)),
          ),
        );
      } else {
        tab = 2;
        dispatch(
          saveDeletedOrders(
            deletedOrders.map((o) => (o.id === order.id
              ? {
                ...o,
                ...values,
                articles: articles.map((a) => ({
                  ...a,
                  handleChange: undefined,
                  handleRemoveArticle: undefined,
                })),
              }
              : o)),
          ),
        );
      }
    } else {
      const localId = uniqueId;
      setId(localId);
      dispatch(
        saveOrders([
          ...orders,
          {
            ...values,
            articles: articles.map((a) => ({
              ...a,
              handleChange: undefined,
              handleRemoveArticle: undefined,
            })),
            date: new Date(),
            id: localId,
          },
        ]),
      );
    }
    if (normal) {
      handleClose(tab);
    }
  };
  const total = useMemo(
    () => articles.reduce((prev, curr) => prev + curr.quantity * curr.p, 0)?.toFixed(2),
    [articles],
  );
  const encourPm = useMemo(
    () => dashboard?.totalNonReglePm + dashboard?.totalRegEncoursPm + dashboard?.totalImpayePm,
    [dashboard],
  );
  const encourHuile = useMemo(
    () => dashboard?.totalImpayeHuile + dashboard?.totalRegEncoursHuile + dashboard?.totalNonRegleHuile,
    [dashboard],
  );

  useEffect(() => {
    const saveCond = Object.keys(values).reduce((prev, key) => prev || !!values[key], false)
      || (articles && articles.length > 0);
    if (saveCond) {
      saveSalesOrders(false);
    }
  }, [values, articles]);
  const addOrderSage = async () => {
    const MESSAGES = [];
    if (articles.length > 40) {
      MESSAGES.push("Le nombre maximum d'articles dans une commande est de 40");
    }
    articles.forEach((a) => {
      if (!a.des) {
        MESSAGES.push(`Le produit ${a.reference} n'a pas de désignation`);
      }
    });
    if (
      values.client?.comptant
      && (isEmptyOrNull(values.clientName) || isEmptyOrNull(values.ice))
    ) {
      toast.error('Attention ! le Nom de client et ICE est obligatoir.');
      return;
    }
    if (
      values.paymentMode?.code === '3'
      && totalNet > 5000
      && articles.reduce((prev, curr) => prev + curr.quantity, 0) > 1
    ) {
      toast.error(
        'Attention ! le montant ne dois pas dépasse 5000 dh pour les commandes contre remboursement.',
      );
      return;
    }
    const isGamevalid = articles.reduce(
      (prev, curr) => prev
        && (curr.salesCondition === 'GAME' && curr.gameQuantity
          ? curr.quantity % curr.gameQuantity === 0
          : true),
      true,
    );
    if (!isGamevalid) {
      MESSAGES.push('Merci de vérifier la quantité du jeu');
    }
    if (!isSellerSpecial) {
      articles.forEach((a) => {
        if (parseFloat(a.pb) > parseFloat(a.p)) {
          MESSAGES.push(`Attention le prix de produit ${a.reference} est inférieur au prix de vente`);
        }
      });
    }

    if (MESSAGES.length) {
      toast(<Messages messages={MESSAGES} />, { type: 'error' });
      return;
    }
    try {
      dispatchRedux(setAppLoadingOn());
      const bodyR = {
        ...values,
        deposit: values.deposit === 'Bouskoura' && articles[0].iH ? 'Cfcim' : values.deposit,
        articles: articles.map((a) => ({
          ...a,
          handleChange: undefined,
          handleRemoveArticle: undefined,
        })),
      };
      const { statusCode, statusLabel } = await addOrder(
        order ? { ...order, ...bodyR } : { ...bodyR, date: new Date(), id: uniqueId },
      );
      if (statusCode !== '000') {
        throw statusLabel;
      }
      const localId = order ? order.id : id;
      const currentOrders = orders.filter((o) => o.id !== localId);
      await dispatch(saveOrders(currentOrders));
      handleClose();
    } catch (e) {
      if (typeof e === 'string') {
        toast(<Messages messages={e.split('\n')} />, { type: 'error' });
      } else {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      }
    } finally {
      dispatchRedux(setAppLoadingOff());
    }
  };
  const productFilterBy = localStorage.getItem('productFilterBy');
  const [activeType, setActiveType] = useState(productFilterBy);
  const [isActiveType, setIsActiveType] = useState(!!productFilterBy);
  const setType = (event, val) => {
    handleInputChange('controlled')({ target: { value: null } }, val);
    setActiveType(val?.id);
    setIsActiveType(true);
    localStorage.setItem('productFilterBy', val?.id);
    toast.success('Votre choix est enregistré');
  };
  const editType = () => {
    setIsActiveType(false);
  };
  const activeProducts = useMemo(() => (products || []).filter((p) => p.iA), [products]);
  return (
    <>
      <DialogCmp
        title=""
        endIcon={false}
        fullWidth={false}
        open={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        onHandleSubmit={onConfirmExpedition}
        labelKo="Non"
        labelOk="Oui"
      >
        <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
          <Box sx={{
            borderRadius: '50%',
            width: '65px',
            height: '65px',
            backgroundColor: '#00B894',
            margin: 'auto',
            color: '#FFF',
            display: 'flex',
          }}
          >
            <QuestionMarkIcon fontSize="large" style={{ margin: 'auto' }} />
          </Box>
          <Typography sx={{
            marginTop: '20px',
            textAlign: 'center',
            font: 'normal 600 18px Montserrat',
          }}
          >
            Pouvez-vous confirmer que vous souhaitez
            {' '}
            <br />
            effectuer la modification du transport,
            {' '}
            <br />
            même si le client est éligible au port payé ?
          </Typography>
        </Box>
      </DialogCmp>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        fullWidth
        open
        PaperProps={{
          style: {
            background: '#FFF 0% 0% no-repeat padding-box',
            border: '1px solid #C5C5C5',
            borderRadius: 12,
          },
        }}
        BackdropProps={{
          style: {
            background: '#245283 0% 0% no-repeat padding-box',
            boxShadow: '0px 12px 26px #0000000D',
            opacity: 0.67,
          },
        }}
      >
        <DialogTitle id="simple-dialog-title">
          <Box
            style={{
              display: 'flex',
              columnGap: 10,
              alignItems: 'center',
              // marginBottom: 13,
            }}
          >
            <Typography style={{ flexGrow: 1 }} className={classes.newOrderText}>
              {order ? 'Modifier la commande' : 'Une nouvelle commade'}
            </Typography>
            {dashboard && (
              <LinearProgressWithLabel
                total={dashboard.plafond}
                value={encourPm}
                classes={{ colorPrimary: classes.colorPrimaryPM, bar: classes.barPM }}
              />
            )}
            {dashboard && (
              <LinearProgressWithLabel
                total={dashboard.plafondHuile}
                value={encourHuile}
                classes={{ colorPrimary: classes.colorPrimaryHuile, bar: classes.barHuile }}
              />
            )}
            <IconButton
              style={{
                background: '#C5C5C5 0% 0% no-repeat padding-box',
                borderRadius: 24,
                opacity: 0.53,
              }}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon style={{ color: '#707070' }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: '23px' }}>
            {isSellerSpecial && (
            <Autocomplete
              autoSelect
              PopperComponent={PopperMy}
              classes={{ root: classes.filter }}
              style={{ flexGrow: 1 }}
              options={sellers || []}
              onChange={handleInputChange('commercial')}
              value={values.commercial}
              size="small"
              autoHighlight
              getOptionLabel={(option) => (option.name)}
              filterOptions={(options, v) => options.filter(
                (option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()),
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Commercial"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
            )}
            <Box sx={{ position: 'relative' }}>
              {values.client ? (
                <Typography sx={{
                  position: 'absolute',
                  top: '-22px',
                  color: '#007BC6',
                  font: 'normal 600 12px Montserrat',
                }}
                >
                  <FmdGoodIcon style={{ fontSize: '12px' }} />
                  {values.client.city}
                  {values.client.eligibleAuPortPaye !== null ? '. Eligible ✓' : null}
                </Typography>
              ) : null}
              <Autocomplete
                autoSelect
                PopperComponent={PopperMy}
                classes={{ root: classes.filter }}
                style={{ flexGrow: 1 }}
                options={clients || []}
                onChange={handleInputChange('client')}
                value={values.client}
                size="small"
                autoHighlight
                getOptionLabel={(option) => (option.code
                  ? `${option.code}: ${option.name} ${option.type ? `(${option.type})` : ''}`
                  : '')}
                filterOptions={(options, v) => options.filter(
                  (option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase())
                      || option.code?.toLowerCase().startsWith(v.inputValue.toLowerCase()),
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      ...params.InputProps,
                      classes: { notchedOutline: classes.notched },
                    }}
                    InputLabelProps={{
                      classes: { root: classes.label },
                    }}
                  />
                )}
                popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
              />
            </Box>
            <Autocomplete
              PopperComponent={PopperMy}
              autoSelect
              classes={{ root: classes.filter }}
              style={{ flexGrow: 1 }}
              options={expeditions || []}
              onChange={handleInputChange('expedition')}
              value={values.expedition}
              size="small"
              autoHighlight
              getOptionLabel={(option) => option.name}
              filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Expedition"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
            <Autocomplete
              PopperComponent={PopperMy}
              autoSelect
              classes={{ root: classes.filter }}
              style={{ flexGrow: 1 }}
              options={filtredPms || []}
              disabled={isSellerSpecial}
              onChange={handleInputChange('paymentMode')}
              value={values.paymentMode}
              size="small"
              autoHighlight
              getOptionLabel={(option) => option.name}
              filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mode de paiment"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
            {values.client?.comptant && (
              <>
                <TextField
                  label="Nom client"
                  variant="outlined"
                  style={{ flexGrow: 1, marginRight: 15 }}
                  value={values.clientName}
                  onChange={handleChangeSimpleInput}
                  name="clientName"
                  size="small"
                  classes={{ root: classes.filter }}
                  InputProps={{
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
                <TextField
                  label="Ville"
                  variant="outlined"
                  style={{ flexGrow: 1, marginRight: 15 }}
                  onChange={handleChangeSimpleInput}
                  name="city"
                  value={values.city}
                  size="small"
                  classes={{ root: classes.filter }}
                  InputProps={{
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
                <TextField
                  label="Ice"
                  variant="outlined"
                  style={{ flexGrow: 1, marginRight: 15 }}
                  onChange={handleChangeSimpleInput}
                  name="ice"
                  value={values.ice}
                  size="small"
                  classes={{ root: classes.filter }}
                  InputProps={{
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              </>
            )}
            {!values.client?.comptant && (
              <Autocomplete
                PopperComponent={PopperMy}
                autoSelect
                classes={{ root: classes.filter }}
                style={{ flexGrow: 1 }}
                options={cyties}
                onChange={handleInputChange('city')}
                value={values.city}
                size="small"
                autoHighlight
                getOptionLabel={(option) => option}
                filterOptions={(options, v) => options.filter((option) => option?.toLowerCase().includes(v.inputValue.toLowerCase()))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ville"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      ...params.InputProps,
                      classes: { notchedOutline: classes.notched },
                    }}
                    InputLabelProps={{
                      classes: { root: classes.label },
                    }}
                  />
                )}
                popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
              />
            )}
            <Autocomplete
              PopperComponent={PopperMy}
              autoSelect
              classes={{ root: classes.filter }}
              style={{ flexGrow: 1 }}
              options={user?.depots?.length === 0 ? DEPOSITS : user.depots}
              onChange={handleInputChange('deposit')}
              value={values.deposit}
              size="small"
              autoHighlight
              getOptionLabel={(option) => option}
              filterOptions={(options, v) => options.filter((option) => option.toLowerCase().includes(v.inputValue.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Dépots"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
            <Autocomplete
              PopperComponent={PopperMy}
              autoSelect
              classes={{ root: classes.filter }}
              options={types}
              onChange={handleInputChange('type')}
              value={values.type}
              size="small"
              autoHighlight
              getOptionLabel={(option) => option.name}
              filterOptions={(options, v) => options.filter((option) => option.name.toLowerCase().includes(v.inputValue.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type document"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
          </Box>
          <Grid container alignItems="center">
            <Grid item container xs={8} alignItems="center">
              {!isActiveType && (
                <Grid item xs={6} style={{ display: 'flex' }}>
                  <Autocomplete
                    PopperComponent={PopperMy}
                    autoSelect
                    fullWidth
                    classes={{ root: classes.filter }}
                    options={SEARCH_TYPES || []}
                    onChange={setType}
                    value={values.controlled}
                    size="small"
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    filterOptions={(options, v) => options.filter((option) => option.label?.toLowerCase().includes(v.inputValue.toLowerCase()))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type de Recherche"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                          ...params.InputProps,
                          classes: { notchedOutline: classes.notched },
                        }}
                        InputLabelProps={{
                          classes: { root: classes.label },
                        }}
                      />
                    )}
                    popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
                  />
                </Grid>
              )}
              {isActiveType && (
                <Box
                  style={{
                    display: 'flex',
                    columnGap: '10px',
                    alignItems: 'center',
                    marginRight: '5px',
                  }}
                >
                  <IconButton onClick={editType} size="large">
                    <EditIcon />
                  </IconButton>
                  <Typography style={{ font: 'normal normal 600 15px Montserrat' }}>
                    {SEARCH_TYPES.find((s) => s.id === activeType)?.label}
                  </Typography>
                </Box>
              )}
              <Grid item container xs={6} style={{ display: 'flex' }} alignItems="center">
                <Autocomplete
                  options={activeProducts}
                  classes={{
                    root: classes.product,
                  }}
                  autoSelect
                  onChange={handleInputChangeArticle('reference')}
                  value={article.reference || null}
                  size="small"
                  fullWidth
                  autoHighlight
                  getOptionLabel={(option) => option[mapFilterNameToProperty(activeType)]}
                  ListboxComponent={ListboxComponent}
                  filterOptions={(options, v) => options
                    .filter((option) => mapFilterCondition(activeType, option, v))
                    .slice(0, 5000)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={inputRef}
                      label="Produit"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        ...params.InputProps,
                        classes: { notchedOutline: classes.notched },
                      }}
                      InputLabelProps={{
                        classes: { root: classes.label },
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box style={{ height: 45 }} {...props}>
                      <Typography
                        style={{
                          font: 'normal normal 600 16px/19px Montserrat',
                          color: '#38485C',
                          textTransform: 'capitalize',
                        }}
                      >
                        {option?.reference}
                      </Typography>
                      <Typography
                        style={{
                          font: 'normal normal 500 12px/15px Montserrat',
                          color: '#0C88D2',
                          textTransform: 'capitalize',
                        }}
                      >
                        {option?.des}
                      </Typography>
                    </Box>
                  )}
                  popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
                />
              </Grid>
            </Grid>
            <Grid xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              <Quantity
                value={values.quantity}
                quantity={article.quantity}
                refQuantity={refQuantity}
                inputRef={inputRef}
                onChangeQuantity={handleInputChangeArticle}
                onKeyDown={addArticle}
              />
              <Button
                variant="contained"
                disabled={!article.quantity || !article.reference}
                style={{ flexGrow: 1 }}
                color="primary"
                classes={{ disabled: classes.disabledAddButton, root: classes.addButton }}
                onClick={addArticle()}
              >
                Ajouter
              </Button>
            </Grid>
          </Grid>
          <CustomTable
            rows={articles || []}
            headCells={columns}
            count={(articles || []).length}
            rowsPerPageOptions={[5, 10, 15]}
            handleInputChange={handleInputChangeArticle}
            values={article}
            classes={classes}
            addElement={addArticle}
            pagination={false}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start' }}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 5,
              width: '100%',
            }}
          >
            {false && (
              <Box style={{ flexGrow: 1 }}>
                <TextField
                  id="personal-information"
                  label="Informations personalisée"
                  variant="outlined"
                  size="small"
                  value={values.personalInfo}
                  onChange={handleInputChange('personalInfo')}
                />
              </Box>
            )}
            <Typography
              style={{
                font: ' normal normal 500 15px Montserrat',
                letterSpacing: 0,
                textTransform: 'uppercase',
                opacity: 0.88,
                flexGrow: 1,
                flexShrink: 0,
              }}
            >
              Nombre de produits:
              {articles?.length}
            </Typography>
            {false && (
              <Typography style={{ marginRight: 20 }}>
                Total brut de la commande:
                {' '}
                {total?.replace(/\d(?=(\d{3})+\.)/g, '$& ')}
              </Typography>
            )}
            <Box style={{ flexGrow: 1, flexShrink: 0 }}>
              <Typography
                style={{ font: 'normal normal 600 14px/18px Montserrat', color: '#007BC6' }}
              >
                TOTAL NET:
              </Typography>
              <Typography
                style={{ font: 'normal normal bold 42px/52px Montserrat', color: '#000000' }}
              >
                {totalNet?.replace(/\d(?=(\d{3})+\.)/g, '$& ')}
              </Typography>
            </Box>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                flexShrink: 0,
              }}
            >
              <Button
                onClick={addOrderSage}
                disabled={!isFormValid || isLoading}
                color="secondary"
                variant="contained"
                autoFocus
                style={{
                  background:
                    'transparent linear-gradient(245deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
                  boxShadow: '0px 4px 10px #108CD650',
                  borderRadius: 12,
                  font: 'normal normal bold 18px/22px Montserrat',
                  letterSpacing: 0,
                  color: '#FFFFFF',
                  textTransform: 'uppercase',
                  cursor: !isFormValid && 'not-allowed',
                }}
                endIcon={isLoading ? <CircularProgress size={18} sx={{ color: 'white' }} /> : <SendIcon />}
              >
                Envoyer
              </Button>
              <Button
                onClick={saveSalesOrders}
                disabled={articles.length === 0}
                color="primary"
                variant="contained"
                style={{
                  background: '#38485C 0% 0% no-repeat padding-box',
                  border: '1px solid #007BC6',
                  font: 'normal normal bold 18px/22px Montserrat',
                  color: '#FFFFFF',
                  marginRight: 10,
                  borderRadius: 12,
                }}
                endIcon={<SaveIcon />}
              >
                Enregistré
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewOrder;
