import { Button } from '@mui/material';
import React from 'react';

const ButtonApp = ({
  label, onClick, startIcon, endIcon, disabled = false, style = {},
}) => (
  <Button
    variant="contained"
    onClick={onClick}
    disabled={disabled}
    style={{
      background:
        disabled
          ? 'transparent linear-gradient(243deg, #adadad 0%, #d8d8d8 100%) 0% 0% no-repeat padding-box'
          : 'transparent linear-gradient(243deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
      boxShadow: '0px 4px 10px #108CD650',
      borderRadius: 12,
      font: 'normal normal 600 10px/12px Montserrat',
      color: '#FFFFFF',
      ...style,
    }}
    startIcon={startIcon || null}
    endIcon={endIcon || null}
  >
    {label}
  </Button>
);

export default ButtonApp;
