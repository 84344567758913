export const TYPES = ['FA', 'MF', 'SPECIALE'];
export const PAYMENT_STATUS = [
  { value: 'payed', label: 'Réglé' },
  { value: 'not_payed', label: 'Non Réglée' },
  { value: 'partially_payed', label: 'Partiellement réglée' },
];
export const filtersName = 'filtersSAV';
export const CONTROL_LIST_LABELS = [
  { value: 'CONTROLED', label: 'Contrôlé ' },
  { value: 'UNCONTROLED', label: 'Non Contrôlé' },
];
export const Fields = [
  {
    type: 'text',
    id: 'code',
    label: 'Code client',
    disabled: true,
  },
  {
    type: 'text',
    id: 'name',
    label: 'Name client',
  },
  {
    type: 'text',
    id: 'address',
    label: 'Adresse',
  },
  {
    type: 'autocomplete',
    id: 'city',
    label: 'Ville',
    listName: 'cities',
    filter: (option, value) => option?.toLowerCase().includes(value),
    optionLabel: (option) => option,
  },
  {
    type: 'autocomplete',
    id: 'zone',
    label: 'Zone',
    listName: 'areas',
    filter: (option, value) => option?.toLowerCase().includes(value),
    optionLabel: (option) => option,
  },
  {
    type: 'text',
    id: 'phone',
    label: 'Téléphone',
  },
  {
    type: 'text',
    id: 'email',
    label: 'Email',
  },
  {
    type: 'autocomplete',
    id: 'expedition',
    label: 'Moyen d’expédition par défaut',
    listName: 'expeditions',
    filter: (option, value) => option.name?.toLowerCase().includes(value),
    optionLabel: (option) => option.name,
  },
  {
    type: 'autocomplete',
    id: 'payment',
    label: 'Mode de paiement par défaut',
    listName: 'paymentModes',
    filter: (option, value) => option.name?.toLowerCase().includes(value),
    optionLabel: (option) => option.name,
  },
  {
    type: 'autocomplete',
    id: 'type',
    label: 'Souche par défaut',
    listName: 'types',
    filter: (option, value) => option?.toLowerCase().includes(value),
    optionLabel: (option) => option,
  },
  {
    type: 'text',
    id: 'ice',
    label: 'ICE',
  },
  {
    type: 'text',
    id: 'rc',
    label: 'Identifiant fiscal',
  },
  {
    type: 'text',
    id: 'patente',
    label: 'Patente',
  },
];
/* eslint-disable import/prefer-default-export */
export const TYPES_COMMANDES = [
  { id: 1, label: 'BC', style: { background: '#007BC639 0% 0% no-repeat padding-box', color: 'blue' } },
  { id: 3, label: 'BL', style: { background: '#007BC639 0% 0% no-repeat padding-box', color: 'green' } },
  { id: 4, label: 'BR', style: { background: '#007BC639 0% 0% no-repeat padding-box', color: 'red' } },
  { id: 6, label: 'FA', style: { background: '#007BC639 0% 0% no-repeat padding-box', color: 'gray' } },
  { id: 7, label: 'FC', style: { background: '#007BC639 0% 0% no-repeat padding-box', color: 'fuchsia' } },
];

export const SOUCHES = [
  { id: 1, name: 'FACTURES' },
  { id: 2, name: 'DEVIS' },
  { id: 4, name: 'FACTURE HUILE' },
  { id: 5, name: 'DEVIS HUILE' },
];
