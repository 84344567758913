/* eslint-disable import/prefer-default-export */
import React from 'react';

export const Info = ({ color = '#F86060' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18.621" height="18.621" viewBox="0 0 18.621 18.621">
    <g id="Icon_feather-info" data-name="Icon feather-info" transform="translate(-2 -2)">
      <path id="Path_35549" data-name="Path 35549" d="M19.621,11.311A8.311,8.311,0,1,1,11.311,3a8.311,8.311,0,0,1,8.311,8.311Z" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path id="Path_35550" data-name="Path 35550" d="M18,21.324V18" transform="translate(-6.689 -6.689)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path id="Path_35551" data-name="Path 35551" d="M18,12h0" transform="translate(-6.689 -4.014)" fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </g>
  </svg>

);
