export const GET_REFERENCIAL = 'GET_REFERENCIAL';
export const getReferencial = () => ({
  type: GET_REFERENCIAL,
});

export const GET_REFERENCIAL_SUCCESS = 'GET_REFERENCIAL_SUCCESS';
export const getReferencialSuccess = (data) => ({
  type: GET_REFERENCIAL_SUCCESS,
  data,
});
export const SET_REFERENCIAL = 'SET_REFERENCIAL';
export const setReferencial = (data) => ({
  type: SET_REFERENCIAL,
  data,
});

export const GET_REFERENCIAL_ERROR = 'GET_REFERENCIAL_ERROR';
export const getReferencialError = (message) => ({
  type: GET_REFERENCIAL_ERROR,
  message,
});

export const SET_SELECTED_FAMILY = 'SET_SELECTED_FAMILY';
export const setSelectedFamily = (data) => ({
  type: SET_SELECTED_FAMILY,
  data,
});

export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const setSelectedProduct = (data) => ({
  type: SET_SELECTED_PRODUCT,
  data,
});
export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';
export const setSelectedBrand = (data) => ({
  type: SET_SELECTED_BRAND,
  data,
});
