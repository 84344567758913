import {
  getReferencial,
  getReferencialSuccess,
  getReferencialError,
} from 'Shared/ReferencialContext';
import { securePost } from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getReferencialProducts = async (dispatch, timestamp) => {
  try {
    dispatch(getReferencial());
    const url = '/ci/api/GetNewReferentiel';
    const { data } = await securePost(`${url}?timestamp=${timestamp}`);
    dispatch(getReferencialSuccess(data));
  } catch (e) {
    const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
    dispatch(getReferencialError(statusLabel));
  }
};
export const getPartialyReferencialProducts = async (dispatch, timestamp) => {
  const url = '/ci/api/GetNewReferentiel';
  const { data } = await securePost(`${url}?timestamp=${timestamp}`);
  return data;
};
