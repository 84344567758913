import { Box, IconButton, Typography } from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import { defaultMessage } from 'Shared/Referencial/ReferencialService';
import Product from 'Components/Catalog/Products/Product';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { getAnnoncements } from '../HomeServices';
import Annoncement from '../Annoncement';
import Family from '../Family/Family';

function compare(a, b) {
  if (
    a.priority > b.priority
    || (a.priority === b.priority && new Date(a.updatedOn) > new Date(b.updatedOn))
  ) return -1;
  if (
    a.priority < b.priority
    || (a.priority === b.priority && new Date(a.updatedOn) < new Date(b.updatedOn))
  ) return 1;
  // a doit être égal à b
  return 0;
}
const activeStyle = {
  color: 'white',
  background:
    'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
  boxShadow: '0px 4px 10px #108CD650',
  borderRadius: 12,
};
const inactiveStyle = {
  background: '#D7DFE4 0% 0% no-repeat padding-box',
  borderRadius: 12,
  color: '#286183',
};
const Annoncements = () => {
  const [annoncementList, setAnnoncementList] = useState([]);
  const [cardPerSlide, setCardPerSlide] = useState(0);
  const [cardPerSlideA, setCardPerSlideA] = useState(0);
  const [indexCard, setIndexCard] = useState(0);
  const [indexCardA, setIndexCardA] = useState(0);
  const contentWidth = useRef(null);
  const contentAWidth = useRef(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setCardPerSlide(
        parseInt((contentWidth?.current?.getBoundingClientRect()?.width - 50) / 260, 10),
      );
      setCardPerSlideA(
        parseInt((contentAWidth?.current?.getBoundingClientRect()?.width - 50) / 260, 10),
      );
    });
    resizeObserver.observe(contentWidth?.current);
    resizeObserver.observe(contentAWidth?.current);
    return () => resizeObserver.disconnect();
  }, [setCardPerSlide, setCardPerSlideA]);

  const nextElem = () => {
    setIndexCard(indexCard + 1);
  };
  const prevElem = () => {
    setIndexCard(indexCard - 1);
  };
  const nextElemA = () => {
    setIndexCardA(indexCardA + 1);
  };
  const prevElemA = () => {
    setIndexCardA(indexCardA - 1);
  };
  const getAnnoncementList = useCallback(async () => {
    try {
      const {
        announcements = [],
        // eslint-disable-next-line no-unused-vars
        families = [],
        products = [],
      } = await getAnnoncements();
      setAnnoncementList(
        [
          ...announcements.map((a) => ({ ...a, typeA: 'announcement' })),
          ...families.map((a) => ({ ...a, typeA: 'family' })),
          ...products.map((a) => ({ ...a, typeA: 'product' })),
        ].sort(compare),
      );
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    }
  }, [setAnnoncementList]);

  useEffect(() => {
    getAnnoncementList();
  }, [getAnnoncementList]);
  const announcements = useMemo(() => annoncementList.filter((a) => a.typeA === 'announcement'), [
    annoncementList,
  ]);
  const productAnnouncements = useMemo(
    () => annoncementList.filter((a) => a.typeA !== 'announcement'),
    [annoncementList],
  );
  return (
    <>
      {announcements.length > 0 && (
        <Box style={{ display: 'flex' }}>
          <Typography
            style={{
              flexGrow: 1,
              color: '#000',
              font: 'normal normal 600 26px Montserrat',
              marginTop: 15,
            }}
          >
            Annonce
          </Typography>
          <Box>
            {indexCardA === 0 && (
              <IconButton
                color="primary"
                style={{ ...inactiveStyle, marginRight: 16 }}
                component="span"
                size="small"
                disabled
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            )}
            {indexCardA > 0 && (
              <IconButton
                color="primary"
                style={{ ...activeStyle, marginRight: 16 }}
                component="span"
                size="small"
                onClick={prevElemA}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            )}
            {cardPerSlideA + indexCardA + 1 > announcements.length && (
              <IconButton
                color="primary"
                style={inactiveStyle}
                component="span"
                size="small"
                disabled
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            )}
            {cardPerSlideA + indexCardA + 1 <= announcements.length && (
              <IconButton
                color="primary"
                style={activeStyle}
                component="span"
                size="small"
                onClick={nextElemA}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
      <Box style={{ width: '100%', display: 'flex' }} ref={contentAWidth}>
        {announcements.slice(indexCard, cardPerSlide + indexCard).map((announcement) => (
          <Annoncement annoncement={announcement} key={announcement?.id} />
        ))}
      </Box>
      {productAnnouncements?.length > 0 && (
        <Box style={{ display: 'flex' }}>
          <Typography
            style={{
              flexGrow: 1,
              color: '#000',
              font: 'normal normal 600 26px Montserrat',
              marginTop: 15,
            }}
          >
            À ne pas rater
          </Typography>
          <Box>
            {indexCard === 0 && (
              <IconButton
                color="primary"
                style={{ ...inactiveStyle, marginRight: 16 }}
                component="span"
                size="small"
                disabled
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            )}
            {indexCard > 0 && (
              <IconButton
                color="primary"
                style={{ ...activeStyle, marginRight: 16 }}
                component="span"
                size="small"
                onClick={prevElem}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
            )}
            {cardPerSlide + indexCard + 1 > productAnnouncements.length && (
              <IconButton
                color="primary"
                style={inactiveStyle}
                component="span"
                size="small"
                disabled
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            )}
            {cardPerSlide + indexCard + 1 <= productAnnouncements.length && (
              <IconButton
                color="primary"
                style={activeStyle}
                component="span"
                size="small"
                onClick={nextElem}
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
      <Box style={{ width: '100%', display: 'flex' }} ref={contentWidth}>
        {productAnnouncements.slice(indexCard, cardPerSlide + indexCard).map((announcement) => (
          <>
            {announcement.typeA === 'product' && (
              <Product product={announcement} key={announcement?.id} />
            )}
            {announcement.typeA === 'family' && (
              <Family family={announcement} key={announcement?.id} />
            )}
          </>
        ))}
      </Box>
    </>
  );
};

export default Annoncements;
