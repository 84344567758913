import {
  Box, Button, Divider, Grid, IconButton, Link, List, Typography,
} from '@mui/material';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';
import React, { useContext } from 'react';
import EnhancedTable from 'Shared/EnhancedTable';
import UserContext from 'Shared/UserContext';

const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 8px',
  height: 54,
  boxSizing: 'border-box',
};
const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};
export const columns = (handleDelete, handleEdit, displayAction) => {
  const actions = [
    {
      id: 'actions',
      label: 'Supprimer',
      style: TitleStyle,
      render: ({ row }) => {
        if (row.firstName === '-') {
          return '';
        }

        return [
          <IconButton aria-label="edit" onClick={() => handleEdit(row)} size="large">
            <EditIcon />
          </IconButton>,
          <IconButton aria-label="delete" onClick={() => handleDelete(row)} size="large">
            <DeleteIcon />
          </IconButton>,
        ];
      },
    },
  ];
  return [
    {
      id: 'firstName',
      label: 'Nom et Prenom',
      style: TitleStyle,
      render: ({ row }) => (
        <Typography style={defaultColumnStyle}>{`${row.lastName} ${row.firstName}`}</Typography>
      ),
      // order: true,
    },
    {
      id: 'type',
      label: 'Contact Type',
      style: TitleStyle,
      render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
      // order: true,
    },
    {
      id: 'value',
      label: 'Contact',
      style: TitleStyle,
      render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
      // order: true,
    },
    ...(displayAction ? actions : []),
  ];
};

const formatContact = (contacts) => {
  const all = contacts?.flatMap(({
    firstName, lastName, fixe, mobile, email, whatsapp, id,
  }) => {
    const group = [
      {
        id,
        firstName,
        lastName,
        type: '-',
        value: '-',
      },
    ];
    if (fixe) {
      group.push({
        lastName: '-',
        firstName: '-',
        type: 'fixe',
        value: fixe,
      });
    }
    if (mobile) {
      group.push({
        lastName: '-',
        firstName: '-',
        type: 'mobile',
        value: mobile,
      });
    }
    if (email) {
      group.push({
        lastName: '-',
        firstName: '-',
        type: 'email',
        value: email,
      });
    }
    if (whatsapp) {
      group.push({
        lastName: '-',
        firstName: '-',
        type: 'whatsapp',
        value: whatsapp,
      });
    }
    return group;
  });
  return all;
};

const getDaysBetweenDates = (dateOne) => {
  const dateTwo = new Date();
  let differenceInMs = dateTwo.getTime() - dateOne.getTime();

  if (differenceInMs < 0) {
    differenceInMs = dateOne.getTime() - dateTwo.getTime();
  }

  const msInOneDay = 1000 * 60 * 24 * 60;

  return Math.ceil(differenceInMs / msInOneDay);
};

const Notes = ({ notes, onHandleDelete, onHandleEdit }) => {
  const {
    state: { user },
  } = useContext(UserContext);
  const isRECOUVREMENT = user.role === 'RECOUVREMENT';

  return (
    <List
      style={{
        width: '100%', padding: 10, bgcolor: 'background.paper', boxSizing: 'border-box',
      }}
    >
      {notes
        .filter((note) => !note.isDeleted)
        .map((note) => (
          <>
            <Box
              style={{
                padding: '23px',
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                border: '1px solid #C5C5C5',
                borderRadius: 12,
              }}
            >
              <Box style={{ display: 'flex' }}>
                <Typography
                  style={{
                    font: 'normal normal 600 18px/26px Montserrat',
                    color: '#007BC6',
                    flexGrow: 1,
                    alignItems: 'center',
                  }}
                >
                  {note.addedBy.firstName}
                  {' '}
                  {note.addedBy.lastName}
                </Typography>
                <Typography style={{ font: 'normal normal 600 12px Montserrat', color: 'gray' }}>
                  {format(new Date(note.createdOn), 'dd MMMM yyyy')}
                </Typography>
              </Box>
              {note.attachmentUrl ? (
                <Link
                  href={note?.attachmentUrl}
                  underline="always"
                  style={{ paddingLeft: 10, color: '#566497', fontSize: 12 }}
                  target="_blank"
                >
                  Attachment :
                  <IconButton aria-label="open attachment" size="large">
                    <AttachmentIcon />
                  </IconButton>
                </Link>
              ) : (
                <Typography style={{ font: 'normal normal 600 18px Montserrat', color: '#000' }}>
                  {note.comment}
                </Typography>
              )}
            </Box>
            <Box>
              {isRECOUVREMENT
                && note.addedBy.id === user.id
                && getDaysBetweenDates(new Date(note.createdOn)) <= 1 && (
                  <>
                    <IconButton aria-label="edit" onClick={() => onHandleEdit(note)} size="large">
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => onHandleDelete(note.id)} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </>
              )}
            </Box>
            <Divider component="li" />
          </>
        ))}
    </List>
  );
};

const View = ({
  client, fields, handleEditMode, currentMode, handleDelete, handleEdit,
}) => {
  const {
    state: { user },
  } = useContext(UserContext);
  const isRECOUVREMENT = user.role === 'RECOUVREMENT';
  return (
    <Box
      style={{
        width: '100%',
        height: 330,
        overflowY: 'auto',
      }}
    >
      <Grid
        container
        spacing={2}
        style={{
          paddingBottom: '20px 0',
          margin: 0,
          width: '100%',
        }}
      >
        {!['contactMode', 'addNote'].includes(currentMode)
          && fields.map((c) => (
            <Grid item xs={6} key={c.id} container>
              <Grid
                item
                xs={12}
                style={{
                  font: 'normal normal 600 13px Montserrat',
                  color: '#007BC6',
                  textTransform: 'uppercase',
                }}
              >
                {c.label}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  font: 'normal normal 600 18px Montserrat',
                  color: '#000000',
                  textTransform: 'capitalize',
                }}
              >
                {c.display ? c.display(client[c.id]) : client[c.id]}
              </Grid>
            </Grid>
          ))}
        {!['contactMode', 'addNote'].includes(currentMode) && isRECOUVREMENT && (
          <Grid
            container
            spacing={2}
            style={{ padding: '20px', margin: 0, width: '100%' }}
            direction="row-reverse"
          >
            <Button variant="contained" color="primary" onClick={handleEditMode}>
              Modifier
            </Button>
          </Grid>
        )}
        {currentMode === 'contactMode' && (
          <EnhancedTable
            rows={formatContact(client?.contacts)}
            headCells={columns(handleDelete, handleEdit, isRECOUVREMENT)}
            rowsPerPageOptions={[3, 5, 10]}
            // pagination={false}
            // staticData
          />
        )}
        {currentMode === 'addNote' && (
          <Notes
            notes={client.notes || []}
            onHandleDelete={handleDelete}
            onHandleEdit={handleEdit}
          />
        )}
      </Grid>
    </Box>
  );
};

export default View;
