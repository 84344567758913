import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  card: {
    width: '25%',
    borderLeft: '0.5px solid #f1f1f1',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 25px',
  },
  cardContainer: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 12px 26px #0000000D',
    borderRadius: '12px',
    padding: '9.5px 0',
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
    height: 107,
  },
  cardTitle: {
    font: 'normal normal 600 14px Montserrat',
    color: '#707070',
    textTransform: 'uppercase',
  },
  cardValue: {
    font: 'normal normal 600 16px Montserrat',
    color: '#38485c',
    textTransform: 'uppercase',
  },
  tab: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 12px 26px #0000000D',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    padding: '24px 32px',
    cursor: 'pointer',
  },
  tabTitle: {
    font: 'normal normal medium 16px/19px Montserrat',
    color: '#000000',
    textTransform: 'uppercase',
  },
  tabIcon: {
    color: '#000',
    marginRight: 15,
  },
  categories: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 12px 26px #0000000D',
    borderRadius: '12px',
    width: '100%',
    marginTop: 35,
    padding: '32px 40px',
    position: 'relative',
    boxSizing: 'border-box',
  },
  labelItemTree: {
    font: 'normal normal medium 16px/19px Montserrat',
    color: '#000000',
    textTransform: 'uppercase',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  traingle: {
    width: 0,
    height: 0,
    borderLeft: '30px solid transparent',
    borderRight: '30px solid transparent',
    borderBottom: '60px solid #FFFFFF',
    boxShadow: '0px 12px 26px #0000000',
    position: 'absolute',
    top: '-30px',
  },
}));

export default useStyles;
