import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import UserContext from 'Shared/UserContext';
import {
  addOrder, defaultMessage, getAllBlockedOrders, getBlockedOrders,
} from '../RecoveryServices';
import { columns } from './constants';

const BlockedOrders = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const {
    state: { user },
  } = useContext(UserContext);
  const isCommercialOrSales = ['COMMERCIAL', 'SALES'].includes(user.role);
  const getOrderList = useCallback(async () => {
    try {
      setLoading(true);
      const data = await (isCommercialOrSales ? getBlockedOrders() : getAllBlockedOrders());
      setOrders(data.filter((o) => o.treated === 0));
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setOrders, setLoading, isCommercialOrSales]);

  useEffect(() => {
    getOrderList();
  }, [getOrderList]);

  const resendBlockedOrder = useCallback(async (order) => {
    try {
      setLoading(true);
      const { statusCode, statusLabel } = await addOrder(order);
      if (statusCode === '000') {
        toast.success('la commande a été renvoyé avec succès');
      } else {
        toast.error(statusLabel);
      }
      getOrderList();
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setLoading, getOrderList]);
  const salesOrders = useMemo(
    () => orders
      .map((order) => ({
        ...order,
        date: order.created_on,
        client: `${order.client_code}: ${order.client_name}`,
        brutAmount: order.commande
          .reduce((prev, curr) => prev + curr.quantity * curr.product?.p, 0).toFixed(2),
        netAmount: order.commande
          .reduce(
            (prev, curr) => prev
            + curr.quantity * curr.product?.p * ((100.0 - curr.product?.r) / 100),
            0,
          ).toFixed(2),
        action: () => resendBlockedOrder(order),
      })),
    [orders],
  );
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <EnhancedTable
        staticData
        rows={salesOrders}
        headCells={columns}
        count={orders.length}
        rowsPerPageOptions={[10, 15, 20]}
      />

    </>
  );
};

export default BlockedOrders;
