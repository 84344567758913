import { CardMedia, IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HomeIcon from '@mui/icons-material/Home';
import PostAddIcon from '@mui/icons-material/PostAdd';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ViewListIcon from '@mui/icons-material/ViewList';
import TuneIcon from '@mui/icons-material/Tune';
import React, { useContext, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { authUrl } from 'Utils/authConfig';
import { Link, useLocation } from 'react-router-dom';
import UserContext from 'Shared/UserContext';
import { resetLogin } from 'Shared/UserContext/Actions';
import CopimaLogo from 'Images/copima_logo.svg';
import CopimaMiniLogo from 'Images/copima.svg';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { ReactComponent as LogoutIcon } from './icons/logout.svg';
import './style.css';

const styles = {
  sectionTitle: {
    position: 'static',
    width: 131,
    height: 16,
    left: 12,
    top: 16,
    fontFamily: 'Noto Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 10,
    lineHeight: '158%',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.15em',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    margin: '0px 12px',
  },
  avatarBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    height: 40,
    marginBottom: 12,
    marginTop: 56,
    padding: '8px 35px 8px 20px',
  },
  username: {
    margin: 0,
    fontWeight: 400,
    fontSize: 18,
    color: '#FFFFFF',
  },
  userRole: {
    margin: 0,
    fontSize: 12,
    fontWeight: 400,
    color: '#FFFFFF',
  },
  borderCorner: {
    background: `linear-gradient(to right, #2a69bc 2px, transparent 2px) 0 0,
        linear-gradient(to right, #2a69bc 2px, transparent 2px) 0 100%,
        linear-gradient(to left, #2a69bc 2px, transparent 2px) 100% 0,
        linear-gradient(to left, #2a69bc 2px, transparent 2px) 100% 100%,
        linear-gradient(to bottom, #2a69bc 2px, transparent 2px) 0 0,
        linear-gradient(to bottom, #2a69bc 2px, transparent 2px) 100% 0,
        linear-gradient(to top, #2a69bc 2px, transparent 2px) 0 100%,
        linear-gradient(to top, #2a69bc 2px, transparent 2px) 100% 100%`,
    width: 85,
    textAlign: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 20px',
    cursor: 'pointer',
  },
};

function Sidebar() {
  const location = useLocation();
  const {
    dispatch,
    state: { user },
  } = useContext(UserContext);
  const [collapsed, setCollapsed] = useState(false);
  const isAdmin = user.role === 'ADMIN';
  const isRecouvrement = user.role === 'RECOUVREMENT';
  const isSAV = user.role === 'SAV';
  const isReglement = user.role === 'REGLEMENT';
  const isCommercialOrSales = ['COMMERCIAL', 'SALES'].includes(user.role);
  const isSellerSpecial = user.role === 'SELLER_SPECIAL';
  const isBUYER = user.role === 'BUYER';
  const isImporter = user.role === 'IMPORTER';

  const logOut = () => {
    dispatch(resetLogin());
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = authUrl;
  };

  return (
    <div
      id="header"
      style={{
        padding: 10,
        width: collapsed ? 80 : 352,
        position: 'relative',
        flexShrink: 0,
      }}
    >
      <ProSidebar collapsed={collapsed}>
        <SidebarHeader>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 32,
            }}
          >
            <CardMedia
              component="img"
              src={collapsed ? CopimaMiniLogo : CopimaLogo}
              style={{ width: collapsed ? 53 : 259, height: 53 }}
            />
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square">
            {(isCommercialOrSales || isAdmin || isBUYER || isSellerSpecial) && (
              <>
                <MenuItem icon={<HomeIcon />} active={location.pathname === '/home'}>
                  <Typography class="title">Acceuil</Typography>
                  <Link to="/home" />
                </MenuItem>
                <MenuItem icon={<StorefrontIcon />} active={location.pathname === '/catalog'}>
                  <Typography class="title">Catalogue</Typography>
                  <Link to="/catalog" />
                </MenuItem>
                {false && isBUYER && (
                  <MenuItem
                    icon={<AccountTreeIcon />}
                    active={location.pathname === '/proposition'}
                  >
                    <Typography class="title">Propositions</Typography>
                    <Link to="/proposition" />
                  </MenuItem>
                )}
                {(isCommercialOrSales || isSellerSpecial) && (
                  <MenuItem icon={<ViewListIcon />} active={location.pathname === '/orders'}>
                    <Typography class="title">Mes commandes</Typography>
                    <Link to="/orders" />
                  </MenuItem>
                )}
              </>
            )}
            {isImporter && (
              <>
                <MenuItem icon={<ViewListIcon />} active={location.pathname === '/import'}>
                  <Typography class="title">List Des Articles</Typography>
                  <Link to="/import" />
                </MenuItem>
                <MenuItem icon={<AccountTreeIcon />} active={location.pathname === '/import/family'}>
                  <Typography class="title">List Des Familles</Typography>
                  <Link to="/import/family" />
                </MenuItem>
                <MenuItem
                  icon={<ViewListIcon />}
                  active={location.pathname === '/import/achat-document'}
                >
                  <Typography class="title">Documents D’achat</Typography>
                  <Link to="/import/achat-document" />
                </MenuItem>
              </>
            )}
            {isBUYER && (
              <MenuItem
                icon={<StorefrontIcon />}
                active={location.pathname === '/products-settings'}
              >
                <Typography class="title">Paramètres</Typography>
                <Link to="/products-settings" />
              </MenuItem>
            )}
            {isAdmin && (
              <MenuItem
                icon={<StorefrontIcon />}
                active={location.pathname === '/products-settings'}
              >
                <Typography class="title">Paramètres Produits</Typography>
                <Link to="/products-settings" />
              </MenuItem>
            )}
            {isAdmin && (
              <MenuItem icon={<StorefrontIcon />} active={location.pathname === '/announcement'}>
                <Typography class="title">Annonces</Typography>
                <Link to="/announcement" />
              </MenuItem>
            )}
            {isAdmin && (
              <MenuItem icon={<TuneIcon />} active={location.pathname === '/settings'}>
                <Typography class="title">Paramètres</Typography>
                <Link to="/settings" />
              </MenuItem>
            )}
            {(isRecouvrement || isSAV || isCommercialOrSales) && (
              <MenuItem icon={<StorefrontIcon />} active={location.pathname === '/recovery'}>
                <Typography class="title">Gestion des clients</Typography>
                <Link to="/recovery" />
              </MenuItem>
            )}
            {isSAV && (
              <MenuItem icon={<ReceiptIcon />} active={location.pathname === '/controle-orders'}>
                <Typography class="title">Contrôle et Commandes</Typography>
                <Link to="/controle-orders" />
              </MenuItem>
            )}
            {isAdmin && (
              <MenuItem icon={<ReceiptIcon />} active={location.pathname === '/discount-settings'}>
                <Typography class="title">Remise</Typography>
                <Link to="/discount-settings" />
              </MenuItem>
            )}
            {(isReglement || isRecouvrement) && (
              <MenuItem icon={<StorefrontIcon />} active={location.pathname === '/payment'}>
                <Typography class="title">Règlement</Typography>
                <Link to="/payment" />
              </MenuItem>
            )}
            {isCommercialOrSales && (
              <MenuItem icon={<ViewListIcon />} active={location.pathname === '/externe-search'}>
                <Typography class="title">Recherche générale</Typography>
                <Link to="/externe-search" />
              </MenuItem>
            )}
            {isCommercialOrSales && (
              <MenuItem icon={<SubdirectoryArrowRightIcon />} active={location.pathname === '/unhonored'}>
                <Typography class="title">Vente Non Honorees</Typography>
                <Link to="/unhonored" />
              </MenuItem>
            )}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <Menu iconShape="square">
            {false && (
              <SubMenu
                title={<Typography> Paramètres </Typography>}
                icon={<SettingsOutlinedIcon />}
              >
                <MenuItem icon={<BusinessCenterIcon />} active={location.pathname === '/services'}>
                  <Typography>Services</Typography>
                  <Link to="/services" />
                </MenuItem>
                <MenuItem active={location.pathname === '/documentTypes'}>
                  <Typography>Référentiel des documents</Typography>
                  <Link to="/documentTypes" />
                </MenuItem>
                <MenuItem icon={<PostAddIcon />} active={location.pathname === '/types'}>
                  <Typography>Champs personnalisés</Typography>
                  <Link to="/types" />
                </MenuItem>
              </SubMenu>
            )}
            <MenuItem onClick={() => logOut()} icon={<LogoutIcon />}>
              <Typography class="title">Se déconnecter</Typography>
            </MenuItem>
          </Menu>
        </SidebarFooter>
      </ProSidebar>
      <IconButton
        style={{
          zIndex: 1205,
          position: 'absolute',
          right: 0,
          top: 83,
          backgroundColor: '#FFF',
          boxShadow: 'rgba(0, 0, 0, 0.2) 4px 7px 19px 3px',
        }}
        aria-label="delete"
        size="small"
        onClick={() => setCollapsed(!collapsed)}
      >
        {!collapsed ? (
          <KeyboardArrowLeftIcon fontSize="inherit" />
        ) : (
          <KeyboardArrowRightIcon fontSize="inherit" />
        )}
      </IconButton>
    </div>
  );
}

export default withStyles(styles)(Sidebar);
