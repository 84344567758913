import CacheManager from 'Utils/CacheManager';
// eslint-disable-next-line import/no-cycle
import { SET_SELECTED_BRAND, SET_SELECTED_FAMILY, SET_SELECTED_PRODUCT } from '.';
import {
  GET_REFERENCIAL,
  GET_REFERENCIAL_SUCCESS,
  GET_REFERENCIAL_ERROR,
  SET_REFERENCIAL,
} from './Actions';

const ReferencialReducer = (state, action) => {
  switch (action.type) {
    case SET_REFERENCIAL:
      return { ...state, referencial: action.data };
    case SET_SELECTED_FAMILY:
      return { ...state, selectedFamily: action.data };
    case SET_SELECTED_PRODUCT:
      return { ...state, selectedProduct: action.data };
    case SET_SELECTED_BRAND:
      return { ...state, selectedBrand: action.data };
    case GET_REFERENCIAL:
      return { ...state, status: 'loading' };
    case GET_REFERENCIAL_SUCCESS:
      CacheManager.writeData('referencial', action.data);
      CacheManager.writeData('lastDateCall', new Date());
      return { ...state, status: 'success', referencial: action.data };
    case GET_REFERENCIAL_ERROR:
      return { ...state, status: 'error', errorMessage: action.message };
    default:
      throw new Error();
  }
};

export default ReferencialReducer;
