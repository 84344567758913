import { Box } from '@mui/material';
import React, { useCallback, useContext, useEffect } from 'react';
import ConnectedStore from 'Shared/ConnectedStore';
import CacheManager from 'Utils/CacheManager';
import { saveDeletedOrders, saveOrders } from './Actions';
import OrdersContext, { initialState, Provider } from './Context';
import NewOrder from './NewOrder';
import OrderList from './OrderList';
import OrderReducer from './reducer';

const Orders = ({ add }) => {
  const {
    dispatch,
    state: { orders = [], deletedOrders = [] },
  } = useContext(OrdersContext);
  const getOrders = useCallback(async () => {
    const storedOrders = await CacheManager.readData('salesOrders');
    const deletedSalesOrders = await CacheManager.readData('deletedSalesOrders');
    if (orders.length === 0 && storedOrders && storedOrders.length) {
      dispatch(saveOrders(storedOrders));
    }
    if (deletedOrders.length === 0 && deletedSalesOrders && deletedSalesOrders.length) {
      dispatch(saveDeletedOrders(deletedSalesOrders));
    }
  }, [dispatch, orders, deletedOrders]);
  useEffect(() => {
    getOrders();
  }, [getOrders]);
  return (
    <div>
      <Box style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}>
        {!add && <OrderList />}
        {add && <NewOrder />}
      </Box>
    </div>
  );
};

export default ConnectedStore(Orders, OrderReducer, initialState, Provider);
