import { Box, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Model = (car) => {
  const history = useHistory();
  const {
    type, name, vin, img,
  } = car;
  const navigateToDetail = () => history.push(`/model/${vin}`, car);
  return (
    <Box
      style={{
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 12px 26px #0000000D',
        borderRadius: '12px',
        display: 'flex',
        padding: '7px 14px',
        height: 92,
        width: 450,
        columnGap: 10,
        cursor: 'pointer',
      }}
      onClick={navigateToDetail}
    >
      <CardMedia
        style={{ width: '123px', height: 'auto', objectFit: 'contain' }}
        component="img"
        src={img}
      />
      <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography
          style={{
            font: 'normal normal 600 14px/18px Montserrat',
            color: '#007BC6',
            textTransform: 'uppercase',
          }}
        >
          {vin}
          {' '}
          (
          {type}
          )
        </Typography>
        <Typography
          style={{
            font: 'normal normal 500 18px/22px Montserrat',
            color: '#000000',
            textTransform: 'uppercase',
          }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default Model;
