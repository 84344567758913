import React from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useBasicInputStyles = (
  large, hasError, isRegular, multiline,
) => makeStyles((theme) => ({
  nativeInput: {
    // color: colors.indigo2,
    fontWeight: 500,
    boxShadow: isRegular ? '0 -1px 4px 0 rgba(0,0,0,0.17)' : '0 2px 4px 0 rgba(0,0,0,0.17)',
  },
  inputBase: {
    backgroundColor: 'white',
    height: multiline ? 'auto' : '42px',
    maxWidth: large ? 'auto' : '320px',
    [theme.breakpoints.up('xl')]: {
      maxWidth: large ? 'auto' : '360px',
    },
  },
  inputBaseExpanded: {
    height: multiline ? 'auto' : '42px',
    maxWidth: 'auto',
    [theme.breakpoints.up('xl')]: {
      maxWidth: 'auto',
    },
  },
  label: {
    color: hasError ? '#EA2027' : '#8F8F8F',
  },
  inputLabel: {
    // transform: 'translate(12px, 15px) scale(1)',
    // zIndex: 3,
    top: '-6px',
    color: '#CCCCCC',
  },
  border: {
    borderColor: hasError ? '#EA2027' : '#CCCCCC',
    borderBottomRightRadius: isRegular && 0,
    borderBottomLeftRadius: isRegular && 0,
  },
}))();
const InputFile = ({
  name, label, large, hasError, onChange, value,
}) => {
  const classes = useBasicInputStyles(large, hasError);
  return (
    <>
      <Typography className={classes.label}>{label}</Typography>
      <Box
        component="label"
        for={`file-upload-${name}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          marginTop: '6.5px',
          height: '43px',
          border: '1px dashed #CCCCCC',
          borderRadius: '5px',
          paddingLeft: '14px',
          paddingRight: '14px',
          backgroundColor: '#FFFFFF',
          boxShadow: '0 2px 4px 0 rgba(0,0,0,0.17)',
        }}
      >
        <Box style={{ color: '#CCCCCC', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          { value || label}
        </Box>
        <PublishIcon style={{
          marginRight: 0,
          marginLeft: 'auto',
          borderLeft: '1px solid #CCCCCC',
          height: '46px',
          paddingLeft: '7px',
        }}
        />
      </Box>
      <input name={name} onChange={onChange} hidden id={`file-upload-${name}`} type="file" />
    </>
  );
};

export default InputFile;
