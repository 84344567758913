import {
  Card, CardActionArea, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import ReferencialContext, { setSelectedFamily } from 'Shared/ReferencialContext';

const useStyles = makeStyles({
  root: {
    width: 238,
    height: 340,
    margin: '10px 10px 10px 0',
  },
  media: {
    height: 131,
    width: '100%',
  },
  familyName: {
    color: '#FFFFFF',
    fontSize: 18,
    fontWeight: 'bold',
  },
  familyCount: {
    opacity: 0.8,
    color: '#FFFFFF',
    fontSize: 14,
  },
});

const Family = ({ family }) => {
  const {
    dispatch,
    state: {
      referencial: { products },
    },
  } = useContext(ReferencialContext);
  const history = useHistory();
  const classes = useStyles();
  const productCount = useMemo(() => products?.filter((po) => po.fId === family.id)?.length, [
    products,
    family,
  ]);
  const handleClick = () => {
    dispatch(setSelectedFamily(family));
    history.push('/catalog');
  };
  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={handleClick}
        style={{
          height: '100%',
          background: 'linear-gradient(45deg, #313C66 0%, #60779A 100%)',
          boxShadow:
            '0 1px 1px 0 rgba(32,39,71,0.14), 0 2px 1px -1px rgba(32,39,71,0.12), 0 1px 3px 0 rgba(32,39,71,0.2)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography className={classes.familyName}>{family?.name}</Typography>
        <Typography className={classes.familyCount}>{productCount}</Typography>
      </CardActionArea>
    </Card>
  );
};

export default Family;
