import {
  Box, Button, IconButton, TextField, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { requestProduct } from 'Components/Catalog/CatalogServices';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'react-toastify';

const RequestProduct = ({
  classes, openSuccess, product, handleClose,
}) => {
  const [values, setValues] = useState({ quantity: 1 });
  const handleChangeValues = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const changeQuantity = (value) => (event) => {
    setValues({ ...values, quantity: parseInt(value || event.target.value, 10) });
  };
  const handleRequestProduct = async () => {
    try {
      const extra = product.brandName
        ? {
          tecDocProductDto: {
            reference: product.reference,
            description: product.des,
            brand: product.brandName,
          },
        }
        : { productReference: product?.reference || product?.id };
      await requestProduct({
        ...values,
        ...extra,
      });
      openSuccess(true);
    } catch (e) {
      toast.error('Erreur systéme');
    }
  };
  const isValidRequest = values.quantity;
  return (
    <Box style={{ padding: '25px 20px', backgroundColor: '#FFF' }}>
      <Typography
        variant="h6"
        style={{
          font: 'normal normal 600 25px Montserrat',
          color: '#000000',
          marginBottom: 15,
        }}
      >
        Demande d&apos;achat:
      </Typography>
      <Box style={{ flexGrow: 1, paddingLeft: 10, alignItems: 'center' }}>
        <IconButton
          style={{
            background:
              'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
            boxShadow: '0px 4px 10px #108CD650',
            borderRadius: 12,
            color: '#FFF',
            marginRight: 10,
            padding: 7,
          }}
          onClick={values.quantity - 1 > 0 ? changeQuantity(values.quantity - 1) : null}
          size="large"
        >
          <RemoveIcon />
        </IconButton>
        <TextField
          variant="outlined"
          size="small"
          value={values.quantity}
          name="quantity"
          onChange={handleChangeValues}
          style={{ width: 80, borderRadius: 16 }}
          InputProps={{
            classes: { input: classes.input, root: classes.rootQuantity },
          }}
        />
        <IconButton
          type="number"
          inputProps={{ min: 0 }}
          onClick={changeQuantity(values.quantity + 1)}
          style={{
            background:
              'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
            boxShadow: '0px 4px 10px #108CD650',
            borderRadius: 12,
            color: '#FFF',
            marginLeft: 10,
            padding: 7,
          }}
          size="large"
        >
          <AddIcon />
        </IconButton>
      </Box>
      <Box style={{ margin: '10px 0' }}>
        <TextField
          fullWidth
          id="transfer-commentaire"
          label="Commentaire"
          variant="outlined"
          name="comment"
          multiline
          rows={5}
          onChange={handleChangeValues}
          value={values.comment}
          InputProps={{
            classes: {
              root: classes.inputContainer,
              input: classes.comment,
              notchedOutline: classes.notched,
            },
          }}
          InputLabelProps={{
            classes: { root: classes.label },
          }}
          style={{ background: '#F7F7F7 0% 0% no-repeat padding-box', borderRadius: 12 }}
        />
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'row-reverse', columnGap: 10 }}>
        <Button
          variant="contained"
          onClick={handleRequestProduct}
          disabled={!isValidRequest}
          style={{
            background: '#007BC6 0% 0% no-repeat padding-box',
            border: '1px solid #007BC6',
            color: '#FFF',
            font: 'normal normal bold 14px Montserrat',
            borderRadius: 12,
          }}
        >
          ENVOYER LA DEMANDE D&apos;ACHAT
          <SendIcon style={{ marginLeft: 10, color: '#FFF' }} />
        </Button>
        <Button
          onClick={handleClose}
          variant="text"
          style={{
            font: 'normal normal bold 1dpx Montserrat',
            color: '#38485C',
          }}
        >
          ANULLER
        </Button>
      </Box>
    </Box>
  );
};

export default RequestProduct;
