export const SAVE_ORDERS = 'SAVE_ORDERS';
export const saveOrders = (data) => ({
  type: SAVE_ORDERS,
  data,
});
export const SAVE_DELETE_ORDERS = 'SAVE_DELETE_ORDERS';
export const saveDeletedOrders = (data) => ({
  type: SAVE_DELETE_ORDERS,
  data,
});
