import { useCallback, useEffect, useState } from 'react';
import { removeProductsFromPropositions } from '../PropositionServices';

const useRemoveProducts = (referenceProposition, lastAddedIds) => {
  const [targetDeletionProduct, setTargetDeletionProduct] = useState({ ids: [] });

  useEffect(() => {
    if (lastAddedIds?.selectedList) {
      setTargetDeletionProduct({ ids: lastAddedIds.selectedList });
    }
  }, [lastAddedIds]);

  const removeProductIdsFromProposition = useCallback(async (callBack) => {
    await removeProductsFromPropositions(
      referenceProposition, targetDeletionProduct.ids,
    );
    setTargetDeletionProduct({ ids: [] });
    callBack();
  }, [referenceProposition, targetDeletionProduct]);

  const onAddProducts = useCallback((ids) => {
    const currentTarget = targetDeletionProduct.ids;
    const addedIds = ids.filter((id) => {
      const isIndexExist = currentTarget.indexOf(id);
      if (isIndexExist > -1) {
        // tobe removed
        currentTarget.splice(isIndexExist, 1);
        return false;
      }
      return true;
    });
    setTargetDeletionProduct({ ids: [...currentTarget, ...addedIds] });
  }, [targetDeletionProduct]);

  return {
    targetDeletionProduct,
    onSubmitRemove: removeProductIdsFromProposition,
    onAddProducts,
  };
};

export default useRemoveProducts;
