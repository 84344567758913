import {
  Box, Button, Dialog, Step, StepButton, StepLabel, Stepper, Typography,
} from '@mui/material';
import React, {
  useContext, useMemo, useState,
} from 'react';
import useForm from 'Shared/Hooks/useForm';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ReferencialContext from 'Shared/ReferencialContext';
import RenderOnDemandThenHide from 'Components/RenderOnDemandThenHide';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { ANNOUCE_TYPE, stepsAnnancement } from './constants';
import InfoGenerale from './InfoGenerale';
import ProductCategory from './ProductCategory';
import { addAnnoncementsApi, addAttachment, defaultMessage } from '../AnnouncementService';

const ErrorText = ({ label }) => (<Typography style={{ fontSize: 10 }} variant="caption" color="error">{label}</Typography>);

const SuccessModal = ({ open, onCloseAll }) => (
  <Dialog open={open} onClose={onCloseAll}>
    <Box style={{ padding: '20px 40px', textAlign: 'center' }}>
      <ThumbUpIcon fontSize="large" color="primary" />
      <Typography
        style={{
          color: '#8F8F8F',
          fontSize: 14,
          fontWeight: 600,
          marginTop: 2.5,
          marginBottom: 2.5,
        }}
      >
        Modification enregistrée avec succès
      </Typography>
      <Button
        onClick={onCloseAll}
        variant="contained"
        color="primary"
        style={{ textTransform: 'capitalize' }}
      >
        Fermer
      </Button>
    </Box>
  </Dialog>
);
const AnnouncementAdd = ({
  activeStep, setActiveStep, onClose, annouceToedit,
}) => {
  const [hasError, setError] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { values, onChange } = useForm(
    annouceToedit
      || { productToggle: ANNOUCE_TYPE.PRODUCT, all: { promo: false, new: false, info: false } },
  );
  const {
    state: {
      referencial,
    },
  } = useContext(ReferencialContext);
  const categories = useMemo(() => {
    const { families = [] } = referencial;
    return families?.filter((family) => family.familyLevel === 1);
  }, [referencial.families]);
  const onSubmit = async () => {
    if (!values.title) {
      setError({ step: 0, message: (<ErrorText label="/ajouter un titre" />) });
      return;
    }
    if (!values.image?.name && !values.image) {
      setError({ step: 0, message: (<ErrorText label="/ajouter Attachement" />) });
      return;
    }
    if (!values.startDate) {
      setError({ step: 0, message: (<ErrorText label="/Date début" />) });
      return;
    }
    if (!values.endDate) {
      setError({ step: 0, message: (<ErrorText label="/Date de fin" />) });
      return;
    }
    if (
      values.productToggle === ANNOUCE_TYPE.CATEGORY_PRODUCT && values.linkedFamilies?.length === 0
    ) {
      setError({ step: 1, message: (<ErrorText label="/Ajouter une catégorie marque" />) });
      return;
    }
    const callBackUploadAttachement = async (data) => {
      if (values.productToggle && (values.all.promo || values.all.new)) {
        values.families = values.filter.family !== '' ? [values.filter.family] : [];
        values.brands = values.filter.brand !== '' ? [values.filter.brand] : [];
      }
      try {
        setLoading(true);
        await addAnnoncementsApi(
          {
            ...values,
            productToggle: values.productToggle === ANNOUCE_TYPE.PRODUCT,
            startDate: format(new Date(values.startDate), "yyyy-MM-dd'T'00:00:00zzzz"),
            endDate: format(new Date(values.endDate), "yyyy-MM-dd'T'23:59:59zzzz"),
            image: data?.attachment?.attachment_url,
          },
        );
        setOpen(true);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    };
    if (values?.image.name) {
      try {
        setLoading(true);
        const data = await addAttachment(
          values.image,
        );
        callBackUploadAttachement(data);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    } else {
      callBackUploadAttachement(values.image);
    }
  };
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const onCloseAll = () => {
    onClose();
    setOpen(false);
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Box style={{ marginLeft: '100px', marginRight: '100px', marginBottom: '40px' }}>
        <Typography style={{
          color: '#4D4D4D',
          fontSize: 21,
          fontWeight: 600,
          textAlign: 'center',
        }}
        >
          Création d’une nouvelle annonce
        </Typography>
        <Stepper nonLinear alternativeLabel activeStep={activeStep}>
          {stepsAnnancement.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                <StepLabel error={hasError?.step === index}>
                  {label}
                  {' '}
                  {hasError?.step === index && hasError.message}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0
        && (
        <InfoGenerale
          values={values}
          onChange={onChange}
          onClose={onClose}
          onSubmit={handleStep(1)}
        />
        )}
        <RenderOnDemandThenHide visible={activeStep === 1}>
          <ProductCategory
            values={values}
            onChange={onChange}
            onPrev={handleStep(0)}
            onClose={onClose}
            onSubmit={onSubmit}
            categories={categories}
          />
        </RenderOnDemandThenHide>
        <SuccessModal open={open} setOpen={setOpen} onCloseAll={onCloseAll} />
      </Box>
    </>
  );
};

export default AnnouncementAdd;
