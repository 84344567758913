import { Paper, Tab, Tabs } from '@mui/material';
import React, { useContext, useState } from 'react';
import UserContext from 'Shared/UserContext';
import AddClient from '../AddClient';
import BlockedOrders from '../BlockedOrders';
import RecoveryList from '../RecoveryList/RecoveryList';
import useStyles from './style';

const RecoveryListTabs = () => {
  const {
    state: { user },
  } = useContext(UserContext);
  const [tab, setTab] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const isCommercialOrSales = ['COMMERCIAL', 'SALES'].includes(user.role);
  const isSAV = ['SAV'].includes(user.role);
  const classes = useStyles();
  return (
    <Paper elevation={0} classes={{ root: classes.paper }}>
      {!isSAV && (
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
        >
          <Tab
            classes={{
              root: classes.tab,
              selected: classes.tabSelected,
              wrapper: classes.tabWrapper,
            }}
            label="Gestion des clients"
            value={0}
          />
          {!isCommercialOrSales && !isSAV && (
            <Tab
              classes={{
                root: classes.tab,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              label="Commandes bloquées"
              value={1}
            />
          )}
          {!isCommercialOrSales && !isSAV && (
            <Tab
              classes={{
                root: classes.tab,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              label="+ Ajouter client"
              value={3}
            />
          )}
        </Tabs>
      )}
      {tab === 0 && <RecoveryList />}
      {tab === 1 && !isCommercialOrSales && !isSAV && <BlockedOrders />}
      {tab === 3 && !isCommercialOrSales && !isSAV && <AddClient />}
    </Paper>
  );
};

export default RecoveryListTabs;
