import React, { useContext } from 'react';
import { IconButton, Typography } from '@mui/material';
import ReferencialContext from 'Shared/ReferencialContext';
import SettingsIcon from '@mui/icons-material/Settings';
/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  color: '#727CA2',
  fontSize: '12px',
  fontWeight: 500,
  background: '#F8F8FF',
  borderBottom: 'none',
  textTransform: 'uppercase',
};
const defaultColumnStyle = { color: '#333D66', fontSize: '14px' };
export const columns = [
  {
    id: 'reference',
    label: 'Réference',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    id: 'des',
    label: 'Description',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'fId',
    label: 'Famille',
    style: TitleStyle,
    render: ({ value }) => {
      const {
        state: {
          referencial: { families },
        },
      } = useContext(ReferencialContext);
      return (
        <Typography sx={defaultColumnStyle}>
          {value && families.find((f) => value === f.id)?.name}
        </Typography>
      );
    },
  },
  {
    id: 'bId',
    label: 'Marque',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'p',
    label: 'Prix',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'r',
    label: 'Remise',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'sB',
    label: 'Stock Bouskoura',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'sD',
    label: 'Stock Derb Omar',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'action',
    label: 'Action',
    style: TitleStyle,
    render: ({ row }) => (
      <IconButton aria-label="settings" onClick={row.navigateSettings} size="large">
        <SettingsIcon />
      </IconButton>
    ),
  },
];
