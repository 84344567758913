import { useState } from 'react';

/**
 *
 * @param initialState: must include all fields keys (names)
 * @param submit: function. on form submit, may be useful for validation
 * @returns {{onChange: function, values: any}}
 *
 * Supports only fields with event.target.{name, value}
 */
export default function useForm(initialState, submit) {
  const [values, setValues] = useState(initialState);

  function handleChange(e) {
    const { name, value } = e?.target || e;
    setValues((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  function handleChangeSelect(name, value) {
    setValues((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e?.preventDefault();
    submit({ ...values });
  }

  return {
    onChange: handleChange,
    onSubmit: handleSubmit,
    values,
    setValues,
    onChangeSelect: handleChangeSelect,
  };
}
