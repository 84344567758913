import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';

import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import Kpi from 'Shared/Kpi';
import UserContext from 'Shared/UserContext';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AppsIcon from '@mui/icons-material/Apps';
import BarChartIcon from '@mui/icons-material/BarChart';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import useStyles from './style';
import Annoncements from './Annoncements/Annoncements';
import { defaultMessage, getDashboardData } from './HomeServices';
import ObjectivesAndChallenges from './ObjectivesAndChallenges';

const HomeView = () => {
  const classes = useStyles();
  const {
    state: { user },
  } = useContext(UserContext);
  const isCommercialOrSales = ['COMMERCIAL', 'SALES'].includes(user.role);
  const [dashboard, setDashboard] = useState({});
  const [status, setStatus] = useState({});
  const getDashboard = useCallback(async () => {
    setStatus('loading');
    try {
      const data = await getDashboardData(user.sellerId);
      setDashboard(data);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setStatus('');
    }
  }, [user, setDashboard, setStatus]);

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);
  const { clients = [], products = [] } = dashboard;
  return (
    <div style={{ height: '100vh' }}>
      {status === 'loading' && <GlobalLoader size="100vh" />}
      <Box style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}>
        {isCommercialOrSales && (
          <div
            style={{
              display: 'flex',
              columnGap: 20,
              rowGap: 20,
              marginLeft: 25,
              flexWrap: 'wrap',
            }}
          >
            <Kpi
              color="#888888"
              icon={AttachMoneyIcon}
              label="CA/Objectifs"
              value={clients.reduce((prev, curr) => prev + curr.total / 1.2, 0)?.toFixed(2)}
            />
            <Kpi
              color="#ED9747"
              icon={PeopleOutlineIcon}
              label="Clients touchés"
              value={clients.length}
            />
            <Kpi
              color="#4A4AFF"
              icon={AppsIcon}
              label="Familles touchées"
              value={products.map((p) => p.familyId).unique()?.length}
            />
            <Kpi
              color="#EC6A58"
              icon={CropLandscapeIcon}
              label="Marques touchées"
              value={products.map((p) => p.brand).unique()?.length}
            />
            <Kpi
              color="#CE66DF"
              icon={BarChartIcon}
              label="Références vendues"
              value={products?.length}
            />
          </div>
        )}
        <div className={classes.containerAnnoncements}>
          <Annoncements />
        </div>
        {isCommercialOrSales && (
          <div className={classes.containerAnnoncements}>
            <ObjectivesAndChallenges />
          </div>
        )}
      </Box>
    </div>
  );
};
HomeView.propTypes = {};

export default HomeView;
