class FiltersUtils {
  static getSavedFilters(name) {
    return JSON.parse(localStorage.getItem(name));
  }

  static saveFilters(name, values) {
    localStorage.setItem(name, JSON.stringify(values));
  }
}

export default FiltersUtils;
