import { Box, IconButton } from '@mui/material';
import Input from 'Shared/Input/v2';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import { DEPOSITS } from '../constants';

const styleInpt = {
  marginTop: 8, marginBottom: 4, minWidth: '150px', border: '1px solid #007BC6',
};

const EditDocumentItem = ({
  achat, onChange, onClose, onSaveAchat, products = [],
}) => {
  const onChangeSelect = (name, value) => {
    onChange({ target: { name, value } });
    if (name === 'product') {
      onChange({ target: { name: 'productId', value: value?.productId } });
    }
  };
  return (
    <>
      <Box sx={{
        mt: 0.4,
        mb: 0.4,
        pt: 0.5,
        pb: 0.5,
        display: 'flex',
        overflow: 'auto',
        '::-webkit-scrollbar': { height: '7px' },
        '::-webkit-scrollbar-track': { background: '#007bc663' },
        '::-webkit-scrollbar-thumb': {
          background: '#007bc6c2',
          borderRadius: '10px',
        },
        '::-webkit-scrollbar-thumb:hover': { background: '#007BC6' },
      }}
      >
        {achat.isNewLine ? (
          <AutoCompleteCmp
            options={products}
            onChange={(e, value) => onChangeSelect('product', value)}
            getOptionLabel={(option) => option.reference}
            filterOptions={
              (options, v) => options.filter((option) => option.reference?.toLowerCase()
                .includes(v.inputValue.toLowerCase()))
            }
            value={achat.product}
            label="Référence*"
            isPrimaryTheme
            style={{
              ...styleInpt, display: 'inline-block',
            }}
          />
        ) : (
          <Input
            value={achat.productId}
            onChange={onChange}
            style={styleInpt}
            label="RÉFÉRENCE"
            name="productId"
            disabled
          />
        )}
        <Input
          value={achat.designation}
          onChange={onChange}
          style={styleInpt}
          label="DÉSIGNATION"
          name="designation"
          // disabled
        />
        <Input
          value={achat.achatReference}
          onChange={onChange}
          style={styleInpt}
          label="N° FACTUR"
          name="achatReference"
          // disabled
        />
        <Input
          value={achat.quantity}
          onChange={onChange}
          style={styleInpt}
          label="QTÉ"
          name="quantity"
        />
        <Input
          value={achat.prixUnitaire}
          onChange={onChange}
          style={styleInpt}
          label="PRIX UNITAIRE"
          name="prixUnitaire"
        />
        <Input
          value={achat.prixDepart}
          onChange={onChange}
          style={styleInpt}
          label="PRIX DÉPART UNITAIRE"
          name="prixDepart"
        />
        <Input
          value={achat.origine}
          onChange={onChange}
          style={styleInpt}
          label="ORIGINE"
          name="origine"
        />
        <Input
          value={achat.remise}
          onChange={onChange}
          style={styleInpt}
          label="REMISE"
          name="remise"
        />
        <Input
          value={achat.prixRevient}
          onChange={onChange}
          style={styleInpt}
          label="PRIX REVIENT"
          name="prixRevient"
        />
        <Input
          value={achat.prixRevientTotal}
          onChange={onChange}
          style={styleInpt}
          label="PRIX REVIENT TOTAL"
          name="prixRevientTotal"
        />
        <AutoCompleteCmp
          options={DEPOSITS}
          onChange={(e, value) => onChangeSelect('repo', value)}
          value={achat.repo}
          label="DÉPOT"
          name="repo"
          isPrimaryTheme
          style={{
            ...styleInpt, display: 'inline-block',
          }}
        />
        <Input
          value={achat.ancienPrixRevient}
          onChange={onChange}
          style={styleInpt}
          label="ANCIEN PRIX REVIENT"
          name="ancienPrixRevient"
        />
        <Input
          value={achat.ancienPa}
          onChange={onChange}
          style={styleInpt}
          label="ANCIEN PA"
          name="ancienPa"
        />
        <Input
          value={achat.ancienPv}
          onChange={onChange}
          style={styleInpt}
          label="ANCIEN PV"
          name="ancienPv"
        />
        <Input
          value={achat.hcee}
          onChange={onChange}
          style={styleInpt}
          label="H/CEE"
          name="hcee"
        />
        <Input
          value={achat.tauxDouane}
          onChange={onChange}
          style={styleInpt}
          label="TAUX DOUANE"
          name="tauxDouane"
        />
        <Input
          value={achat.poid}
          onChange={onChange}
          style={styleInpt}
          label="Poid"
          name="poid"
        />
      </Box>
      <Box sx={{ mt: 1, mb: 1 }}>
        <IconButton
          onClick={onSaveAchat}
          sx={{
            backgroundColor: '#FFF',
            borderRadius: 1.5,
            padding: 0.5,
            boxShadow: '0px 4px 10px #108CD629',
            color: '#00B894',
            border: '1px solid #00B894',
          }}
          size="large"
        >
          <SaveIcon size="small" />
        </IconButton>
        <IconButton
          onClick={onClose}
          sx={{
            backgroundColor: '#FFF',
            borderRadius: 1.5,
            padding: 0.5,
            boxShadow: '0px 4px 10px #108CD629',
            marginLeft: 1,
            color: '#767676',
            border: '1px solid #767676',
          }}
          size="large"
        >
          <CloseIcon size="small" />
        </IconButton>
      </Box>
    </>
  );
};

export default EditDocumentItem;
