import React, { useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import { makeStyles } from '@mui/styles';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { TableHead, Typography } from '@mui/material';
import './tableStyle.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    borderCollapse: 'separate',
    borderSpacing: '0px 10px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    top: 20,
    width: 1,
    padding: 0,
  },
  position: 'absolute',
  avatar: {
    color: '#FFFFFF',
    backgroundColor: '#88BCDE',
    width: 32,
    height: 32,
    fontSize: 14,
  },
  addIcon: {
    color: 'transparent',
    padding: 0,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  data: {
    fontSize: 12,
    color: '#000000',
    fontFamily: 'SFProDisplay !important',
  },
  pagination: {
    color: 'rgba(0, 0, 0, 0.5)',
    fontFamily: 'SFProDisplay !important',
  },
  row: {
    height: 48,
    position: 'relative',
  },
  chip: {
    height: 20,
  },
  ref: {
    minWidth: 200,
  },
  containerClass: { maxHeight: 535, overflowY: 'auto' },
  input: { backgroundColor: '#FFFFFF' },
  highlight: {
    backgroundColor: '#00ffd9',
  },
  error: {
    background: '#F860600F 0% 0% no-repeat padding-box',
    border: '1px solid #F86060',
  },
}));

export default function CustomTable({
  getData,
  rows,
  headCells,
  count = 0,
  pagination = true,
  update,
  resetSort,
  rowsPerPageOptions,
  handleInputChange,
  values,
  addElement,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const tableRef = useRef(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    rowsPerPageOptions ? rowsPerPageOptions[0] : 5,
  );
  useEffect(() => {
    if (rows.length > rowsPerPage && tableRef) {
      tableRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [tableRef, rows]);
  useEffect(() => {
    setOrder('asc');
    setOrderBy('');
    setPage(0);
    setRowsPerPage(rowsPerPageOptions ? rowsPerPageOptions[0] : 5);
  }, [resetSort]);
  useEffect(() => {
    const orderList = orderBy.split('+');
    if (orderList.length === 1 && getData) {
      getData({
        page,
        size: rowsPerPage,
        sort: orderBy ? `${orderBy},${order}` : '',
      });
    } else if (getData) {
      getData({
        page,
        size: rowsPerPage,
        sort: `${orderList[0]},${order}&sort=${orderList[1]},${order}`,
      });
    }
  }, [page, rowsPerPage, order, orderBy, getData, update]);
  /* const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }; */

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  /* const handleRequestSort = (property) => (event) => {
    onRequestSort(event, property);
  }; */
  // eslint-disable-next-line no-nested-ternary
  const emptyRows = pagination
    ? rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage)
    : count < rowsPerPage
      ? rowsPerPage - (count % rowsPerPage)
      : 0;
  const inputRef = useRef();
  return (
    <div className={classes.root}>
      <TableContainer className={classes.containerClass}>
        <Table size="medium" stickyHeader ref={tableRef} className={classes.table}>
          <TableHead>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.order ? order : false}
                classes={{ stickyHeader: classes.stickyHeader }}
                style={{ ...headCell.style, verticalAlign: 'middle' }}
              >
                {headCell.renderHeader
                  ? headCell.renderHeader({
                    value: headCell.type === 'input' ? values[headCell.id] : headCell.label,
                    row: values,
                    classes,
                    handleInputChange: handleInputChange(headCell.id),
                    addElement,
                    inputRef,
                  })
                  : headCell.label}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {(pagination
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => {
              // eslint-disable-next-line max-len
              const stockDisponible = (row.sB || 0) + (row.sD || 0) - (row.res || 0) - (row.resD || 0);
              const isNotPossibleQuatity = row.quantity > stockDisponible;
              const isGameOK = row?.salesCondition === 'GAME' ? row.quantity % row.gameQuantity === 0 : true;
              return (
                <>
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    className={classes.row}
                    onClick={row.click}
                  >
                    {headCells.map((headCell) => {
                      const value = row[headCell.id];
                      return (
                        <>
                          <TableCell
                            className={index === count - 1 && row.highlight && classes.highlight}
                            id="cell"
                            size="medium"
                            key={headCell.id}
                            align={headCell.align}
                          >
                            {headCell.render({
                              value,
                              row,
                              handleChange:
                                row[headCell.handleChange]
                                && row[headCell.handleChange](
                                  row.reference, rows, headCell.id, index,
                                ),
                              handleAction:
                                row[headCell.actionHandler]
                                && row[headCell.actionHandler](row.reference, rows, index),
                            })}
                          </TableCell>
                        </>
                      );
                    })}
                  </TableRow>
                  {isNotPossibleQuatity && (
                    <TableRow hover tabIndex={-1} key={`${row.id}possible`} className={classes.row}>
                      <TableCell
                        colSpan={headCells.length}
                        classes={{ root: classes.error }}
                        id="cell-error"
                      >
                        <Typography>
                          Attention la quantite commandée du produit n’est plus disponible en stock
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {!isGameOK && (
                    <TableRow tabIndex={-1} key={`${row.id}game`} className={classes.row}>
                      <TableCell
                        colSpan={headCells.length}
                        classes={{ root: classes.error }}
                        id="cell-error"
                      >
                        <Typography>
                          Attention la quantite commandée du produit n’est plus disponible en stock
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 77 * emptyRows }}>
                <TableCell colSpan={headCells?.length || 8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <TablePagination
          labelRowsPerPage="Lignes par page"
          rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{ caption: classes.pagination, select: classes.pagination }}
        />
      )}
    </div>
  );
}
