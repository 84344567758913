import CacheManager from 'Utils/CacheManager';
import { SAVE_ORDERS, SAVE_DELETE_ORDERS } from './Actions';

const OrderReducer = (state, action) => {
  switch (action.type) {
    case SAVE_ORDERS:
      CacheManager.writeData('salesOrders', action.data);
      return { ...state, orders: action.data };
    case SAVE_DELETE_ORDERS:
      CacheManager.writeData('deletedSalesOrders', action.data);
      return { ...state, deletedOrders: action.data };
    default:
      throw new Error();
  }
};

export default OrderReducer;
