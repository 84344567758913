/* eslint-disable import/prefer-default-export */
import { secureGet } from 'config/apiConfig';
import { loginError, loginSucces } from 'Shared/UserContext/Actions';

export const defaultMessage = 'Erreur système';

export const getMyProfile = async ({ dispatch, history, token }) => {
  try {
    const { data: profile } = await secureGet('/ci/api/user/me');
    dispatch(loginSucces(profile, token));
    if (['RECOUVREMENT', 'SAV'].includes(profile.role)) {
      history.push('/recovery');
    } else if (profile.role === 'REGLEMENT') {
      history.push('/payment');
    } else {
      history.push('/');
    }
  } catch (e) {
    let message = null;
    if (typeof e === 'string') {
      message = e;
    } else {
      const {
        response: {
          data: { statusLabel = defaultMessage },
        },
      } = e;
      message = statusLabel;
    }
    dispatch(loginError(message));
  }
};
