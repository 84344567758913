import { Box, Typography } from '@mui/material';
import React from 'react';

const Kpi = ({ icon: Icon, label, value }) => (
  <>
    <Box
      style={{
        width: 200,
        height: 90,
        backgroundColor: '#FFF',
        boxShadow: '0px 20px 80px #00000011',
        borderRadius: 12,
        opacity: 1,
        paddingTop: 22,
        paddingLeft: 27,
      }}
    >
      <Typography
        display="block"
        style={{ font: 'normal normal normal 45px Montserrat', color: '#161616', opacity: 1 }}
      >
        {value}
      </Typography>
      <Box style={{ display: 'flex', columnGap: 0, alignItems: 'center' }}>
        <Icon />
        <Typography
          display="block"
          style={{ font: 'normal normal 300 15px Montserrat', color: '#434343' }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  </>
);

export default Kpi;
