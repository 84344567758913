import {
  Box, Popper, TextField, Autocomplete,
} from '@mui/material';
import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import EnhancedTable from 'Shared/EnhancedTable';
import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import ReferencialContext from 'Shared/ReferencialContext';
import useStyles from '../style';
import { columns } from './constants';

const PopperMy = (props) => <Popper {...props} style={{ width: 400 }} placement="bottom-start" />;

const Products = () => {
  const history = useHistory();
  const {
    state: { referencial },
  } = useContext(ReferencialContext);
  const { brands } = referencial;
  const classes = useStyles();
  const [productList, setProductList] = React.useState([]);
  const [search, setSearch] = React.useState(null);
  const [values, setValues] = React.useState({ brand: [], family4: [] });

  const handleInputChange = (prop) => (event, value) => {
    setValues({ ...values, [prop]: value });
  };

  const families4 = useMemo(() => {
    const { families = [] } = referencial;
    return families?.filter((family) => family.familyLevel === 4);
  }, [referencial.families]);
  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };
  useEffect(() => {
    if (referencial.products) {
      setProductList(
        referencial.products
          .filter((p) => p.iA)
          .filter((po) => (!search
        || (po.reference && po.reference.toLowerCase().includes(search.toLowerCase()))
        || (po.des && po.des.toLowerCase().includes(search.toLowerCase()))
        || (po.e && po.e.toLowerCase().includes(search.toLowerCase()))
        || (po.eF && po.eF.toLowerCase().includes(search.toLowerCase()))
        || (po.d && po.d.toLowerCase().includes(search.toLowerCase()))
        || (po.dimensions2 && po.dimensions2.toLowerCase().includes(search.toLowerCase()))
        || (po.rO && po.rO.toLowerCase().includes(search.toLowerCase())))
        && (!values.family4.length || values.family4.map((f) => f.id).includes(po.fId))
        && (!values.brand.length || values.brand.map((b) => b.id).includes(po.bId)))
          .map((p) => ({ ...p, navigateSettings: () => history.push('/product-settings', p) })),
      );
    }
  }, [referencial.products, setProductList, search, values]);
  return (
    <>
      <Box style={{ display: 'flex', columnGap: 15 }}>
        <TextField
          variant="outlined"
          onChange={handleChangeSearch}
          value={search}
          label="chercher"
          size="small"
          className={classes.filter}
          style={{ width: 200 }}
        />
        <Autocomplete
          PopperComponent={PopperMy}
          classes={{ root: classes.filter }}
          options={brands || []}
          onChange={handleInputChange('brand')}
          value={values.brand}
          size="small"
          autoHighlight
          getOptionLabel={(option) => option.name}
          ListboxComponent={ListboxComponent}
          multiple
          filterOptions={(options, v) => options
            .filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Marques"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
        <Autocomplete
          PopperComponent={PopperMy}
          classes={{ root: classes.Lastfilter }}
          options={families4 || []}
          onChange={handleInputChange('family4')}
          value={values.family4}
          size="small"
          autoHighlight
          getOptionLabel={(option) => option.name}
          ListboxComponent={ListboxComponent}
          multiple
          filterOptions={(options, v) => options
            .filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Famille"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Box>
      <EnhancedTable
        staticData
        rows={productList}
        headCells={columns}
        count={productList.length}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </>
  );
};

export default Products;
