/* eslint-disable import/prefer-default-export */
import { secureGet } from 'config/apiConfig';
import { format } from 'date-fns';

export const getUnhonored = async (filters) => {
  // one loader
  const client = filters.client ? `&client=${filters.client.code}` : '';
  const sdate = filters.start ? `&startDate=${format(filters.start, 'yyyy-MM-dd')}` : '';
  const edate = filters.end ? `&endDate=${format(filters.end, 'yyyy-MM-dd')}` : '';
  const ref = filters.product ? `&reference=${filters.product.id}` : '';
  const { data } = await secureGet(`/ci/api/GetOrderHistoryByCriteria?reliquat=true${client}${sdate}${edate}${ref}`);
  // off loader
  return data;
};
