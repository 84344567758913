import { Box, Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import UserContext from 'Shared/UserContext';
import { deleteNote } from '../ClientServices';
import { NOTESTYPE } from '../constants';
import Edit from '../Edit';
import View from '../View';
import { Fields } from './constants';
import NoteSAV from './NoteSAV';
import AddNoteSAV from './AddNoteSAV';

const Note = ({ client, getClient }) => {
  const {
    state: { user },
  } = useContext(UserContext);
  const isRECOUVREMENT = user.role === 'RECOUVREMENT';
  const isSAV = user.role === 'SAV';
  const [editMode, setEditMode] = useState(false);
  const [editNote, setEditNote] = useState(null);
  const toEditMode = () => {
    setEditMode(true);
  };
  const toViewMode = () => {
    setEditMode(false);
  };

  useEffect(() => {
    if (!editMode) {
      getClient();
      setEditNote(null);
    }
  }, [editMode]);

  const onHandleEdit = (note) => {
    toEditMode(true);
    const { id, attachmentUrl, comment } = note;
    const noteType = attachmentUrl ? NOTESTYPE[1] : NOTESTYPE[0];
    setEditNote({
      noteId: id,
      file: attachmentUrl,
      comment,
      code: client.code,
      noteType,
    });
  };

  const onHandleDelete = async (noteId) => {
    await deleteNote(noteId);
    getClient();
  };

  return (
    <>
      {!isSAV && (
        <>
          {editMode && (
            <Edit
              fields={Fields}
              handleViewMode={toViewMode}
              client={editNote || client}
              currentMode="addNote"
            />
          )}
          {!editMode && isRECOUVREMENT && (
            <div style={{ padding: '20px', margin: 0 }}>
              <Button
                style={{ float: 'right' }}
                variant="contained"
                color="primary"
                onClick={toEditMode}
              >
                Ajouter Note
              </Button>
            </div>
          )}
          <View
            fields={Fields}
            client={client}
            handleEditMode={toEditMode}
            currentMode="addNote"
            handleDelete={onHandleDelete}
            handleEdit={onHandleEdit}
          />
        </>
      )}
      {isSAV && (
        <Box style={{ overflow: 'auto', height: '420px', backgroundColor: '#FFF' }}>
          {client?.notes?.map((note) => (
            <NoteSAV note={note} />
          ))}
          <AddNoteSAV client={client} refreshClient={getClient} />
        </Box>
      )}
    </>
  );
};

export default Note;
