import { Box, Button, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import Filters from '../Filters';
import { DEFAULT_VALUES } from '../NewOrder/constants';
import {
  defaultMessage, downloadBC, downloadBL, downloadInvoice, getOrders,
} from '../OrdersService';
import { columns, mapOrderStatus } from './constants';
import useStyles from './style';
import './style.css';
import NewOrder from '../NewOrder';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflowY: 'unset',
  },
}))(MuiDialogContent);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const titles = [
  { title: 'Référence produit', xs: 2 },
  { title: 'Désignation', xs: 2 },
  { title: 'Quantité', xs: 2 },
  { title: 'Prix unitaire', xs: 2 },
  { title: 'Remise', xs: 2 },
  { title: 'Montant TTC', xs: 2 },
];
const getAnomalies = (order) => {
  const quantity = order?.quantityDifference ? ' quantityDifference, ' : '';
  const price = order?.priceAndDiscountDifference ? ' priceAndDiscountDifference, ' : '';
  const payment = order?.paymentModeChange ? ' paymentModeChange, ' : '';
  const expedition = order?.expeditionModeChange || order?.expeditionmodeChange ? ' expeditionModeChange.' : '';
  return `${quantity}${price}${payment}${expedition}`;
};
export const OrderDetail = ({
  open, setOpen, order, printBC,
}) => (
  <Dialog
    onClose={() => setOpen(false)}
    aria-labelledby="customized-dialog-title"
    maxWidth="lg"
    fullWidth
    open={open}
  >
    <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
      <Grid container>
        <Grid item xs={4}>
          {`Numéro : ${order?.reference}`}
          {order?.type === 1 && printBC && (
            <IconButton
              aria-label="visible"
              onClick={() => printBC(order?.reference)}
              size="large"
            >
              <VisibilityIcon />
            </IconButton>
          )}
        </Grid>
        <Grid xs={3} item>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Piquée par :
          </Typography>
          <Typography display="inline">{order?.picker}</Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Facturée par :
          </Typography>
          <Typography display="inline">{order?.biller}</Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Date :
          </Typography>
          <Typography display="inline">
            {order?.date && new Date(order?.date).toLocaleDateString('en-GB')}
          </Typography>
        </Grid>
        <Grid xs={4} item>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Client :
          </Typography>
          <Typography display="inline">
            {`${order?.client?.code || ''} - ${order?.client?.name || ''} - ${
              order?.client?.city || ''
            }`}
          </Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Nombre de colis :
          </Typography>
          <Typography display="inline">{order?.colisage}</Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Mode d’expedition :
          </Typography>
          <Typography display="inline">{order?.expedition?.name}</Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Mode de payment :
          </Typography>
          <Typography display="inline">{order?.paymentMode?.name}</Typography>
        </Grid>
        <Grid xs={4} item>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Montant TTC :
          </Typography>
          <Typography display="inline">{order?.amount}</Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Commercial :
          </Typography>
          <Typography display="inline">
            {order?.details?.length > 0
              && `${order.details[0].commercial?.firstName} ${order.details[0].commercial?.lastName}`}
          </Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Depot :
          </Typography>
          <Typography display="inline">
            {order?.repoId === 1 ? 'Bouskoura' : 'Derb Omar'}
          </Typography>
        </Grid>
        <Grid xs={3} item>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Date d&apos;impression :
          </Typography>
          <Typography display="inline">
            {order?.printedDate && format(new Date(order?.printedDate), 'dd/MM/yyyy HH:mm')}
          </Typography>
        </Grid>
        <Grid xs={2} item>
          <Typography display="inline" style={{ fontWeight: 'bold' }}>
            Expedition :
          </Typography>
          <Typography display="inline">{order?.numExpedition}</Typography>
        </Grid>
        {order?.noAnomaly === false && (
          <Grid xs={2} item>
            <Typography display="inline" style={{ fontWeight: 'bold' }}>
              Anomalies :
            </Typography>
            <Typography display="inline">{getAnomalies(order)}</Typography>
          </Grid>
        )}
      </Grid>
    </DialogTitle>
    <DialogContent>
      <>
        <Grid container spacing={2} style={{ marginBottom: 5, padding: 10 }}>
          {titles.map((t) => (
            <Grid item className="detail" xs={t.xs}>
              <span style={{ width: '100%' }}>{t.title}</span>
            </Grid>
          ))}
        </Grid>
        {order?.details?.map((detail) => (
          <Grid
            container
            spacing={2}
            style={{ marginBottom: 10, backgroundColor: '#e0f2f1', padding: 10 }}
          >
            <Grid item className="detail" xs={2}>
              <span style={{ width: '100%' }}>{detail?.product?.reference}</span>
            </Grid>
            <Grid item className="detail" xs={2}>
              <span style={{ width: '100%' }}>{detail?.product?.designation}</span>
            </Grid>
            <Grid item className="detail" xs={2}>
              <span style={{ width: '100%' }}>{detail.quantity}</span>
            </Grid>
            <Grid item className="detail" xs={2}>
              <span style={{ width: '100%' }}>{detail.prixUnitaire}</span>
            </Grid>
            <Grid item className="detail" xs={2}>
              <span style={{ width: '100%' }}>
                {detail.remise}
                %
              </span>
            </Grid>
            <Grid item className="detail" xs={2}>
              <span style={{ width: '100%' }}>{detail.montantTtc}</span>
            </Grid>
          </Grid>
        ))}
      </>
    </DialogContent>
  </Dialog>
);

export const DispalyFileFormLink = ({ link, open, setOpen }) => (
  <Dialog
    onClose={() => setOpen(false)}
    aria-labelledby="customized-dialog-title"
    maxWidth="lg"
    fullWidth
    fullScreen
    sx={{ height: 'auto' }}
    open={open}
  >
    <DialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
      Déclaration D`expédition :
    </DialogTitle>
    <DialogContent>
      <embed src={link} width="100%" height="100%" type="application/pdf" />
    </DialogContent>
  </Dialog>
);

const SageOrders = () => {
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [orders, setOrders] = useState({ content: [] });
  const [values, setValues] = useState(
    localStorage.getItem('filters-sage-orders')
      ? JSON.parse(localStorage.getItem('filters-sage-orders') || '{}')
      : DEFAULT_VALUES,
  );
  const [openDetail, setOpenDetail] = useState(false);
  const [openNewOrder, setOpenNewOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openDisplayFile, setOpenDisplayFile] = useState(false);
  const [fileLink, setFileLink] = useState(null);
  const classes = useStyles();
  const handleInputChange = (prop) => (event, value) => {
    const newValue = {
      ...values,
      [prop]: event instanceof Date ? event : value || event?.target.value,
    };
    localStorage.setItem('filters-sage-orders', JSON.stringify(newValue));
    setValues(newValue);
  };
  const handleRefresh = () => {
    setValues({ ...values });
  };
  const getOrderList = useCallback(
    async (sort) => {
      try {
        setLoading(true);
        const data = await getOrders(sort, {
          city: values.city,
          client: values?.client?.code,
          expedition: values?.expedition?.code,
          paymentMode: values?.paymentMode?.code,
          status: values?.status?.id,
          startDate: values.startDate
            ? format(
              values.startDate instanceof Date ? values.startDate : new Date(values.startDate),
              'yyyy-MM-dd',
            )
            : undefined,
          endDate: values.endDate
            ? format(
              values.endDate instanceof Date ? values.endDate : new Date(values.endDate),
              'yyyy-MM-dd',
            )
            : undefined,
          type: values.type?.id,
        });
        setOrders(data);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setOrders, setLoading, values],
  );

  const getBL = useCallback(
    async (id) => {
      try {
        setLoading(true);
        await downloadBL(id);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const getInvoiceCall = useCallback(
    async (id) => {
      try {
        setLoading(true);
        await downloadInvoice(id);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const getBC = useCallback(
    async (id) => {
      try {
        setLoading(true);
        await downloadBC(id);
        setUpdate((prev) => !prev);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setUpdate],
  );

  const handleSelectOrder = (order) => {
    setSelectedOrder(order);
    setOpenDetail(true);
  };
  const handleDisplayExpedition = async (link) => {
    setFileLink(link);
    setOpenDisplayFile(true);
  };

  const handleCloseNewOrderModal = () => {
    setOpenNewOrder(false);
  };

  const handleOpenNewOrderModal = () => {
    setOpenNewOrder(true);
  };

  const sageOrders = useMemo(
    () => orders.content.map((order) => ({
      ...order,
      amount: order.amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$& '),
      client: order.client?.name,
      paymentMode: order.paymentMode?.name,
      expedition: order.expedition?.name,
      status: order.status?.status,
      statusClass:
          classes[
            mapOrderStatus[
              order.status && order.status.status ? order.status.status : 'NON_ASSIGNEE'
            ]?.class
          ],
      click: () => handleSelectOrder(order),
      handleDownloadBl: (event) => {
        event.stopPropagation();
        getBL(order.reference);
      },
      handleInvoice: (event) => {
        event.stopPropagation();
        getInvoiceCall(order.reference);
      },
      handleDisplayCoord1: (link) => (event) => {
        event.stopPropagation();
        handleDisplayExpedition(link);
      },
    })),
    [orders.content, values],
  );
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      {openNewOrder && <NewOrder handleClose={handleCloseNewOrderModal} />}
      <Box style={{ display: 'flex', padding: 10, alignItems: 'center' }}>
        <Filters
          values={values}
          handleInputChange={handleInputChange}
          product
          handleRefresh={handleRefresh}
          type
        />
        <Button
          variant="contained"
          style={{ minWidth: '230px' }}
          color="primary"
          onClick={handleOpenNewOrderModal}
        >
          Ajouter une commande
        </Button>
      </Box>
      <OrderDetail
        open={openDetail}
        setOpen={setOpenDetail}
        order={selectedOrder}
        classes={classes}
        printBC={getBC}
      />
      <DispalyFileFormLink link={fileLink} open={openDisplayFile} setOpen={setOpenDisplayFile} />
      <EnhancedTable
        getData={getOrderList}
        rows={sageOrders}
        headCells={columns}
        update={update}
        count={orders.totalElements}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </>
  );
};

export default SageOrders;
