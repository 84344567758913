import ReferencialContext from 'Shared/ReferencialContext';
import { useContext, useMemo } from 'react';

const useRelatedProducts = (product) => {
  const {
    state: { referencial },
  } = useContext(ReferencialContext);

  const relatedProducts = useMemo(() => {
    const { products } = referencial;
    const relatedProductsN1 = products?.filter((po) => (product?.e || product?.equivalents
      ? (product?.e || product?.equivalents).split(';').includes(po.reference)
      : false));
    const relatedProductRefs = relatedProductsN1?.reduce(
      (previous, current) => previous.concat(current.e ? current.e.split(';') : []).unique(),
      product?.e || product?.equivalents ? (product?.e || product?.equivalents)?.split(';') : [],
    );
    return products
      ?.filter(
        (p) => p?.reference !== (product?.reference || product?.id)
              && relatedProductRefs.includes(p?.reference),
      )
      .unique();
  }, [referencial, product]);

  return { relatedProducts };
};

export default useRelatedProducts;
