/* eslint-disable import/prefer-default-export */
import { defaultMessage } from 'Shared/Referencial/ReferencialService';
import api from '../../config/apiConfig';
import { login, loginError, loginSucces } from '../../Shared/UserContext/Actions';

export const loginUser = async (dispatch, credential, history) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.set('username', credential.username);
    bodyFormData.set('password', credential.password);
    bodyFormData.set('imei', 'test');
    dispatch(login());

    const {
      data: { userProfile, token, statusLabel },
    } = await api.post('/ci/api/LoginMobileNew', bodyFormData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
    if (!token) {
      throw statusLabel;
    }
    dispatch(loginSucces(userProfile, token));
    if (['RECOUVREMENT', 'SAV'].includes(userProfile.role)) {
      history.push('/recovery');
    } else if (userProfile.role === 'REGLEMENT') {
      history.push('/payment');
    } else {
      history.push('/home');
    }
  } catch (e) {
    let message = null;
    if (typeof e === 'string') {
      message = e;
    } else {
      const {
        response: {
          data: { statusLabel = defaultMessage },
        },
      } = e;
      message = statusLabel;
    }
    dispatch(loginError(message));
  }
};
