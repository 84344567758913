import {
  Dialog, DialogContent, Paper, Tab, Tabs,
} from '@mui/material';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import UserContext from 'Shared/UserContext';
import { defaultMessage, getCLientByCode } from '../ClientServices';
import Contact from '../Contact';
import FinancialSituation from '../FinancialSituation/FinancialSituation';
import Identification from '../Identification';
import LegalInformation from '../LegalInformation';
import Notes from '../Note';
import Settings from '../Settings';
import useStyles from './style';

const ClientTabs = ({ handleClose }) => {
  const {
    state: { user },
  } = useContext(UserContext);
  const isCommercialOrSales = ['COMMERCIAL', 'SALES'].includes(user.role);
  const { id } = useParams();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(0);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const classes = useStyles();
  const getClientData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getCLientByCode(id);
      setClient(data);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [id, getCLientByCode, setLoading, setClient]);
  useEffect(() => {
    getClientData();
  }, [getClientData]);
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Dialog
        onClose={handleClose}
        maxWidth="lg"
        open
        fullWidth
        PaperProps={{
          style: {
            background: '#F9FAFC 0% 0% no-repeat padding-box',
            border: '1px solid #C5C5C5',
            borderRadius: 24,
          },
        }}
        BackdropProps={{
          style: {
            background: '#245283 0% 0% no-repeat padding-box',
            boxShadow: '0px 12px 26px #0000000D',
            opacity: 0.67,
          },
        }}
      >
        <DialogContent className={classes.dialogContent}>
          <Paper elevation={0} classes={{ root: classes.paper }}>
            <div className={classes.container}>
              <Tabs
                value={tab}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                classes={{ root: classes.tabs }}
                TabIndicatorProps={{ style: { backgroundColor: '#2188CC' } }}
              >
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  label="Identification"
                  value={0}
                />
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  label="Informations légales"
                  value={1}
                />
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  label="Contacts"
                  value={2}
                />
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  label="Situation financière"
                  value={3}
                />
                <Tab
                  classes={{
                    root: classes.tab,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  label="Paramètres"
                  value={4}
                />
                {!isCommercialOrSales && (
                  <Tab
                    classes={{
                      root: classes.tab,
                      selected: classes.tabSelected,
                      wrapper: classes.tabWrapper,
                    }}
                    label="Notes"
                    value={5}
                  />
                )}
              </Tabs>
              {tab === 0 && <Identification client={client} getClient={getClientData} />}
              {tab === 1 && <LegalInformation client={client} getClient={getClientData} />}
              {tab === 3 && <FinancialSituation client={client} />}
              {tab === 4 && <Settings client={client} getClient={getClientData} />}
              {tab === 2 && <Contact client={client} getClient={getClientData} />}
              {tab === 5 && !isCommercialOrSales && (
                <Notes client={client} getClient={getClientData} />
              )}
            </div>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ClientTabs;
