import Client from 'Components/Client';
import Orders from 'Components/Orders';
import Payment from 'Components/Payment';
import Recovery from 'Components/Recovery';
import ControleOrders from 'Components/ControleOrders';
import history from 'config/history';
import React, { useEffect, useState } from 'react';
import {
  Redirect, Route, Router, Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ConnectedStore from 'Shared/ConnectedStore';
import ProtectedRoute from 'Shared/ProtectedRoute';
import {
  initialState as initialStateRef,
  Provider as ProviderRef,
  ReferencialReducer,
} from 'Shared/ReferencialContext';
import { initialState, Provider, UserReducer } from 'Shared/UserContext';
import ProductSettings from 'Components/ProductSettings';
import Announcement from 'Components/Announcement';
import Profile from 'Shared/Profile/Profile';
import { authUrl } from 'Utils/authConfig';
import { isAuthenticated } from 'Utils/ulilities';
import ExternSearch from 'Components/ExternSearch';
import ModelDetailView from 'Components/ExternSearch/ModelDetailView';
import Settings from 'Components/Settings';
import Setting from 'Components/ProductSettings/Setting';
import ReactPiwik from 'react-piwik';
import AchatDocuments from 'Components/Import/AchatDocuments/AchatDocuments';
import Detail from 'Components/Import/AchatDocuments/Detail';
import DiscountSettings from 'Components/ProductSettings/DiscountSettings/DiscountSettings';
import Traceability from 'Components/Traceability';
import Families from 'Components/Import/Families';
import Unhonored from 'Components/Unhonored';
import { secureGet } from 'config/apiConfig';
import Catalog from './Components/Catalog';
import Home from './Components/Home';
import Login from './Components/Login';
import Proposition from './Components/Proposition';
import AddProposition from './Components/Proposition/Add';
import Import from './Components/Import';

const MATOMO_SITE_IDS = {
  development: 'X', // local, uat, preprod
  production: 4, // prod
};

const piwik = new ReactPiwik({
  url: 'https://analytics.copima.ma',
  siteId:
    window.location.host === 'sales.copima.ma'
      ? MATOMO_SITE_IDS.production
      : MATOMO_SITE_IDS[process.env.NODE_ENV],
});
function App() {
  const [loading, setLoading] = useState(true);
  const userX = localStorage.getItem('user');
  const user = userX ? JSON.parse(userX) : null;
  useEffect(() => {
    if (user?.id) {
      ReactPiwik.push(['setUserId', `id_${user.id}`]);
      setLoading(false);
    } else if (isAuthenticated()) {
      setLoading(true);
      secureGet('/ci/api/user/me').then((profile) => {
        localStorage.setItem('user', JSON.stringify(profile.data));
        // setInterval(setLoading(false), 2000);
        window.location.reload();
      });
    } else {
      setLoading(false);
    }
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Profile>
      <Router history={piwik.connectToHistory(history, false)}>
        <ToastContainer />
        <Traceability userRole={user?.role} />
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route
            path="/"
            exact
            render={() => {
              if (isAuthenticated()) {
                const roleStore = localStorage.getItem('user');
                const role = roleStore == null ? null : JSON.parse(roleStore)?.role;
                switch (role) {
                  case 'RECOUVREMENT':
                    return <Redirect to="/recovery" />;
                  case 'SAV':
                    return <Redirect to="/recovery" />;
                  case 'REGLEMENT':
                    return <Redirect to="/payment" />;
                  case 'IMPORTER':
                    return <Redirect to="/import" />;
                  default:
                    return <Redirect to="/home" />;
                }
              }
              if (!window.location.href.includes('iframe')) {
                window.location.href = authUrl;
              }
              return <Redirect to="/login" />;
            }}
          />
          <ProtectedRoute exact path="/home" component={Home} />
          <ProtectedRoute path="/recovery" component={Recovery} />
          <ProtectedRoute path="/controle-orders" component={ControleOrders} />
          <ProtectedRoute path="/payment" component={Payment} />
          <ProtectedRoute path="/client" component={Client} />
          <ProtectedRoute path="/catalog" component={Catalog} exact />
          <ProtectedRoute path="/proposition" component={Proposition} exact />
          <ProtectedRoute path="/proposition/add" component={AddProposition} exact />
          <ProtectedRoute path="/orders" component={Orders} exact />
          <ProtectedRoute path="/products-settings" component={ProductSettings} exact />
          <ProtectedRoute path="/announcement" component={Announcement} exact />
          <ProtectedRoute path="/discount-settings" component={DiscountSettings} exact />
          <ProtectedRoute path="/product-settings" component={Setting} exact />
          <ProtectedRoute path="/externe-search" component={ExternSearch} exact />
          <ProtectedRoute path="/unhonored" component={Unhonored} exact />
          <ProtectedRoute path="/order-add" component={Orders} exact add />
          <ProtectedRoute path="/model/:id" component={ModelDetailView} exact add />
          <ProtectedRoute path="/settings" component={Settings} />
          <ProtectedRoute path="/import/achat-document/:id" component={Detail} />
          <ProtectedRoute path="/import/achat-document" component={AchatDocuments} />
          <ProtectedRoute path="/import/family" component={Families} />
          <ProtectedRoute path="/import" component={Import} />
        </Switch>
      </Router>
    </Profile>
  );
}

export default ConnectedStore(
  ConnectedStore(App, UserReducer, initialState, Provider),
  ReferencialReducer,
  initialStateRef,
  ProviderRef,
);
