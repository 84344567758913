import { EXPANDED_COLUMN_IDS } from './actions';

const ProductReducer = (state, action) => {
  switch (action.type) {
    case EXPANDED_COLUMN_IDS:
      return { ...state, expandedColumnsIds: action.data };
    default:
      throw new Error();
  }
};

export default ProductReducer;
