import {
  Card, CardActionArea, Tooltip, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  root: {
    width: 238,
    height: 340,
    margin: '10px 10px 10px 0',
  },
  media: {
    height: 131,
    width: '100%',
  },
});
const commonStyle = {
  height: 169, width: 118, borderColor: 'black', borderStyle: 'solid', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
};
const Objective = ({ objective = { intitule: 'MARS 2022', ca: 10000000 } }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea style={{ height: '100%', display: 'flex', flexWrap: 'wrap' }}>
        <div style={{
          ...commonStyle, borderRight: 1, borderBottom: 1,
        }}
        >
          <Typography
            style={{
              color: '#ff7f00',
              fontSize: 17,
              fontWeight: 'bold',
            }}
          >
            Clients
          </Typography>
          <Tooltip title={objective.clients}>
            <Typography
              style={{
                color: '#ff7f00',
                fontSize: 15,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'center',
              }}
            >
              {objective.clients}
            </Typography>
          </Tooltip>
        </div>
        <div style={{
          ...commonStyle, borderLeft: 1, borderBottom: 1,
        }}
        >
          <Typography
            style={{
              color: '#800080',
              fontSize: 17,
              fontWeight: 'bold',
            }}
          >
            Produits
          </Typography>
          <Tooltip title={objective.products}>
            <Typography
              style={{
                color: '#800080',
                fontSize: 15,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'center',
              }}
            >
              {objective.products}
            </Typography>
          </Tooltip>
        </div>
        <div style={{
          ...commonStyle, borderTop: 1, borderRight: 1,
        }}
        >
          <Typography
            style={{
              color: '#0000FF',
              fontSize: 17,
              fontWeight: 'bold',
            }}
          >
            Familles
          </Typography>
          <Tooltip title={objective.families}>
            <Typography
              style={{
                color: '#0000FF',
                fontSize: 15,
              }}
            >
              {objective.families}
            </Typography>
          </Tooltip>
        </div>
        <div style={{
          ...commonStyle, borderTop: 1, borderLeft: 1,
        }}
        >
          <Typography
            style={{
              color: '#00FF00',
              fontSize: 17,
              fontWeight: 'bold',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textAlign: 'center',
            }}
          >
            Marques
          </Typography>
          <Tooltip title={objective.brands}>
            <Typography
              style={{
                color: '#00FF00',
                fontSize: 15,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textAlign: 'center',
              }}
            >
              {objective.brands}
            </Typography>
          </Tooltip>
        </div>
      </CardActionArea>
    </Card>
  );
};

export default Objective;
