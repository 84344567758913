import { securePost } from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getNotifications = async () => {
  const { data } = await securePost('/ci/api/GetMyNotificationsSpecial');
  return data;
};
export const MarkAllNotificationsRead = async () => {
  const { data } = await securePost('/ci/api/ReadAllNotifications');
  return data;
};
