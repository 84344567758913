/* eslint-disable */
import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

export const stepsAnnancement = ['Informations générales', 'Séléction catégorie/produit'];

export const ANNOUCE_TYPE = { PRODUCT: 'PRODUCT', CATEGORY_PRODUCT: 'CATEGORY_PRODUCT' };

const defaultColumnStyle = { color: '#4D4D4D', fontSize: '12px' };

export const columnsProduct = (allPromo, allNew, allInfo, onCheck, all) => [
  {
    id: 'reference',
    label: 'Référence',
    order: 'reference',
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'designation',
    label: 'Désignation',
    order: 'designation',
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'family',
    label: 'Famille',
    order: 'family',
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'promo',
    label: 'Promotion ('+ (all?.promo ? 'désélectionner' : 'tous')+')',
    style: { cursor: 'pointer' },
    onClick: allPromo,
    render: ({ value, row }) => (
      <FormControlLabel
        control={(
          <Checkbox
            onChange={onCheck(false, false, row.reference)}
            checked={value}
          />
              )}
        style={{
          color: '#4D4D4D', textTransform: 'capitalize', fontSize: 10, fontWeight: 600,
        }}
      />
    ),
  },
  {
    id: 'new',
    label:'Nouveauté ('+(all?.new ? 'désélectionner' : 'tous')+')',
    style: { cursor: 'pointer' },
    onClick: allNew,
    render: ({ value, row }) => (
      <FormControlLabel
        control={(
          <Checkbox
            onChange={onCheck(true, false, row.reference)}
            checked={value}
          />
              )}
        style={{
          color: '#4D4D4D', textTransform: 'capitalize', fontSize: 10, fontWeight: 600,
        }}
      />
    ),
  },
  {
    id: 'info',
    label: 'Information ('+(all?.info ? 'désélectionner' : 'tous')+')',
    style: { cursor: 'pointer' },
    onClick: allInfo,
    render: ({ value, row }) => (
      <FormControlLabel
        control={(
          <Checkbox
            onChange={onCheck(false, true, row.reference)}
            checked={value}
          />
              )}
        style={{
          color: '#4D4D4D', textTransform: 'capitalize', fontSize: 10, fontWeight: 600,
        }}
      />
    ),
  },
];
