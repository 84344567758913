import {
  Box, CardMedia, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import DEFAULT_PRODUCT from 'Images/default_product.png';

const useStyles = makeStyles(() => ({
  cardContainer: {
    background: '#FFFFFF',
    boxShadow: '0px 12px 26px #0000000D',
    borderRadius: '12px',
    margin: '13px 7px',
  },
  body: { padding: '20px 7px' },
  title: {
    font: 'normal normal 600 16px Montserrat',
    textTransform: 'uppercase',
  },
  model: {
    font: 'normal normal 600 14px Montserrat',
    textTransform: 'uppercase',
    color: '#007BC6',
    marginTop: '12px',
    marginBottom: '12px',
  },
  details: {
    font: 'normal normal 600 12px Montserrat',
    textTransform: 'uppercase',
    color: '#707070',
  },
}));

const VehiculeCard = ({ vehicule }) => {
  const classes = useStyles();
  const carNames = vehicule.carName?.length > 0 ? vehicule.carName.split(' ') : [''];
  // eslint-disable-next-line no-unused-vars
  const otherNames = carNames.length > 1 ? carNames.slice(1, carNames.length).join(' ') : '';
  return (
    <Box className={classes.cardContainer}>
      <CardMedia
        src={vehicule.vehiculeImage || DEFAULT_PRODUCT}
        component="img"
        style={{
          width: '213px', maxHeight: '135px', margin: 'auto', borderRadius: '12px',
        }}
      />
      <Box className={classes.body}>
        <Typography className={classes.title}>{`${vehicule.manuName}, ${carNames[0]}`}</Typography>
        <Typography className={classes.model}>
          {`Model /${vehicule.manuId} - ${vehicule.modelId}`}
        </Typography>
        <Typography className={classes.details}>
          {`Année /${vehicule.beginYearMonth}`}
        </Typography>
        {vehicule.engineCodeList.map((code) => (
          <Typography className={classes.details}>{`${vehicule.engineType} /(${code})`}</Typography>
        ))}
      </Box>
    </Box>
  );
};

export default VehiculeCard;
