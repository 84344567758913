import React from 'react';
import {
  Typography, Checkbox, Box,
} from '@mui/material';
import SwitchCmp from 'Shared/SwitchCmp';
import EditIcon from '@mui/icons-material/EditOutlined';

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};

export const TYPES = [
  { value: 1, label: 'Huile' },
  { value: 0, label: 'PM' },
];
/* eslint-disable import/prefer-default-export */
export const columns = (
  onSelectAll,
  onSelectOne,
  onCheckHuile,
  editExtra,
  isAllSelected = false,
  selectedRows = [],
  checkedOils = [],
  isAllOils,
) => ([
  {
    id: '',
    render: ({ row }) => (<Checkbox checked={selectedRows.includes(row.id)} onChange={onSelectOne(row.id)} color="primary" style={{ color: '#007BC6' }} size="small" />),
    renderHeader: () => (<Checkbox checked={isAllSelected} onChange={onSelectAll} color="primary" style={{ color: '#007BC6' }} size="small" />),
    style: { ...TitleStyle, width: '10px' },
  },
  {
    id: 'reference',
    label: 'Réference',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'des',
    label: 'Désignation',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'bId',
    label: 'Marque',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'fId',
    label: 'Code famille',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'eF',
    label: 'Info supplémentaire',
    style: TitleStyle,
    render: ({ row, value }) => (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Typography style={defaultColumnStyle}>{value !== null ? value : '-'}</Typography>
        <EditIcon fontSize="small" style={{ color: '#007BC6', cursor: 'pointer' }} onClick={editExtra(row)} />
      </Box>
    ),
  },
  {
    id: 'iH',
    label: 'HUILE',
    style: TitleStyle,
    render: ({ row, value }) => {
      let isChecked = value;
      if (checkedOils.length > 0 && selectedRows.includes(row.id) && checkedOils.includes(row.id)) {
        isChecked = isAllOils;
      }
      return (
        <SwitchCmp
          checked={isChecked}
          onChange={onCheckHuile(row.id)}
          name="checkedB"
        />
      );
    },
  },
]);
