/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable max-len */
import {
  Autocomplete, Box, Popper, TextField,
} from '@mui/material';
import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import ReferencialContext from 'Shared/ReferencialContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStyles from './styles';

const PopperMy = (props) => <Popper {...props} style={{ width: 400 }} placement="bottom-start" />;

const Filters = ({ values, onChange }) => {
  const {
    state: { referencial },
  } = useContext(ReferencialContext);
  const {
    brands, families: allFamilies, products, clients,
  } = referencial;
  const ref = useRef(false);
  const families = useMemo(() => allFamilies?.filter((family) => family.familyLevel === 4), [allFamilies]);
  const [filtredBrands, setFiltredBrands] = useState([]);
  const [filtredFamilies, setFiltredFamilies] = useState([]);
  const [filtredProducts, setFiltredProducts] = useState([]);
  useEffect(() => {
    if (brands?.length > 0 && families?.length > 0 && products?.length > 0 && !ref.current) {
      ref.current = true;
      setFiltredBrands(brands);
      setFiltredFamilies(families);
      setFiltredProducts(products);
    }
  }, [brands, families, products, ref.current, setFiltredBrands, setFiltredBrands, setFiltredProducts]);
  const handleInputChange = (name, event, value) => {
    if (name === 'brand') {
      if (value?.length) {
        const productsSelected = products.filter((p) => value.map((b) => b.id).includes(p.bId));
        const familyIds = productsSelected.map((p) => p.fId).unique();
        setFiltredFamilies(families.filter((f) => familyIds.includes(f.id)));
        setFiltredProducts(productsSelected);
      } else {
        setFiltredFamilies(families);
        setFiltredProducts(products);
      }
    }
    if (name === 'family') {
      if (value?.length) {
        const productsSelected = products.filter((p) => value.map((f) => f.id).includes(p.fId));
        setFiltredProducts(productsSelected);
      } else {
        setFiltredProducts(products);
      }
    }
    onChange({ target: { name, value } });
  };
  const classes = useStyles();
  return (
    <Box className={classes.filterContainer} sx={{ mb: 3 }}>
      <Box className={classes.filterZone}>
        <Autocomplete
          PopperComponent={PopperMy}
          classes={{ root: classes.filter }}
          options={filtredBrands || []}
          onChange={(event, value) => handleInputChange('brand', event, value)}
          value={values?.brand}
          size="small"
          getOptionLabel={(option) => option.name}
          ListboxComponent={ListboxComponent}
          multiple
          filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Marques"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                ...params.InputProps,
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
          popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
        />
        <Autocomplete
          PopperComponent={PopperMy}
          classes={{ root: classes.filter }}
          options={filtredFamilies || []}
          onChange={(event, value) => handleInputChange('family', event, value)}
          value={values?.family}
          size="small"
          autoHighlight
          getOptionLabel={(option) => option.name}
          ListboxComponent={ListboxComponent}
          multiple
          filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Familles"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              InputProps={{
                ...params.InputProps,
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
          popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
        />
        <Autocomplete
          PopperComponent={PopperMy}
          classes={{ root: classes.filter }}
          options={filtredProducts || []}
          onChange={(event, value) => handleInputChange('reference', event, value)}
          value={values?.reference}
          size="small"
          autoHighlight
          getOptionLabel={(option) => option.reference}
          ListboxComponent={ListboxComponent}
          multiple
          filterOptions={(options, v) => options.filter((option) => option.reference?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Références"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              InputProps={{
                ...params.InputProps,
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
          popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
        />
        <Autocomplete
          PopperComponent={PopperMy}
          classes={{ root: classes.filter }}
          options={clients || []}
          onChange={(event, value) => handleInputChange('client', event, value)}
          value={values?.client}
          size="small"
          autoHighlight
          getOptionLabel={(option) => option.name}
          ListboxComponent={ListboxComponent}
          multiple
          filterOptions={(options, v) => options.filter((option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Clients"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              InputProps={{
                ...params.InputProps,
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
          popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
        />
      </Box>
    </Box>
  );
};

export default Filters;
