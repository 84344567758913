import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  filter: {
    marginRight: 16,
    flexGrow: 1,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
  },
  Lastfilter: {
    minWidth: 200,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    marginBottom: 16,
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('xl')]: {
      display: 'flex',
    },
  },
  filterZone: {
    flexGrow: 1,
    display: 'flex',
    background: '#E9EBED 0% 0% no-repeat padding-box',
    borderRadius: 12,
    borderTopLeftRadius: 0,
    opacity: 1,
    padding: '10px 7px',
  },
  notched: {
    border: 'none',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  icon: {
    color: '#0681cc !important',
    fontSize: 22,
  },
}));

export default useStyles;
