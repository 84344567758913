/* eslint-disable max-len */
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { DispalyFileFormLink } from 'Components/Orders/SageOrders/SageOrders';
import { mapStatusRegelement } from 'Components/Payment/AttachInvoicesPayment/constants';
import { format } from 'date-fns';
import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import UserContext from 'Shared/UserContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GetAppIcon from '@mui/icons-material/GetApp';
import { setIntervalX } from 'Utils/ulilities';
import Filters from '../Filters';
import { DOCUMENTS, downloadFromLink } from '../Orders/constants';
import {
  defaultMessage, downloadBL, getOrders, setNoAnomalyOrder,
} from '../RecoveryServices';
import {
  columns,
  columnsSAV,
  mapOrderStatus, // TYPES,
} from './constants';
import useStyles from './style';
import ControleOrdersContext from '../context';
import { refreshOrders } from '../action';
import useAppliedFilter from '../useAppliedFilter';

let cancelToken;
const Orders = () => {
  const {
    state: { user },
  } = useContext(UserContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState({ content: [] });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const {
    setValues,
    handleRefresh,
    values,
    appliedFilter,
    onHandleSubmitFilters,
  } = useAppliedFilter();
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;
  const [arrowRef, setArrowRef] = React.useState(null);
  const {
    state: { refreshData },
    dispatch,
  } = useContext(ControleOrdersContext);
  const [updateList, setUpdateList] = useState(false);
  const [openDisplayFile, setOpenDisplayFile] = useState(false);
  const [fileLink, setFileLink] = useState(null);
  const classes = useStyles();
  const handleStateNewOrderPopup = () => {
    history.push('/o-add');
  };
  const handleInputChange = (prop) => (event, value) => {
    if (event?.toString() !== 'Invalid Date') {
      setValues((prev) => ({
        ...prev,
        [prop]: event instanceof Date ? event : value || event?.target.value,
      }));
    }
  };
  const getOrderList = useCallback(
    async (sort) => {
      try {
        setLoading(true);
        if (typeof cancelToken !== typeof undefined) {
          cancelToken.cancel('Cancel');
        }
        cancelToken = axios.CancelToken.source();
        let controlled;
        if (appliedFilter.controlled) {
          controlled = appliedFilter.controlled.value === 'CONTROLED';
        }
        const data = await getOrders(
          sort,
          {
            controlled,
            city: appliedFilter.city,
            zone: appliedFilter.zone,
            client: appliedFilter?.client?.map((c) => c.code),
            expedition: appliedFilter?.expedition?.map((c) => c.code),
            paymentMode: appliedFilter?.paymentMode?.map((c) => c.code),
            statusP: appliedFilter?.statusP?.value,
            commercial: appliedFilter.commercial?.map((c) => c.id),
            startDate: appliedFilter.startDate
              ? format(
                appliedFilter.startDate instanceof Date
                  ? appliedFilter.startDate
                  : new Date(appliedFilter.startDate),
                'yyyy-MM-dd',
              )
              : undefined,
            endDate: appliedFilter.endDate
              ? format(
                appliedFilter.endDate instanceof Date
                  ? appliedFilter.endDate
                  : new Date(appliedFilter.endDate),
                'yyyy-MM-dd',
              )
              : undefined,
            numCommande: appliedFilter.numCommande,
            numExpedition: appliedFilter.numExpedition,
            type: appliedFilter.type?.id,
            deposit: appliedFilter.deposit,
            // type: TYPES_COMMANDES.find((type) => type.label === 'BC')?.id,
          },
          { cancelToken: cancelToken.token },
          true,
        );
        setOrders(data);
      } catch (e) {
        if (e.message !== 'Cancel') {
          const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
          toast.error(statusLabel);
        }
      } finally {
        setLoading(false);
      }
    },
    [setOrders, setLoading, appliedFilter],
  );

  const getBL = useCallback(
    async (idf, bl) => {
      try {
        setLoading(true);
        await downloadBL(idf, bl);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const handleDisplayExpedition = (link) => {
    setFileLink(link);
    setOpenDisplayFile(true);
  };

  const noAnomaly = useCallback(
    async (reference) => {
      try {
        setLoading(true);
        await setNoAnomalyOrder(reference);
        setUpdateList((prev) => !prev);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setUpdateList],
  );

  const onChangeSort = (sortBy, direction) => {
    setValues({ ...values, sort: { sortBy, direction } });
  };
  const isSAV = user.role === 'SAV';

  useEffect(() => {
    if (refreshData) {
      setIntervalX(
        () => setUpdateList((prev) => !prev),
        1000,
        4,
        () => dispatch(refreshOrders(false)),
      );
    }
  }, [refreshData]);

  const handleSelectOrder = (o) => {
    history.push('/controle-orders/detail', { order: o });
  };
  const salesOrders = useMemo(
    () => orders.content.map((o, index) => ({
      ...o,
      id: index,
      amountTTC: o.details
        .reduce((prev, curr) => prev + curr.montantTtc, 0)
        ?.toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$& '),
      amountHT: o.details
        .reduce(
          (prev, curr) => prev + (curr.prixUnitaire * curr.quantity * (100 - curr.remise)) / 100,
          0,
        )
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$& '),
      client: `${o.client?.code}: ${o.client?.name}`,
      paymentMode: o.paymentMode?.name,
      expedition: o.expedition?.name,
      expeditionNum: o.numExpedition,
      commercial: `${o.details[0]?.commercial?.firstName} ${o.details[0]?.commercial?.lastName}`,
      city: o.client?.city,
      click: () => handleSelectOrder(o),
      noColor: o.noAnomaly,
      orange: o.quantityDifference || o.priceAndDiscountDifference,
      red: o.falseReference,
      greeen: o.paymentModeChange || o.expeditionModeChange,
      statusP: o.reglementStatus && mapStatusRegelement[o.reglementStatus]?.label,
      statusO:
          mapOrderStatus[o.status && o.status.status ? o.status.status : 'NON_ASSIGNEE']?.label,
      statusClass:
          classes[
            mapOrderStatus[o.status && o.status.status ? o.status.status : 'NON_ASSIGNEE'].class
          ],
      handleDownloadBl: (bl) => (event) => {
        event.stopPropagation();
        getBL(o.currentReference, bl);
        setAnchorEl(null);
      },
      handleDisplayCoord1: (link) => (event) => {
        event.stopPropagation();
        handleDisplayExpedition(link);
        setAnchorEl(null);
      },
      handleNoAnomaly: (event) => {
        event.stopPropagation();
        noAnomaly(o.currentReference);
        setAnchorEl(null);
      },
      openPopOver: (row) => (event) => {
        event?.stopPropagation();
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setSelectedRow(row);
      },
    })),
    [orders],
  );
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        className={classes.popper}
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
      >
        <span className={classes.arrow} ref={setArrowRef} />
        <Paper
          className={{
            maxWidth: 400,
            overflow: 'auto',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 12px 26px #0000001C',
            border: '1px solid #F7F7F7',
          }}
        >
          <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
            {DOCUMENTS[selectedRow?.type] && (
              <ListItem
                button
                onClick={selectedRow?.handleDownloadBl([3, 4].includes(selectedRow?.type))}
              >
                <ListItemIcon>
                  <GetAppIcon style={{ color: '#38485C' }} />
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography
                      style={{ color: '#38485C', font: 'normal normal 600 16px/19px Montserrat' }}
                    >
                      Télecharger le document
                    </Typography>
                  )}
                />
              </ListItem>
            )}
            {selectedRow?.coord1 && (
              <ListItem
                button
                onClick={downloadFromLink(selectedRow?.coord1, selectedRow?.currentReference, () => setAnchorEl(null))}
              >
                <ListItemIcon>
                  <GetAppIcon style={{ color: '#38485C' }} />
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography
                      style={{ color: '#38485C', font: 'normal normal 600 16px/19px Montserrat' }}
                    >
                      Télécharger la déclaration d&apos;expédition
                    </Typography>
                  )}
                />
              </ListItem>
            )}
            {selectedRow?.coord1 && (
              <ListItem button onClick={selectedRow?.handleDisplayCoord1(selectedRow?.coord1)}>
                <ListItemIcon>
                  <VisibilityIcon style={{ color: '#38485C' }} />
                </ListItemIcon>
                <ListItemText
                  primary={(
                    <Typography
                      style={{ color: '#38485C', font: 'normal normal 600 16px/19px Montserrat' }}
                    >
                      Aperçu la déclaration d&apos;expédition
                    </Typography>
                  )}
                />
              </ListItem>
            )}
          </List>
        </Paper>
      </Popper>
      <Box
        style={{
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          background: '#E9EBED 0% 0% no-repeat padding-box',
          bordeRradius: '0px 0px 12px 12px',
          opacity: 1,
          marginBottom: 16,
        }}
      >
        <Filters
          values={values}
          handleInputChange={handleInputChange}
          product
          handleRefresh={handleRefresh}
          onSubmitSearch={onHandleSubmitFilters}
        />
        {!isSAV && (
          <Button
            variant="contained"
            style={{ minWidth: '230px' }}
            color="primary"
            onClick={handleStateNewOrderPopup}
          >
            Ajouter une commande
          </Button>
        )}
      </Box>
      <DispalyFileFormLink link={fileLink} open={openDisplayFile} setOpen={setOpenDisplayFile} />
      <EnhancedTable
        getData={getOrderList}
        rows={salesOrders}
        update={updateList}
        headCells={isSAV ? columnsSAV : columns}
        count={orders.totalElements}
        rowsPerPageOptions={[30, 40, 60]}
        defaultOrderBy={values.sort?.sortBy || 'reference'}
        defaultOrder={values.sort?.direction || 'desc'}
        onSort={onChangeSort}
      />
    </>
  );
};

export default Orders;
