import { Box } from '@mui/material';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SettingsTabs from './SettingsTabs';

const Settings = ({ path }) => (
  <div>
    <Box style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}>
      <Switch>
        <Route exact path={`${path}/`} component={SettingsTabs} />
      </Switch>
    </Box>
  </div>
);

export default Settings;
