import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  filter: {
    marginRight: 16,
    flexGrow: 1,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
  },
  date: {
    marginRight: 16,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
  },
  notched: {
    border: 'none',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  formControlDate: {
    marginTop: '4px !important',
    marginLeft: 10,
  },
  labelDate: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
    top: -13,
    left: 18,
  },
  icon: {
    color: '#0681cc !important',
    fontSize: 22,
  },
}));

export default useStyles;
