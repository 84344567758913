import React from 'react';
import { Box, IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import useStyles from './style';

const Quantity = ({
  quantity, refQuantity, inputRef, onChangeQuantity, onKeyDown = () => null,
}) => {
  const classes = useStyles();
  return (
    <Box style={{ alignItems: 'center', marginRight: 10 }}>
      <IconButton
        style={{
          background:
                    'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
          boxShadow: '0px 4px 10px #108CD650',
          borderRadius: 12,
          color: '#FFF',
          marginRight: 10,
          padding: 7,
        }}
        onClick={
                  quantity - 1 > 0
                    ? onChangeQuantity('quantity', quantity - 1)
                    : null
                }
        size="large"
      >
        <RemoveIcon />
      </IconButton>
      <TextField
        variant="outlined"
        size="small"
        value={quantity || ''}
        inputProps={{ min: 1 }}
        name="quantity"
        inputRef={refQuantity}
        onChange={onChangeQuantity('quantity')}
        style={{ width: 80, borderRadius: 16 }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          classes: { input: classes.input, root: classes.rootQuantity },
        }}
        onKeyDown={onKeyDown(inputRef)}
      />
      <IconButton
        type="number"
        inputProps={{ min: 0 }}
        onClick={onChangeQuantity('quantity', quantity + 1)}
        style={{
          background:
                    'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
          boxShadow: '0px 4px 10px #108CD650',
          borderRadius: 12,
          color: '#FFF',
          marginLeft: 10,
          padding: 7,
        }}
        size="large"
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export default Quantity;
