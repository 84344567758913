import ReferencialContext from 'Shared/ReferencialContext';
import { useContext } from 'react';

const useProductsRef = () => {
  const {
    state: {
      referencial,
    },
  } = useContext(ReferencialContext);

  const findProducts = (traces = []) => {
    if (traces.length === 0) {
      return [];
    }

    const productTraceIds = traces.map((trace) => trace.changes[0].globalId.cdoId);

    return referencial.products
      ?.filter((product) => productTraceIds.includes(product.productId))
      .map(({ productId, reference }) => ({ productId, reference }));
  };

  return { findProducts };
};

export default useProductsRef;
