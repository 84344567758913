import { Box } from '@mui/material';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ClientTabs from './ClientTabs';

const Client = ({ path }) => (
  <div>
    <Box style={{ overflowY: 'auto', height: 'calc(100vh - 64px)' }}>
      <Switch>
        <Route exact path={`${path}/:id`} component={ClientTabs} />
      </Switch>
    </Box>
  </div>
);

export default Client;
