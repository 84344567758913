import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  relatedContainer: {
    height: 405,
    backgroundColor: '#F9F9F9',
    boxShadow: '0 2px 5px 0 rgba(20,27,56,0.1)',
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
  },
  nextButtonEnabled: {
    width: 46,
    height: 46,
    minWidth: 46,
    boxSizing: 'border-box',
    background:
      'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 10px #108CD650',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
  },
  nextButtonDisabled: {
    width: 46,
    height: 46,
    minWidth: 46,
    boxSizing: 'border-box',
    background: '#D7DFE4 0% 0% no-repeat padding-box',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#286183',
  },
  commentContainer: {
    backgroundColor: '#F9F9F9',
    boxShadow: '0 2px 5px 0 rgba(20,27,56,0.1)',
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    width: '100%',
  },
  tab: {
    textTransform: 'initial',
    font: 'normal normal 600 12px Montserrat',
    color: '#707070',
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'left',
  },
  tabSelected: {
    color: '#38485C !important',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 10px #BEBEBE50',
    border: '1px solid #EBEDED',
    borderRadius: 12,
  },
  input: {
    font: 'normal normal 600 26px Montserrat',
    padding: '4px 14px',
  },
  comment: {
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    borderRadius: 12,
    color: '#000',
    font: 'normal normal 600 14px Montserrat',
  },
  notched: {
    border: 'none',
  },
  label: {
    top: -8,
    left: -4,
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  rootQuantity: {
    borderRadius: 14,
  },
  labelSelect: {
    top: -10,
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  commentList: {
    maxHeight: 200,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
}));

export default useStyles;
