/* eslint-disable react/jsx-no-duplicate-props */
import { Popper, TextField, Autocomplete } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  filter: {
    marginRight: 16,
    flexGrow: 1,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
  },
  notched: {
    border: 'none',
  },
  input: {
    '&::placeholder': {
      font: 'normal normal 400 14px/18px Montserrat',
      color: '#AFB0B2',
      fontSize: 14,
      opacity: 1,
    },
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
}));
const PopperMy = (props) => (
  <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />
);
const AutoCompleteCmp = ({
  value,
  label,
  getOptionLabel,
  filterOptions,
  onChange,
  isPrimaryTheme = false,
  options = [],
  disabled = false,
  style = {},
  onInputChange,
  disableClearable = false,
  styleInput,
  styleLabel = {},
}) => {
  const classes = useStyles();
  // eslint-disable-next-line no-nested-ternary
  const primaryTheme = (params) => (isPrimaryTheme
    ? {
      InputProps: {
        ...params.InputProps,
        classes: { notchedOutline: classes.notched, input: classes.input },
      },
      InputLabelProps: {
        classes: { root: classes.label },
      },
    }
    : styleInput ? {
      InputProps: { ...params.InputProps, classes: { root: styleInput } },
      InputLabelProps: { classes: { root: styleLabel } },
    } : {});
  const WIDTH = isPrimaryTheme ? 210 : 150;
  return (
    <Autocomplete
      disableClearable={disableClearable}
      PopperComponent={PopperMy}
      classes={{ root: classes.filter }}
      style={{ width: WIDTH, ...style }}
      options={options}
      onChange={onChange}
      onInputChange={onInputChange}
      value={value}
      size="small"
      disabled={disabled}
      autoHighlight
      getOptionLabel={getOptionLabel || ((option) => `${option.name}`)}
      filterOptions={filterOptions || ((ops, v) => ops.filter(
        (o) => !v.inputValue
                  || o.name
                    ?.toLowerCase()
                    .includes(v.inputValue.toLowerCase()),
      ))}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          {...primaryTheme(params)}
        />
      )}
    />
  );
};

export default AutoCompleteCmp;
