/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import ButtonApp from 'Shared/Button';
import { toast } from 'react-toastify';
import { ventilation } from 'Components/Import/importServices';
import DialogCmp from 'Shared/Dialog';
import HelpIcon from '@mui/icons-material/Help';
import { useStylesInvoiceDetails } from './style';

const InvoiceDetails = ({
  invoices, loading, totalFrais, totalTTC, folderId, folderName, callbackInvoices,
}) => {
  const classes = useStylesInvoiceDetails();
  const [items, setItems] = useState([]);
  const [openValidation, setOpenValidation] = useState(false);
  useEffect(() => {
    if (invoices?.length > 0) {
      setItems(
        invoices.map((invoice) => ({
          ...invoice,
          totalFrais: typeof totalTTC === 'number' && typeof invoice.totalTtc === 'number'
            ? Math.round((100 * totalFrais * invoice.totalTtc) / totalTTC) / 100 : 0,
        })),
      );
    }
  }, [invoices]);

  const handleVentilation = async () => {
    const ventilationObj = items.map(({ reference, totalFrais: frais }) => ({ reference, frais }));
    try {
      const dataRep = await ventilation(folderId, ventilationObj);
      console.log(dataRep);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setOpenValidation(false);
      callbackInvoices({ page: 0, size: 10 });
      toast.success('Les factures ont été ventilés avec succès');
    }
  };
  return (
    <Box sx={{
      justifyContent: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
      <DialogCmp
        title=""
        endIcon={false}
        fullWidth={false}
        open={openValidation}
        handleClose={() => setOpenValidation(false)}
        onHandleSubmit={handleVentilation}
        labelKo="Non"
        labelOk="Oui"
      >
        <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
          <Box sx={{
            borderRadius: '50%',
            width: '65px',
            height: '65px',
            backgroundColor: '#00B894',
            margin: 'auto',
            color: '#FFF',
            display: 'flex',
          }}
          >
            <HelpIcon fontSize="large" style={{ margin: 'auto' }} />
          </Box>
          <Typography sx={{
            marginTop: '20px',
            textAlign: 'center',
            font: 'normal 600 18px Montserrat',
          }}
          >
            Êtes-vous sûr ventilé les frais pour ce document
            {' '}
            {folderName}
            {' '}
            ?
          </Typography>
        </Box>
      </DialogCmp>
      <Box className={classes.header}>
        <Typography className={classes.title}>FACTURE</Typography>
        <Typography className={classes.title}>MONTANT</Typography>
        <Typography className={classes.title}>FRAIS CALCULER</Typography>
      </Box>
      <Box className={classes.body}>
        {loading
          ? (
            <>
              <Skeleton animation="wave" variant="rounded" sx={{ width: '100%' }} height={40} />
              <Skeleton animation="wave" variant="rounded" sx={{ mt: '5px', width: '100%' }} height={40} />
              <Skeleton animation="wave" variant="rounded" sx={{ mt: '5px', width: '100%' }} height={40} />
            </>
          )
          : items.map((invoice, index) => (
            <Box className={classes.invoice} key={`${index}-detail-invoice-ref-${invoice.reference}`}>
              <Typography className={classes.invoiceText}>{invoice.reference || 'vide'}</Typography>
              <Typography className={classes.invoiceText}>{invoice.totalTtc ? `${invoice.totalTtc} Dh` : '0 Dh'}</Typography>
              <Typography className={classes.invoiceText}>{`${invoice.totalFrais} Dh`}</Typography>
            </Box>
          ))}
      </Box>
      <Box sx={{ textAlign: 'right' }}>
        <ButtonApp label="VENTILLÉ" onClick={() => setOpenValidation(true)} style={{ marginTop: 10, width: 110 }} />
      </Box>
    </Box>
  );
};

export default InvoiceDetails;
