/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Typography } from '@mui/material';
import { mapOrderStatus } from 'Components/Orders/SageOrders/constants';
import { format } from 'date-fns';

const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 8px',
};

const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};

export const columns = [
  {
    id: 'reference',
    label: 'Reference',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    id: 'date',
    label: 'Date',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>
        {format(new Date(value), 'yyyy-MM-dd')}
      </Typography>
    ),
  },
  {
    id: 'client',
    label: 'Client',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value?.name}</Typography>,
  },
  {
    id: 'client',
    label: 'Ville',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value?.city}</Typography>,
  },
  {
    id: 'paymentMode',
    label: 'Moyen de paiment',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value?.name}</Typography>,
  },
  {
    id: 'expedition',
    label: 'Expedition',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value?.name}</Typography>,
  },
  {
    id: 'status',
    label: 'Statut',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{
        ...defaultColumnStyle,
        backgroundColor: '#00B894',
        borderRadius: '10px',
        textAlign: 'center',
        color: '#FFF',
        p: 0.5,
      }}
      >
        {mapOrderStatus[value?.status]?.label || value?.status}
      </Typography>
    ),
  },
  {
    id: 'amount',
    label: 'Total NET',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={{ ...defaultColumnStyle, textAlign: 'center' }}>{value}</Typography>,
  },
];

export const columnsDetail = [
  {
    id: 'product',
    label: 'Reference',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>{value?.id}</Typography>
    ),
  },
  {
    id: 'designation',
    label: 'DÉSIGNATION',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>{value}</Typography>
    ),
  },
  {
    id: 'quantity',
    label: 'QUANTITER',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>{value}</Typography>
    ),
  },
  {
    id: 'prixUnitaire',
    label: 'PRIX UNITAIRE',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>{value}</Typography>
    ),
  },
  {
    id: 'remise',
    label: 'REMISE',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>{value}</Typography>
    ),
  },
  {
    id: 'montantTtc',
    label: 'MONTANT NET',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>{value}</Typography>
    ),
  },
];
