import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import EnhancedTable from 'Shared/EnhancedTable';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useForm from 'hooks/useForm';
import useDebounce from 'Shared/Hooks/useDebounce';
import { columns } from './constants';
import {
  defaultMessage, getSuppliers, updateDeliveryDays, importSuppliersByFile,
} from '../ProductSettingsServices';
import useStyles from './styles';
import EditDeliveryDays from './EditDeliveryDays';

const SupplierSettings = () => {
  const classes = useStyles();
  const [supplierData, setSupplierData] = useState({});
  const [supplierEdited, setSupplierEdited] = useState(null);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const fileInputRef = useRef(null);
  const { values, onChange } = useForm({ search: '' });
  const deboucedFilter = useDebounce(values, 500);

  const onCloseEdit = () => {
    setUpdate(false);
    setSupplierEdited(null);
  };

  const handleUpload = () => {
    fileInputRef.current.click();
  };

  const onHandleEditDeliveryDays = (supplier) => () => {
    setUpdate(true);
    setSupplierEdited(supplier);
  };

  const editDeliveryDays = useCallback(async (newDeliveryDays) => {
    setLoading(true);
    try {
      const newSupplier = { ...supplierEdited, deliveryDays: newDeliveryDays };
      await updateDeliveryDays(newSupplier);
      const newContent = supplierData.content
        .map((itm) => (itm.id === newSupplier.id ? newSupplier : itm));
      setSupplierData({ ...supplierData, content: newContent });
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
      onCloseEdit();
    }
  }, [supplierEdited, supplierData]);

  const getSuppliersData = useCallback(async (sort) => {
    setLoading(true);
    try {
      const filters = deboucedFilter.search !== '' ? { searchBy: deboucedFilter.search } : {};
      const data = await getSuppliers(sort, filters);
      setSupplierData(data);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [deboucedFilter]);
  const supplierList = useMemo(() => supplierData?.content || [], [supplierData]);

  const loadSuppliers = useCallback(
    async (file, event) => {
      setLoading(true);
      try {
        const data = await importSuppliersByFile(file);
        if (data?.statusCode === '400') {
          toast.error(data?.statusLabel);
        } else {
          toast.success('Ficher chargé avec succès');
          fileInputRef.current.value = null;
        }
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
        // eslint-disable-next-line no-param-reassign
        event.target.value = null;
      }
    },
    [setLoading],
  );

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      loadSuppliers(file, event);
    }
  };
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      {update
        ? (
          <EditDeliveryDays
            supplier={supplierEdited}
            handleClose={onCloseEdit}
            handleSend={editDeliveryDays}
          />
        )
        : null}
      <Box
        className={classes.filterZone}
        style={{
          display: 'flex', flexDirection: 'row-reverse', marginBottom: 16, justifyContent: 'space-between',
        }}
      >
        <Button
          variant="contained"
          endIcon={<CloudUploadIcon />}
          style={{
            background:
              'transparent linear-gradient(243deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
            boxShadow: '0px 4px 10px #108CD650',
            borderRadius: 12,
            font: 'normal normal 600 16px/19px Montserrat',
            color: '#FFFFFF',
            height: 40,
          }}
          size="medium"
          onClick={handleUpload}
        >
          Upload
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Box>
          <TextField
            classes={{ root: classes.filter }}
            placeholder="Recherche"
            variant="outlined"
            size="small"
            name="search"
            className={classes.filter}
            style={{ width: 200 }}
            InputProps={{
              classes: { notchedOutline: classes.notched },
              endAdornment: <SearchIcon style={{ color: '#AFB0B2' }} />,
            }}
            InputLabelProps={{
              classes: { root: classes.label },
            }}
            value={values.search}
            onChange={onChange}
            onKeyDown={onChange}
          />
        </Box>
      </Box>
      <EnhancedTable
        update={update}
        getData={getSuppliersData}
        rows={supplierList}
        headCells={columns(onHandleEditDeliveryDays)}
        count={supplierData?.totalElements}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </>
  );
};

export default SupplierSettings;
