import { InputAdornment, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

export const useBasicInputStyles = (
  large, hasError, isRegular, multiline,
) => makeStyles((theme) => ({
  nativeInput: {
    // color: colors.indigo2,
    fontWeight: 500,
    boxShadow: isRegular ? '0 -1px 4px 0 rgba(0,0,0,0.17)' : '0 2px 4px 0 rgba(0,0,0,0.17)',
  },
  inputBase: {
    backgroundColor: 'white',
    height: multiline ? 'auto' : '42px',
    maxWidth: large ? 'auto' : '320px',
    [theme.breakpoints.up('xl')]: {
      maxWidth: large ? 'auto' : '360px',
    },
  },
  inputBaseExpanded: {
    height: multiline ? 'auto' : '42px',
    maxWidth: 'auto',
    [theme.breakpoints.up('xl')]: {
      maxWidth: 'auto',
    },
  },
  label: {
    color: hasError ? '#EA2027' : '#8F8F8F',
  },
  inputLabel: {
    // transform: 'translate(12px, 15px) scale(1)',
    // zIndex: 3,
    top: '-6px',
    color: '#CCCCCC',
  },
  border: {
    borderColor: hasError ? '#EA2027' : '#CCCCCC',
    borderBottomRightRadius: isRegular && 0,
    borderBottomLeftRadius: isRegular && 0,
  },
}))();
const Input = ({
  type,
  name,
  value,
  onChange,
  expanded,
  large = true,
  label,
  hint,
  iconEnd,
  hasError,
  isRegular,
  multiline,
  ...rest
}) => {
  const classes = useBasicInputStyles(large, hasError, isRegular, multiline);

  return (
    <>
      <Typography className={classes.label}>{label}</Typography>
      <TextField
        name={name}
        value={value}
        type={type || 'text'}
        label={hint || ''}
        onChange={onChange}
        variant="outlined"
        InputProps={{
          className: classes.nativeInput,
          classes: {
            root: classes.inputBase,
            notchedOutline: classes.border,
          },
          inputProps: rest.type === 'number' ? { min: 0, max: 100 } : {},
          endAdornment: iconEnd ? (
            <InputAdornment
              sx={{
                maxHeight: 'none', height: '100%', paddingLeft: '10px', borderLeft: '1px solid #CCC',
              }}
              position="end"
            >
              {iconEnd}
            </InputAdornment>
          ) : null,
        }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          className: classes.nativeInput,
          'data-testid': name,
          classes: {
            // root: cx({
            //  [classes.inputBase]: !expanded,
            //  [classes.inputBaseExpanded]: expanded && !rest.multiline,
            // }),
            root: classes.inputBase,
            notchedOutline: classes.border,
          },
        }}
        InputLabelProps={{
          classes: {
            outlined: classes.inputLabel,
          },
        }}
        fullWidth={large}
        multiline={multiline}
        {...rest}
      />
    </>
  );
};

export default Input;
