import { REFRESH_ORDERS } from './action';

const ControleOrderReducer = (state, action) => {
  switch (action.type) {
    case REFRESH_ORDERS:
      return { ...state, refreshData: action.data };
    default:
      throw new Error();
  }
};

export default ControleOrderReducer;
