import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  notAssigned: {
    color: '#2188CC !important',
  },
  NotAssignedStatus: {
    background: '#EDF1F2',
    color: '#888888 !important',
  },
  awaitingPicking: {
    background: '#FFECCC',
    color: '#CE8500 !important',
  },
  awaitingValidation: {
    background: '#FFE0E0',
    color: '#E24D4D !important',
  },
  pickingInprogress: {
    background: '#E6E6F2',
    color: '#4A4AFF !important',
  },
  closed: {
    background: '#CDFFCD',
    color: '#007F00 !important',
  },
  invoiced: {
    background: '#F8E8FB',
    color: '#CE66DF !important',
  },
  manque: {
    background: '#F8E8FB',
    color: '#CE66DF !important',
  },
  TableContainer: {
    height: 400,
  },
  TableHead: {
    position: 'sticky',
    top: 0,
  },
  clientInfo: {
    font: 'normal normal 600 18px Montserrat',
    color: '#007BC6',
    textTransform: 'uppercase',
  },
}));

export default useStyles;
