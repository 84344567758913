import React from 'react';
import { Radio, Typography } from '@mui/material';
/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  color: '#727CA2',
  fontSize: '12px',
  fontWeight: 500,
  background: '#F8F8FF',
  borderBottom: 'none',
  textTransform: 'uppercase',
};
const defaultColumnStyle = { color: '#333D66', fontSize: '14px' };
export const columns = [
  {
    id: 'action',
    label: 'Action',
    style: TitleStyle,
    render: ({ row }) => (
      <Radio
        checked={row.selected}
        onChange={() => row.handleSelectProduct(row.reference)}
        value={row.reference}
        name="related-product"
      />
    ),
  },
  {
    id: 'reference',
    label: 'Réference',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    id: 'des',
    label: 'Désignation',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
];
