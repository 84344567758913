import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, CardMedia, Typography } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useHistory } from 'react-router-dom';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import useStyles from './style';
import { getCarFamilies } from '../ExternSearchServices';

const ModelDetailView = ({ location }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [families, setFamilies] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState(0);

  const { state } = location;
  const renderTree = ({ assemblyGroupNodeId, subFamilies, ...nodes }) => {
    // eslint-disable-next-line no-shadow
    const history = useHistory();
    const navigateToArticle = (ref) => history.push(`/externe-search?carId=${state.carId}&familyId=${ref}`);

    return (
      <TreeItem
        key={assemblyGroupNodeId}
        nodeId={assemblyGroupNodeId}
        label={(
          <Typography
            onClick={!subFamilies ? () => navigateToArticle(assemblyGroupNodeId) : null}
          >
            {nodes.assemblyGroupName}
          </Typography>
        )}
        classes={{ label: classes.labelItemTree }}
      >
        {Array.isArray(subFamilies) ? subFamilies.map((node) => renderTree(node)) : null}
      </TreeItem>
    );
  };
  useEffect(async () => {
    if (state.carId) {
      setLoading(true);
      try {
        const dataRep = await getCarFamilies(state.carId);
        setFamilies(dataRep);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  }, [state.carId]);
  return (
    <div style={{ height: '100vh', overflow: 'auto' }}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: 10,
          paddingLeft: 5,
        }}
      >
        <ArrowBackIcon
          style={{ fontSize: '32px', cursor: 'pointer' }}
          onClick={() => history.push('/externe-search')}
        />
        <Typography style={{ font: 'normal normal 600 32px/39px Montserrat', color: '#000000' }}>
          {state.name}
        </Typography>
      </Box>
      <Box style={{ paddingLeft: 48, paddingRight: 25 }}>
        <Typography
          style={{
            font: 'normal normal 600 20px/24px Montserrat',
            color: '#007BC6',
            textTransform: 'uppercase',
          }}
        >
          {state.vin}
        </Typography>
        <Box
          style={{
            display: 'flex',
            columnGap: 46,
            padding: '40px 10px',
            alignItems: 'center',
          }}
        >
          <CardMedia
            src={state.img}
            component="img"
            style={{ width: '313px', maxHeight: '235px' }}
          />
          <Box className={classes.cardContainer}>
            <Box className={classes.card} style={{ borderLeft: 'none' }}>
              <Typography className={classes.cardTitle}>MODEL</Typography>
              <Typography className={classes.cardValue}>
                {`${state.manuId} - ${state.modelId}`}
              </Typography>
            </Box>
            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Année</Typography>
              <Typography className={classes.cardValue}>{state.beginYear}</Typography>
            </Box>
            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Carburant</Typography>
              <Typography className={classes.cardValue}>{state.fuelType}</Typography>
            </Box>
            <Box className={classes.card}>
              <Typography className={classes.cardTitle}>Type/Code de moteur</Typography>
              <Typography className={classes.cardValue}>{`${state.engineType} / (${state.engineCode})`}</Typography>
            </Box>
          </Box>
        </Box>
        <Box style={{ display: 'flex', overflow: 'auto', columnGap: 8 }}>
          {families.map((family, index) => (
            <Box
              key={family.assemblyGroupNodeId}
              className={classes.tab}
              onClick={() => setSelectedFamily(index)}
            >
              <LinkIcon className={classes.tabIcon} />
              <Typography className={classes.tabTitle}>{family.assemblyGroupName}</Typography>
            </Box>
          ))}
        </Box>
        <Box className={classes.categories}>
          <Box className={classes.traingle} />
          {loading && <GlobalLoader size="100%" style={{ position: 'absolte' }} />}
          <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={['root']}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {families[selectedFamily] && renderTree(families[selectedFamily])}
          </TreeView>
        </Box>
      </Box>
    </div>
  );
};

export default ModelDetailView;
