/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Popper } from '@mui/material';

export const fields = [
  { key: 1, title: 'Produits', roles: ['IMPORTER', 'BUYER'] },
  { key: 2, title: 'Documents d’achat', roles: ['IMPORTER'] },
  { key: 3, title: 'Propositions', roles: ['BUYER'] },
  { key: 4, title: 'Clients', roles: ['SAV'] },
  { key: 5, title: 'Documents de vente', roles: ['SAV'] },
];
export const fieldsBuyer = [{ key: 1, title: 'Propositions' }, { key: 2, title: 'Tab2' }, { key: 3, title: 'Tab3' }];

export const TRACE_TYPES = {
  NewObject: 'Ajout',
  ValueChange: 'Modification',
  removeObject: 'Suppression',
  ReferenceChange: 'Modification',
};

export const PopperMy = (props) => <Popper {...props} style={{ width: 400 }} placement="bottom-start" />;

const ignoredChangeTypes = ['InitialValueChange', 'ReferenceChange', 'ListChange'];

export const formatChanges = (changesCommit) => changesCommit.flatMap((commit) => {
  const changes = commit.changes
    .filter((change) => !ignoredChangeTypes.includes(change.changeType))
    .reduce((changesById, change) => {
      const targetChanges = changesById[change.globalId.cdoId] || [];
      return { ...changesById, [change.globalId.cdoId]: [...targetChanges, change] };
    }, {});
  return Object.values(changes).map((subChange) => ({ ...commit, changes: subChange }));
});
