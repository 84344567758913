import React from 'react';

import {
  Box, Typography,
} from '@mui/material';

const NoEquivalent = ({ message }) => (
  <Box style={{ width: '100%' }}>
    <Typography style={{ textAlign: 'center' }}>
      { message || 'Pas de produits équivalents'}
    </Typography>
  </Box>
);

export default NoEquivalent;
