import React from 'react';
import { Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};
export const TYPES = [
  { id: 1, name: 'FACTURES' },
  { id: 2, name: 'DEVIS' },
  { id: 4, name: 'FACTURE HUILE' },
  { id: 5, name: 'DEVIS HUILE' },
];
export const STATUS = [
  { value: 0, label: 'Traité' },
  { value: 1, label: 'Non traité' },
];

/* eslint-disable import/prefer-default-export */
export const columns = (onHandleEdit) => ([
  {
    id: 'id',
    label: 'id',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: '',
    label: 'Commerciale',
    style: TitleStyle,
    render: ({ row }) => (
      <Typography style={defaultColumnStyle}>
        {`${row.commercial?.firstName} ${row.commercial?.lastName}`}
      </Typography>
    ),
  },
  {
    id: 'client',
    label: 'Client',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>{`${value?.code} : ${value?.name}`}</Typography>
    ),
  },
  {
    id: 'createdOn',
    label: 'Date',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>
        {value && format(new Date(value), 'dd/MM/yyy hh:mm')}
      </Typography>
    ),
  },
  {
    id: 'souche',
    label: 'Souche',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>{TYPES.find((t) => t.id === value)?.name}</Typography>
    ),
  },
  {
    id: 'treated',
    label: 'Statut',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>
        {STATUS.find((t) => t.value === value)?.label}
      </Typography>
    ),
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    render: ({ row }) => (
      <Button
        variant="outlined"
        color="primary"
        style={{
          background: '#FFFFFF 0% 0% no-repeat padding-box',
          border: '1px solid #38485C',
          borderRadius: '12px',
          width: 40,
          padding: 5,
          minWidth: 'unset',
        }}
        onClick={() => onHandleEdit(row)}
      >
        <EditIcon style={{ color: '#38485C' }} />
      </Button>
    ),
  },
]);
