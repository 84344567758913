import {
  Box, IconButton, Skeleton, Stack, Typography,
} from '@mui/material';
import ButtonApp from 'Shared/Button';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DialogCmp from 'Shared/Dialog';
import Input from 'Shared/Input/v2';
import useForm from 'Shared/Hooks/useForm';
import Select from 'Shared/Select';
import { format } from 'date-fns';
import UserContext from 'Shared/UserContext';
import Anomaly from './Anomaly';
import { anomaliesOptions, sourceOptions } from './constants';
import { addAnomaly, getAnomalies } from './AnomalyServices';
import { useStylesRoot } from './style';

const INIT_COMMENT = {
  comment: '', source: null, anomaly: null, parentId: null,
};

const PreviousAnomalies = ({ classes, parent }) => (
  <Typography className={classes.anomalyComment}>{parent.comment}</Typography>
);

const Anomalies = ({
  handleClose, orderId, orderReference, pieceBL = '', pieceBC = '',
}) => {
  const classes = useStylesRoot();
  const {
    state: { user },
  } = useContext(UserContext);
  const [openComment, setOpenComment] = useState(false);
  const [loading, setLoading] = useState(false);
  const { values, onChange, reset } = useForm(INIT_COMMENT);
  const [anomalies, setAnomalies] = useState([]);

  const getAnomaliesCall = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getAnomalies(orderReference, pieceBL, pieceBC);
      setAnomalies(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getAnomaliesCall();
  }, []);

  const onHandleComment = (parentId = -1, source = null, anomaly = null) => {
    setOpenComment(!openComment);
    reset({
      ...INIT_COMMENT, parentId, source, anomaly,
    });
  };
  const onChangeSource = (e) => {
    onChange(e);
    onChange({ target: { name: 'anomaly', value: null } });
  };

  const onSubmitComment = async () => {
    const { parentId, ...body } = values;
    await addAnomaly(body, orderId, orderReference, parentId);
    onHandleComment();
    getAnomaliesCall();
  };

  const parentAno = anomalies.find((ano) => ano.id === values.parentId);
  return (
    <Box className={classes.root} style={{ zIndex: openComment && 1300 }}>
      <Box className={classes.header}>
        <Typography className={classes.title}>Les Anomalies</Typography>
        <IconButton aria-label="close" onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <ButtonApp
        label="AJOUTER"
        onClick={() => onHandleComment()}
        style={{
          width: '100%', height: 38, marginTop: 10, marginBottom: 13,
        }}
      />
      <Box className={classes.notes}>
        {loading ? (
          <Stack spacing={1}>
            <Skeleton animation="wave" variant="rounded" width={380} height={170} />
            <Skeleton animation="wave" variant="rounded" width={380} height={100} />
            <Skeleton animation="wave" variant="rounded" width={380} height={150} />
          </Stack>
        ) : anomalies.map((anomaly) => (
          <Anomaly
            {...anomaly}
            key={anomaly.createdOn}
            onHandleAddNote={onHandleComment}
          />
        ))}
      </Box>
      <DialogCmp
        title={`${user.firstName} ${user.lastName}`}
        subTitle={format(new Date(), 'dd/MM/yyyy')}
        endIcon={false}
        fullWidth={false}
        open={openComment}
        handleClose={onHandleComment}
        onHandleSubmit={onSubmitComment}
        labelOk="AJOUTER LA NOTE"
        styleOk={{ height: '35px' }}
        styleKo={{ display: 'none' }}
        isOkDisabled={values.source === null || values.type === null || values.comment === ''}
      >
        <Box className={classes.newNote}>
          <Box className={classes.selectors}>
            {parentAno ? (
              <>
                <Typography className={classes.sourceAnomaly}>{`${parentAno.source} (${parentAno.anomaly})`}</Typography>
                <PreviousAnomalies classes={classes} parent={parentAno} />
                {parentAno.anomalies.map((ano) => (
                  <PreviousAnomalies classes={classes} parent={ano} />))}
              </>
            ) : (
              <>
                <Select
                  options={sourceOptions}
                  value={values.source}
                  label="Source"
                  name="source"
                  onChange={onChangeSource}
                  style={{ backgroundColor: '#e0e0e073', marginRight: 16 }}
                />
                <Select
                  name="anomaly"
                  label="Anomalies"
                  value={values.anomaly}
                  onChange={onChange}
                  disabled={values.source === null}
                  style={{ backgroundColor: '#e0e0e073' }}
                  options={values.source ? anomaliesOptions[values.source] : []}
                />
              </>
            )}
          </Box>
          <Input
            label="Commentaire..."
            name="comment"
            onChange={onChange}
            rows={7}
            multiline
            value={values.comment}
            style={{ backgroundColor: '#e0e0e073', maxWidth: 700, width: 700 }}
          />
        </Box>
      </DialogCmp>
    </Box>
  );
};

export default Anomalies;
