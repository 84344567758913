import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  containerAnnoncements: {
    margin: '10px 10px 10px 25px',
  },
  paper: {
    borderRadius: 8,
    border: '1px solid rgba(42, 105, 188, 0.12)',
    margin: '10px 50px',
    padding: 0,
    background: '#FFFFFF',
  },
  kpiContainer: {
    margin: '24px 50px',
  },
  tabs: {
    margin: '0 5px 30px 5px',
  },
  tab: {
    textTransform: 'initial',
    color: '#2F2F2F',
    marginRight: 67,
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'left',
  },
  container: {
    margin: '0 24px',
  },
  tabSelected: {
    color: '#2188CC !important',
  },
}));

export default useStyles;
