/* eslint-disable import/prefer-default-export */
import { Typography } from '@mui/material';
import React from 'react';
import { format } from 'date-fns';
import ButtonApp from 'Shared/Button';
import CheckIcon from '@mui/icons-material/Check';

const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  color: '#707070',
};
const defaultColumnStyle = {
  color: '#38485C',
  font: 'normal normal 600 14px Montserrat',
  textAlign: 'center',
};

export const columns = (onSelectProposition, selectedId) => [
  {
    id: 'name',
    label: 'DÉSIGNATION',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>{value}</Typography>
    ),
  },
  {
    id: 'id',
    label: 'N° DE PROPOSITION',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>{value}</Typography>
    ),
  },
  {
    id: 'date',
    label: 'DATE',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>{value && format(value, 'dd/MM/yyy')}</Typography>
    ),
  },
  {
    id: 'productsTotal',
    label: 'NBR DE PRODUITS',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>{value}</Typography>
    ),
  },
  {
    id: 'quantities',
    label: 'QTÉ TOTAL',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={defaultColumnStyle}>{value}</Typography>
    ),
  },
  {
    id: 'action',
    label: 'Actions',
    style: TitleStyle,
    render: ({ row }) => (
      <ButtonApp
        onClick={onSelectProposition(row.id, row.name)}
        label={selectedId === row.id ? <CheckIcon style={{ fontSize: '12px' }} /> : 'Ajouter'}
        style={
          selectedId === row.id ? { background: '#00B894', width: '90px' } : { width: '90px' }
        }
      />
    ),
  },
];
