import ReferencialContext from 'Shared/ReferencialContext';
import { useContext } from 'react';

const useClientsRef = () => {
  const {
    state: {
      referencial,
    },
  } = useContext(ReferencialContext);

  const findClients = (traces = []) => {
    if (traces.length === 0) {
      return [];
    }

    const clientTraceIds = traces.map((trace) => trace.changes[0].globalId.cdoId);

    return referencial.clients
      ?.filter((client) => clientTraceIds.includes(client.id))
      .map(({ id, name }) => ({ id, name }));
  };

  return { findClients };
};

export default useClientsRef;
