/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-useless-escape */
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/lab';
import React from 'react';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';

const RenderInput = ({
  input,
  name,
  value,
  handleChange,
  fullWidth = true,
  styleInput,
  styleLabel,
}) => (
  <>
    {input.type === 'number' && (
      <FormControl size="small" variant="outlined" fullWidth={fullWidth}>
        <InputLabel className={styleLabel}>{input.label}</InputLabel>
        <OutlinedInput
          type="number"
          value={value}
          name={name}
          onChange={handleChange}
          inputProps={{ min: 0 }}
          classes={{ root: styleInput }}
          disabled={input.disabled}
        />
      </FormControl>
    )}
    {input.type === 'text' && (
      <FormControl size="small" variant="outlined" fullWidth={fullWidth}>
        <InputLabel className={styleLabel}>{input.label}</InputLabel>
        <OutlinedInput
          classes={{ root: styleInput }}
          disabled={input.disabled}
          type="text"
          value={value || ''}
          name={name}
          onChange={handleChange}
        />
      </FormControl>
    )}
    {input.type === 'autocomplete' && (
      <AutoCompleteCmp
        options={input[input.listName]}
        onChange={handleChange}
        label={name}
        name={name}
        value={value || null}
        getOptionLabel={(option) => input.optionLabel(option)}
        filterOptions={(options, v) => options.filter(
          (option) => input.filter(option, v.inputValue.toLowerCase()),
        )}
        styleInput={styleInput}
        styleLabel={styleLabel}
        disabled={input.disabled}
        style={{ width: 210 }}
      />
    )}
    {input.type === 'file' && (
      <>
        <Button variant="contained" component="label" style={{ marginRight: 10 }}>
          Upload File
          <input
            type="file"
            hidden
            /// value={value?.name}
            name={name}
            onChange={handleChange}
          />
        </Button>
        {value?.name}
      </>
    )}
    {input.type === 'date' && (
      <DateTimePicker
        disabled={input.disabled}
        fullWidth={fullWidth}
        size="small"
        label={input.label}
        inputVariant="outlined"
        value={value}
        name={name}
        onChange={handleChange}
      />
    )}
    {input.type === 'date-no-time' && (
      <DatePicker
        clearable
        disabled={input.disabled}
        fullWidth={fullWidth}
        size="small"
        label={input.label}
        inputVariant="outlined"
        value={value}
        name={name}
        format="dd/MM/yyyy"
        onChange={handleChange}
        views={['year', 'month', 'date']}
      />
    )}
    {input.type === 'time' && (
      <FormControl size="small" variant="outlined" fullWidth={fullWidth}>
        <InputLabel className={styleLabel}>{input.label}</InputLabel>
        <OutlinedInput
          classes={{ root: styleInput }}
          disabled={input.disabled}
          type="time"
          value={value || ''}
          name={name}
          onChange={handleChange}
        />
      </FormControl>
    )}
  </>
);

export default RenderInput;
