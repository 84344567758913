import React, { useRef } from 'react';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

import {
  Popper,
  Fade,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import useOnClickOutside from 'Shared/Hooks/useClickOutSide';
import { useStylesActions } from './style';

const ActionsProduct = ({
  isOpen, onHandleDetail, onHandleEdit, onHandleDelete, onClose, anchorEl,
}) => {
  const classes = useStylesActions();
  const refActions = useRef();
  useOnClickOutside(refActions, (e) => onClose({})(e));

  return (
    <Popper
      sx={{ zIndex: 1200 }}
      open={isOpen}
      anchorEl={anchorEl}
      placement="bottom-end"
      transition
      ref={refActions}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <Box onClick={onHandleDetail} className={classes.action}>
              <RemoveRedEyeOutlinedIcon />
              <Typography className={classes.actionLabel}>Consulter</Typography>
            </Box>
            <Box onClick={onHandleEdit} className={classes.action}>
              <EditOutlinedIcon />
              <Typography className={classes.actionLabel}>Modifier</Typography>
            </Box>
            <Box className={classes.action} sx={{ color: '#F86060' }} onClick={onHandleDelete}>
              <DeleteForeverOutlinedIcon />
              <Typography className={classes.actionLabel} style={{ color: '#F86060' }}>
                Supprimer
              </Typography>
            </Box>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default ActionsProduct;
