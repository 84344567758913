import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';
import useForm from 'hooks/useForm';
import useStyles from './styles';

const EditDeliveryDays = ({ supplier, handleClose, handleSend }) => {
  const classes = useStyles();
  const { values, onChange } = useForm({ deliveryDays: supplier?.deliveryDays });

  const onSubmit = () => {
    handleSend(values.deliveryDays);
  };

  return (
    <Dialog
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      open
      classes={{ scrollPaper: classes.scrollPaper }}
      PaperProps={{
        style: {
          background: '#F9FAFC 0% 0% no-repeat padding-box',
          border: '1px solid #C5C5C5',
          borderRadius: 12,
          top: 50,
        },
      }}
      BackdropProps={{
        style: {
          background: '#245283 0% 0% no-repeat padding-box',
          boxShadow: '0px 12px 26px #0000000D',
          opacity: 0.67,
        },
      }}
    >
      <DialogContent>
        <Typography
          style={{
            font: 'normal normal 600 18px/22px Montserrat',
            color: '#007BC6',
            textTransform: 'uppercase',
          }}
        >
          DELAI DE LIVRAISON
        </Typography>
        <Grid container spacing={2} style={{ marginTop: 46, marginBottom: 46 }}>
          <Grid item xs={2} />
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel className={classes.label}>DELAI DE LIVRAISON</InputLabel>
              <OutlinedInput
                value={values.deliveryDays}
                name="deliveryDays"
                type="number"
                onChange={onChange}
                labelWidth={60}
                classes={{
                  root: classes.rootInput,
                  input: classes.input,
                  notchedOutline: classes.notchedOutline,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          autoFocus
          variant="contained"
          endIcon={<SaveIcon />}
          style={{
            background: 'transparent linear-gradient(248deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
            boxShadow: '0px 4px 10px #108CD650',
            borderRadius: 12,
          }}
        >
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDeliveryDays;
