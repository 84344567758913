import { format } from 'date-fns';
import React, {
  useCallback, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import AttachInvoicesPayment from '../AttachInvoicesPayment';
import { defaultMessage, getPaymentDocuments } from '../PaymentServices';
import { getDateWithoutTime } from '../Utils';
import { columns, PAYEMENT_STATUSES } from './constants';
import Filters from './Filters';

const PaymentDocuments = ({ values, handleInputChange }) => {
  const [loading, setLoading] = useState(false);
  const [paymentDocuments, setPaymentDocuments] = useState({ content: [] });
  const [selectedPayment, setSelectedPayment] = useState(null);
  const getPaymentList = useCallback(async (sort) => {
    try {
      setLoading(true);
      const {
        startDate, endDate, echeanceStartDate, echeanceEndDate, ...rest
      } = values;
      const additionalFields = Object.keys(rest).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: (rest[curr]?.code ? rest[curr]?.code : rest[curr]) || undefined,
        }), {},
      );
      const data = await getPaymentDocuments(sort,
        {
          startDate: startDate ? format(getDateWithoutTime(startDate), "yyyy-MM-dd'T'HH:mm:sszzzz") : undefined,
          endDate: endDate ? format(getDateWithoutTime(endDate), "yyyy-MM-dd'T'HH:mm:sszzzz") : undefined,
          echeanceStartDate: echeanceStartDate ? format(getDateWithoutTime(echeanceStartDate), "yyyy-MM-dd'T'HH:mm:sszzzz") : undefined,
          echeanceEndDate: echeanceEndDate ? format(getDateWithoutTime(echeanceEndDate), "yyyy-MM-dd'T'HH:mm:sszzzz") : undefined,
          ...additionalFields,
        });
      setPaymentDocuments(data);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setPaymentDocuments, setLoading, values]);
  const selectPayment = useCallback((payment) => () => {
    setSelectedPayment(payment);
  }, [setSelectedPayment]);
  const paymentDocs = useMemo(
    () => paymentDocuments.content
      .map((p) => {
        const formattedData = {
          ...p,
          date: p.receptionDate,
          clientCode: p.client?.code,
          clientName: p.client?.name,
          statusLabel: PAYEMENT_STATUSES.find((r) => r.code === p.status)?.label,
        };
        return { ...formattedData, click: selectPayment(formattedData) };
      }),
    [paymentDocuments.content],
  );
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Filters values={values} handleInputChange={handleInputChange} />
      {selectedPayment && (
      <AttachInvoicesPayment
        payment={selectedPayment}
        handleClose={selectPayment(null)}
      />
      )}
      <EnhancedTable
        getData={getPaymentList}
        rows={paymentDocs}
        headCells={columns}
        count={paymentDocuments.totalElements}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </>
  );
};

export default PaymentDocuments;
