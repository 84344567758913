import { SAVE_RELTAD_LIST } from './actions';

const ExternSearchReducer = (state, action) => {
  switch (action.type) {
    case SAVE_RELTAD_LIST:
      return { ...state, relatedList: action.data };
    default:
      throw new Error();
  }
};

export default ExternSearchReducer;
