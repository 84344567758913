import {
  Box, Button, Paper, Tab, Tabs,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import UserContext from 'Shared/UserContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import BlockedOrders from '../BlockedOrders';
import Orders from '../Orders';
import OrdersControl from '../OrdersControl';
import useStyles from './style';
import TransformationBL from '../TransformationBL/TransformationBL';

const RecoveryListTabs = () => {
  const { state } = useLocation();
  const {
    state: { user },
  } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(state ? state.tab : 1);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const isCommercialOrSales = ['COMMERCIAL', 'SALES'].includes(user.role);
  const isSAV = ['SAV'].includes(user.role);

  const classes = useStyles();
  return (
    <Paper elevation={0} classes={{ root: classes.paper }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tabs
          value={tab}
          sx={{ flexGrow: 1 }}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
        >
          {!isCommercialOrSales && (
            <Tab
              classes={{
                root: classes.tab,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              label="Commandes"
              value={1}
            />
          )}
          {!isCommercialOrSales && (
            <Tab
              classes={{
                root: classes.tab,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              label="Contrôles"
              value={3}
            />
          )}
          {!isCommercialOrSales && !isSAV && (
            <Tab
              classes={{
                root: classes.tab,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              label="Commandes bloquées"
              value={2}
            />
          )}
        </Tabs>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          sx={{
            boxShadow: '0px 4px 10px #108CD650',
            borderRadius: '12px',
            font: 'normal normal 600 12px Montserrat',
            color: '#FFFFFF',
            textTransform: 'none',
          }}
        >
          Transformer documents
        </Button>
      </Box>
      {tab === 1 && !isCommercialOrSales && <Orders />}
      {tab === 3 && !isCommercialOrSales && <OrdersControl />}
      {tab === 2 && !isCommercialOrSales && !isSAV && <BlockedOrders />}
      {open && <TransformationBL handleClose={handleClose} />}
    </Paper>
  );
};

export default RecoveryListTabs;
