import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Input from 'Shared/Input/v2';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import SwitchCmp from 'Shared/SwitchCmp';
import { getSuppliers } from 'Components/ProductSettings/ProductSettingsServices';
import useDebounce from 'Shared/Hooks/useDebounce';
import InputFile from 'Shared/InputFile/V2';
import {
  inputSuppliers, inputsA, inputsB, inputsC,
} from '../constants';
import FormBox from './FormBox';
import Suppliers from './Suppliers';

const NewArticle = ({
  article, onChange, families, brands, isEditMode = false,
}) => {
  const [suppliers, setSuppliers] = useState([]);
  const deboucedSupplier = useDebounce(article.searchBy, 500);

  const searchSuppliers = useCallback(async () => {
    const response = await getSuppliers({ page: 0, size: 100 }, { searchBy: deboucedSupplier || '' });
    setSuppliers(response?.content || []);
  }, [deboucedSupplier]);

  useEffect(() => {
    searchSuppliers();
  }, [deboucedSupplier]);

  const onChangeSelect = (name) => (event, value) => {
    onChange(value ? { target: { name, value } } : event);
  };
  const onActivateSupplier = () => {
    onChange({ target: { name: 'openSupplierSearch', value: !article.openSupplierSearch } });
    onChange({ target: { name: 'devisSupplier', value: null } });
    onChange({ target: { name: 'refSupplier', value: null } });
    onChange({ target: { name: 'priceSupplier', value: null } });
    onChange({ target: { name: 'supplier', value: null } });
    onChange({ target: { name: 'searchBy', value: null } });
  };
  const onAddSupplier = () => {
    if (!article.supplier
      || !article.refSupplier || !article.priceSupplier || !article.devisSupplier) {
      return;
    }
    const targetSuppliers = article.suppliers
      .filter((supplier) => article.supplier.id !== supplier.id);
    const referenceFournisseurs = {
      prix: article.priceSupplier,
      devise: article.devisSupplier.name,
      reference: article.refSupplier,
    };
    onChange({
      target: {
        name: 'suppliers',
        value: [...targetSuppliers, { ...article.supplier, referenceFournisseurs }],
      },
    });
    onActivateSupplier();
  };
  const onSearchSupplier = (event, value) => {
    onChange({ target: { name: 'searchBy', value } });
  };
  const onHandleRemoveSupplier = (id) => () => {
    const newSuppliers = article.suppliers.filter((supplier) => supplier.id !== id);
    onChange({ target: { name: 'openSupplierSearch', value: !article.openSupplierSearch } });
    onChange({ target: { name: 'suppliers', value: newSuppliers } });
  };
  const onHandleEditSupplier = (id) => () => {
    const supplier = article.suppliers.find((s) => s.id === id);
    onChange({ target: { name: 'openSupplierSearch', value: !article.openSupplierSearch } });
    onChange({ target: { name: 'supplier', value: supplier } });
    onChange({ target: { name: 'refSupplier', value: supplier.referenceFournisseurs.reference } });
    onChange({ target: { name: 'priceSupplier', value: supplier.referenceFournisseurs.prix } });
    onChange({ target: { name: 'devisSupplier', value: { name: supplier.referenceFournisseurs.devise } } });
    // onActivateSupplier();
  };
  return (
    <Box style={{ display: 'flex', flexDirection: 'row' }}>
      <Box style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
        <FormBox title="Information A" isTop>
          {inputsA.map((input) => (
            input.type === 'select' ? (
              <AutoCompleteCmp
                options={families || []}
                onChange={onChangeSelect(input.name)}
                value={article[input.name]}
                label={input.label}
                isPrimaryTheme
                style={{
                  width: '190px', marginTop: 8, marginBottom: 4, display: 'inline-flex',
                }}
              />
            ) : (
              <Input
                value={article[input.name]}
                onChange={onChange}
                margin="dense"
                style={{ width: '190px' }}
                label={input.label}
                name={input.name}
                disabled={isEditMode && input.name === 'reference'}
              />
            )
          ))}
        </FormBox>
        <FormBox title="Information B">
          {inputsB.map((input) => (
            input.type === 'select' ? (
              <AutoCompleteCmp
                options={brands || []}
                onChange={onChangeSelect(input.name)}
                value={article[input.name]}
                label={input.label}
                isPrimaryTheme
                style={{
                  width: '190px', marginTop: 8, marginBottom: 4, display: 'inline-flex',
                }}
              />
            ) : (
              <Input
                value={article[input.name]}
                onChange={onChange}
                margin="dense"
                style={{ width: '190px' }}
                label={input.label}
                name={input.name}
              />
            )
          ))}
        </FormBox>
        <InputFile
          name="image"
          label="Faites glisser et déposez la photo ou parcourez pour choisir un fichier"
          value={isEditMode && !article.image ? article.img : article.image?.name}
          onChange={onChange}
        />
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
        <FormBox title="Info douaniere" isTop>
          {inputsC.map((input) => (
            input.type === 'check' ? (
              <Box style={{ display: 'inline-flex', alignItems: 'center', marginTop: 18 }}>
                <Typography style={{ font: 'normal bold 14px Montserrat', color: '#007BC6', marginRight: 5 }}>
                  {input.label}
                </Typography>
                <SwitchCmp
                  checked={article[input.name]}
                  onChange={onChange}
                  name={input.name}
                />
              </Box>
            ) : (
              <Input
                value={article[input.name]}
                onChange={onChange}
                margin="dense"
                style={{ width: '190px' }}
                label={input.label}
                name={input.name}
              />
            )
          ))}
        </FormBox>
        <FormBox
          title="Reference Fournisseur"
          action="Ajouter"
          handleAction={onActivateSupplier}
          handleAdd={onAddSupplier}
          isSearchOpen={article.openSupplierSearch}
        >
          <Box style={{ marginTop: 8 }}>
            <Box style={{ display: 'flex' }}>
              {article.openSupplierSearch ? (
                inputSuppliers.map((input) => (
                  input.type === 'select' ? (
                    <AutoCompleteCmp
                      options={input.options || suppliers || []}
                      onChange={onChangeSelect(input.name)}
                      onInputChange={input.name === 'supplier' && onSearchSupplier}
                      value={article[input.name]}
                      label={input.label}
                      isPrimaryTheme
                      style={{
                        width: input.name === 'supplier' ? '100px' : '50px',
                        marginTop: 8,
                        marginBottom: 4,
                        marginRight: input.name === 'supplier' ? 5 : 0,
                        display: 'inline-flex',
                      }}
                    />
                  ) : (
                    <Input
                      value={article[input.name]}
                      onChange={onChange}
                      margin="dense"
                      style={{ width: '100px', marginRight: 5 }}
                      label={input.label}
                      name={input.name}
                    />
                  )
                ))
              ) : null }
            </Box>
            <Suppliers
              suppliers={article.suppliers}
              onHandleRemoveSupplier={onHandleRemoveSupplier}
              onHandleEditSupplier={onHandleEditSupplier}
              isSearch={article.openSupplierSearch}
            />
          </Box>
        </FormBox>
      </Box>
    </Box>
  );
};

export default NewArticle;
