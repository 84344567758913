import React from 'react';
import { makeStyles, styled } from '@mui/styles';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  container: {
    backgroundColor: '#e9ebed',
    padding: 10,
    borderRadius: 8,
    opacity: 1,
    height: 52,
    boxSizing: 'border-box',
  },
  label: {
    marginLeft: theme.spacing(2),
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  activeArrow: {
    background: '#007BC6 0% 0% no-repeat padding-box',
    borderRadius: 12,
    color: '#FFF',
    padding: 4,
  },
  arrow: {
    backgroundColor: '#FFFFFF !important',
    borderRadius: 12,
    color: '#000 !important',
    padding: 4,
  },
}));

const StyledSelect = styled(Select)({
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  borderRadius: 12,
  opacity: 1,
  height: '34px',
  border: 'none',
  '&:focus': {
    backgroundColor: '#fff',
    border: 'none',
  },
  '& .MuiSelect-icon': {
    color: '#000',
  },
  '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-selectMenu': {
    font: 'normal normal 600 14px Montserrat',
  },
});
function CustomTablePagination(props) {
  const classes = useStyles();
  const {
    count, rowsPerPage, page, onChangePage, onChangeRowsPerPage, rowsPerPageOptions,
  } = props;

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: 10 }}>
        <Box className={classes.container} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="caption" className={classes.label} style={{ marginRight: 5 }}>
            Rows per page:
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel />
            <StyledSelect
              value={rowsPerPage}
              onChange={onChangeRowsPerPage}
              label=""
              size="small"
              IconComponent={ExpandMoreIcon}
            >
              {rowsPerPageOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Box>
        <Box className={classes.container}>
          <Typography variant="caption" className={classes.label} style={{ marginRight: 5 }}>
            {`1-${rowsPerPage * (page + 1)} of ${count}`}
          </Typography>
          <IconButton
            style={{ marginRight: 5 }}
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
            className={page === 0 ? classes.arrow : classes.activeArrow}
            size="large"
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
            className={
              page >= Math.ceil(count / rowsPerPage) - 1 ? classes.arrow : classes.activeArrow
            }
            size="large"
          >
            <KeyboardArrowRight />
          </IconButton>
        </Box>
      </div>
    </div>
  );
}

export default CustomTablePagination;
