import api, { secureGet, securePost } from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const getClients = async (sortParams, filters = {}) => {
  let url = `/ci/api/GetPagedClients?page=${sortParams.page}&size=${sortParams.size}${
    sortParams.sort ? `&sort=${sortParams.sort}` : ''
  }`;
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      url += Array.isArray(filters[key])
        ? filters[key].reduce((prev, curr) => `${prev}&${key}=${curr}`, '')
        : `&${key}=${filters[key]}`;
    }
  });
  const { data } = await secureGet(url);
  return data;
};
export const editCLient = async (client) => {
  const url = '/ci/api/EditClient';
  const { data } = await securePost(url, client);
  return data;
};
export const getCommerciaux = async () => {
  const url = '/ci/api/GetCommerciaux';
  const { data } = await securePost(url);
  return data;
};
export const getBlockedOrders = async () => {
  const { data } = await securePost('/ci/api/GetPendingTransactionHistory');
  return data;
};
export const getAllBlockedOrders = async () => {
  const { data } = await secureGet('/ci/api/GetAllPendingTransactionHistory');
  return data;
};
export const getOrders = async (sortParams, filters = {}) => {
  let url = `/ci/api/GetOrderHistorybyCriteria?page=${sortParams.page}&size=${sortParams.size}${
    sortParams.sort ? `&sort=${sortParams.sort}` : ''
  }`;
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      url += Array.isArray(filters[key])
        ? filters[key].reduce((prev, curr) => `${prev}&${key}=${curr}`, '')
        : `&${key}=${filters[key]}`;
    }
  });
  const { data } = await secureGet(url);
  return data;
};
export const addCLient = async (client) => {
  const url = '/ci/api/AddClient';
  const { data } = await securePost(url, { ...client, id: null });
  return data;
};
export const addOrder = async (order) => {
  const body = {
    city: order.city,
    clientCode: order.client_code,
    comment: order.comment,
    expedition: order.expedition,
    paymentMode: order.paymentMode,
    inventory: order.inventory,
    souche: order.souche,
    channel: 'DESKTOP',
    uuid: Date.now(),
    products: JSON.stringify(
      order.commande.map((a) => ({
        productId: a.productId,
        quantity: parseInt(a.quantity, 10),
      })),
    ),
    id: order.id,
  };
  const { data } = await securePost(
    '/ci/api/AddTransactionSpecialDesktop',
    body,
  );
  return data;
};
export const downloadBL = async (id, bl) => {
  const url = `${bl ? '/ci/api/getBl/' : '/ci/api/getFacture/'}${id}`;
  const { data } = await api.post(`${url}?token=${localStorage.getItem('token')}`, null, {
    headers: {
      accept: 'application/pdf',
    },
    responseType: 'blob',
  });
  const file = new Blob([data], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = id;
  link.click();
  link.remove();
  return data;
};
