/* eslint-disable react/jsx-no-duplicate-props */
import {
  Box, Button, TextField, Typography, Autocomplete,
} from '@mui/material';
import React, {
  useCallback, useContext, useEffect, useState, useMemo,
} from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import axios from 'axios';
import ReferencialContext from 'Shared/ReferencialContext';
import useForm from 'Shared/Hooks/useForm';
import ConnectedStore from 'Shared/ConnectedStore';
import { useHistory } from 'react-router-dom';
import useStyles from './style';
import { INIT_FILTERS, SearchList } from './constants';
import Model from './Model';
import {
  defaultMessage,
  getArticles,
  getVehicules,
  getArticlesByCarFamily,
} from './ExternSearchServices';
import NodataMessage from './NodataMessage';
import ResultArea from './ResultArea';
import ExternSearchReducer from './reducer';
import ExternSearchContext, { Provider, initialState } from './context';

const copimaFilterNames = ['copimaFamily', 'copimaBrand'];
const tecdocFilterNames = ['tecdocFamily', 'tecdocBrand'];
let cancelToken;
const ExternSearch = ({ location }) => {
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [articles, setArticles] = useState(null);
  const [articlesData, setArticlesData] = useState([]);
  const [filtredTecDocfamilies, setFiltredTecDocFamilies] = useState([]);
  const [filtredTecDocBrands, setFiltredTecDocBrands] = useState([]);
  const [vehicules, setVehicules] = useState([]);
  const [resetIndex, setResetIndex] = useState(false);
  const [isNoArticle, setIsNoArticle] = useState(false);
  const [isNoProduct, setIsNoProduct] = useState(false);
  const [page, setPage] = useState(1);
  const [size] = useState(10);
  const [productsCopima, setProductsCopima] = useState(null);
  const { values, onChange, setValues } = useForm(INIT_FILTERS);
  const { state: { relatedList: tecDocRelatedList } = {} } = useContext(ExternSearchContext);

  const [refArticleFamilies, setRefArticleFamilies] = useState(null);
  const classes = useStyles();
  const {
    state: { referencial },
  } = useContext(ReferencialContext);
  const { brands, families } = referencial;

  const history = useHistory();
  const [copimaFamilies, setCopimaFamilies] = useState(families);
  const [copimaBrands, setCopimaBrands] = useState(brands);

  const getArticleData = useCallback(
    async (tecdocFamily, tecdocBrand, append = false, p = 1) => {
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Cancel');
      }
      cancelToken = axios.CancelToken.source();
      if ((search && search.length >= 2) || tecdocFamily || tecdocBrand) {
        setLoading(true);
        if (!append) {
          setArticles([]);
        }
        setRefArticleFamilies(null);
        const carId = location.search.length > 4
          ? location.search.replace('?', '').split('&')[0].split('=')[1]
          : null;
        const familyCarId = location.search.length > 4
          ? location.search.replace('?', '').split('&')[1].split('=')[1]
          : null;
        try {
          const data = await getArticles(
            search,
            tecdocFamily?.genericArticleId,
            tecdocBrand?.dataSupplierId,
            { cancelToken: cancelToken.token },
            0,
            size,
            p,
            carId,
            familyCarId,
          );
          const articleList = !append
            ? data?.articles || []
            : [...articles, ...(data?.articles || [])];
          if (!append) {
            setResetIndex((prev) => !prev);
            setPage(1);
          }
          setArticles(articleList);
          setArticlesData(data);
          if (location.search.replace('?', '').length < 2) {
            setFiltredTecDocBrands(data?.dataSupplierFacets?.counts);
            setFiltredTecDocFamilies(data.genericArticleFacets?.counts);
          }
          setIsNoArticle(articleList.length === 0);
        } catch (e) {
          if (e.message !== 'Cancel') {
            const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
            toast.error(statusLabel);
          }
        } finally {
          setLoading(false);
        }
      }
    },
    [
      search,
      setLoading,
      setArticles,
      articles,
      values,
      setFiltredTecDocBrands,
      page,
      size,
      setResetIndex,
    ],
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (search.length > 0) {
      if (queryParams.has('carId')) {
        // setError('There was a problem.');
        queryParams.delete('carId');
        history.replace({
          search: queryParams.toString(),
        });
      }
      if (queryParams.has('familyId')) {
        // setError('There was a problem.');
        queryParams.delete('familyId');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
  }, [search]);

  const handleChangeSelect = (event) => {
    let extra = {};
    if (event.target.name === tecdocFilterNames[0] && !event.target.value) {
      extra = { [tecdocFilterNames[1]]: null };
      onChange({ target: { name: tecdocFilterNames[1], value: null } });
    }
    const newValues = { ...values, [event.target.name]: event.target.value, ...extra };
    getArticleData(newValues.tecdocFamily, newValues.tecdocBrand);
    onChange(event);
  };

  const nextPage = (last) => {
    if (last && articles.length < articlesData.totalMatchingArticles) {
      getArticleData(values.tecdocFamily, values.tecdocBrand, true, page + 1);
      setPage((prev) => prev + 1);
    }
  };
  const isNextPage = (last) => {
    if (articles.length < articlesData.totalMatchingArticles) {
      return false;
    }
    return last;
  };
  const getArticleCarFamilyData = useCallback(
    async (carFamily) => {
      setLoading(true);
      try {
        const data = await getArticlesByCarFamily(carFamily);
        const articleList = data?.articles || [];
        setResetIndex((prev) => !prev);
        setPage(1);
        setArticles(articleList);
        const articleNumbers = articleList.map((a) => a.articleNumber);
        setProductsCopima(
          // eslint-disable-next-line max-len
          referencial?.products.filter((p) => articleNumbers.includes(p.refT)),
        );
        setArticlesData(data);
        setFiltredTecDocBrands(data?.dataSupplierFacets?.counts);
        setFiltredTecDocFamilies(data.genericArticleFacets?.counts);
        setIsNoArticle(articleList.length === 0);
        setRefArticleFamilies(articleList.map((item) => item.articleNumber));
      } catch (e) {
        if (e.message !== 'Cancel') {
          const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
          toast.error(statusLabel);
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setArticles, setProductsCopima, referencial?.products],
  );
  const getVehiculesData = useCallback(async () => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('Cancel');
    }
    cancelToken = axios.CancelToken.source();
    if (search && search.length >= 2) {
      setLoading(true);
      try {
        const vehiculeList = await getVehicules(search, { cancelToken: cancelToken.token });
        setVehicules(vehiculeList);
        // setIsNoData(vehiculeList.length === 0);
      } catch (e) {
        if (e.message !== 'Cancel') {
          const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
          toast.error(statusLabel);
        }
      } finally {
        setLoading(false);
      }
    }
  }, [search, setLoading, setVehicules]);

  const handleChange = (event) => {
    setSearch(event.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (type?.value === 'reference') {
        getArticleData();
      }
      if (type?.value === 'vin') {
        getVehiculesData();
      }
      if (search !== '') {
        const checkFilters = (pro) => {
          const isBrandMatch = values.copimaBrand === null || pro.bId === values.copimaBrand.id;
          const isFamilyMatch = values.copimaFamily === null || pro.fId === values.copimaFamily.id;
          return isBrandMatch && isFamilyMatch;
        };
        const pos = referencial?.products?.filter(
          // eslint-disable-next-line max-len
          (p) => p.bId !== 'EN SOMMEIL'
            && (p.refT?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
              || p.reference?.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
            && checkFilters(p),
        );
        setProductsCopima(pos);
        if (pos) {
          const familyIds = pos.map((a) => a.fId).unique();
          const brandIds = pos.map((a) => a.bId).unique();
          setCopimaFamilies(families.filter((f) => familyIds.includes(f.id)));
          setCopimaBrands(brands.filter((f) => brandIds.includes(f.id)));
        }
        setIsNoProduct(pos === null || pos?.length === 0);
        if (!event.reset) {
          setValues(INIT_FILTERS);
        }
      }
    }
  };
  const handleSearchClick = (reset) => {
    handleKeyDown({ key: 'Enter', reset });
  };
  useEffect(() => {
    handleSearchClick(true);
  }, [values.copimaFamily, values.copimaBrand]);

  const relatedProducts = useMemo(() => {
    let productsResult = [];
    if (refArticleFamilies && !location.search) {
      productsResult = referencial.products
        ?.filter((p) => refArticleFamilies.includes(p.reference)).unique();
    } else if (productsCopima?.length > 0) {
      const productsCopimaEqs = productsCopima
        .map((po) => po.e)
        .filter((eq) => !!eq)
        .join(';');
      const productsCopimaRefs = productsCopima.map((po) => po.reference);
      if (productsCopimaRefs?.length > 0) {
        const { products } = referencial;
        const relatedProductsN1 = products?.filter((po) => productsCopimaEqs.split(';').includes(po.reference));
        const relatedProductRefs = relatedProductsN1?.reduce(
          (previous, current) => previous.concat(current.e ? current.e.split(';') : []).unique(),
          productsCopimaEqs.split(';'),
        );

        productsResult = products
          ?.filter(
            // eslint-disable-next-line max-len
            (p) => !productsCopimaRefs.includes(p.reference) && relatedProductRefs.includes(p.reference),
          )
          .unique();
      }
    }
    if (tecDocRelatedList?.length > 0) {
      const { products } = referencial;
      return [...productsResult, ...products?.filter((p) => tecDocRelatedList.includes(p.refT) || tecDocRelatedList.map((a) => a.replaceAll(' ', '')).includes(p.refT))]
        .unique();
    }
    return productsResult;
  }, [
    referencial.products, productsCopima, refArticleFamilies, location.search, tecDocRelatedList,
  ]);
  const onChangeSearchType = (value) => {
    setType(value);
    setSearch('');
  };
  useEffect(() => {
    /* const carId = query.get('carId');
    const familyId = query.get('familyId'); */
    if (location.search && referencial?.products /* carId !== null && familyId !== null */) {
      // getArticleCarFamilyData(`carId=${carId}&familyId=${familyId}`);
      getArticleCarFamilyData(location.search.replace('?', ''));
      onChangeSearchType(SearchList[1]);
    }
  }, [location.search, referencial?.products]);
  const isNoData = !loading && isNoArticle && isNoProduct;
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <div style={{ height: '100vh', overflowY: 'auto' }}>
        <Box style={{ padding: '30px 15px' }}>
          <Typography
            display="block"
            style={{
              font: 'normal normal 600 26px Montserrat',
              color: '#000000',
            }}
          >
            Recherche générale
          </Typography>
          <Typography
            display="block"
            style={{
              font: 'normal normal 500 20px/24px Montserrat',
              paddingTop: 8,
              paddingBottom: 18,
              color: '#000000',
              textTransform: 'capitalize',
            }}
          >
            Utilisez La Recherche générale Pour Obtenir Plus D’informations Sur Les Produits
          </Typography>

          <Box className={classes.filterZone}>
            <Autocomplete
              classes={{ root: classes.filter }}
              options={SearchList}
              onChange={(event, value) => onChangeSearchType(value)}
              size="small"
              autoHighlight
              getOptionLabel={(option) => option.label}
              // eslint-disable-next-line max-len
              filterOptions={(options, v) => options.filter((option) => option.label?.toLowerCase().includes(v.inputValue.toLowerCase()))}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Type de recherche"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    readOnly: true,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    classes: { notchedOutline: classes.notched, input: classes.input },
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                  }}
                  value=""
                />
              )}
              popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
            />
            <TextField
              classes={{ root: classes.filter }}
              style={{ flexGrow: 1 }}
              placeholder={`Chercher par ${type ? type?.label : ''}`}
              variant="outlined"
              size="small"
              value={search}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              inputProps={{
                autoComplete: 'new-password', // disable autocomplete and autofill,
                maxLength: 17,
              }}
              disabled={!type}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                classes: { notchedOutline: classes.notched, input: classes.input },
                startAdornment: <SearchIcon style={{ color: '#AFB0B2' }} />,
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
            <Button
              disabled={search === ''}
              onClick={() => handleSearchClick(false)}
              variant="contained"
              color="inherit"
              className={classes.searchBtn}
              endIcon={<SearchIcon />}
            >
              RECHERCHE
            </Button>
          </Box>
          {type?.value === 'reference' && (
            <>
              {isNoData && <NodataMessage />}
              {!isNoData && (productsCopima || values.copimaFamily || values.copimaBrand) && (
                <ResultArea
                  search={search}
                  relatedProducts={relatedProducts}
                  products={productsCopima}
                  classes={classes}
                  filters={values}
                  onChangeFilter={onChange}
                  filterNames={copimaFilterNames}
                  primaryOptions={copimaFamilies}
                  secondaryOptions={copimaBrands}
                />
              )}
              {!isNoData && (articles || values.tecdocFamily || values.tecdocBrand) && (
                <ResultArea
                  articles={articles}
                  search={search}
                  classes={classes}
                  filters={values}
                  onChangeFilter={handleChangeSelect}
                  filterNames={tecdocFilterNames}
                  isTecdoc
                  primaryOptions={filtredTecDocfamilies}
                  secondaryOptions={filtredTecDocBrands}
                  nextPage={nextPage}
                  isNextPage={isNextPage}
                  resetIndex={resetIndex}
                />
              )}
            </>
          )}
          {type?.value === 'vin' && (
            <>
              <Typography
                style={{
                  marginTop: 33,
                  marginBottom: 25,
                  font: 'normal normal 600 26px Montserrat',
                  color: '#000000',
                }}
              >
                Choisissez un modèle
              </Typography>
              <Box style={{ display: 'flex', gap: 10 }}>
                {vehicules.map((v) => (
                  <Model
                    key={v.carId}
                    carId={v.carId}
                    name={v.carName}
                    vin={search}
                    type={v.fuelType}
                    img={v.vehiculeImage}
                    manuId={v.manuId}
                    modelId={v.modelId}
                    beginYear={v.beginYearMonth}
                    fuelType={v.fuelType}
                    engineType={v.engineType}
                    engineCode={v.engineCode}
                  />
                ))}
              </Box>
            </>
          )}
        </Box>
      </div>
    </>
  );
};

export default ConnectedStore(ExternSearch, ExternSearchReducer, initialState, Provider);
