import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    borderRadius: '12px',
    padding: '10px 14px',
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  name: {
    font: 'normal 600 14px Montserrat',
    color: '#38485C',
  },
  price: {
    font: 'normal medium 14px Montserrat',
    color: '#0D7DD4',
  },
  code: {
    font: 'normal 300 14px Montserrat',
    color: '#38485C',
    marginRight: 8,
  },
  subDetails: { display: 'flex', alignItems: 'center' },
}));

export default useStyles;
