import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 10px 20px 25px',
    overflowY: 'auto',
    height: 'calc(100vh - 104px)',
  },
  header: { display: 'flex', justifyContent: 'space-between', marginBottom: 25 },
  filterZone: {
    flexGrow: 1,
    display: 'flex',
    background: '#E9EBED 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    padding: '10px 7px',
    marginBottom: 11,
  },
  dates: {
    display: 'flex', maxWidth: 400, marginTop: 8, marginBottom: 4,
  },
  title: { font: 'normal 600 26px Montserrat', flexGrow: 1 },
}));

export default useStyles;
