import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import TableChartIcon from '@mui/icons-material/TableChart';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import useStyles from './style';
import { defaultMessage, getExportProposition } from '../PropositionServices';

const Item = ({
  onClick, label, icon, color,
}) => {
  const classes = useStyles();
  return (
    <Box onClick={onClick} className={classes.item}>
      {icon}
      <Typography style={{ color, marginLeft: '8px' }}>{label}</Typography>
    </Box>
  );
};

const PropositionActions = ({
  status, setOpenValidation, setOpenRemove, id, name,
}) => {
  const classes = useStyles();
  const onConfirmValidation = (event) => {
    event.stopPropagation();
    setOpenValidation(true);
  };
  const isValidated = status === 'VALIDATED';
  const DownloadProposition = async (event) => {
    event.stopPropagation();
    try {
      await getExportProposition(id, name);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    }
  };
  return (
    <Paper elevation={24}>
      <Box className={classes.paper}>
        <Item
          label="Télécharger Excel"
          icon={<TableChartIcon style={{ color: '#38485C' }} />}
          color="#38485C"
          onClick={DownloadProposition}
        />
        {!isValidated && (
          <Item
            label="Valider"
            onClick={onConfirmValidation}
            icon={<CheckIcon style={{ color: '#00B894' }} />}
            color="#00B894"
          />
        )}
        {!isValidated && (
          <Item
            label="Supprimer La Proposition"
            onClick={setOpenRemove}
            icon={<DeleteIcon style={{ color: '#F86060' }} />}
            color="#F86060"
          />
        )}
      </Box>
    </Paper>
  );
};

export default PropositionActions;
