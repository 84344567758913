/* eslint-disable max-len */
import {
  Box, Popper, TextField, Autocomplete,
} from '@mui/material';
import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import ReferencialContext from 'Shared/ReferencialContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { defaultMessage, getClients } from '../RecoveryServices';
import RecoveryView from '../RecoveryView';
import { columns } from './constants';
import useStyles from './style';

const PopperMy = (props) => (
  <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />
);

const RecoveryList = () => {
  const {
    state: {
      referencial: { clients: clientList },
    },
  } = useContext(ReferencialContext);
  const classes = useStyles();
  // const history = useHistory();
  const [openDetail, setopenDetail] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clientData, setClientData] = useState({ content: [] });
  const [values, setValues] = useState({});
  const handleInputChange = (prop) => (event, value) => {
    setValues({ ...values, [prop]: event instanceof Date ? event : value || event?.target.value });
  };
  const handleClose = () => {
    setopenDetail(false);
  };
  const handleSelectClient = (client) => () => {
    setSelectedClient(client);
    setopenDetail(true);
    // history.push(`/client/${client.code}`);
  };
  const getClientList = useCallback(
    async (sort) => {
      try {
        setLoading(true);
        const data = await getClients(sort, {
          city: values.city,
          code: values?.client?.code,
          zone: values?.zone,
        });
        setClientData(data);
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    },
    [setClientData, setLoading, values],
  );
  const clients = useMemo(
    () => clientData.content.map((client) => ({
      ...client,
      id: client.code,
      click: handleSelectClient(client),
    })),
    [clientData.content, handleSelectClient],
  );
  const cities = useMemo(() => (clientList || []).map((c) => c.city).unique(), [clientList]);
  const zones = useMemo(() => (clientList || []).map((c) => c.zone).unique(), [clientList]);

  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      {openDetail && <RecoveryView handleClose={handleClose} clientCode={selectedClient?.code} />}
      <Box
        style={{
          display: 'flex',
          padding: 10,
          alignItems: 'center',
          background: '#E9EBED 0% 0% no-repeat padding-box',
          bordeRradius: '0px 0px 12px 12px',
          marginBottom: 16,
        }}
      >
        <Autocomplete
          autoSelect
          PopperComponent={PopperMy}
          classes={{ root: classes.filter }}
          options={clientList || []}
          onChange={handleInputChange('client')}
          value={values.client}
          size="small"
          autoHighlight
          getOptionLabel={(option) => (option.code ? `${option.code}: ${option.name}` : '')}
          filterOptions={(options, v) => options.filter(
            (option) => option.name?.toLowerCase().includes(v.inputValue.toLowerCase())
                || option.code?.toLowerCase().startsWith(v.inputValue.toLowerCase()),
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Client"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                ...params.InputProps,
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
          popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
        />
        <Autocomplete
          PopperComponent={PopperMy}
          classes={{ root: classes.filter }}
          options={zones || []}
          onChange={handleInputChange('zone')}
          size="small"
          autoHighlight
          getOptionLabel={(option) => option}
          filterOptions={(options, v) => options.filter((option) => option?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          autoSelect
          renderInput={(params) => (
            <TextField
              {...params}
              label="Zone"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                ...params.InputProps,
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
          popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          value={values.zone}
        />
        <Autocomplete
          PopperComponent={PopperMy}
          classes={{ root: classes.filter }}
          options={cities || []}
          onChange={handleInputChange('city')}
          size="small"
          autoHighlight
          getOptionLabel={(option) => option}
          filterOptions={(options, v) => options.filter((option) => option?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          autoSelect
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ville"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                ...params.InputProps,
                classes: { notchedOutline: classes.notched },
              }}
              InputLabelProps={{
                classes: { root: classes.label },
              }}
            />
          )}
          popupIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
          value={values.city}
        />
      </Box>
      <EnhancedTable
        getData={getClientList}
        rows={clients}
        headCells={columns}
        count={clientData.totalElements}
        rowsPerPageOptions={[20, 30, 40]}
      />
    </>
  );
};

export default RecoveryList;
