import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  color: '#727CA2',
  fontSize: '12px',
  fontWeight: 500,
  background: '#F8F8FF',
  borderBottom: 'none',
  textTransform: 'uppercase',
};
const defaultColumnStyle = { color: '#333D66', fontSize: '14px' };
export const columns = [
  {
    id: 'reference',
    label: 'Reference',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    id: 'date',
    label: 'Date',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ ...defaultColumnStyle, mr: '9px' }} display="inline">
        {value && format(new Date(value), 'dd/MM/yyy')}
      </Typography>
    ),
  },
  {
    id: 'client',
    label: 'Client',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'type',
    label: 'Type',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'paymentMode',
    label: 'Moyen de paiment',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'expedition',
    label: 'Expedition',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'amount',
    label: 'Montant',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'error',
    label: 'Motif de blocage',
    style: TitleStyle,
    render: ({ row }) => <Typography sx={defaultColumnStyle}>{row.error ? row.error : '-'}</Typography>,
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    render: ({ row }) => !row.blocked && (
      <IconButton aria-label="delete" onClick={row.deleteOrder} size="large">
        <DeleteIcon />
      </IconButton>
    ),
  },
];
