export const TYPES = ['Famille', 'Marque', 'Référence'];

export const INIT_FORM = {
  type: TYPES[0],
  minQte: null,
  maxQte: null,
  discount: null,
  price: null,
  brand: null,
  family: null,
  reference: null,
  client: null,
};

export const isValidNewAdd = (form) => {
  const {
    type, minQte, maxQte, brand, family, reference,
  } = form;
  // check qteMin < qteMax
  if (!minQte || !maxQte || minQte > maxQte) {
    return false;
  }
  // is type is TYPE[0] then family is required
  if (type === TYPES[0] && !family) {
    return false;
  }
  // is type is TYPE[1] then brand is required
  if (type === TYPES[1] && !brand) {
    return false;
  }
  // is type is TYPE[2] then reference is required
  if (type === TYPES[2] && !reference) {
    return false;
  }

  return !!type;
};
