import { secureGet, securePost, secureDelete } from 'config/apiConfig';

export const defaultMessage = 'Erreur système';
export const editCLient = async (client) => {
  const url = '/ci/api/EditClient';
  const { data } = await securePost(url, client);
  return data;
};
export const getCLientByCode = async (code) => {
  const url = `/ci/api/GetClientByCode?code=${code}`;
  const { data } = await secureGet(url);
  return data;
};
export const addCLientContact = async (contact) => {
  const url = '/ci/api/Client/Contact';
  const { data } = await securePost(url, contact);
  return data;
};
export const deleteCLientContact = async (contactId) => {
  const url = `/ci/api/Client/Contact?id=${contactId}`;
  const { data } = await secureDelete(url);
  return data;
};

export const addNote = async (body) => {
  const url = '/ci/api/Client/note';
  const { data } = await securePost(url, body);
  return data;
};

export const deleteNote = async (id) => {
  const url = `/ci/api/Client/note/${id}`;
  const { data } = await secureDelete(url);
  return data;
};
