import {
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Tab, Tabs,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';
import EnhancedTable from 'Shared/EnhancedTable';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import {
  attachDocumentsToInvoices,
  defaultMessage,
  detachDocumentsToInvoices,
  getReglementById,
  getUnpaidOrders,
} from '../PaymentServices';
import { openLink } from '../Utils';
import { generateColumns } from './constants';
import useStyles from './style';

const AttachInvoicesPayment = ({ handleClose, payment: propPayment }) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [payment, setPayment] = useState({});
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const [unpaidOrders, setUnpaidOrders] = useState([]);
  const [unpaids] = useState([]);
  const [loading, setLoading] = useState([]);
  const selectInvoice = useCallback((reference) => () => {
    setUnpaidOrders((orders) => orders.map((o) => (o.reference === reference ? {
      ...o,
      selected: !o.selected,
    } : o)));
  }, [setUnpaidOrders]);
  const changeAmount = useCallback((reference) => (event) => {
    setUnpaidOrders((orders) => orders.map((o) => (o.reference === reference ? {
      ...o,
      amountEntered: event.target?.value,
    } : o)));
  }, [setUnpaidOrders]);

  const loadRegelement = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getReglementById(propPayment?.id);
      if (data.statusCode === '999') {
        toast.error(data.statusLabel);
      } else {
        setPayment(data);
      }
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setPayment, propPayment?.id]);
  useEffect(() => {
    loadRegelement();
  }, [loadRegelement]);

  const dettach = useCallback((echeanceId) => async () => {
    try {
      setLoading(true);
      const data = await detachDocumentsToInvoices(echeanceId);
      if (data.statusCode === '999') {
        toast.error(data.statusLabel);
      } else {
        toast.success('la factures a été bien dettachée');
        loadRegelement();
      }
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setLoading, loadRegelement]);
  const getUnpaidOrderlist = useCallback(async () => {
    try {
      setLoading(true);
      const localUnpaidOrders = await getUnpaidOrders(propPayment?.clientCode);
      setUnpaidOrders(localUnpaidOrders);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setUnpaidOrders, propPayment, setLoading]);

  const attach = useCallback((rows) => async () => {
    try {
      setLoading(true);
      const data = await attachDocumentsToInvoices({
        reglementId: propPayment.id,
        details: rows.filter((o) => o.selected).reduce(
          (prev, curr) => [...prev, { orderId: curr.id, amount: parseFloat(curr.amountEntered) }],
          [],
        ),
      });
      if (data.statusCode === '999') {
        toast.error(data.statusLabel);
      } else {
        getUnpaidOrderlist();
        toast.success(rows.length === 1 ? 'la facture a été bien attaché' : 'les factures on été bien attachées');
      }
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setLoading, propPayment, getUnpaidOrderlist]);
  useEffect(() => {
    getUnpaidOrderlist();
  }, [getUnpaidOrderlist]);
  const paidOrders = useMemo(() => payment?.echeances
    ?.map((o) => ({
      amountEntered: o.amount, ...o.commande, id: o.id, dettach: dettach(o.id),
    })),
  [payment?.echeances, dettach]);
  const fullUnpaidOrders = useMemo(() => unpaidOrders
    ?.map((uo) => ({
      ...uo,
      rest: (uo.amount - uo.paidAmount).toFixed(2),
      selected: uo.selected !== undefined ? uo.selected : false,
      amountEntered: uo.amountEntered ? uo.amountEntered : '',
      handleSelectInvoice: selectInvoice(uo.reference),
      handleChangeAmount: changeAmount(uo.reference),
      attach,
    })),
  [unpaidOrders, selectInvoice, changeAmount, attach]);
  const attachActivated = useMemo(() => {
    const selected = fullUnpaidOrders
      .filter((o) => o.selected);
    if (!selected.length) {
      return false;
    }
    return selected.reduce((prev, curr) => prev && !!curr.amountEntered, true);
  }, [fullUnpaidOrders]);

  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <Dialog
        open
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Box style={{ display: 'flex' }}>
            <p style={{ flexGrow: 1 }}>
              Attacher la pièce de règlement du client:
              {' '}
              {payment && payment.clientName}
            </p>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Grid container style={{ height: '70vh' }}>
            <Grid item xs={3} style={{ height: '100%', overflowY: 'auto' }}>
              {payment?.urls?.map((url, index) => (
                <embed
                  src={url}
                  key={url}
                  width="100%"
                  height="auto"
                  onClick={() => openLink(url)}
                  style={{ cursor: 'pointer', marginTop: index === 0 ? 0 : 10 }}
                />
              ))}
            </Grid>
            <Grid item xs={9} style={{ height: '100%' }}>
              <Paper elevation={0} classes={{ root: classes.paper }} style={{ height: '100%' }}>
                <div className={classes.container}>
                  <Tabs
                    value={tab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    classes={{ root: classes.tabs }}
                    TabIndicatorProps={{ style: { backgroundColor: '#2188CC' } }}
                  >
                    <Tab
                      classes={{
                        root: classes.tab,
                        selected: classes.tabSelected,
                        wrapper: classes.tabWrapper,
                      }}
                      label="Factures réglées par la pièce"
                      value={0}
                    />
                    <Tab
                      classes={{
                        root: classes.tab,
                        selected: classes.tabSelected,
                        wrapper: classes.tabWrapper,
                      }}
                      label="Factures non réglées"
                      value={1}
                    />
                    <Tab
                      classes={{
                        root: classes.tab,
                        selected: classes.tabSelected,
                        wrapper: classes.tabWrapper,
                      }}
                      label="les impayés"
                      value={2}
                    />
                  </Tabs>
                </div>
                <div style={{ height: '60vh', width: '100%', overflowY: 'auto' }}>
                  {tab === 0 && (
                  <EnhancedTable
                    staticData
                    rows={paidOrders || []}
                    headCells={generateColumns(true)}
                    count={paidOrders?.length}
                    rowsPerPageOptions={[5, 10, 15]}
                  />
                  )}
                  {tab === 1 && (
                  <EnhancedTable
                    staticData
                    rows={fullUnpaidOrders}
                    headCells={generateColumns(false)}
                    count={fullUnpaidOrders.length}
                    rowsPerPageOptions={[5, 10, 15]}
                  />
                  )}
                  {tab === 2 && (
                  <EnhancedTable
                    staticData
                    rows={unpaids}
                    headCells={generateColumns(false)}
                    count={unpaids.length}
                    rowsPerPageOptions={[5, 10, 15]}
                  />
                  )}
                </div>
              </Paper>

            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Annuler
          </Button>
          {tab === 1 && (
          <Button onClick={attach(unpaidOrders)} variant="contained" color="primary" autoFocus disabled={!attachActivated}>
            Attacher
          </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AttachInvoicesPayment;
