/* eslint-disable import/prefer-default-export */
/* eslint-disable no-extend-native */
/* eslint-disable no-plusplus */
Object.defineProperty(Array.prototype, 'unique', {
  enumerable: false,
  configurable: false,
  writable: false,
  value() {
    const a = this.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }
    return a;
  },
});
export const withoutTime = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d;
};

export const isAuthenticated = () => {
  const accessToken = window.localStorage.getItem('token');
  return accessToken !== null && accessToken !== '';
};

export const getDatesInRange = (startDate, endDate) => {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const addYearsByNumber = (date, years) => {
  date.setFullYear(date.getFullYear() + years);
  return date;
};

export const setIntervalX = (callback, delay, repetitions, finalize) => {
  let x = 0;
  const intervalID = window.setInterval(() => {
    callback();

    if (++x === repetitions) {
      finalize();
      window.clearInterval(intervalID);
    }
  }, delay);
};

export const isEmptyOrNull = (str) => str === undefined || str === null || str.trim().length === 0;

export const getProductStock = (product) => (product.sD || 0) + (product.sB || 0);

export const roundPrice = (price, precision = 100) => Math.round(precision * price) / precision;
