import { Box, Typography } from '@mui/material';
import EnhancedTable from 'Shared/EnhancedTable';
import React from 'react';
import { format } from 'date-fns';
import { roundPrice } from 'Utils/ulilities';
import { columnsDetail } from './constants';

const Item = ({ label, value }) => (
  <Box>
    <Typography sx={{ font: 'normal 600 13px Montserrat', color: '#707070' }}>{label}</Typography>
    <Typography sx={{ font: 'normal 600 16px Montserrat', color: '#38485C' }}>{value}</Typography>
  </Box>
);
const getSellerName = (details = []) => {
  const seller = details[0]?.commercial || {};
  return `${seller.firstName} ${seller.firstName}`;
};
const UnhonoredDetail = ({ order = {} }) => {
  const total = order.details?.reduce((acc, item) => (acc + (item.montantTtc * item.quantity)), 0);
  return (
    <Box>
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', mt: 2, mb: 3,
      }}
      >
        <Item label="DATE" value={order.date ? format(new Date(order.date), 'dd/MM/yyyy') : ''} />
        <Item label="NUMÉRO DE PIÈCE" value={order.pieceBc} />
        <Item label="MODE DE PAIEMENT" value={order.paymentMode?.name} />
        <Item label="MODE EXPEDITION" value={order.expeditionModeChange} />
        <Item label="COMMERCIALE" value={getSellerName(order.details)} />
      </Box>
      <EnhancedTable
        // getData={getList}
        rows={order.details || []}
        headCells={columnsDetail}
        count={order.details?.length}
        staticData
        pagination={false}
      />
      <Box sx={{ float: 'right' }}>
        <Typography sx={{ color: '#007BC6', font: 'normal 600 16px Montserrat' }}>Total NET:</Typography>
        <Typography sx={{ font: 'normal bold 40px Montserrat' }}>{roundPrice(total)}</Typography>
      </Box>
    </Box>
  );
};

export default UnhonoredDetail;
