// eslint-disable-next-line import/prefer-default-export
export const Fields = [
  {
    type: 'autocomplete',
    id: 'type',
    label: 'Type client',
    listName: 'types',
    filter: (option, value) => option?.toLowerCase().includes(value),
    optionLabel: (option) => option,
  },
  {
    type: 'autocomplete',
    id: 'payment',
    label: 'Mode de règlement',
    listName: 'paymentModes',
    filter: (option, value) => option.name?.toLowerCase().includes(value),
    optionLabel: (option) => option.name,
  },
  {
    type: 'autocomplete',
    id: 'expedition',
    label: 'Transport',
    listName: 'expeditions',
    filter: (option, value) => option.name?.toLowerCase().includes(value),
    optionLabel: (option) => option.name,
  },
  {
    type: 'number',
    id: 'nbrReleveAuthorise',
    label: 'Nombre de relevés autorisés',
  },
  {
    type: 'number',
    id: 'encours',
    label: 'Encours maximum PM autorisé',
  },
  {
    type: 'number',
    id: 'encoursHuile',
    label: 'Encours maximum H autorisé',
  },
  {
    type: 'number',
    id: 'nbrJourEcheancePm',
    label: 'Échéance en nombre de jours PM',
    disabled: (values) => (values.payment ? ['ESPECES', 'E/C'].includes(values.payment.name) : false),
  },
  {
    type: 'number',
    id: 'nbrJourEcheanceH',
    label: 'Échéance en nombre de jours H',
    disabled: (values) => (values.payment ? ['ESPECES', 'E/C'].includes(values.payment.name) : false),
  },
  {
    type: 'number',
    id: 'montantAlertePm',
    label: 'Montants PM Alertes',
  },
  {
    type: 'number',
    id: 'montantAlerteH',
    label: 'Montants H Alertes',
  },
  {
    type: 'autocomplete',
    id: 'blocage',
    label: 'Blocage',
    listName: 'blockings',
    filter: (option, value) => option
      ?.toLowerCase().includes(value),
    optionLabel: (option) => option,
    display: (value) => (value === true ? 'Manuel' : 'Automatique'),
  },
  {
    type: 'date',
    id: 'blocageTerme',
    label: 'Blocage à terme',
    disabled: (values) => values.blocage === 'Manuel',
  },
];
