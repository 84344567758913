/* eslint-disable react/jsx-no-duplicate-props */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box, IconButton, Typography,
} from '@mui/material';
import Product from 'Components/Catalog/Products/Product';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import CachedIcon from '@mui/icons-material/Cached';
import RelatedList from '../ArticleCard/RelatedList';
import ArticleCard from '../ArticleCard';
import ProductDetail from '../../Catalog/Products/ProductDetail';
import NodataMessage from '../NodataMessage';
import PaginationActions from '../PaginationActions';

const ResultArea = ({
  relatedProducts,
  search,
  classes,
  filters,
  onChangeFilter,
  products = [],
  filterNames = [],
  primaryLabel = 'NATURE DE PRODUITS',
  secondaryLabel = 'MARQUE',
  primaryOptions = [],
  secondaryOptions = [],
  articles = [],
  isTecdoc = false,
  nextPage,
  isNextPage,
  resetIndex,
}) => {
  const [productDetail, setProductDetail] = useState(null);
  const [openProductDetail, setOpenProductDetail] = useState(false);
  const onHandleCloseProductDetail = () => {
    setOpenProductDetail(false);
    setProductDetail(null);
    // dispatch(saveRelatedList([]));
  };
  const onHandleOpenProductDetail = (eProduct) => {
    setProductDetail({ ...eProduct, searchedBy: search });
    setOpenProductDetail(true);
  };
  const relatedArticles = useMemo(() => articles.flatMap(({ oemNumbers }) => oemNumbers || []), [
    articles,
  ]);
  const onChangeSelect = (name) => (e, value) => {
    onChangeFilter({ target: { name, value } });
    if (!isTecdoc && name === filterNames[0] && !value) {
      onChangeFilter({ target: { name: filterNames[1], value: null } });
    }
  };
  const onHandleReset = () => {
    onChangeFilter({ target: { name: filterNames[0], value: null } });
    onChangeFilter({ target: { name: filterNames[1], value: null } });
  };
  const isNoResult = isTecdoc ? articles.length === 0 : products.length === 0;
  const isRelatedExist = isTecdoc ? relatedArticles?.length > 0 : relatedProducts?.length > 0;
  const [cardPerSlide, setCardPerSlide] = useState(0);
  const [indexCard, setIndexCard] = useState(0);
  const contentWidth = useRef(null);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setCardPerSlide(
        parseInt((contentWidth?.current?.getBoundingClientRect()?.width - 50) / 280, 10),
      );
    });
    if (contentWidth?.current) resizeObserver.observe(contentWidth?.current);
    return () => resizeObserver.disconnect();
  }, [setCardPerSlide, contentWidth]);
  const nextElem = () => {
    if (nextPage) {
      nextPage(indexCard + cardPerSlide >= (isTecdoc ? articles : products)?.length - 1);
    }
    setIndexCard(indexCard + 1);
  };
  const prevElem = () => {
    setIndexCard(indexCard - 1);
  };
  useEffect(() => {
    setIndexCard(0);
  }, [resetIndex]);
  useEffect(() => {
    if (!isTecdoc) {
      setCardPerSlide(0);
      setIndexCard(0);
    }
  }, [products]);
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        background: '#F7F7F7',
        border: '1px solid #7070703E',
        borderRadius: '12px',
        marginTop: 20,
        padding: 15,
      }}
    >
      <Box style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Box style={{ display: 'flex', width: 480 }}>
          <AutoCompleteCmp
            options={primaryOptions}
            onChange={onChangeSelect(filterNames[0])}
            value={filters[filterNames[0]]}
            label={primaryLabel}
            filterOptions={
              // eslint-disable-next-line max-len
              (ops, v) => ops.filter(
                (o) => !v.inputValue
                    || (isTecdoc ? o.genericArticleDescription : o.name)
                      ?.toLowerCase()
                      .includes(v.inputValue.toLowerCase()),
              )
            }
            getOptionLabel={(v) => (isTecdoc ? v.genericArticleDescription : v.name)}
            isPrimaryTheme
          />
          <AutoCompleteCmp
            options={secondaryOptions}
            onChange={onChangeSelect(filterNames[1])}
            value={filters[filterNames[1]]}
            label={secondaryLabel}
            disabled={!filters[filterNames[0]]}
            filterOptions={
              // eslint-disable-next-line max-len
              (ops, v) => ops.filter(
                (o) => !v.inputValue
                    || (isTecdoc ? o.mfrName : o.name)
                      ?.toLowerCase()
                      .includes(v.inputValue.toLowerCase()),
              )
            }
            getOptionLabel={(v) => (isTecdoc ? `${v.mfrName} (${v.count})` : v.name)}
            isPrimaryTheme
          />
        </Box>
        <IconButton
          aria-label="reset"
          edge="end"
          style={{
            background: '#FFFFFF',
            borderRadius: 12,
            color: '#3479BF',
            marginRight: 10,
            padding: 7,
          }}
          onClick={onHandleReset}
          size="large"
        >
          <CachedIcon />
        </IconButton>
      </Box>
      <Box style={{ display: 'flex' }}>
        <Typography style={{ marginTop: 12, flexGrow: 1 }} className={classes.title}>
          {`Résultat dans le catalogue ${isTecdoc ? 'Tecdoc' : 'copima'}`}
        </Typography>
        <PaginationActions
          indexCard={indexCard}
          classes={classes}
          prevElem={prevElem}
          nextElem={nextElem}
          isNextPage={isNextPage}
          cardPerSlide={cardPerSlide}
          size={(isTecdoc ? articles : products)?.length}
        />
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          overflow: 'hidden',
        }}
        ref={contentWidth}
      >
        {!isNoResult ? (
          products
            .slice(indexCard, cardPerSlide + indexCard)
            .map((pro) => <Product product={pro} key={pro?.id} />)
        ) : (
          <NodataMessage isSmall />
        )}
      </Box>
      {openProductDetail && (
        <ProductDetail
          product={productDetail}
          handleClose={onHandleCloseProductDetail}
          isTecDoc={!productDetail.reference || !productDetail.id}
          onHandleOpenProductDetail={onHandleOpenProductDetail}
        />
      )}
      {isTecdoc && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            overflow: 'hidden',
          }}
          ref={contentWidth}
        >
          {articles.slice(indexCard, cardPerSlide + indexCard).map((article) => (
            <ArticleCard
              key={article.articleNumber}
              imgs={article.images}
              category=""
              brand={article.mfrName}
              name={article.genericArticles}
              desc={article.articleText}
              original={article}
              onHandleOpenProductDetail={onHandleOpenProductDetail}
            />
          ))}
        </Box>
      )}
      {isRelatedExist && (
        <RelatedList
          list={isTecdoc ? relatedArticles : relatedProducts}
          title={`Équivalent dans le catalogue ${isTecdoc ? 'Tecdoc' : 'copima'}`}
          classes={classes}
          onHandleOpenProductDetail={onHandleOpenProductDetail}
          isArticle={isTecdoc}
          isNoData={!isRelatedExist}
          saveArticles={isTecdoc}
        />
      )}
    </Box>
  );
};

export default ResultArea;
