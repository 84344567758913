import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  filter: {
    marginRight: 16,
    flexGrow: 1,
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    borderRadius: 12,
    marginBottom: 16,
  },
  product: {
    marginRight: 16,
    flexGrow: 1,
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    borderRadius: 12,
  },
  date: {
    marginRight: 16,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
  },
  notched: {
    border: 'none',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  input: {
    font: 'normal normal 600 26px Montserrat',
    padding: '4px 14px',
  },
  rootQuantity: {
    borderRadius: 14,
  },
  disabledAddButton: {
    background: '#A0A0A0 0% 0% no-repeat padding-box !important',
    borderRadius: 12,
    font: 'normal normal 600 18px Montserrat',
    letterSpacing: 0,
    color: '#FFFFFF !important',
    boxShadow: 'none',
    height: 38,
  },
  addButton: {
    font: 'normal normal 600 18px Montserrat',
    letterSpacing: 0,
    color: '#FFFFFF',
    background:
      'transparent linear-gradient(250deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 10px #108CD650',
    borderRadius: 12,
    height: 38,
  },
  newOrderText: {
    font: 'normal normal 600 28px Montserrat',
  },
  colorPrimaryPM: {
    backgroundColor: '#9fe4d7',
  },
  barPM: {
    borderRadius: 5,
    backgroundColor: '#43cbb0',
  },
  colorPrimaryHuile: {
    backgroundColor: '#f5e3a4',
  },
  barHuile: {
    borderRadius: 5,
    backgroundColor: '#eed26b',
  },
}));

export default useStyles;
