import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DeleteIcon from '@mui/icons-material/DeleteOutline';

export default function ConfirmRemoveDialog({
  content, handleClose, handleClick,
}) {
  return (
    <div>
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{
          textAlign: 'center', marginTop: '30px', marginLeft: '50px', marginRight: '50px',
        }}
        >
          <DeleteIcon style={{
            backgroundColor: '#F86060', padding: '25px', borderRadius: '50%', fontSize: 30, color: '#FFF',
          }}
          />
          <DialogContentText
            id="alert-dialog-description"
            style={{
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
              color: 'black',
              marginTop: '20px',
            }}
          >
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', marginBottom: '30px' }}>
          <Button
            onClick={handleClick}
            style={{
              textTransform: 'capitalize', width: '100px', backgroundColor: '#F86060', color: '#FFF', boxShadow: '0px 4px 10px #BEBEBE50',
            }}
            autoFocus
          >
            Oui
          </Button>
          <Button
            onClick={handleClose}
            style={{
              textTransform: 'capitalize', color: 'black', width: '100px', boxShadow: '0px 4px 10px #BEBEBE50',
            }}
            color="primary"
          >
            Non
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
