import Comment from 'Components/Catalog/Products/Comments/Comment';
import {
  Box, Button, FilledInput, FormControl, InputLabel,
} from '@mui/material';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import React, { useEffect, useState } from 'react';
import useForm from 'Shared/Hooks/useForm';
import useStyles from './styles';

const Notes = ({
  notes, onSubmit, onRemove, loading,
}) => {
  const [comments, setComments] = useState([]);
  const classes = useStyles();
  const { values: form, onChange } = useForm({ comment: '' });
  const [values, setValues] = useState({});

  useEffect(() => {
    setComments(notes);
  }, [notes]);

  const changeAnswerState = (id, state) => {
    const commentList = [...comments];
    setComments(
      commentList.map((c) => ({
        ...c,
        anwser: c.commentId === id ? state : c.anwser,
      })),
    );
  };
  const handleAnswer = (id) => () => changeAnswerState(id, true);
  const handleAbortAnswer = (id) => () => changeAnswerState(id, false);
  const handleChangeValues = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const onHandleSubmit = (parentId, msg) => {
    onSubmit(msg, parentId);
    onChange({ target: { name: 'comment', value: '' } });
  };
  const addComment = (parentId) => {
    onHandleSubmit(parentId, form.comment);
  };

  const addSubComment = (parentId, text) => () => {
    onHandleSubmit(parentId, text);
    setValues({});
  };

  const onHandleRemove = (noteId) => {
    onRemove(noteId, notes.length === 1);
  };

  return (
    <Box className={classes.commentContainer}>
      {loading && <GlobalLoader size="100vh" />}
      <Box className={classes.commentList}>
        {comments.map((c, index) => (
          <Comment
            key={c.commentId}
            comment={c}
            index={index}
            onHandleAnswer={handleAnswer}
            onHandleAbortAnswer={handleAbortAnswer}
            onChangeValues={handleChangeValues}
            values={values}
            deleteComment={onHandleRemove}
            addComment={addSubComment}
          />
        ))}
      </Box>
      <Box style={{ padding: '24px' }}>
        <FormControl variant="filled" fullWidth>
          <InputLabel htmlFor="component-filled">Commentaire ...</InputLabel>
          <FilledInput
            id="component-filled"
            style={{
              backgroundColor: '#e0e0e073',
              borderRadius: 4,
              '& .MuiFilledInput-underline:before': { borderWidth: '0 !important' },
            }}
            disableUnderline={false}
            name="comment"
            value={form.comment}
            onChange={onChange}
            multiline
            rows={3}
            fullWidth
          />
        </FormControl>
      </Box>
      <Button
        color="primary"
        disabled={form.comment.length === 0}
        onClick={() => addComment(null)}
        style={{
          background: '#007BC6',
          border: '1px solid #007BC6',
          borderRadius: 12,
          color: '#FFF',
          marginRight: 15,
          alignSelf: 'flex-end',
        }}
      >
        AJOUTER LE COMMENTAIRE
      </Button>
    </Box>
  );
};

export default Notes;
