import React from 'react';
import {
  Box, Button, Chip, Tooltip, Typography, ButtonGroup,
} from '@mui/material';
import { format } from 'date-fns';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { toast } from 'react-toastify';
import DoneIcon from '@mui/icons-material/Done';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { TYPES_COMMANDES } from 'Shared/constants/order';

export const mapOrderStatus = {
  NON_ASSIGNEE: { label: 'Non assignée', class: 'NotAssignedStatus' },
  EN_ATTENTE_PICKAGE: { label: 'En attente', class: 'awaitingPicking' },
  EN_ATTENTE_VALIDATION: { label: 'Validation', class: 'awaitingValidation' },
  EN_COURS_PICKAGE: { label: 'En cours', class: 'pickingInprogress' },
  CLOTUREE: { label: 'Clôturée', class: 'closed' },
  BL_GENERE: { label: 'BL généré', class: 'invoiced' },
  MANQUE: { label: 'Manque', class: 'manque' },
  STOCK: { label: 'Stock', class: 'stock' },
  IMPRIME: { label: 'Imprimé', class: 'closed' },
};

const getTypeName = (typeCode) => {
  const item = TYPES_COMMANDES.find((type) => type.id === typeCode);
  return item ? item.label : '-';
};

const TitleStyle = {
  color: '#727CA2',
  fontSize: '12px',
  fontWeight: 500,
  background: '#F8F8FF',
  borderBottom: 'none',
  textTransform: 'uppercase',
};
const defaultColumnStyle = { color: '#333D66', fontSize: '14px' };
const downloadFromLink = (url, filename) => (event) => {
  event.stopPropagation();
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    })
    .catch(() => {
      toast.error('Erreur Système');
    });
};
export const columns = [
  {
    id: 'printed',
    label: '',
    style: TitleStyle,
    render: ({ value }) => (
      <>
        {value && <DoneIcon style={{ color: 'green' }} />}
        {!value && <NotInterestedIcon />}
      </>
    ),
  },
  {
    id: 'reference',
    label: 'Reference',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    id: 'date',
    label: 'Date',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ ...defaultColumnStyle, mr: '9px' }} display="inline">
        {format(new Date(value), 'dd/MM/yyy')}
      </Typography>
    ),
  },
  {
    id: 'client',
    label: 'Client',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'type',
    label: 'Type',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{getTypeName(value)}</Typography>,
  },
  {
    id: 'paymentMode',
    label: 'Moyen de paiment',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'expedition',
    label: 'Expedition',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'amount',
    label: 'Montant',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'status',
    label: 'Status',
    style: TitleStyle,
    render: ({ value, row }) => (
      <>
        {value && (
          <Chip
            className={row.statusClass}
            style={{ height: 20 }}
            icon={<FiberManualRecordIcon style={{ width: 10, height: 10, color: 'inherit' }} />}
            label={value}
          />
        )}
        {!value && <span>-</span>}
      </>
    ),
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    render: ({ row }) => (
      <Box style={{ display: 'flex', columnGap: '5px' }}>
        {[3].includes(row.type) && (
        <Tooltip title="Télécharger le bon de livraison">
          <Button
            variant="contained"
            color="primary"
            style={{ minWidth: '40px', padding: '4px 0' }}
            size="small"
            onClick={row.handleDownloadBl}
          >
            <GetAppIcon />
          </Button>
        </Tooltip>
        )}
        {[6, 7].includes(row.type) && (
        <Tooltip title="Télécharger la facture">
          <Button
            variant="contained"
            color="primary"
            style={{ minWidth: '40px', padding: '4px 0' }}
            size="small"
            onClick={row.handleInvoice}
          >
            <GetAppIcon />
          </Button>
        </Tooltip>
        )}
        {row.numExpedition && !['LUI MEME BOUSK', 'LUI MEME DERB OMAR', 'CAR'].includes(row.expedition) && (
        <ButtonGroup size="small" variant="contained">
          <Tooltip title="Télécharger la déclaration d'expédition">
            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: '40px', padding: '4px 0' }}
              size="small"
              onClick={downloadFromLink(row.numExpedition, row.reference)}
            >
              <GetAppIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Aperçu la déclaration d'expédition">
            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: '40px', padding: '4px 0' }}
              size="small"
              onClick={row.handleDisplayCoord1(row.numExpedition)}
            >
              <VisibilityIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
        )}
      </Box>
    ),
  },
];
