import { makeStyles } from '@mui/styles';

export const useStylesActions = makeStyles(() => ({
  action: {
    display: 'flex', padding: '10px 15px', cursor: 'pointer', margin: '3px 0',
  },
  actionLabel: { marginLeft: 5, color: '#38485C', font: 'normal 600 16px Montserrat' },
}));

export const useStylesSuppliers = makeStyles(() => ({
  label: {
    font: 'normal 600 16px Montserrat',
    color: '#38485C',
    flex: 1 / 4,
  },
}));

const useStyles = makeStyles(() => ({
  tab: {
    marginRight: 67,
    font: 'normal normal 600 14px Montserrat',
    color: '#707070',
    textTransform: 'capitalize',
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'left',
  },
  tabSelected: {
    color: '#38485C !important',
    background: '#FFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
  },
  tabRoot: {
    background: '#E9EBED 0% 0% no-repeat padding-box',
    borderRadius: 12,
    padding: 5,
    marginBottom: 16,
  },
  tabContent: {
    background: '#E9EBED',
    borderRadius: 12,
    padding: 15,
    marginTop: 5,
    minHeight: 300,
  },
  itemLabel: {
    font: 'normal 600 16px Montserrat',
    color: '#007BC6',
  },
  itemValue: { font: 'normal 600 20px Montserrat', marginBottom: 15 },
  oilIcon: {
    background: '#B9D659',
    padding: 5,
    color: '#FFF',
    borderRadius: 24,
    display: 'flex',
    marginRight: '10px',
    maxHeight: '24px',
  },
  title: { display: 'flex', justifyContent: 'space-between' },
  subTitle: { font: 'normal 600 20px Montserrat', color: 'black' },
  topRefSuppliers: {
    background: 'rgba(56, 72, 92, 0.11)',
    padding: '12px',
    display: 'flex',
    borderRadius: '12px',
  },
  label: {
    font: 'normal 600 16px Montserrat',
    color: '#38485C',
    flex: 1 / 4,
    opacity: 0.88,
  },
}));

export default useStyles;
