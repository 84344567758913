import { createContext } from 'react';

export const initialState = {
  orders: [],
  deletedOrders: [],
};
const OrdersContext = createContext(initialState);

export const { Provider } = OrdersContext;
export default OrdersContext;
