import { Button, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

export const mapOrderStatus = {
  NON_ASSIGNEE: { label: 'Non assignée', class: 'NotAssignedStatus' },
  EN_ATTENTE_PICKAGE: { label: 'En attente', class: 'awaitingPicking' },
  EN_ATTENTE_VALIDATION: { label: 'Validation', class: 'awaitingValidation' },
  EN_COURS_PICKAGE: { label: 'En cours', class: 'pickingInprogress' },
  CLOTUREE: { label: 'Clôturée', class: 'closed' },
  FACTUREE: { label: 'Facturée', class: 'invoiced' },
  MANQUE: { label: 'Manque', class: 'manque' },
  STOCK: { label: 'Stock', class: 'stock' },
  IMPRIME: { label: 'Imprimé', class: 'closed' },
};
const TitleStyle = {
  color: '#727CA2',
  fontSize: '12px',
  fontWeight: 500,
  background: '#F8F8FF',
  borderBottom: 'none',
  textTransform: 'uppercase',
};
const defaultColumnStyle = { color: '#333D66', fontSize: '14px' };
export const columns = [
  {
    id: 'date',
    label: 'Date',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ ...defaultColumnStyle, mr: '9px' }} display="inline">
        {format(new Date(value), 'dd/MM/yyy')}
      </Typography>
    ),
  },
  {
    id: 'client',
    label: 'Client',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={{ color: '#333D66', fontSize: '16px', fontWeight: 500 }}>{value}</Typography>
    ),
  },
  {
    id: 'expedition',
    label: 'Mode d`\'expdition',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'paymentMode',
    label: 'Mode de paiment',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography sx={defaultColumnStyle}>{value}</Typography>
    ),
  },
  {
    id: 'brutAmount',
    label: 'Montant brut',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'netAmount',
    label: 'Montant net',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'error',
    label: 'Motif de blocage',
    style: TitleStyle,
    render: ({ value }) => <Typography sx={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'action',
    label: 'action',
    style: TitleStyle,
    render: ({ value }) => <Button variant="contained" color="primary" onClick={value}> Renvoyer</Button>,
  },
];
