import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import { debugContextDevtool } from 'react-context-devtool';
import { ThemeProvider } from '@mui/styles';
import { createTheme, adaptV4Theme, StyledEngineProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Provider } from 'react-redux';
import App from './App';
import 'fonts/SF-Pro-Display-Regular.otf';
import 'fonts/FontsFree-Net-SFProDisplay-Bold.ttf';
import 'fonts/SF-Pro-Display-Medium.otf';
import './index.css';
import 'Utils/ulilities';
import store from './store';

const theme = createTheme(adaptV4Theme({
  overrides: {
    MuiButton: {
      sizeLarge: {
        height: 56,
      },
      containedSizeSmall: {
        background: '#10277C',
        color: '#FFFFFF',
        fontFamily: 'SFProDisplayBold',
        borderRadius: 8,
        textTransform: 'Capitalize',
      },
    },
  },
}));

const container = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <Provider store={store}>
            <App />
          </Provider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </React.StrictMode>,
  container,
);

// eslint-disable-next-line no-underscore-dangle
if (window.__REACT_CONTEXT_DEVTOOL_GLOBAL_HOOK) {
  debugContextDevtool(container, {
    disable: process.env.NODE_ENV === 'production',
  });
}
