import { useCallback, useEffect, useState } from 'react';
import { getProductsPropositions } from '../PropositionServices';

const useTargetProducts = (referenceProposition, checkOptions) => {
  const [targetProduct, setTargetProduct] = useState({ ids: [], totalElements: 0 });
  const [productsProposition, setProductsProposition] = useState([]);

  // fill target ids from checkOptions
  useEffect(() => {
    if (!referenceProposition && checkOptions?.productIds) {
      setTargetProduct({
        ids: checkOptions.productIds,
        totalElements: checkOptions.productIds.length,
      });
    }
  }, [referenceProposition, checkOptions]);

  const getProductIdsByProposition = useCallback(async ({ size, page }) => {
    const productsProp = await getProductsPropositions(referenceProposition, size, page);
    setTargetProduct({
      ids: productsProp.content.map((pp) => pp.productId).filter((pp) => pp !== null),
      totalElements: productsProp.totalElements,
    });
    setProductsProposition(productsProp.content);
  }, [referenceProposition]);

  // fill target ids from proposition id (referenceProposoition)
  useEffect(() => {
    if (referenceProposition) {
      getProductIdsByProposition({ size: 10, page: 0 });
    }
  }, [referenceProposition]);

  return { targetProduct, getProductIdsByProposition, productsProposition };
};

export default useTargetProducts;
