import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  customFilledInput: {
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none !important',
    },
    '&:hover .MuiFilledInput-underline:before': {
      borderBottom: 'none !important',
    },
    '& .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
      border: 'none', // Remove the border
      content: 'none', // Remove the content
      position: 'absolute',
    },
    '&:hover .MuiFilledInput-underline:before, & .MuiFilledInput-underline:after': {
      border: 'none', // Remove the border on hover
      content: 'none', // Remove the content on hover
    },
  },
  label: {
    font: 'normal normal 800 16px Montserrat',
    letterSpacing: 0,
    color: '#000000',
    opacity: 1,
    top: -3,
    zIndex: 10,
  },
  inputBase: {
    backgroundColor: '#F7F7F7',
    borderRadius: 4,
    padding: '4px',
    marginBottom: 10,
    marginTop: 10,
    '&:hover': {
      backgroundColor: '#F7F7F7',
      borderBottom: 'none',
      outline: 'none',
    },
  },
}));

export default useStyles;
