import { Paper, Tab, Tabs } from '@mui/material';
import { format } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import Kpi from 'Shared/Kpi';
import {
  BoxIcon, ClockIcon, InprogressIcon,
} from '../Icons';
import PaymentDocuments from '../PaymentDocuments';
import { Fields } from '../PaymentDocuments/Filters/constants';
import { defaultMessage, getReglementKpis } from '../PaymentServices';
import { getDateWithoutTime } from '../Utils';
import useStyles from './style';

const PaymentListTabs = () => {
  const [tab, setTab] = useState(0);
  const [kpis, setKpis] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const [values, setValues] = useState(
    Fields.reduce((prev, curr) => ({ ...prev, [curr.id]: null }), {}),
  );
  const handleInputChange = (prop) => (event, value) => {
    setValues({ ...values, [prop]: prop.includes('Date') ? event : value || event?.target?.value });
  };
  const classes = useStyles();

  const getReglementKpiList = useCallback(async () => {
    try {
      setLoading(true);
      const {
        startDate, endDate, echeanceStartDate, echeanceEndDate, ...rest
      } = values;
      const additionalFields = Object.keys(rest).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: (rest[curr]?.code ? rest[curr]?.code : rest[curr]) || undefined,
        }), {},
      );
      const data = await getReglementKpis({
        startDate: startDate ? format(getDateWithoutTime(startDate), "yyyy-MM-dd'T'HH:mm:sszzzz") : undefined,
        endDate: endDate ? format(getDateWithoutTime(endDate), "yyyy-MM-dd'T'HH:mm:sszzzz") : undefined,
        echeanceStartDate: echeanceStartDate ? format(getDateWithoutTime(echeanceStartDate), "yyyy-MM-dd'T'HH:mm:sszzzz") : undefined,
        echeanceEndDate: echeanceEndDate ? format(getDateWithoutTime(echeanceEndDate), "yyyy-MM-dd'T'HH:mm:sszzzz") : undefined,
        ...additionalFields,
      });
      setKpis(data);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setKpis, values]);
  useEffect(() => {
    getReglementKpiList();
  }, [getReglementKpiList]);
  return (
    <>
      {loading && <GlobalLoader size="100vh" />}
      <div style={{ margin: '20px 50px' }}>
        <Kpi color="#888888" icon={BoxIcon} label="Total des reglements attachés" value={kpis?.attachedAmount} />
        <Kpi
          color="#ED9747"
          icon={ClockIcon}
          label="Total des règlements non attachés"
          value={kpis?.unattachedAmount}
        />
        <Kpi
          color="#4A4AFF"
          icon={InprogressIcon}
          label="Total des règlements saisies"
          value={kpis?.totalAmount}
        />
      </div>
      <Paper elevation={0} classes={{ root: classes.paper }}>
        <div className={classes.container}>
          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            classes={{ root: classes.tabs }}
            TabIndicatorProps={{ style: { backgroundColor: '#2188CC' } }}
          >
            <Tab
              classes={{
                root: classes.tab,
                selected: classes.tabSelected,
                wrapper: classes.tabWrapper,
              }}
              label="Pièces de règlements"
              value={0}
            />
          </Tabs>
        </div>
        {tab === 0 && <PaymentDocuments handleInputChange={handleInputChange} values={values} />}
      </Paper>
    </>
  );
};

export default PaymentListTabs;
