import { configureStore } from '@reduxjs/toolkit';
import traceabilityReducer from 'Components/Traceability/traceabilitySlice';
import appReducer from './appSlice';

const store = configureStore({
  reducer: {
    traceability: traceabilityReducer,
    app: appReducer,
  },
});

export default store;
