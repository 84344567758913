import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import React, { useEffect } from 'react';
import CustomTablePagination from 'Shared/CustomTablePagination';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import './style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    top: 20,
    width: 1,
    padding: 0,
  },
  position: 'absolute',
  avatar: {
    color: '#FFFFFF',
    backgroundColor: '#88BCDE',
    width: 32,
    height: 32,
    fontSize: 14,
  },
  addIcon: {
    color: 'transparent',
    padding: 0,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.5)',
  },
  data: {
    fontSize: 12,
    color: '#000000',
    fontFamily: 'SFProDisplay !important',
  },
  pagination: {
    color: 'rgba(0, 0, 0, 0.5)',
    fontFamily: 'SFProDisplay !important',
  },
  chip: {
    height: 20,
  },
}));

export default function EnhancedTable({
  getData,
  rows,
  headCells,
  regularColumns = [],
  count = 0,
  pagination = true,
  update,
  containerClass,
  headClass,
  resetSort,
  rowsPerPageOptions,
  staticData,
  updateData,
  expandColumn,
  expandedRows = [],
  onChangeRowsPerPage = () => null,
  onChangePage = () => null,
  onSort = () => null,
  defaultOrderBy,
  defaultOrder,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState(defaultOrder || 'asc');
  const [orderBy, setOrderBy] = React.useState(defaultOrderBy || '');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    rowsPerPageOptions ? rowsPerPageOptions[0] : 5,
  );
  const onHandleRowsPage = (number) => {
    setRowsPerPage(number);
    onChangeRowsPerPage(number);
  };
  const onHandlePage = (number) => {
    setPage(number);
    onChangePage(number);
  };
  useEffect(() => {
    setOrder(defaultOrder || 'asc');
    setOrderBy(defaultOrderBy || '');
    onHandlePage(0);
    onHandleRowsPage(rowsPerPageOptions ? rowsPerPageOptions[0] : 5);
    if (updateData) {
      updateData(0, rowsPerPageOptions ? rowsPerPageOptions[0] : 5);
    }
  }, [resetSort, defaultOrder, defaultOrderBy]);
  useEffect(() => {
    const orderList = orderBy.split('+');
    if (orderList.length === 1 && getData) {
      getData({
        page,
        size: rowsPerPage,
        sort: orderBy ? `${orderBy},${order}` : '',
      });
    } else if (getData) {
      getData({
        page,
        size: rowsPerPage,
        sort: `${orderList[0]},${order}&sort=${orderList[1]},${order}`,
      });
    }
  }, [page, rowsPerPage, order, orderBy, getData, update]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    onSort(property, isAsc ? 'desc' : 'asc');
  };

  const handleChangePage = (event, newPage) => {
    onHandlePage(newPage);
    if (updateData) {
      updateData(newPage, undefined);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    onHandleRowsPage(parseInt(event.target.value, 10));
    onHandlePage(0);
    if (updateData) {
      updateData(0, parseInt(event.target.value, 10));
    }
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);
  return (
    <div className={classes.root}>
      {false && <EnhancedTableToolbar />}
      <TableContainer className={containerClass || ''}>
        <Table className={classes.table} size="medium" stickyHeader>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            expandColumn={expandColumn}
            expandedRows={expandedRows}
            headClass={headClass}
          />
          <TableBody>
            {(pagination && !staticData
              ? rows
              : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ).map((row, index) => {
              let i = 0;
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  className={classes.row}
                  onClick={row.click}
                >
                  {headCells.map((headCell) => {
                    const value = row[headCell.id];
                    i = !regularColumns.includes(headCell.id) ? i + 1 : i;
                    let backgroundStyle = {};
                    if (
                      regularColumns.length > 0
                      && !regularColumns.map((c) => c.id).includes(headCell.id)
                    ) {
                      backgroundStyle = {
                        backgroundColor: i % 2 === 0 ? '#FFF' : '#ededed',
                      };
                    }
                    return (
                      <TableCell
                        size="medium"
                        key={headCell.id}
                        align={headCell.align}
                        style={{
                          padding: '12px 3px',
                          ...backgroundStyle,
                          ...(headCell.parentRowStyle || {}),
                        }}
                      >
                        {headCell.render({
                          value,
                          row,
                          headCell,
                          idx: index,
                          expandedRow: expandedRows[index],
                          expandedRowValue: expandedRows[index]
                            ? expandedRows[index][headCell.id]
                            : null,
                        })}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <Box style={{ display: 'flex', flexDirection: 'row-reverse', padding: '10px 0' }}>
          <CustomTablePagination
            labelRowsPerPage="Lignes par page"
            rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            classes={{ caption: classes.pagination, select: classes.pagination }}
          />
        </Box>
      )}
    </div>
  );
}
