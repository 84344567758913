import React from 'react';
import { Box, Typography } from '@mui/material';
import DialogCmp from 'Shared/Dialog';
import Input from 'Shared/Input/v2';
import AutoCompleteCmp from 'Shared/Select/AutoCompleteCmp';
import {
  BLOCKAGE_IMPAYE,
  BLOCKAGE_MANUEL,
  BLOCKAGE_RELEVE,
  BLOCKAGE_RELEVE_H,
} from './constants';
import useForm from '../../../hooks/useForm';

const EditClient = ({
  client, setOpen, onSubmit, open,
}) => {
  const { values, onChange } = useForm(client);

  const onHandleSave = () => {
    onSubmit({ ...values });
  };

  const handleSelectChange = (name) => (event, newValue) => {
    onChange({ target: { name, value: newValue } });
  };
  return (
    <DialogCmp
      title="Modifier Les Informations"
      subTitle={(
        <Typography sx={{ color: '#0480CA', font: 'normal bold 18px Montserrat' }}>
          {`${client.code} - ${client.name}`}
        </Typography>
      )}
      open={open}
      handleClose={() => setOpen(false)}
      onHandleSubmit={onHandleSave}
      fullWidth={false}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: '#E9EBED 0% 0% no-repeat padding-box',
          borderRadius: 12,
          opacity: 1,
          padding: '20px 12px',
          minWidth: '600px',
        }}
      >
        <Box style={{ marginBottom: '20px', display: 'flex' }}>
          <Input
            label="Encours"
            name="encours"
            onChange={onChange}
            value={values?.encours}
            type="number"
            style={{ maxWidth: 210 }}
          />
          <Input
            label="ENCOURS HUILE"
            name="encoursHuile"
            onChange={onChange}
            value={values?.encoursHuile}
            type="number"
            style={{ maxWidth: 210 }}
          />
          <Input
            label="NOMBRE DE RELEVÉ..."
            name="nbrReleveAuthorise"
            onChange={onChange}
            value={values?.nbrReleveAuthorise}
            type="number"
            style={{ maxWidth: 210 }}
          />
        </Box>
        <Box style={{ marginBottom: '20px', display: 'flex' }}>
          <AutoCompleteCmp
            options={BLOCKAGE_MANUEL}
            onChange={handleSelectChange('blocked')}
            value={values?.blocked}
            label="BLOCKAGE MANNUEL"
            isPrimaryTheme
            getOptionLabel={(v) => v}
              // eslint-disable-next-line max-len
            filterOptions={(ops, v) => ops.filter((o) => o?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          />
          <AutoCompleteCmp
            options={BLOCKAGE_IMPAYE}
            onChange={handleSelectChange('impaye')}
            value={values?.impaye}
            label="BLOCKAGE IMPAYÉ"
            isPrimaryTheme
            getOptionLabel={(v) => v}
              // eslint-disable-next-line max-len
            filterOptions={(ops, v) => ops.filter((o) => o?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          />
          <AutoCompleteCmp
            options={BLOCKAGE_RELEVE}
            onChange={handleSelectChange('releve')}
            value={values?.releve}
            label="BLOCKAGE RELEVÉ"
            isPrimaryTheme
            getOptionLabel={(v) => v}
              // eslint-disable-next-line max-len
            filterOptions={(ops, v) => ops.filter((o) => o?.toLowerCase().includes(v.inputValue.toLowerCase()))}
          />
        </Box>
        <AutoCompleteCmp
          options={BLOCKAGE_RELEVE_H}
          onChange={handleSelectChange('huile')}
          value={values?.huile}
          label="BLOCKAGE HUILE"
          isPrimaryTheme
          getOptionLabel={(v) => v}
            // eslint-disable-next-line max-len
          filterOptions={(ops, v) => ops.filter((o) => o?.toLowerCase().includes(v.inputValue.toLowerCase()))}
        />
      </Box>
    </DialogCmp>
  );
};

export default EditClient;
