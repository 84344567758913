import React from 'react';
import { IconButton, Typography } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '16px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 14px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
  marginLeft: 5,
};
export const columns = (onHandleActions) => ([
  {
    id: 'reference',
    label: 'Réference',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'brand',
    label: 'Marque',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'family',
    label: 'Famille',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'minQte',
    label: 'Qte Min',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'maxQte',
    label: 'Qte Max',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'remise',
    label: 'Remise',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'price',
    label: 'SPECIAL PRICE',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'client',
    label: 'SPECIAL CLIENT',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,

  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    render: ({ row }) => (
      <IconButton aria-label="actions" size="large" onClick={(e) => onHandleActions(row)(e)}>
        <MoreHorizIcon />
      </IconButton>
    ),
  },
]);
