import {
  secureGet,
} from 'config/apiConfig';
import { formatChanges } from './constants';

export const defaultMessage = 'Erreur système';

const url = '/ci/api/traceability';

export const getProductsTrace = async ({ size = 100, page = 0 }) => {
  const { data } = await secureGet(`${url}/products/snapshots?page=${page}&size=${size}`);
  return data;
};

export const getProductsChange = async ({ size = 100, page = 0, search }) => {
  let path = `${url}/products/changes?page=${page}&size=${size}`;
  if (search) {
    path = `${path}&reference=${search}`;
  }
  const { data } = await secureGet(path);
  return { ...data, changes: formatChanges(JSON.parse(data.changes)) };
};

export const getClientsChange = async ({ size = 100, page = 0, search }) => {
  const seachQuery = search ? `&id=${search}` : '';
  const { data } = await secureGet(`${url}/clients/changes?page=${page}&size=${size}${seachQuery}`);
  return { ...data, changes: formatChanges(JSON.parse(data.changes)) };
};

export const getPropositionChange = async ({
  size = 100, page = 0, id, isProduct = false,
}) => {
  let path = `${url}/propositions/changes?page=${page}&size=${size}&isProduct=${isProduct}`;
  if (id) {
    path = `${path}&id=${id}`;
  }
  const { data } = await secureGet(path);
  if (data?.statusCode === '400') {
    throw new Error('not found');
  }
  return {
    ...data,
    changes: formatChanges(JSON.parse(data.changes)),
    shadows: JSON.parse(data.shadows),
  };
};
export const getDocumentAchatsChange = async ({ size = 100, page = 0, id }) => {
  let path = `${url}/document-achat/changes?page=${page}&size=${size}`;
  if (id) {
    path = `${path}&id=${id}`;
  }
  const { data } = await secureGet(path);
  if (data?.statusCode === '400') {
    throw new Error('not found');
  }
  return {
    ...data,
    changes: formatChanges(JSON.parse(data.changes)),
    shadows: data.shadowsMin,
  };
};

export const getControlesChange = async ({ size = 100, page = 0, id }) => {
  let path = `${url}/control/changes?page=${page}&size=${size}`;
  if (id) {
    path = `${path}&id=${id}`;
  }
  const { data } = await secureGet(path);
  if (data?.statusCode === '400') {
    throw new Error('not found');
  }
  return {
    ...data,
    changes: formatChanges(JSON.parse(data.changes)),
    shadows: JSON.parse(data.shadows),
  };
};

export const getOrders = async (sortParams, filters = {}, conf) => {
  let path = `/ci/api/anomaly/orders?page=${sortParams.page}&size=${sortParams.size}${
    sortParams.sort ? `&sort=${sortParams.sort}` : ''
  }`;
  Object.keys(filters).forEach((key) => {
    if (filters[key] || filters[key] === false) {
      path += Array.isArray(filters[key])
        ? filters[key].reduce((prev, curr) => `${prev}&${key}=${curr}`, '')
        : `&${key}=${filters[key]}`;
    }
  });
  const { data } = await secureGet(path, conf);
  return data;
};
export const getAllDocuments = async (sortParams, filters, conf) => {
  const xSearch = filters.search ? `&documentNumber=${filters.search}` : '';

  const path = `/ci/api/achat?page=${sortParams.page}&size=${sortParams.size}${xSearch}${sortParams.sort ? `&sort=${sortParams.sort}` : ''}`;
  const { data } = await secureGet(path, conf);
  return data;
};

const path = '/ci/api/proposition/';

export const getPropositions = async ({
  size = 10, page = 0, name, conf,
}) => {
  const xName = name && name.length > 0 ? `&name=${name}` : '';
  const { data } = await secureGet(
    `${path}?page=${page}&size=${size}${xName}`,
    conf,
  );
  return data;
};
