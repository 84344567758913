/* eslint-disable import/prefer-default-export */
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '12px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};
const parentRowStyle = {
  padding: '2px 5px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  height: 30,
};

export const columns = [
  {
    id: 'date',
    label: 'Date',
    style: TitleStyle,
    parentRowStyle,
    render: ({ row }) => (
      <FormControlLabel
        control={(
          <Checkbox />
              )}
        style={{
          color: '#4D4D4D', textTransform: 'capitalize', fontSize: 10, fontWeight: 600, ml: 1,
        }}
        label={row.date && format(new Date(row.date), 'dd/MM/yyyy')}
      />
    ),
    renderHeader: () => (
      <FormControlLabel
        control={(
          <Checkbox />
                )}
        sx={{
          textTransform: 'capitalize',
          fontSize: 10,
          fontWeight: 600,
          ...defaultColumnStyle,
          '& .MuiFormControlLabel-label': {
            ...defaultColumnStyle,
            color: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        label="Date"
      />
    ),
  },
  {
    id: 'piece',
    label: 'Pièce',
    style: TitleStyle,
    parentRowStyle,
    render: ({ row }) => (
      <Typography style={{ defaultColumnStyle }}>
        {row.reference}
      </Typography>
    ),
  },
  {
    id: 'client',
    label: 'Client',
    style: TitleStyle,
    parentRowStyle,
    render: ({ row }) => (
      <Typography style={{ defaultColumnStyle }}>
        {row.client?.name}
      </Typography>
    ),
  },
  {
    id: 'paymentMode',
    label: 'mode de paiment',
    style: TitleStyle,
    parentRowStyle,
    render: ({ row }) => (
      <Typography style={{ defaultColumnStyle }}>
        {row.paymentMode?.name}
      </Typography>
    ),
  },
  {
    id: 'expedition',
    label: 'mode d\'expédition',
    style: TitleStyle,
    parentRowStyle,
    render: ({ row }) => (
      <Typography style={{ defaultColumnStyle }}>
        {row.expedition?.name}
      </Typography>
    ),
  },
  {
    id: 'commercial',
    label: 'Commercial',
    style: TitleStyle,
    parentRowStyle,
    render: ({ row }) => (
      <Typography style={{ defaultColumnStyle }}>
        {row.details[0] && `${row.details[0]?.commercial?.firstName} ${row.details[0]?.commercial?.lastName}`}
      </Typography>
    ),
  },
  {
    id: 'montantTtc',
    label: 'Montant TTC',
    style: TitleStyle,
    parentRowStyle,
    render: ({ row }) => (
      <Typography style={{ defaultColumnStyle }}>
        {row.amount}
      </Typography>
    ),
  },
];
