import React, { useEffect, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { checkLastGeneratedFile } from 'Components/Import/importServices';

const DownloadLastFile = ({ folderId, update }) => {
  const [lastFile, setLastFile] = useState({ isExist: false, url: null, label: '' });

  useEffect(async () => {
    const lastGenerated = await checkLastGeneratedFile(folderId);
    if (!!lastGenerated && lastGenerated.length > 0) {
      const urlObj = new URL(lastGenerated);
      const pathnameParts = urlObj.pathname.split('/');
      const filename = pathnameParts[pathnameParts.length - 1];
      // eslint-disable-next-line no-unused-expressions, no-new
      const date = new Date(filename.split('_')[1].split('.')[0]);
      setLastFile({
        isExist: true,
        url: lastGenerated,
        label: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
      });
    }
  }, [folderId, update]);

  if (!lastFile.isExist) return null;

  return (
    <Box
      sx={{
        cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', marginTop: '6px',
      }}
    >
      <Link
        href={lastFile.url}
        underline="none"
        target="_blank"
      >
        <Typography sx={{ font: 'normal 600 10px Montserrat', color: '#00B894' }}>
          <TaskOutlinedIcon style={{ fontSize: '10px' }} />
          Ventilation Douaniere -
          <span style={{ color: '#707070' }}>{lastFile.label}</span>
        </Typography>
      </Link>

    </Box>

  );
};

export default DownloadLastFile;
