import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    '&::placeholder': {
      font: 'normal normal 400 14px/18px Montserrat',
      color: '#AFB0B2',
      fontSize: 14,
      opacity: 1,
    },
  },
  filter: {
    marginRight: 16,
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    minWidth: 250,
  },
  Lastfilter: {
    minWidth: 200,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('xl')]: {
      display: 'flex',
    },
  },
  filterZone: {
    flexGrow: 1,
    display: 'flex',
    background: '#E9EBED 0% 0% no-repeat padding-box',
    borderRadius: 12,
    opacity: 1,
    padding: '10px 7px',
  },
  notched: {
    border: 'none',
  },
  label: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  icon: {
    color: '#0681cc !important',
    fontSize: 22,
  },
  nextButtonEnabled: {
    width: 46,
    height: 46,
    minWidth: 46,
    boxSizing: 'border-box',
    background:
      'transparent linear-gradient(213deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 10px #108CD650',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
  },
  nextButtonDisabled: {
    width: 46,
    height: 46,
    minWidth: 46,
    boxSizing: 'border-box',
    background: '#D7DFE4 0% 0% no-repeat padding-box',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#286183',
  },
  title: { font: 'normal normal 600 22px/29px Montserrat' },
  searchBtn: {
    borderRadius: 12, color: '#FFF', backgroundColor: '#007BC6', boxShadow: '0px 4px 10px #108CD650', textTransform: 'capitalize',
  },
}));

export default useStyles;
