const getName = (parents, id) => parents
  ?.find((parent) => parent.id === id)?.name;

const useFindObjectName = ({
  productsRef, isDoc, objectId, isClient, clientsRef, parents = [],
}) => {
  if (isClient) {
    // find client name from clients ref
    return { name: clientsRef?.find((p) => p.id === objectId)?.name };
  }
  const name = !isDoc
    ? productsRef?.find((p) => p.productId === objectId)?.reference
    : (`entête de ${getName(parents, objectId)}`);

  return { name };
};

export default useFindObjectName;
