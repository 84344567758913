import React, { useRef, useState } from 'react';

import {
  Paper,
  Box,
  Typography,
  Popper,
  Fade,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { costPrice } from 'Components/Import/importServices';
import { toast } from 'react-toastify';
import DialogCmp from 'Shared/Dialog';
import HelpIcon from '@mui/icons-material/Help';
import useOnClickOutside from 'Shared/Hooks/useClickOutSide';

const useStylesActions = makeStyles(() => ({
  action: {
    display: 'flex', padding: '10px 15px', cursor: 'pointer', margin: '3px 0',
  },
  actionLabel: {
    marginLeft: 5, color: '#38485C', font: 'normal 600 16px Montserrat', textWrap: 'nowrap',
  },
}));

const CalculateActions = ({
  isOpen, anchorEl, onClose, onHandleVentile, folderId, setLoading, folderName,
}) => {
  const [openValidation, setOpenValidation] = useState(false);
  const refActions = useRef();
  useOnClickOutside(refActions, onClose);
  const classes = useStylesActions();

  const onHandleCalculatePrixRevient = (e) => {
    e.stopPropagation();
    try {
      setLoading(true);
      costPrice(folderId);
      toast.success('Le prix de revient est calculé avec succès');
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
      setOpenValidation(false);
      onClose();
    }
  };
  return (
    <>
      <DialogCmp
        title=""
        endIcon={false}
        fullWidth={false}
        open={openValidation}
        handleClose={() => setOpenValidation(false)}
        onHandleSubmit={onHandleCalculatePrixRevient}
        labelKo="Non"
        labelOk="Oui"
      >
        <Box style={{ margin: '0 20px', marginTop: '-34px' }}>
          <Box sx={{
            borderRadius: '50%',
            width: '65px',
            height: '65px',
            backgroundColor: '#00B894',
            margin: 'auto',
            color: '#FFF',
            display: 'flex',
          }}
          >
            <HelpIcon fontSize="large" style={{ margin: 'auto' }} />
          </Box>
          <Typography sx={{
            marginTop: '20px',
            textAlign: 'center',
            font: 'normal 600 18px Montserrat',
          }}
          >
            Êtes-vous sûr calculé les prix de revient moyen pour ce document
            {' '}
            {folderName}
            {' '}
            ?
          </Typography>
        </Box>
      </DialogCmp>
      <Popper
        sx={{ zIndex: 1200 }}
        open={isOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        ref={refActions}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Box onClick={onHandleVentile} className={classes.action}>
                <Typography className={classes.actionLabel}>Ventilé Frais</Typography>
              </Box>
              <Box onClick={() => setOpenValidation(true)} className={classes.action}>
                <Typography className={classes.actionLabel}>Calculer Prix De Revient</Typography>
              </Box>
              <Box onClick={() => null} className={classes.action}>
                <Typography className={classes.actionLabel}>Calculer CMUP</Typography>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default CalculateActions;
