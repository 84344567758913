/* eslint-disable import/prefer-default-export */
export const SearchList = [
  { label: 'V.I.N.', value: 'vin' },
  { label: 'Référence', value: 'reference' },
];

export const INIT_FILTERS = {
  copimaFamily: null,
  copimaBrand: null,
  tecdocFamily: null,
  tecdocBrand: null,
};
