import React from 'react';
import { Button, Typography } from '@mui/material';
import { format } from 'date-fns';

/* eslint-disable import/prefer-default-export */
const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 10px Montserrat',
  letterSpacing: 0,
  color: '#707070',
  padding: '16px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 14px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
  marginLeft: 5,
};
export const columns = [
  {
    id: 'reference',
    label: 'Reference',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'date',
    label: 'Date',
    style: TitleStyle,
    render: ({ value }) => (
      <Typography style={{ ...defaultColumnStyle, mr: '9px' }} display="inline">
        {value && format(new Date(value), 'dd/MM/yyy')}
      </Typography>
    ),
  },
  {
    id: 'client',
    label: 'Client',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'type',
    label: 'Type',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'paymentMode',
    label: 'Moyen de paiment',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'expedition',
    label: 'Expedition',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'amount',
    label: 'Montant',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    render: ({ row }) => (
      <Button
        variant="contained"
        color="primary"
        onClick={row.affect}
        size="small"
        style={{
          background:
            'transparent linear-gradient(237deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
          boxShadow: '0px 4px 10px #108CD650',
          borderRadius: 12,
          font: 'normal normal 600 14px/18px Montserrat',
          letterSpacing: 0,
          color: '#FFFFFF',
          textTransform: 'capitalize',
          marginLeft: 8,
        }}
      >
        Assigner
      </Button>
    ),
  },
];
