import {
  secureDelete, secureGet, securePost, securePut,
} from 'config/apiConfig';
import { TYPES } from './NewDiscount/constants';

export const defaultMessage = 'Erreur système';

export const getDiscounts = async (sortParams, filters) => {
  let url = `/ci/api/discounts?page=${sortParams.page}&size=${sortParams.size}${
    sortParams.sort ? `&sort=${sortParams.sort}` : ''
  }`;
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      url += Array.isArray(filters[key])
        ? filters[key].reduce(
          (prev, curr) => `${prev}${!url?.includes('?') && !prev ? '?' : '&'}${key}=${curr}`,
          '',
        )
        : `${url?.includes('?') ? '&' : '?'}${key}=${filters[key]}`;
    }
  });
  const { data } = await secureGet(url);
  return data;
};

export const deleteDiscountsById = async (id) => {
  const { data } = await secureDelete(`/ci/api/discounts/${id}`);
  return data;
};

// service to add new discount form
export const addNewDiscount = async (form) => {
  // distruct form object to get only the required fields
  const {
    type, brand, family, reference, client, ...restForm
  } = form;

  if (type === TYPES[0]) {
    restForm.family = family.id;
  } else if (type === TYPES[1]) {
    restForm.brand = brand.id;
  } else {
    restForm.reference = reference.reference;
  }
  if (client) {
    restForm.client = client.code;
  }
  const url = '/ci/api/discounts/add';
  const { data } = await securePost(url, { ...restForm, remise: restForm.discount });
  return data;
};

export const updateNewDiscount = async (form) => {
  const {
    type, brand, family, reference, client, ...restForm
  } = form;

  if (type === TYPES[0] || family?.id) {
    restForm.family = family.id;
  }
  if (type === TYPES[1] || brand?.id) {
    restForm.brand = brand.id;
  }
  if (type === TYPES[2] || reference?.reference) {
    restForm.reference = reference.reference;
  }
  if (client) {
    restForm.client = client.code;
  }
  const url = `/ci/api/discounts/${restForm.id}`;
  const { data } = await securePut(url, { ...restForm, remise: restForm.discount });
  return data;
};
