import {
  Box, FilledInput, FormControl, InputLabel, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import ConfirmDialog from 'Shared/ConfirmDialog/ConfirmDialog';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '21.5px 16px 16px 16px',
    border: '1px solid #c5c5c5',
    borderRadius: 12,
  },
  btn: {
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    boxShadow: '0px 4px 10px #BEBEBE50',
    border: '1px solid #EBEDED',
    borderRadius: 12,
    padding: '9px 12px',
  },
  name: {
    color: '#1084C7',
    fontSize: 16,
    fontWeight: 600,
  },
  content: { color: '#2F3C6B', fontSize: 16, fontWeight: 600 },
  link: {
    position: 'absolute',
    left: 15,
    top: '100%',
    height: 65,
    width: 15,
    borderLeft: '1px solid #1084C7',
    borderBottom: '1px solid #1084C7',
  },
  commentActions: {
    position: 'absolute',
    right: 15,
    top: '35%',
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
}));

const CommentBody = ({
  comment, onHandleAnswer, handleDetele, isParent,
}) => {
  const classes = useStyles();
  return (
    <Box margin="7px 24px" style={{ position: 'relative' }}>
      <Box className={classes.root}>
        <Box style={{ display: 'flex' }}>
          <Box>
            <Typography display="inline" className={classes.name}>
              {`${comment.first_name} ${comment.last_name}`}
            </Typography>
            <Typography
              style={{
                color: '#777F9B',
                fontSize: 11,
              }}
            >
              {format(new Date(comment.created_on), 'HH:mm - dd/MM/yyyy')}
            </Typography>
          </Box>
          <Typography display="inline" className={classes.content}>
            {comment.content}
          </Typography>
        </Box>
        <Box style={{ alignSelf: 'center' }}>
          {isParent && (
            <Typography
              display="inline"
              style={{
                color: comment.anwser ? '#CED1DB' : '#38485C',
                marginRight: 14,
              }}
              className={classes.btn}
              onClick={!comment.anwser && onHandleAnswer(comment.commentId)}
            >
              Répondre
            </Typography>
          )}
          <Typography
            display="inline"
            style={{
              color: '#F86060',
            }}
            className={classes.btn}
            onClick={handleDetele(comment.commentId)}
          >
            Supprimer
          </Typography>
        </Box>
      </Box>
      {(comment.comments?.length > 0 || comment.anwser) && <Box className={classes.link} />}
    </Box>
  );
};

const Comment = ({
  comment,
  index, onHandleAnswer, onChangeValues, values, deleteComment, addComment, onHandleAbortAnswer,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [commentToDetele, setCommentToDelete] = useState(null);
  const handleCloseConfirmDialog = () => {
    setOpen(false);
  };
  const handleDetele = (commentId) => () => {
    setOpen(true);
    setCommentToDelete(commentId);
  };

  const handleConfirmDelete = () => {
    deleteComment(commentToDetele);
    handleCloseConfirmDialog();
  };

  return (
    <Box style={{ position: 'relative' }}>
      <CommentBody
        comment={comment}
        onHandleAnswer={onHandleAnswer}
        handleDetele={handleDetele}
        isParent
      />
      {comment.comments?.map((cc, subIndex) => (
        <Box key={cc.commentId} style={{ marginLeft: 30, marginTop: subIndex === 0 ? 10 : 0 }}>
          <CommentBody
            comment={cc}
            onHandleAnswer={onHandleAnswer}
            handleDetele={handleDetele}
          />
        </Box>
      ))}
      {comment.anwser && (
        <Box style={{ display: 'flex', margin: 16, marginLeft: 55 }}>
          <FormControl
            variant="filled"
            fullWidth
            style={{
              flexGrow: 1,
              marginRight: 10,
            }}
          >
            <InputLabel htmlFor="component-filled">Commentaire ...</InputLabel>
            <FilledInput
              id="component-filled"
              value={values[index]}
              onChange={onChangeValues(index)}
              multiline
              fullWidth
              rows={2}
              InputProps={{ disableUnderline: true }}
              style={{
                backgroundColor: '#e0e0e073',
                borderRadius: 4,
              }}
            />
            <Box className={classes.commentActions}>
              <Typography
                display="inline"
                style={{
                  color: '#38485C',
                  marginRight: 14,
                  backgroundColor: '#FFF',
                }}
                className={classes.btn}
                onClick={onHandleAbortAnswer(comment.commentId)}
              >
                Annuler
              </Typography>
              <Typography
                display="inline"
                style={{
                  color: '#FFF',
                  backgroundColor: '#007BC6',
                }}
                className={classes.btn}
                onClick={addComment(comment.commentId, values[index], true)}
              >
                Ajouter
              </Typography>
            </Box>
          </FormControl>
        </Box>
      )}

      {open && (
        <ConfirmDialog
          title="Suppresion du commentaire"
          content="Voulez vous vraiment supprimer ce commentaire ?"
          handleClose={handleCloseConfirmDialog}
          handleClick={handleConfirmDelete}
        />
      )}
    </Box>
  );
};

export default Comment;
