import React, { useCallback, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserContext from 'Shared/UserContext';
import { resetLogin } from 'Shared/UserContext/Actions';
import { authUrl } from 'Utils/authConfig';
import { getMyProfile } from './ProfileServies';

const Profile = ({ children }) => {
  const {
    dispatch,
    state: { status, errorMessage },
  } = useContext(UserContext);
  const history = useHistory();
  useEffect(() => {
    if (status === 'error') {
      dispatch(resetLogin());
      toast.error(errorMessage);
    }
  }, [status, errorMessage, dispatch]);
  const getProfile = useCallback(async (token) => {
    await getMyProfile({ dispatch, token, history });
  });
  const receiveMessage = (e) => {
    if (e.origin === authUrl) {
      localStorage.setItem('token', e.data.token);
      getProfile(e.data.token);
    }
  };
  useEffect(() => {
    window.addEventListener('message', receiveMessage);
  }, []);
  return <>{children}</>;
};
export default Profile;
