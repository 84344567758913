import React, { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {
  Box, CardActions, Chip, Divider,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ReferencialContext from 'Shared/ReferencialContext';
import DEFAULT_PRODUCT from 'Images/default_product.png';
import { SALES_CONDITIONS } from 'Components/ProductSettings/constants';

const useStyles = makeStyles({
  root: {
    width: 238,
    height: 340,
    margin: 10,
    // overflow: 'unset',
  },
  media: {
    height: 131,
    width: '100%',
  },
});

function RelatedProduct({ product, handleClick }) {
  const {
    state: {
      referencial: { families },
    },
  } = useContext(ReferencialContext);
  const classes = useStyles();
  const image = React.createRef();
  return (
    <Card className={classes.root}>
      <CardActionArea onClick={handleClick}>
        <Box style={{ position: 'relative' }}>
          <img
            ref={image}
            // eslint-disable-next-line no-return-assign
            onError={() => (image.current.src = DEFAULT_PRODUCT)}
            className={classes.media}
            src={`https://media.copima.ma/products/${product?.img || product?.image}`}
            alt={product?.fId || product?.familyId}
          />
          <div
            style={{
              position: 'absolute',
              top: 16,
              left: 16,
            }}
          >
            {(product?.iN === 1 || product?.isNew === 1) && (
              <div
                style={{
                  width: 'fit-content',
                  fontSize: 10,
                  textAlign: 'center',
                  color: 'white',
                  margin: 'auto',
                  backgroundColor: '#65C979',
                  boxShadow: '0px 4px 10px #108CD650',
                  font: '600 11px Montserrat',
                  borderRadius: 20,
                  marginBottom: 5,
                  padding: '3px 7px',
                }}
              >
                <p>NOUVEAU</p>
              </div>
            )}
            {(product?.iP === 1 || product?.isPromo === 1) && (
              <div
                style={{
                  height: 16,
                  width: 'fit-content',
                  fontSize: 10,
                  textAlign: 'center',
                  color: 'white',
                  background: '#EAC644 0% 0% no-repeat padding-box',
                  boxShadow: '0px 4px 10px #108CD650',
                  font: '600 11px Montserrat',
                  borderRadius: 20,
                  padding: '3px 7px',
                }}
              >
                <span>PROMO</span>
              </div>
            )}
          </div>
        </Box>
        <CardContent style={{ paddingBottom: 0 }}>
          <Tooltip
            title={
              product?.fId ? families.find((f) => product?.fId === f.id)?.name : product?.familyName
            }
          >
            <Typography
              style={{
                fontSize: 12,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                font: 'normal normal 600 14px/18px Montserrat',
                color: '#007BC6',
              }}
            >
              {product?.fId
                ? families.find((f) => product?.fId === f.id)?.name
                : product?.familyName}
            </Typography>
          </Tooltip>
          <Tooltip title={product?.des || product?.designation}>
            <Typography
              style={{
                color: '#000',
                fontSize: 14,
                height: 42,
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                font: 'normal normal 600 15px Montserrat',
              }}
            >
              {product?.des || product?.designation}
            </Typography>
          </Tooltip>
          <Box style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
            <Typography
              style={{
                color: '#000',
                fontSize: 15,
                flexGrow: 1,
                font: 'bold 14px Montserrat',
              }}
            >
              {`REF: ${product?.reference || product?.id}`}
            </Typography>
            {product.salesCondition && (
              <Chip
                size="small"
                label={
                  SALES_CONDITIONS.find((sc) => sc.value === product.salesCondition)?.label
                  + (product.gameQuantity ? ` (de ${product.gameQuantity})` : '')
                }
              />
            )}
          </Box>
          <Divider light />
        </CardContent>
      </CardActionArea>
      <CardActions style={{ padding: 16 }}>
        <Box style={{ width: '100%' }}>
          <Box style={{ display: 'flex', marginBottom: 13 }}>
            <Typography
              style={{
                flexGrow: 1,
                color: '#007BC6',
                font: 'normal normal 600 20px Montserrat',
              }}
            >
              {`${product?.p >= 0 ? product?.p : product?.price} DH`}
            </Typography>
          </Box>
          <Divider light />
          <Box style={{ display: 'flex' }}>
            <Box style={{ flexGrow: 1, display: 'flex' }}>
              {product?.sB > 0 || product?.stockBouskoura > 0 ? (
                <CheckCircleIcon
                  style={{
                    color: '#00b894',
                    fontSize: 30,
                    marginRight: 10,
                    marginTop: 2,
                  }}
                />
              ) : (
                <CancelIcon
                  style={{
                    color: '#f86060',
                    fontSize: 30,
                    marginRight: 10,
                    marginTop: 2,
                  }}
                />
              )}
              <div style={{ flexGrow: 1 }}>
                <Typography style={{ font: 'bold 12px Montserrat', color: '#000' }}>
                  Bouskoura
                </Typography>
                <Typography
                  style={{ color: '#007BC6', font: 'normal normal 600 14px/18px Montserrat' }}
                >
                  {Number.isInteger(product?.sB) ? product?.sB : product?.stockBouskoura}
                </Typography>
              </div>
            </Box>
            <Box style={{ flexGrow: 1, display: 'flex' }}>
              {product?.sD > 0 || product?.stockDerbOmar > 0 ? (
                <CheckCircleIcon
                  style={{
                    color: '#00b894',
                    fontSize: 30,
                    marginRight: 10,
                    marginTop: 2,
                  }}
                />
              ) : (
                <CancelIcon
                  style={{
                    color: '#f86060',
                    fontSize: 30,
                    marginRight: 10,
                    marginTop: 2,
                  }}
                />
              )}
              <div style={{ flexGrow: 1 }}>
                <Typography style={{ font: 'bold 12px Montserrat', color: '#000' }}>
                  Derb Omar
                </Typography>
                <Typography
                  style={{ color: '#007BC6', font: 'normal normal 600 14px/18px Montserrat' }}
                >
                  {Number.isInteger(product?.sD) ? product?.sD : product?.stockDerbOmar}
                </Typography>
              </div>
            </Box>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
}
export default RelatedProduct;
