/* eslint-disable max-len */
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Popper,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  Autocomplete,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import ListboxComponent from 'Shared/Header/AdapaterReactWindow';
import ReferencialContext from 'Shared/ReferencialContext';

export const DEFAULT_VALUES = {
  client: null,
  expedition: null,
  paymentMode: null,
  city: null,
  personalInfo: '',
  type: null,
  deposit: null,
  commercial: null,
};
export const DEPOSITS = ['Bouskoura', 'Derb Omar', 'Ain Sebaa', 'Cfcim'];
export const TYPES = [
  { id: 1, name: 'FACTURES' },
  { id: 2, name: 'DEVIS' },
  { id: 4, name: 'FACTURE HUILE' },
  { id: 5, name: 'DEVIS HUILE' },
];
const TitleStyle = {
  fontSize: '12px',
  fontWeight: 500,
  borderBottom: 'none',
  textTransform: 'uppercase',
  background: '#e4e7ea',
  font: 'normal normal 600 16px Montserrat;',
  letterSpacing: 0,
  color: '#707070',
  padding: '18px 8px',
};
const defaultColumnStyle = {
  font: 'normal normal 600 12px Montserrat',
  letterSpacing: 0,
  color: '#38485C',
};
const PopperMy = (props) => <Popper {...props} style={{ width: 250 }} placement="bottom-start" />;
export const mapFilterNameToProperty = (filterName) => {
  if (filterName?.includes('DESIGNATION')) {
    return 'des';
  }
  return 'reference';
};
export const mapFilterCondition = (type, option, v) => {
  switch (type) {
    case 'FIND_BY_DESIGNATION_CONTAIN':
      return option.des?.toLowerCase().includes(v.inputValue.toLowerCase());
    case 'FIND_BY_REFERENCE_CONTAIN':
      return option.reference?.toLowerCase().includes(v.inputValue.toLowerCase());
    default:
      return option.reference?.toLowerCase().startsWith(v.inputValue.toLowerCase());
  }
};

export const SEARCH_TYPES = [
  { id: 'FIND_BY_DESIGNATION_CONTAIN', label: 'Recherche par designation (contient)' },
  { id: 'FIND_BY_REFERENCE_CONTAIN', label: 'Recherche par référence (contient)' },
  { id: 'FIND_BY_REFERENCE_LEFT_TO_RIGHT', label: 'Recherche par référence (Left-to-right)' },
];
export const columns = [
  {
    id: 'reference',
    label: 'Réference',
    type: 'input',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    renderHeader1: ({
      value, classes, handleInputChange, inputRef,
    }) => {
      const {
        state: {
          referencial: { products },
        },
      } = useContext(ReferencialContext);
      const productFilterBy = localStorage.getItem('productFilterBy');
      const [activeType, setActiveType] = useState(productFilterBy);
      const [isActiveType, setIsActiveType] = useState(!!productFilterBy);
      const setType = (event) => {
        setActiveType(event.target.value);
        setIsActiveType(true);
        localStorage.setItem('productFilterBy', event.target.value);
        toast.success('Votre choix est enregistré');
      };
      const editType = () => {
        setIsActiveType(false);
      };
      const activeProducts = (products || []).filter((p) => p.iA);
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!isActiveType && (
            <FormControl variant="outlined" size="small" style={{ marginRight: 10, width: 100 }}>
              <InputLabel id="demo-simple-select-label">Type </InputLabel>
              <Select value={activeType} label="Type de Recherche" onChange={setType}>
                <MenuItem value="FIND_BY_DESIGNATION_CONTAIN">
                  Recherche par designation (contient)
                </MenuItem>
                <MenuItem value="FIND_BY_REFERENCE_CONTAIN">
                  Recherche par référence (contient)
                </MenuItem>
                <MenuItem value="FIND_BY_REFERENCE_LEFT_TO_RIGHT">
                  Recherche par référence (Left-to-right)
                </MenuItem>
              </Select>
            </FormControl>
          )}
          {isActiveType && (
            <IconButton onClick={editType} size="large">
              <EditIcon />
            </IconButton>
          )}
          <Autocomplete
            PopperComponent={PopperMy}
            classes={{ root: classes.ref, inputRoot: classes.input }}
            style={{ width: 100 }}
            options={activeProducts}
            onChange={handleInputChange}
            size="small"
            autoHighlight
            getOptionLabel={(option) => option[mapFilterNameToProperty(activeType)]}
            ListboxComponent={ListboxComponent}
            filterOptions={(options, v) => options.filter((option) => mapFilterCondition(activeType, option, v))}
            autoSelect
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={inputRef}
                label="Produit"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
            value={value || null}
          />
        </div>
      );
    },
    parentHeader: { width: 150, paddingLeft: 2 },
  },
  {
    id: 'quantity',
    type: 'input',
    label: 'Quantity',
    style: TitleStyle,
    handleChange: 'handleChange',
    render: ({ value, handleChange }) => (
      <FormControl size="small" variant="outlined" style={{ width: 100 }}>
        <InputLabel>Quantity</InputLabel>
        <OutlinedInput
          type="number"
          value={value || 0}
          onChange={handleChange}
          style={{ width: 100 }}
          inputProps={{ min: 0 }}
        />
      </FormControl>
    ),
    renderHeader1: ({
      value, handleInputChange, addElement, classes, inputRef, row,
    }) => (
      <FormControl size="small" variant="outlined" style={{ width: 100 }}>
        <InputLabel>Quantity</InputLabel>
        <OutlinedInput
          type="number"
          value={value || ''}
          onChange={handleInputChange}
          style={{ width: 100 }}
          inputProps={{ min: 1 }}
          onKeyDown={addElement(inputRef)}
          classes={{ input: classes.input }}
          disabled={!row.reference}
        />
      </FormControl>
    ),
    parentHeader: { width: 100, paddingLeft: 0 },
  },
  {
    id: 'des',
    label: 'Désignation',
    style: TitleStyle,
    handleChange: 'handleChange',
    render: ({ value, handleChange, row }) => (row.reference !== 'AUTRES' ? (
      <Tooltip title={value}>
        <Typography
          style={{
            fontSize: 14,
            maxHeight: 42,
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            ...defaultColumnStyle,
          }}
        >
          {value}
        </Typography>
      </Tooltip>
    ) : (
      <FormControl size="small" variant="outlined" style={{ width: 200 }}>
        <InputLabel>Description</InputLabel>
        <OutlinedInput
          type="text"
          value={value || ''}
          onChange={handleChange}
          style={{ width: 200 }}
        />
      </FormControl>
    )),
    renderHeader1: ({ value, classes }) => <span className={classes.title}>{value}</span>,
  },
  {
    id: 'stock',
    label: 'Stock',
    style: TitleStyle,
    render: ({ row: product }) => (
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Box style={{ flexGrow: 1, display: 'flex' }}>
          {product?.sB > 0 ? (
            <CheckCircleIcon
              style={{
                color: 'green',
                fontSize: 12,
                marginRight: 10,
                marginTop: 2,
              }}
            />
          ) : (
            <CancelIcon
              style={{
                color: 'ed8e99',
                fontSize: 12,
                marginRight: 10,
                marginTop: 2,
              }}
            />
          )}
          <div style={{ flexGrow: 1 }}>
            <Typography
              style={{ fontSize: 12, color: '#2F3C6B', marginRight: 10 }}
              display="inline"
            >
              Bouskoura
            </Typography>
            <Typography style={{ color: '#7988BD', fontSize: 12 }} display="inline">
              {Number.isInteger(product?.sB) ? product?.sB : product?.stockBouskoura}
            </Typography>
          </div>
        </Box>
        <Box style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          {product?.sD > 0 ? (
            <CheckCircleIcon
              style={{
                color: 'green',
                fontSize: 12,
                marginRight: 10,
                marginTop: 2,
              }}
            />
          ) : (
            <CancelIcon
              style={{
                color: 'ed8e99',
                fontSize: 12,
                marginRight: 10,
                marginTop: 2,
              }}
            />
          )}
          <div style={{ flexGrow: 1 }}>
            <Typography
              style={{ fontSize: 12, color: '#2F3C6B', marginRight: 10 }}
              display="inline"
            >
              Derb Omar
            </Typography>
            <Typography style={{ color: '#7988BD', fontSize: 12 }} display="inline">
              {Number.isInteger(product?.sD) ? product?.sD : product?.stockDerbOmar}
            </Typography>
          </div>
        </Box>
      </Box>
    ),
    renderHeader1: ({ value, classes }) => <span className={classes.title}>{value}</span>,
  },
  {
    id: 'r',
    label: 'Remise',
    style: TitleStyle,
    handleChange: 'handleChange',
    render: ({ value, handleChange, row }) => (row.editRemise || row.reference === 'AUTRES' ? (
      <FormControl size="small" variant="outlined" style={{ width: 100 }}>
        <InputLabel>Remise</InputLabel>
        <OutlinedInput
          type="number"
          value={value || 0}
          onChange={handleChange}
          style={{ width: 100 }}
          inputProps={{ min: 0 }}
        />
      </FormControl>
    ) : (
      <Typography style={defaultColumnStyle}>{value}</Typography>
    )),
    renderHeader1: ({ value, classes }) => <span className={classes.title}>{value}</span>,
  },
  {
    id: 'pn',
    label: 'P.U Net',
    style: TitleStyle,
    handleChange: 'handleChange',
    render: ({ row }) => (
      <Typography style={defaultColumnStyle}>
        {(row.p * ((100.0 - row.r) / 100.0))?.toFixed(2)}
      </Typography>
    ),
    renderHeader1: ({ value, classes }) => <span className={classes.title}>{value}</span>,
  },
  {
    id: 'p',
    label: 'P.U Brut',
    style: TitleStyle,
    handleChange: 'handleChange',
    render: ({ value, row, handleChange }) => (row.editBrutPrice || row.reference === 'AUTRES' ? (
      <>
        <FormControl size="small" variant="outlined" style={{ width: 100 }}>
          <InputLabel>P.U Brut</InputLabel>
          <OutlinedInput
            type="number"
            value={value || 0}
            onChange={handleChange}
            style={{ width: 100 }}
            inputProps={{ min: 0 }}
            error={parseFloat(row.pb) > parseFloat(value)}
          />
        </FormControl>
        {parseFloat(row.pb) > parseFloat(value) && (
        <p style={{ color: 'red', fontSize: 11, width: 100 }}>
          Attention le prix est saisi inférieur au prix de vente
        </p>
        )}
      </>
    ) : (
      <Typography style={defaultColumnStyle}>{value}</Typography>
    )),
    renderHeader1: ({ value, classes }) => <span className={classes.title}>{value}</span>,
  },
  {
    id: 'total',
    label: 'Montant Brut',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    renderHeader1: ({ value, classes }) => <span className={classes.title}>{value}</span>,
  },
  {
    id: 'totalNet',
    label: 'Montant net',
    style: TitleStyle,
    render: ({ value }) => <Typography style={defaultColumnStyle}>{value}</Typography>,
    renderHeader1: ({ value, classes }) => <span className={classes.title}>{value}</span>,
  },
  {
    id: 'mandatory',
    label: 'Obligatoire',
    style: TitleStyle,
    handleChange: 'handleChange',
    render: ({ value, handleChange }) => (
      <Switch
        checked={value}
        onChange={handleChange}
        color="primary"
        name="mandatory"
        value="mandatory"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    ),
    renderHeader1: ({ value, classes }) => <span className={classes.title}>{value}</span>,
  },
  {
    id: 'action',
    label: '',
    style: TitleStyle,
    actionHandler: 'handleRemoveArticle',
    render: ({ handleAction }) => (
      <IconButton
        aria-label="delete"
        onClick={handleAction}
        style={{
          background: '#F86060 0% 0% no-repeat padding-box',
          borderRadius: 12,
          color: 'white',
          width: 28,
          height: 32,
        }}
        size="large"
      >
        <DeleteIcon style={{ fontSize: '1.25rem' }} />
      </IconButton>
    ),
    renderHeader1: ({ value, classes }) => <span className={classes.title}>{value}</span>,
  },
];

export const PLAFOND_ESPECES = 5000;

export const isEmptyOrNull = (text) => !text || text.trim().length === 0;
