/* eslint-disable max-len */
import { Box, Button } from '@mui/material';
import React, {
  useState, useContext, useMemo, useEffect,
} from 'react';
import EnhancedTable from 'Shared/EnhancedTable';
import { withoutTime } from 'Utils/ulilities';
import ConfirmRemoveDialog from 'Shared/ConfirmDialog/ConfirmRemoveDialog';
import OrdersContext from '../Context';
import { columns } from './constants';
import { DEFAULT_VALUES } from '../NewOrder/constants';
import Filters from '../Filters';
import { saveDeletedOrders } from '../Actions';
import NewOrder from '../NewOrder';

const DeletedSalesOrders = () => {
  const {
    dispatch,
    state: { deletedOrders = [] },
  } = useContext(OrdersContext);
  const [values, setValues] = useState(DEFAULT_VALUES);
  const [openNewOrder, setOpenNewOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openConfirm, setOpenConfirm] = useState({ open: false });
  useEffect(() => {
    if (deletedOrders.length) {
      const diffDays = deletedOrders.filter((o) => (new Date() - o.date) / (1000 * 3600 * 24) > 3);
      if (diffDays && diffDays.length) {
        dispatch(
          saveDeletedOrders(
            deletedOrders.filter((o) => (new Date() - o.date) / (1000 * 3600 * 24) <= 3),
          ),
        );
      }
    }
  }, [deletedOrders, dispatch]);
  const handleInputChange = (prop) => (event, value) => {
    setValues({ ...values, [prop]: event instanceof Date ? event : value || event?.target.value });
  };
  const deleteOrder = (event) => {
    event.stopPropagation();
    dispatch(saveDeletedOrders(deletedOrders.filter((o) => o.id !== openConfirm.id)));
    setOpenConfirm({ open: false });
  };
  const confirmDeleteOrder = (id) => (event) => {
    event.stopPropagation();
    setOpenConfirm({ open: true, id });
  };
  const handleCloseNewOrderModal = () => {
    setSelectedOrder(null);
    setOpenNewOrder(false);
  };

  const handleOpenNewOrderModal = () => {
    setOpenNewOrder(true);
  };
  const handleEditOrder = (id) => () => {
    setSelectedOrder(deletedOrders.find((o) => o.id === id));
    handleOpenNewOrderModal();
  };
  const salesOrders = useMemo(
    () => deletedOrders
      .filter(
        (o) => (!values.client || values.client.code === o.client?.code)
            && (!values.expedition || values.expedition.code === o.expedition?.code)
            && (!values.paymentMode || values.paymentMode.code === o.paymentMode?.code)
            && (!values.city || values.city === o.city)
            && (!values.product
              || o.articles.map((a) => a.reference).includes(values.product?.reference))
            && (!values.startDate || withoutTime(values.startDate) <= withoutTime(o.date))
            && (!values.endDate || withoutTime(values.endDate) >= withoutTime(o.date)),
      )
      .map((o) => ({
        amount: o.articles
          ?.reduce((prev, curr) => prev + parseInt(curr.quantity, 10) * curr.p, 0)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$& '),
        client: o.client?.name,
        date: o.date,
        expedition: o.expedition?.name,
        paymentMode: o.paymentMode?.name,
        personalInfo: o.personalInfo,
        id: o.id,
        reference: o.id,
        type: o.type?.name,
        click: handleEditOrder(o.id),
        deleteOrder: confirmDeleteOrder(o.id),
      })),
    [deletedOrders, values],
  );
  return (
    <>
      {openNewOrder && <NewOrder handleClose={handleCloseNewOrderModal} order={selectedOrder} />}
      {openConfirm.open && (
      <ConfirmRemoveDialog
        title="Suppresion"
        content="Êtes-vous sûr de vouloir supprimer cette commande ?"
        handleClose={() => setOpenConfirm({ open: false })}
        handleClick={deleteOrder}
      />
      )}
      <Box style={{ display: 'flex', padding: 10, alignItems: 'center' }}>
        <Filters values={values} handleInputChange={handleInputChange} />
        <Button
          variant="contained"
          style={{ minWidth: '230px' }}
          color="primary"
          onClick={handleOpenNewOrderModal}
        >
          Ajouter une commande
        </Button>
      </Box>
      <EnhancedTable
        rows={salesOrders}
        headCells={columns}
        count={salesOrders.length}
        rowsPerPageOptions={[10, 15, 20]}
      />
    </>
  );
};

export default DeletedSalesOrders;
