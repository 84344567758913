import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  containerProducts: {
    padding: '20px 50px',
    overflowY: 'auto',
    height: 'calc(100vh - 104px)',
  },
  dialogContent: {
    backgroundColor: '#FFF',
  },
  paper: {
    borderRadius: 8,
    border: '1px solid rgba(42, 105, 188, 0.12)',
    margin: '10px 104px',
    padding: 0,
    backgroundColor: '#FFFFFF',
  },
  kpiContainer: {
    margin: '24px 104px',
  },
  tabs: {
    margin: '0 5px 30px 5px',
  },
  tab: {
    textTransform: 'initial',
    color: '#2F2F2F',
    marginRight: 17,
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'left',
  },
  container: {
    margin: '0 24px',
  },
  tabSelected: {
    color: '#2188CC !important',
  },
}));

export default useStyles;
