/* eslint-disable prefer-template */
import {
  Box,
  FilledInput,
  Button,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  getProductComments,
  addProductComment,
  deleteProductComments,
} from 'Components/Catalog/CatalogServices';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import { defaultMessage } from 'Shared/Referencial/ReferencialService';
import Comment from './Comment';

const Comments = ({ product, classes }) => {
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState([]);
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const handleChangeComment = (event) => {
    setComment(event.target.value);
  };
  const handleChangeValues = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleAnswer = (id) => () => {
    const commentList = [...comments];
    setComments(
      commentList.map((c) => ({
        ...c,
        anwser: c.commentId === id ? true : c.anwser,
      })),
    );
  };
  const handleAbortAnswer = (id) => () => {
    const commentList = [...comments];
    setComments(
      commentList.map((c) => ({
        ...c,
        anwser: c.commentId === id ? false : c.anwser,
      })),
    );
  };
  const getComments = useCallback(async () => {
    setLoading(true);
    try {
      const commentList = await getProductComments(product.id);
      setComments(commentList);
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  }, [product, setComments, setLoading]);
  const deleteComment = async (commentToDetele) => {
    setLoading(true);
    try {
      await deleteProductComments(commentToDetele);
      await getComments();
      toast.success('Le commentaire a été bien supprimé');
    } catch (e) {
      const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
      toast.error(statusLabel);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getComments();
  }, [getComments]);
  const addComment = (prentId, content, action) => async (event) => {
    if (event.key === 'Enter' || action) {
      try {
        setLoading(true);
        await addProductComment(product.id, prentId, content);
        toast.success('Le commentaire a été bien ajouté');
        await getComments();
        setValues({});
        setComment('');
      } catch (e) {
        const { response: { data: { statusLabel = defaultMessage } = {} } = {} } = e;
        toast.error(statusLabel);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <Box className={classes.commentContainer}>
      {loading && <GlobalLoader size="100vh" />}
      <Box className={classes.commentList}>
        {comments.map((c, index) => (
          <Comment
            key={c.commentId}
            comment={c}
            index={index}
            onHandleAnswer={handleAnswer}
            onHandleAbortAnswer={handleAbortAnswer}
            onChangeValues={handleChangeValues}
            values={values}
            deleteComment={deleteComment}
            addComment={addComment}
          />
        ))}
      </Box>
      <Box style={{ padding: '24px 24px 39px 24px' }}>
        <FormControl variant="filled" fullWidth>
          <InputLabel htmlFor="component-filled">Commentaire ...</InputLabel>
          <FilledInput
            id="component-filled"
            style={{
              backgroundColor: '#e0e0e073',
              borderRadius: 4,
              '& .MuiFilledInput-underline:before': { borderWidth: '0 !important' },
            }}
            disableUnderline={false}
            value={comment}
            onChange={handleChangeComment}
            onKeyPress={addComment(0, comment)}
            multiline
            rows={3}
            fullWidth
          />
        </FormControl>
      </Box>
      <Button
        color="primary"
        onClick={addComment(0, comment, true)}
        style={{
          background: '#007BC6',
          border: '1px solid #007BC6',
          borderRadius: 12,
          color: '#FFF',
          marginRight: 15,
          alignSelf: 'flex-end',
        }}
      >
        AJOUTER LE COMMENTAIRE
      </Button>
    </Box>
  );
};

export default Comments;
