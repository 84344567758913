/* eslint-disable import/prefer-default-export */
export const Fields = [
  {
    type: 'text',
    id: 'orderReference',
    label: 'Référence',
  },
  {
    disabled: true,
    type: 'autocomplete',
    id: 'commercial',
    label: 'Nom Commercial',
    listName: 'sales',
    filter: (option, value) => option.name?.toLowerCase().includes(value),
    optionLabel: (option) => option.name,
  },
  {
    type: 'number',
    id: 'packageNumber',
    label: 'Nombre de colis',
  },
  {
    type: 'text',
    id: 'declarationNum',
    label: 'Numero de declaration d’expedition',
  },
  {
    type: 'autocomplete',
    id: 'expedition',
    label: 'Mode d’expedition',
    listName: 'expeditions',
    filter: (option, value) => option.name?.toLowerCase().includes(value),
    optionLabel: (option) => option.name,
  },
  {
    disabled: true,
    type: 'autocomplete',
    id: 'paymentMode',
    label: 'Mode de paiement',
    listName: 'paymentModes',
    filter: (option, value) => option.name?.toLowerCase().includes(value),
    optionLabel: (option) => option.name,
  },
  {
    type: 'text',
    id: 'packedBy',
    label: 'Emballé par',
  },
];
