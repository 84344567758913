import { Box } from '@mui/material';
import ProductDetail from 'Components/Catalog/Products/ProductDetail';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeSelectIsLoading } from 'selectors';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import Header from 'Shared/Header';
import ReferencialContext, { setSelectedProduct } from 'Shared/ReferencialContext';
import SideBar from 'Shared/SideBar';

const Layout = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [product, setProduct] = React.useState(null);
  const isLoading = useSelector(makeSelectIsLoading());
  const {
    dispatch,
    state: {
      selectedProduct, referencial,
    },
  } = useContext(ReferencialContext);
  const handleClose = () => {
    setOpen(false);
    setProduct(null);
  };
  useEffect(() => {
    if (selectedProduct) {
      const { products = [] } = referencial;

      setProduct(
        typeof selectedProduct === 'string'
          ? products.find((p) => p.reference === selectedProduct)
          : selectedProduct,
      );
      setOpen(true);
      dispatch(setSelectedProduct(null));
    }
  }, [dispatch, selectedProduct, setProduct, setOpen]);
  return (
    <Box style={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      {open && <ProductDetail product={product} handleClose={handleClose} />}
      <SideBar />
      {isLoading && <GlobalLoader size="100vh" />}
      <Box flexGrow={1} style={{ overflow: 'hidden' }}>
        <Header />
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
