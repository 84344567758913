/* eslint-disable import/prefer-default-export */
export const PAYMENT_NATURES = [
  'C/R', 'C/CHQ', 'Chèque', 'Effet', 'Bon de caisse', 'Reçu bancaire', 'C/R', 'C/CHQ', 'C/EFF',
];
export const BANKS = [
  'Attijari Wafa Bank', 'Banque Populaire', 'Crédit du Maroc', 'BMCE', 'CIH', 'Société Générale', 'BMCI', 'Crédit Agricole', 'Umnia Bank', 'Bank of Africa', 'Barid BANK', 'Bank Assafa', 'Al akhdar BANK', 'CFG Banque', 'UMB',
];
export const Fields = [
  {
    type: 'date-no-time',
    id: 'startDate',
    label: 'Date de début',
  },
  {
    type: 'date-no-time',
    id: 'endDate',
    label: 'Date de fin',
  },
  {
    type: 'autocomplete',
    id: 'clientCode',
    label: 'Client',
    listName: 'clients',
    filter: (option, value) => option
      ?.code.toLowerCase().includes(value?.toLowerCase()) || option
      ?.name.toLowerCase().includes(value?.toLowerCase()),
    optionLabel: (option) => (option ? `${option?.code}: ${option.name}` : option),
    width: 204,
  },
  {
    type: 'autocomplete',
    id: 'type',
    label: 'Nature du règlement',
    listName: 'paymentNatures',
    filter: (option, value) => option
      ?.toLowerCase().includes(value?.toLowerCase()),
    optionLabel: (option) => option,
    width: 204,
  },
  {
    type: 'autocomplete',
    id: 'bank',
    label: 'Banque',
    listName: 'banks',
    filter: (option, value) => option
      ?.toLowerCase().includes(value?.toLowerCase()),
    optionLabel: (option) => option,
    width: 204,
  },
  {
    type: 'autocomplete',
    id: 'status',
    label: 'Statut du règlement',
    listName: 'paymentStatusList',
    filter: (option, value) => option.label
      ?.toLowerCase().includes(value?.toLowerCase()),
    optionLabel: (option) => option.label,
    width: 204,
  },
  {
    type: 'date-no-time',
    id: 'echeanceStartDate',
    label: 'Date Echéance début',
  },
  {
    type: 'date-no-time',
    id: 'echeanceEndDate',
    label: 'Date Echéance fin',
  },
];
