import {
  Box, Dialog, IconButton, Slide,
} from '@mui/material';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const FullScreenDialog = ({
  open, setOpen, goBack, children,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      style={{ '& .MuiDialog-paper': { backgroundColor: '#F8F8F8' } }}
    >
      <Box>
        <Box style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px 40px',
        }}
        >
          <IconButton
            edge="start"
            onClick={goBack || handleClose}
            aria-label="close"
            style={{ borderRadius: '50%', border: '1px solid #CCCCCC' }}
            size="large"
          >
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ borderRadius: '50%', border: '1px solid #CCCCCC' }}
            size="large"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box>
          {children}
        </Box>
      </Box>
    </Dialog>
  );
};

export default FullScreenDialog;
