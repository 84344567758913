import { secureGet, securePost, securePut } from 'config/apiConfig';

export const defaultMessage = 'Erreur système';

export const updateProductSettings = async (product) => {
  const url = '/ci/api/product/settings';
  const { data } = await securePost(url, product);
  return data;
};

export const linkProductSettings = async (reference, linkedReference) => {
  const url = `/ci/api/product/link?reference=${reference}&linkedReference=${linkedReference}`;
  const { data } = await securePost(url);
  return data;
};
export const unlinkProductSettings = async (reference) => {
  const url = `/ci/api/product/unlink?reference=${reference}`;
  const { data } = await securePost(url);
  return data;
};

export const editProductreferentialQuantities = async (product) => {
  const url = '/ci/api/editProduct/quantities';
  const { data } = await securePost(url, product);
  return data;
};

export const updateDeliveryDays = async (supplier) => {
  const url = '/ci/api/suppliers/';
  const { data } = await securePut(url, supplier);
  return data;
};

export const importProductQuantitiesByFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const url = '/ci/api/load-products-quantities';
  const { data } = await securePost(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

export const importSuppliersByFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const url = '/ci/api/suppliers/add';
  const { data } = await securePost(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return data;
};

export const getSuppliers = async (sortParams, filters = {}) => {
  let url = `/ci/api/suppliers/all?page=${sortParams.page}&size=${sortParams.size}${
    sortParams.sort ? `&sort=${sortParams.sort}` : ''
  }`;
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      url += Array.isArray(filters[key])
        ? filters[key].reduce(
          (prev, curr) => `${prev}${!url?.includes('?') && !prev ? '?' : '&'}${key}=${curr}`,
          '',
        )
        : `${url?.includes('?') ? '&' : '?'}${key}=${filters[key]}`;
    }
  });
  const { data } = await secureGet(url);
  return data;
};
