import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const SuccessDialog = ({
  handleClose, message, icon: Icon, validateButton, buttonTitle,
}) => (
  <Dialog
    onClose={handleClose}
    aria-labelledby="customized-dialog-title"
    maxWidth="sm"
    fullWidth
    open
    PaperProps={{
      style: {
        background: '#F9FAFC 0% 0% no-repeat padding-box',
        border: '1px solid #C5C5C5',
        borderRadius: 24,
      },
    }}
    BackdropProps={{
      style: {
        background: '#245283 0% 0% no-repeat padding-box',
        boxShadow: '0px 12px 26px #0000000D',
        opacity: 0.67,
      },
    }}
  >
    <DialogTitle style={{ display: 'flex', flexDirection: 'row-reverse', paddingBottom: 0 }}>
      <IconButton
        style={{
          background: '#C5C5C5 0% 0% no-repeat padding-box',
          borderRadius: 24,
          opacity: 0.53,
        }}
        size="small"
        onClick={handleClose}
      >
        <CloseIcon style={{ color: '#707070' }} />
      </IconButton>
    </DialogTitle>
    <DialogContent style={{ padding: '0 24px' }}>
      <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Box
          style={{
            background: '#00B894 0% 0% no-repeat padding-box',
            borderRadius: 200,
            width: 100,
            height: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon style={{ color: '#FFF', fontSize: 45 }} />
        </Box>
        <Typography
          style={{
            font: 'normal normal 500 28px Montserrat',
            color: '#000',
            width: '100%',
            textAlign: 'center',
            marginBottom: 25,
            marginTop: 25,
          }}
        >
          {message}
        </Typography>

        <Box
          style={{
            display: 'flex',
            columnGap: 15,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            style={{
              background:
                'transparent linear-gradient(243deg, #007BC6 0%, #1793DD 100%) 0% 0% no-repeat padding-box',
              boxShadow: '0px 4px 10px #108CD650',
              borderRadius: 12,
              marginBottom: 30,
              color: '#FFF',
              font: 'normal normal 600 18px Montserrat',
              textTransform: 'capitalize',
              width: 100,
            }}
            onClick={handleClose}
          >
            Ok
          </Button>
          {validateButton && (
            <Button
              variant="contained"
              style={{
                background: '#FFF',
                boxShadow: '0px 4px 10px #108CD650',
                borderRadius: 12,
                marginBottom: 30,
                color: '#000',
                font: 'normal normal 600 18px Montserrat',
                textTransform: 'capitalize',
              }}
              onClick={validateButton}
            >
              {buttonTitle}
            </Button>
          )}
        </Box>
      </Box>
    </DialogContent>
  </Dialog>
);

export default SuccessDialog;
