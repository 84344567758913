import React, { useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import GlobalLoader from 'Shared/GlobalLoader/GlobalLoader';
import useStyles from './style';

import UserContext from '../../Shared/UserContext';
import { loginUser } from './LoginService';
import { resetLogin } from '../../Shared/UserContext/Actions';

const Login = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    dispatch,
    state: { status, errorMessage },
  } = useContext(UserContext);
  useEffect(() => {
    if (status === 'error') {
      dispatch(resetLogin());
      toast.error(errorMessage);
    }
  }, [status, errorMessage, dispatch]);

  const [values, setValues] = React.useState({
    username: '',
    password: '',
  });
  const login = async () => {
    await loginUser(dispatch, values, history);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onSubmit = (e) => {
    e.preventDefault();
    login();
  };
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      {status === 'loading' && <GlobalLoader size="100vh" />}

      <Paper elevation={3} classes={{ root: classes.paper }}>
        <form noValidate autoComplete="off" onSubmit={onSubmit}>
          <Box marginTop="20px" height={48}>
            <div className={classes.borderCorner}>
              <Typography variant="h3" gutterBottom>
                SALES
              </Typography>
            </div>
          </Box>
          <Box marginTop="65px">
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              autoFocus
              margin="normal"
              value={values.username}
              onChange={handleChange('username')}
            />
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
              <OutlinedInput
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="medium"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )}
                labelWidth={70}
              />
            </FormControl>
          </Box>
          <Box marginTop="45px">
            <Button
              disabled={!values.password || !values.username}
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type="submit"
            >
              Se connecter
            </Button>
          </Box>
        </form>
      </Paper>
    </Grid>
  );
};

Login.propTypes = {};

export default Login;
