import React, { useEffect, useState } from 'react';
import Edit from '../Edit';
import View from '../View';
import { Fields } from './constants';

const LegalInformation = ({ client, getClient }) => {
  const [editMode, setEditMode] = useState(false);
  const toEditMode = () => {
    setEditMode(true);
  };
  const toViewMode = () => {
    setEditMode(false);
  };
  useEffect(() => {
    if (!editMode) {
      getClient();
    }
  }, [editMode]);
  return (
    <>
      {!editMode && <View fields={Fields} client={client} handleEditMode={toEditMode} />}
      {editMode && <Edit fields={Fields} handleViewMode={toViewMode} client={client} />}
    </>
  );
};

export default LegalInformation;
