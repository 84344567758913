import { useEffect, useState } from 'react';

export default function useLocalStorage(targetKey) {
  const [target, setTarget] = useState({});
  useEffect(() => {
    if (targetKey) {
      setTarget(JSON.parse(localStorage[targetKey] || '{}'));
    }
  }, [targetKey]);

  return [target];
}
