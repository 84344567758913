import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  relatedContainer: {
    height: 400,
    backgroundColor: '#F9F9F9',
    boxShadow: '0 2px 5px 0 rgba(20,27,56,0.1)',
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
  },
  commentContainer: {
    height: 400,
    backgroundColor: '#F9F9F9',
    boxShadow: '0 2px 5px 0 rgba(20,27,56,0.1)',
    overflowY: 'auto',
    paddingBottom: 15,
  },
  tabs: {
    width: '100%',
  },
  tab: {
    textTransform: 'initial',
    font: 'normal normal 600 12px Montserrat',
    color: '#707070',
  },
  tabWrapper: {
    flexDirection: 'row',
    justifyContent: 'left',
  },
  tabSelected: {
    color: '#38485C !important',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 4px 10px #BEBEBE50',
    border: '1px solid #EBEDED',
    borderRadius: 12,
  },
  input: {
    font: 'normal normal 600 26px Montserrat',
    padding: '4px 14px',
  },
  comment: {
    background: '#F7F7F7 0% 0% no-repeat padding-box',
    borderRadius: 12,
    color: '#000',
    font: 'normal normal 600 14px Montserrat',
  },
  notched: {
    border: 'none',
  },
  label: {
    top: -8,
    left: -4,
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
  rootQuantity: {
    borderRadius: 14,
  },
  labelSelect: {
    top: -10,
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
  },
}));

export default useStyles;
