import React, { useState } from 'react';
import { Paper, Tab, Tabs } from '@mui/material';
import useStyles from './style';
import Users from '../Users';
import Devices from '../Devices';
import Carts from '../Carts';
import Products from '../Products';
import Clients from '../Clients';

const SettingsTabs = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(1);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Paper elevation={0} classes={{ root: classes.paper }}>
      <Tabs
        value={tab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}
      >
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.tabSelected,
            wrapper: classes.tabWrapper,
          }}
          label="Gestion Des Utilisateurs"
          value={1}
        />
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.tabSelected,
            wrapper: classes.tabWrapper,
          }}
          label="Gestion Des Appareils"
          value={2}
        />
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.tabSelected,
            wrapper: classes.tabWrapper,
          }}
          label="Gestion Des Carts"
          value={3}
        />
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.tabSelected,
            wrapper: classes.tabWrapper,
          }}
          label="Gestion Des Produits"
          value={4}
        />
        <Tab
          classes={{
            root: classes.tab,
            selected: classes.tabSelected,
            wrapper: classes.tabWrapper,
          }}
          label="Gestion Des Clients"
          value={5}
        />
      </Tabs>
      {tab === 1 && <Users />}
      {tab === 2 && <Devices />}
      {tab === 3 && <Carts />}
      {tab === 4 && <Products />}
      {tab === 5 && <Clients />}
    </Paper>
  );
};

export default SettingsTabs;
