import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  labelDate: {
    font: 'normal normal 600 14px Montserrat',
    color: '#000',
    top: -13,
    left: 18,
  },
  date: {
    marginRight: 16,
    background: '#fff',
    // boxShadow: '0 2px 4px 0 rgba(0,0,0,0.17)',
    borderRadius: 12,
    opacity: 1,
    flexGrow: 1,
  },
  formControlDate: {
    marginTop: '4px !important',
    marginLeft: 10,
  },
  notched: { border: 'none' },
}));

export default useStyles;
